<div class="container">
    <div class="box box4">
        <h1 style="grid-column: span 4;">{{action.title}} - {{action!.hint}}</h1>
        <credit-list-cards style="grid-column: span 4;" *ngIf="processStep == 'init'" [cardIsDisabled]="cardIsDisabled" [displayedColumns]="columns" [cardSelected]="cardSelected"></credit-list-cards>
        <div *ngIf="processStep == 'otp1'" class="box box1">
            <div class="note-container">
                <mat-form-field appearance="fill" class="note">
                    <mat-label>NOTE</mat-label>
                    <textarea matInput rows="3" [(ngModel)]="context.actionVars.DOMI.noteValue" name="noteValue"></textarea>
                </mat-form-field>
            </div>
            <core-otp-request class="otp" [config]="otp1Config" (otpSuccess)="otpSuccess($event)" (otpFail)="otpFail($event)"></core-otp-request>
            <credit-list-notes style="grid-column: span 4;" [filterAction]="actionCode"></credit-list-notes>
        </div>

        <ng-container *ngIf="processStep == 'entry'" >
            <form>
                <div class="box box1">
                    <core-data-field-view label="Card Number"     type="text" [value]="creditSvc.card?.cardNumber"></core-data-field-view>
                    <core-data-field-view label="Card Sequence"   type="text" value="001"></core-data-field-view>
                    <core-data-field-view label="Block Code"      type="text" [value]="creditSvc.card?.blockCode"></core-data-field-view>
                    <core-data-field-view label="Last Maint User" type="text" value="j.gonzalez"></core-data-field-view>
                    <core-data-field-view label="Embossed Name"   type="text" [value]="creditSvc.card?.embossedName"></core-data-field-view>
                    <div></div>
                </div>
                <hr style="grid-column: span 4;">
            </form>

            <form [formGroup]="actionForm">
                <div class="box box1">
                    <div class="box box2" style="grid-column: span 2;">
                        <h3>Residential Address</h3>
                        <ng-container *ngFor="let field of fieldConfigs1 | keyvalue" class="ff">
                            <div *ngIf="field.value.type=='submit'" style="grid-column: span 4;">
                                <button mat-raised-button type="button" class="btn btn-primary" [disabled]="!validInput()">
                                    <mat-icon *ngIf="!validInput()">edit</mat-icon><mat-icon *ngIf="validInput()">search</mat-icon>Search
                                </button>
                            </div>
                            
                            <div *ngIf="field.value.type=='divider'" style="grid-column: span 4;"><hr></div>

                            <!-- <core-smart-select *ngIf="field.value.type=='smartselect'" class="smart-field" [options]="fieldOptions.get(field.key)" [config]="field.value" (selectChange)="change($event)"></core-smart-select> -->

                            <core-data-field-view *ngIf="field.value.type=='display'" label="{{field.value.title}}" type="text" [value]="field.value.name"></core-data-field-view>

                            <mat-form-field *ngIf="field.value.type=='date'" class="form-control" appearance="fill"><mat-label>{{field.value.title}}</mat-label><input matInput [matDatepicker]="picker"><mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle><mat-datepicker #picker></mat-datepicker></mat-form-field>
                            <mat-form-field *ngIf="field.value.type=='input'" appearance="fill">
                                <mat-label>{{ field.value.title }}</mat-label>
                                <input matInput 
                                [id]="field.value.name" 
                                [type]="field.value.format" 
                                class="form-control" 
                                [formControlName]="field.value.name" 
                                [placeholder]="field.value.placeholder"
                                autocomplete="off"
                                [readonly]="field.value.readonly"
                                >
                            </mat-form-field>
                            <ng-container  *ngIf="field.value.type=='zip'">
                                <mat-form-field appearance="fill">
                                    <mat-label>{{ field.value.title }}</mat-label>
                                    <input matInput #zip
                                    [id]="field.value.name" 
                                    [type]="field.value.format" 
                                    class="form-control" 
                                    [formControlName]="field.value.name" 
                                    [placeholder]="field.value.placeholder"
                                    autocomplete="off"
                                    [readonly]="field.value.readonly"
                                    ><button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="zipSearch(zip.value, field.key)">
                                        <mat-icon>search</mat-icon>
                                    </button>
                                </mat-form-field>   
                                <core-smart-select *ngIf="this.zips1.length > 0" class="smart-field" [options]="fieldOptions.get(field.key)" [config]="zipSearchFieldConfig1" (selectChange)="changeZip($event)"></core-smart-select> 
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="box box2" style="grid-column: span 2;">
                        <h3>Employer Address</h3>
                        <ng-container *ngFor="let field of fieldConfigs2 | keyvalue" class="ff">
                            <div *ngIf="field.value.type=='submit'" style="grid-column: span 4;">
                                <button mat-raised-button type="button" class="btn btn-primary" [disabled]="!validInput()">
                                    <mat-icon *ngIf="!validInput()">edit</mat-icon><mat-icon *ngIf="validInput()">search</mat-icon>Search
                                </button>
                            </div>
                            
                            <div *ngIf="field.value.type=='divider'" style="grid-column: span 4;"><hr></div>

                            <!-- <core-smart-select *ngIf="field.value.type=='smartselect'" class="smart-field" [options]="fieldOptions.get(field.key)" [config]="field.value" (selectChange)="change($event)"></core-smart-select> -->

                            <core-data-field-view *ngIf="field.value.type=='display'" label="{{field.value.title}}" type="text" [value]="field.value.name"></core-data-field-view>

                            <mat-form-field *ngIf="field.value.type=='date'" class="form-control" appearance="fill"><mat-label>{{field.value.title}}</mat-label><input matInput [matDatepicker]="picker"><mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle><mat-datepicker #picker></mat-datepicker></mat-form-field>
                            <mat-form-field *ngIf="field.value.type=='input'" appearance="fill">
                                <mat-label>{{ field.value.title }}</mat-label>
                                <input matInput 
                                [id]="field.value.name" 
                                [type]="field.value.format" 
                                class="form-control" 
                                [formControlName]="field.value.name" 
                                [placeholder]="field.value.placeholder"
                                autocomplete="off"
                                [readonly]="field.value.readonly"
                                >
                            </mat-form-field>
                            <ng-container  *ngIf="field.value.type=='zip'">
                                <mat-form-field appearance="fill">
                                    <mat-label>{{ field.value.title }}</mat-label>
                                    <input matInput #zip
                                    [id]="field.value.name" 
                                    [type]="field.value.format" 
                                    class="form-control" 
                                    [formControlName]="field.value.name" 
                                    [placeholder]="field.value.placeholder"
                                    autocomplete="off"
                                    [readonly]="field.value.readonly"
                                    ><button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="zipSearch(zip.value, field.key)">
                                        <mat-icon>search</mat-icon>
                                    </button>
                                </mat-form-field>   
                                <core-smart-select *ngIf="this.zips2.length > 0" class="smart-field" [options]="fieldOptions.get(field.key)" [config]="zipSearchFieldConfig2" (selectChange)="changeZip($event)"></core-smart-select> 
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </form>
        </ng-container>

        <div *ngIf="processStep == 'success'">
            <h3 style="grid-column: span 4;">
                <mat-icon color="primary">check_circle</mat-icon> Address Validation and Update for Card {{card.cardNumber}} / {{card.embossedName}} has been submitted.
            </h3>
        </div>
        <div class="box box1" style="grid-column: span 4;">
            <core-smart-button *ngIf="processStep == 'entry'" [config]="saveButtonConfig"></core-smart-button>    
            <core-smart-button *ngIf="processStep == 'success'" [config]="newButtonConfig"></core-smart-button>    
            <core-smart-button [config]="closeButtonConfig" color="warn"></core-smart-button>    
        </div>
        
    </div>  
</div>

