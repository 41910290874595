import { SnackBarService } from './../../../../core/services/snack-bar.service';
import { ButtonConfig, OtpConfig } from 'src/app/modules/core/entities/core';
import { CreditLocate, CreditCustomer, CreditLocateReq } from './../../../entities/credit.d';
import { VplService } from './../../../services/vpl.service';
import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { IncodeApiService } from '../../ext/incode/incode-api.service';

export interface CreditIdMethod {
  name: string,
  type: string,
  prompt?: string,
  value: string,
}

@Component({
  selector: 'credit-customer-identify',
  templateUrl: './identify.component.html',
  styleUrls: ['./identify.component.scss']
})
export class IdentifyComponent implements OnInit {

  @Input() cardNumber!: string;
  @Input() requiredChecks: number = 2;
  @Input() maxFailures: number = 2;
  // @Input() checkData: { embossedName?: string } = {};
  @ViewChild('responseValue') responseValue!: ElementRef<HTMLInputElement>;
  @ViewChild('responseIDType') responseIDType!: ElementRef<HTMLInputElement>;
  @Output() onSuccess = new EventEmitter();
  @Output() onFailure = new EventEmitter();

  public step = 0;
  public successCount: number = 0;
  public failCount: number = 0;
  public log: string[] = [];
  public callSelection: string = '';

  public selfie!: string;

  public compareButtonConfig: ButtonConfig = {
    type: 'text',
    id: '',
    name: '',
    title: 'Respond',
    hint: '',
    hideIfDisabled: false,
    click: function (): boolean {
      return false;
    },
    icon: function (): string {
      return 'verified_user';
    },
    disabled: function (): boolean {
      return false;
    }
  }
  public confirmButtonConfig: ButtonConfig = {
    type: 'text',
    id: '',
    name: '',
    title: 'Yes',
    hint: '',
    hideIfDisabled: false,
    click: function (): boolean {
      return false;
    },
    icon: function (): string {
      return 'verified_user';
    },
    disabled: function (): boolean {
      return false;
    }
  }

  public denyButtonConfig: ButtonConfig = {
    type: 'text',
    id: '',
    name: '',
    title: 'No',
    hint: '',
    hideIfDisabled: false,
    click: function (): boolean {
      return false;
    },
    icon: function (): string {
      return 'cancel';
    },
    disabled: function (): boolean {
      return false;
    }
  }

  private fnDisabledCallButton = () => {
    if (this.responseIDType && this.responseIDType.nativeElement.value.length > 0 && this.callSelection.length > 0) {
      return false;
    }
    return true;
  }

  private fnClickCallButton = () => {
    if (this.responseIDType && this.responseIDType.nativeElement.value.length > 0 && this.callSelection.length > 0) {
      // call verification API function
      let locate: CreditLocateReq = {
        cardNumber: this.cardNumber
      }
  
      this.creditSvc.verifyCustomerCall(locate, this.callSelection, this.responseIDType.nativeElement.value).subscribe((response: any) => {
        if (response.result == 'success') {
          this.log.push(`${this.idMethods[this.step].name} - PASS: ${this.idMethods[this.step].value}`);
          this.successCount +=1;
        } else {
          this.log.push(`${this.idMethods[this.step].name} - FAIL: ${this.idMethods[this.step].value}`);
          this.failCount += 1;  
        }
        this.checkConditions();
      });
    }
    return false;
  }

  public callButtonConfig: ButtonConfig = {
    type: 'text',
    id: '',
    name: '',
    title: 'Validar',
    hint: '',
    hideIfDisabled: false,
    click: this.fnClickCallButton,
    icon: function (): string {
      return 'verified_user';
    },
    disabled: this.fnDisabledCallButton
  }

  public otp1Config!: OtpConfig;

  public idMethods: CreditIdMethod[] = [];

  constructor( 
    public creditSvc: VplService,
    public icSvc: IncodeApiService,
    public snack: SnackBarService
    ) { }

  ngOnInit(): void {
    this.otp1Config = {
      email: this.creditSvc.customer?.contactEmail,
      cell: this.creditSvc.customer?.contactTelephoneCell,
      update: false,
      disabled: (() => { return false })
    }

    this.compareButtonConfig.click = () => {
      if (this.idMethods[this.step].value == this.responseValue.nativeElement.value) {
        this.log.push(`${this.idMethods[this.step].name} - PASS: ${this.idMethods[this.step].value}`);
        this.successCount += 1;
      } else {
        this.log.push(`${this.idMethods[this.step].name} - FAIL: ${this.idMethods[this.step].value}`);
        this.failCount += 1;
      }
      this.checkConditions();
      return false;
    }

    this.confirmButtonConfig.click = () => {
      this.log.push(`${this.idMethods[this.step].name} - PASS: ${this.idMethods[this.step].value}`);
      this.successCount += 1;
      this.checkConditions();
      return false;
    }

    this.denyButtonConfig.click = () => {
      this.log.push(`${this.idMethods[this.step].name} - FAIL: ${this.idMethods[this.step].value}`);
      this.failCount += 1;
      this.checkConditions();
      return false;
    }

    // this.callButtonConfig.click = () => {
    //   this.log.push(`${this.idMethods[this.step].name} - PASS: ${this.callSelection}: ${this.responseIDType}`);
    //   this.successCount += 1;
    //   this.checkConditions(); 
    //   return false;
    // }


    // Prepare Identify Component by getting the relevant challenges
    let locate: CreditLocateReq = {
      cardNumber: this.cardNumber
    }
    this.creditSvc.getCustomerIdMethods(locate).subscribe((response: any) => {
      this.idMethods = response;
    });

    this.selfie = "./../../../../../../assets/mm.webp";

  }

  otpSuccess($event: any) {
    this.log.push(`${this.idMethods[this.step].name} - PASS: ${this.idMethods[this.step].value}`);
    this.successCount +=1;
    this.checkConditions();
  }

  otpFail($event: any) {
    this.log.push(`${this.idMethods[this.step].name} - FAIL: ${this.idMethods[this.step].value}`);
    this.failCount +=1;
    this.checkConditions();
  }

  checkConditions() {
    if(this.successCount >= this.requiredChecks) {
      // Trigger success
      this.onSuccess.emit({
        result: 'success',
        log: this.log,
        step: this.step,
        successCount: this.successCount,
        failCount: this.failCount,
      });
    } else {
      if(this.failCount >= this.maxFailures) {
        // alert('Verification failed.');
        this.snack.openSnackBar('Verification failed.', 'OK');  
        // API Call to update customer/verificationFilure + 1
        this.step = 0;
        this.successCount = 0;
        this.failCount = 0;
        // Trigger Failure
        this.onFailure.emit({
          result: 'failure',
          log: this.log,
          step: this.step,
          successCount: this.successCount,
          failCount: this.failCount,
        });
      } else {
        this.step += 1;
      }
    }
  }


}
