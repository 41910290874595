import { ButtonFilterArgs } from './../entities/core.d';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'myFilter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], filter: { options: ButtonFilterArgs }): any {

    if (!filter || !items || !filter.options) {
      return items;
    }
    if (filter.options.type !== undefined) {
      items = items.filter(item => item.type != undefined && item.type.indexOf(filter.options.type) !== -1);
    }
    if (filter.options.favorite !== undefined) {
      items = items.filter(item => item.favorite != undefined && item.favorite == filter.options.favorite);
    }
    if (filter.options.txnType !== undefined) {
      items = items.filter(item => item.txnType != undefined && item.txnType.indexOf(filter.options.txnType) !== -1) ;
    }
    return items;
  }
}
