<div class="container">
    <div class="box box1">
        <h1 style="grid-column: span 4;">{{action.title}} - {{action!.hint}}</h1>
        <credit-list-cards *ngIf="processStep == 'init'"    style="grid-column: span 4;" [cardIsDisabled]="cardIsDisabled" [displayedColumns]="columns" [cardSelected]="cardSelected"></credit-list-cards>
        <credit-list-cards *ngIf="processStep == 'success'" style="grid-column: span 4;" [cardIsDisabled]="cardIsDisabled" [displayedColumns]="columns2" ></credit-list-cards>
    
        <div *ngIf="processStep == 'otp1'" class="box3">
            <mat-form-field appearance="fill" class="note">
                <mat-label>Comment for {{actionCode}} on {{currCard.cardNumber}}</mat-label>
                <textarea matInput rows="3" [(ngModel)]="context.actionVars.ACTI.noteValue" name="noteValue"></textarea>
            </mat-form-field>
        </div>
        <core-otp-request *ngIf="processStep == 'otp1'" [config]="otp1Config" (otpSuccess)="otpSuccess($event)" (otpFail)="otpFail($event)"></core-otp-request>
    </div>

    <div class="box box1">
        <core-smart-button *ngIf="processStep == 'success'" [config]="newButtonConfig"></core-smart-button> 
        <core-smart-button [config]="closeButtonConfig"></core-smart-button>    
    </div>
</div>