import { ContextService } from './../../services/context.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'credit-list-notes',
  templateUrl: './list-notes.component.html',
  styleUrls: ['./list-notes.component.scss']
})
export class ListNotesComponent implements OnInit {

  // @Output() selected = new EventEmitter();

  dataSource = this.context.actionVars.view.priorViews;
  displayedColumns: string[] = [ /*'select',*/ 'date', 'user', 'action', 'note' ];

  @Input() filterAction!: string;
  public filter!: { action: string};

  constructor(
    // public vplService: VplService,
    public context: ContextService
    ) { }

  ngOnInit(): void {
    this.filter = { action: this.filterAction };

  }

  // selectNote(id: string) {
  //   this.selected.emit(id);
  // }

}
