
<!-- <textarea matInput rows="3" [(ngModel)]="queues" name="noteValue"></textarea> -->
<!-- {{wf.queueId}} -->
<p>
    <input type="text" #addQueueName>
    <button (click)="addQueue(addQueueName.value)">Add Queue (by name)</button>
    <button (click)="addQueue(addQueueName.value, addQueueName.value)">Add Queue (by id)</button>
</p>
<p>
    <button (click)="refreshQueues()">Refresh</button>
</p>
<p>
    <textarea rows="15" cols="100" #state></textarea><br>
    <button (click)="state.value=getTemplate()">Get Template State</button>
    <input type="text" placeholder="queueId" #loadQueueId>
    <input type="text" placeholder="stateId" #loadStateId>
    <button (click)="testLoadState(loadQueueId.value, loadStateId.value)">Fetch State</button>
    
</p>
<ul>
    <li *ngFor="let q of queues"> 
        <button (click)="toggleQueueStatus(q.id, q.status)">{{toggleQueueLabel(q.status)}}</button>
        <button (click)="refreshQueueObjects(q.id)">Refresh</button>
        <button (click)="deleteQueue(q.id)">Delete</button>
        <button (click)="pushState(q.id, state.value)">Push State</button>
        {{q.name}} ({{q.id}}): {{q.status}} ({{q.items?.length}})
        <ul *ngIf="q.items?.length??0>0 && wf.queueId == q.id">
            <li *ngFor="let qo of queueObjects">
                <button (click)="getState(q.id, qo.stateId)">Fetch</button>
                <button (click)="actOnState(q.id, qo.stateId, 'update', state.value)">Update</button>
                <button (click)="actOnState(q.id, qo.stateId, 'delete')">Delete</button>
                {{qo.state?.type}},{{qo.state?.subType}}: {{qo.stateId}} ({{qo.state?.logId}})
            </li>
        </ul>
    </li>
</ul>
