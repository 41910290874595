import { TimerService, CoreTimer } from './../../services/timer.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

const stopFunc = (timer: CoreTimer) => { /*console.log('Timer ' + timer.id + ' stopped.'); console.log(timer);*/ }

@Component({
  selector: 'core-timer2',
  templateUrl: './timer2.component.html',
  styleUrls: ['./timer2.component.scss']
})
export class Timer2Component implements OnInit, OnDestroy {

  @Input() isRunning: boolean = false;
  @Input() timerId: string = '';

  timeSpent: number = 0;
  displayTime: string = '00:00';
  public id!: string;

  startTimer() {
    this.timerSvc.start(this.id);
  }

  pauseTimer() {
    this.timerSvc.pause(this.id);
  }

  resumeTimer() {
    this.timerSvc.resume(this.id);
  }

  stopTimer() {
    this.timerSvc.stop(this.id, stopFunc);
  }

  constructor(public timerSvc: TimerService) { }

  ngOnInit(): void {
    let actionData = {
      actionClass: "Action Code",
      creditCustomerNumber: "CUST12345",
      creditAccountNumber: "ACCT12345",
      creditCardNumber: "CARD12345",
    };

    this.timerSvc.create(this.isRunning, actionData, 
      (id: string) => { 
        // We get back the local ID for accessing the Map of timers in our local service
        this.id = id;
        this.timerSvc.start(id);

        // console.log('Timer ' + this.id + ' created.');

        setInterval(() => {
          this.timerSvc.getTimer(this.id, (timer: CoreTimer) => { 
            this.timeSpent = timer.elapsedSeconds;
            this.isRunning = timer.running;
          }, true);
    
          if (this.isRunning) {
            this.displayTime = new Date(this.timeSpent * 1000).toISOString().substring(14,19);
          }
          // console.log('displaytime = ' + this.displayTime);
    
        },1000)
    
      }, this.timerId
    );
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   console.log(changes);
  // }

  ngOnDestroy() {
    this.timerSvc.stop(this.id, stopFunc );
  }
}
