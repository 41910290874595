import { EnvironmentService } from 'src/app/services/environment.service';
import { Component, OnInit } from '@angular/core';
import { LoginUser } from 'src/app/modules/core/entities/core';
import { AuthService } from 'src/app/services/auth.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html', 
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public wfService: WorkflowService,
    public env: EnvironmentService
    ) { }

  ngOnInit(): void {
  }

  getNewTicket() {
    this.wfService.getNewTicket(this.authService.user.name);
  }

  getUser() {
    return this.authService.user;
  }


}
