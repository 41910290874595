import { VplService } from 'src/app/modules/credit/services/vpl.service';
import { CreditLocate } from './../../modules/credit/entities/credit.d';
import { LocateClientComponent } from './../../modules/credit/components/locate-client/locate-client.component';
import { VscapiService } from './../../modules/core/services/vscapi.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss']
})
export class TestPageComponent implements OnInit {

  public test = 1;

  constructor(
    public env: EnvironmentService,
    public vscapi: VscapiService,
    public vplService: VplService
  ) { }

  ngOnInit(): void {
  }
  
}
