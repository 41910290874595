<div *ngIf="this.context.actionVars.view.priorViews">
    <table mat-table [dataSource]="this.context.getNotes(filter)">
        <!-- <ng-container matColumnDef="select"                 ><th mat-header-cell *matHeaderCellDef>Select Plan     </th><td mat-cell class="center"   *matCellDef="let element"><button mat-raised-button (click)="selectPlan(element.id)" disabled>{{ element.id }}</button></td></ng-container> -->
        <ng-container matColumnDef="date"                   ><th mat-header-cell *matHeaderCellDef>Date            </th><td mat-cell class="odd"      *matCellDef="let element">{{ element.date   | date }}</td></ng-container>
        <ng-container matColumnDef="user"                   ><th mat-header-cell *matHeaderCellDef>User            </th><td mat-cell                  *matCellDef="let element">{{ element.user          }}</td></ng-container>
        <ng-container matColumnDef="action"                 ><th mat-header-cell *matHeaderCellDef>Action          </th><td mat-cell                  *matCellDef="let element">{{ element.action        }}</td></ng-container>
        <ng-container matColumnDef="note"                   ><th mat-header-cell *matHeaderCellDef>Note            </th><td mat-cell class="odd"      *matCellDef="let element">{{ element.note          }}</td></ng-container>
        <!-- <ng-container matColumnDef="openDate"               ><th mat-header-cell *matHeaderCellDef>Date Opened     </th><td mat-cell                  *matCellDef="let element">{{ element.openDate                | date             }}</td></ng-container>
        <ng-container matColumnDef="active"                 ><th mat-header-cell *matHeaderCellDef>Active          </th><td mat-cell class="odd center" *matCellDef="let element"><mat-icon *ngIf="element.active==true" color="primary">check_circle</mat-icon></td></ng-container>
        <ng-container matColumnDef="requiredPayment"        ><th mat-header-cell *matHeaderCellDef>Payment         </th><td mat-cell class="amt"      *matCellDef="let element">{{ element.requiredPayment         | number : '1.2-2' }}</td></ng-container>
        <ng-container matColumnDef="minimumRequiredPayment" ><th mat-header-cell *matHeaderCellDef>Minimum Payment </th><td mat-cell class="odd amt"  *matCellDef="let element">{{ element.minimumRequiredPayment  | number : '1.2-2' }}</td></ng-container>
        <ng-container matColumnDef="sec"                    ><th mat-header-cell *matHeaderCellDef>SEC             </th><td mat-cell                  *matCellDef="let element">{{ element.sec                                        }}</td></ng-container> -->
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
