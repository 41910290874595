import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import * as dayjs from 'dayjs';
import { ContextService } from '../../../services/context.service';
import { CbResult } from '../cb/cb-api.service';
import { icIdResult } from '../incode/incode';


export interface DialogData {
  selectedClientType: string;
  rfc: string;
  bin: string;
  openYear: string;
  dueDate: string;
  cardPaymentAmt: string;
}

@Component({
  selector: 'app-apply2',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss']
})
export class Apply2Component implements OnInit {

  constructor(
    public dialog: MatDialog, 
    public context: ContextService,
  ) {}
  
  clientFG: FormGroup = new FormGroup({}); // Collects the data from the Client Select Dialog

  public isLinear = true;
  public isEditable = false;
  
  public clientTypeSelected = false;
  public incodeDone = false;
  public cbDone = false;

  public stepLabel = {
    Identification: "Identificacion",
    PersonalData: "Datos Personales",
    AddressData: "Informacion Domicilaria",
    EmploymentData: "Informacion del Empleo",
    ReferenceData: "Referencias Personales",
    CreditBureau: "Autorizacion Buro",
    Application: "Solicidud",
    Done: "Fin",
  };

  public fgIdentification!: FormGroup;
  public fgPersonal!: FormGroup;

  ngOnInit(): void {
    // this.openSelectCustomerDialog();

    this.fgIdentification = new FormGroup({
      firstCtrl: new FormControl( '', Validators.required ),
      firstCtrl2: new FormControl( '', Validators.required ),
    });
    this.fgPersonal = new FormGroup({
      secondCtrl: new FormControl( '', Validators.required ),
    });
  }

  public icApp02Complete(icData: icIdResult, stepper: MatStepper) {

    this.context.customer = {
      firstName   : icData.inCodeResult?.id1OCR?.ocrData.name?.firstName ?? '',
      firstName2  : '',
      lastName    : icData.inCodeResult?.id1OCR?.ocrData.name?.maternalLastName ?? '',
      lastName2   : icData.inCodeResult?.id1OCR?.ocrData.name?.paternalLastName ?? '',
      dateOfBirth : dayjs(icData.inCodeResult?.id1OCR?.ocrData?.birthDate ?? 0).toDate(),
      gender      : icData.inCodeResult?.id1OCR?.ocrData?.gender ?? '',
      rfc         : icData.inCodeResult?.id1OCR?.ocrData?.personalNumber ?? '',
      nationality : icData.client.country ?? '',
      birthCountry: icData.client.birthCountry ?? '',
      curp        : icData.inCodeResult?.id1OCR?.ocrData?.curp ?? '',
      home: {
        streetName   : icData.inCodeResult?.id1OCR?.ocrData.addressFields?.street ?? '',
        streetNumber : '',
        zip          : icData.inCodeResult?.id1OCR?.ocrData.addressFields?.postalCode ?? '',
        suburb       : icData.inCodeResult?.id1OCR?.ocrData.addressFields?.colony ?? '',
        state        : icData.inCodeResult?.id1OCR?.ocrData.addressFields?.state ?? '',
        city         : icData.inCodeResult?.id1OCR?.ocrData.addressFields?.city ?? '',
        nearestCorner: '',
        duration     : '',
        homeType     : '',
        country      : 'MX',
      },
      employer: {
        department : '', 
        industry   : '', 
        name       : '', 
        phone      : '', 
        address    : {
          streetName   : '',
          streetNumber : '',
          zip          : '',
          suburb       : '',
          state        : '',
          city         : '',
          nearestCorner: '',
          duration     : '',
          homeType     : '',
          country      : '',
          },
      },
      references: [
        {
          name         : 'Benjamin Cordero',
          relationship : 'Hermano',
          cellPhone    : '+52 328 321 2222',
          homePhone    : '',
        },
        {
          name         : 'Ana Cordero',
          relationship : 'Hermana',
          cellPhone    : '+52 328 321 4444',
          homePhone    : '',
        },
        {
          name         : 'Harman Panang',
          relationship : 'Socio',
          cellPhone    : '+52 333 222 2999',
          homePhone    : '',
        }

      ] 
    }

    this.incodeDone = true;
    stepper.next();
  }

  public cbApp01Complete(cbData: CbResult, stepper: MatStepper) {
    this.cbDone = true;
    stepper.next();
  }







  openSelectCustomerDialog(): void {
    const dialogRef = this.dialog.open(Apply2Dialog, {
      width: '50%',
      data: { 
        selectedClientType: 'new',
      } ,
      disableClose: true, 
    });

    dialogRef.afterClosed().subscribe(result => {
      this.clientFG = result;
      this.clientTypeSelected = true;
    });
  }

}



@Component({
  selector: 'customer-select2-dialog',
  templateUrl: 'customer-select-dialog.html',
  styleUrls: ['customer-select-dialog.scss']
})
export class Apply2Dialog implements OnInit {
  
  ngOnInit() {
    this.myGroup = new FormGroup({
      selectedClientType:   new FormControl(this.data.selectedClientType, [Validators.required]),
      rfc:                  new FormControl(this.data.rfc, [Validators.required]),
      bin:                  new FormControl('', [Validators.required, Validators.min(this.binMin), Validators.max(this.binMax), Validators.maxLength(this.binMaxLength), Validators.minLength(this.binMinLength)]),
      openYear:             new FormControl('', [Validators.required]),
      dueDate:              new FormControl('', [Validators.required, Validators.min(18), Validators.max(100)]),
      cardPaymentAmt:       new FormControl('', [Validators.required]),
    });
  }

  constructor(
    public dialogRef: MatDialogRef<Apply2Dialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  checkFormValid() {
    switch (this.myGroup.controls.selectedClientType.value) {
      case 'new':
        return true;
        break;
      case 'refinance':
        if (this.myGroup.controls.rfc.errors)
          return false;
        return true;
        break;
      case 'guaranteed':
        if (this.myGroup.controls.bin.errors || this.myGroup.controls.openYear.errors || this.myGroup.controls.dueDate.errors || this.myGroup.controls.cardPaymentAmt.errors )
          return false;
        return true;
        break;
      default:
        return false;
        break;
    }
  }

  myGroup!: FormGroup;

  binMinLength = 3;
  binMin = 10;
  binMaxLength = 6;
  binMax = 900000;

}