<div class="container">
    <div class="box box1">
        <div style="grid-column: span 4;"><h1>Account Balances</h1><h3> {{ callDate | date }} - {{ callDate | date : 'shortTime' }}</h3></div>
        <core-data-field-view label="Short Name"      type="text" [value]="account.shortName"></core-data-field-view>
        <core-data-field-view label="Current Balance" type="text" [value]="account.balance       | currency"></core-data-field-view>
        <core-data-field-view label="Open To Buy"     type="text" [value]="account.otb           | currency"></core-data-field-view>
        <core-data-field-view label="Cycle Due"       type="text" [value]="account.cycleDue      | currency"></core-data-field-view>

        <core-data-field-view label="Memo Balance"    type="text" [value]="account.memoBalance   | currency"></core-data-field-view>
        <core-data-field-view label="Debit Balance"   type="text" [value]="account.debitBalance  | currency"></core-data-field-view>
        <core-data-field-view label="Credit Balance"  type="text" [value]="account.creditBalance | currency"></core-data-field-view>
        <core-data-field-view label="Memo Pay"        type="text" [value]="account.memoPay       | currency"></core-data-field-view>
    </div>
    <div class="box box2">
        <div style="grid-column: span 4;"><h3>Monetary</h3></div>
        <core-data-field-view label="Total Amount Due     " type="text" [value]="account.accountBalances?.totalAmtDue        | currency"></core-data-field-view>
        <core-data-field-view label="Last Payment Amount  " type="text" [value]="account.accountBalances?.lastPmtAmt         | currency"></core-data-field-view>
        <core-data-field-view label="Last Purchase Amount " type="text" [value]="account.accountBalances?.lastPurchaseAmt    | currency"></core-data-field-view>
        <core-data-field-view label="Credit Limit         " type="text" [value]="account.accountBalances?.creditLimit        | currency"></core-data-field-view>
        <core-data-field-view label="Last Credit Limit    " type="text" [value]="account.accountBalances?.lastCreditLimit    | currency"></core-data-field-view>
        <core-data-field-view label="High Balance         " type="text" [value]="account.accountBalances?.highBalance        | currency"></core-data-field-view>
        <core-data-field-view label="Total OTB + O/L      " type="text" [value]="account.accountBalances?.totalOtbOverLimit  | currency"></core-data-field-view>
        <core-data-field-view label="Total Disp?          " type="text" [value]="account.accountBalances?.totalDisp          | currency"></core-data-field-view>
        <core-data-field-view label="mindPlusTPD?         " type="text" [value]="account.accountBalances?.mindPlusTPD        | currency"></core-data-field-view>
        <core-data-field-view label="Total Current Due    " type="text" [value]="account.accountBalances?.totalCurrDue       | currency"></core-data-field-view>
        <core-data-field-view label="Payment Due          " type="text" [value]="account.accountBalances?.paymentDue         | currency"></core-data-field-view>
        <core-data-field-view label="Interest Free        " type="text" [value]="account.accountBalances?.interestFree       | currency"></core-data-field-view>
    </div>
    <div class="box box2">
        <div style="grid-column: span 4;"><h3>Dates</h3></div>
        <core-data-field-view label="Payment Due Date           " type="text" [value]="account.accountDates?.paymentDueDate            | date "></core-data-field-view>
        <core-data-field-view label="Last Payment Date          " type="text" [value]="account.accountDates?.lastPaymentDate           | date "></core-data-field-view>
        <core-data-field-view label="Last Purchase Date         " type="text" [value]="account.accountDates?.lastPurchaseDate          | date "></core-data-field-view>
        <core-data-field-view label="Credit Limit Date          " type="text" [value]="account.accountDates?.creditLimitDate           | date "></core-data-field-view>
        <core-data-field-view label="Last Credit Limit Date     " type="text" [value]="account.accountDates?.lastCreditLimitDate       | date "></core-data-field-view>
        <core-data-field-view label="Last Delinquency Date      " type="text" [value]="account.accountDates?.lastDelinquencyDate       | date "></core-data-field-view>
        <core-data-field-view label="Last Reclassification Date " type="text" [value]="account.accountDates?.lastReclassificationDate  | date "></core-data-field-view>
    </div>

    <div class="box box2">
        <div style="grid-column: span 4;"><h3>Delinquency</h3></div>
        <div style="grid-column: span 2;" class="delinquencyTable">
            <table mat-table [dataSource]="getAcctDelq()">
                <ng-container matColumnDef="delq"   ><th mat-header-cell *matHeaderCellDef>Delinquent Days   </th><td mat-cell *matCellDef="let element">{{ element.delq              }}</td></ng-container>
                <ng-container matColumnDef="amount" ><th mat-header-cell *matHeaderCellDef>Delinquent Amount </th><td mat-cell *matCellDef="let element">{{ element.amount | currency }}</td></ng-container>
                <ng-container matColumnDef="count"  ><th mat-header-cell *matHeaderCellDef>Number            </th><td mat-cell *matCellDef="let element">{{ element.count             }}</td></ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedDelqColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedDelqColumns;"></tr>
            </table>
        </div>
        <div style="grid-column: span 2;" class="delinquencyChart">
            <ng-container *ngIf="showAccountDelinquencyChart">
                <canvas baseChart
                    [type]="'line'"
                    [datasets]="this.AccountDelinquencyChart.chartDatasets"
                    [labels]="this.AccountDelinquencyChart.chartLabels"
                    [options]="delqChartOptions"
                >
                </canvas>
            </ng-container>
        </div>
    </div>

    <div class="box box2">
        <div style="grid-column: span 4;"><h3>24 Month Profile</h3></div>
        <div style="grid-column: span 4;" class="profileChart">
            <!-- <ngx-charts-line-chart
                [legend]="profileChartOptions.legend"
                [showXAxisLabel]="profileChartOptions.showXAxisLabel"
                [showYAxisLabel]="profileChartOptions.showYAxisLabel"
                [showGridLines]="profileChartOptions.showGridLines"
                [xAxis]="profileChartOptions.xAxis"
                [yAxis]="profileChartOptions.yAxis"
                [xAxisLabel]="profileChartOptions.xAxisLabel"
                [yAxisLabel]="profileChartOptions.yAxisLabel"
                [yAxisTickFormatting]="formatProfileYAxis"
                [timeline]="profileChartOptions.timeline"
                [results]="profileChartResults"
                (select)="onDLQSelect($event)"
                (activate)="onDLQActivate($event)"
                (deactivate)="onDLQDeactivate($event)"
                >
            </ngx-charts-line-chart> -->
            <ng-container *ngIf="showAccountProfileChart">
                <canvas baseChart 
                    [type]="'line'"
                    [datasets]="this.AccountProfileChart.chartDatasets"
                    [labels]="this.AccountProfileChart.chartLabels"
                    [options]="profileChartOptions"
                >
                </canvas>
            </ng-container>
        </div>
    </div>

    <div class="box box2">
        <div style="grid-column: span 4;"><h3>Collections</h3></div>
        <core-data-field-view label="Collection Date       " type="text" [value]="account.accountCollectionInfo?.collectionDate      | date "></core-data-field-view>
        <core-data-field-view label="Collection Type       " type="text" [value]="account.accountCollectionInfo?.collectionType             "></core-data-field-view>
        <core-data-field-view label="Cycle Due             " type="text" [value]="account.accountCollectionInfo?.cycleDue                   "></core-data-field-view>
        <core-data-field-view label="Collection Reason     " type="text" [value]="account.accountCollectionInfo?.collectionReason           "></core-data-field-view>
        <core-data-field-view label="RCY                   " type="text" [value]="account.accountCollectionInfo?.rcy                        "></core-data-field-view>
        <core-data-field-view label="Charge Off Date       " type="text" [value]="account.accountCollectionInfo?.chargeOffDate       | date "></core-data-field-view>
        <core-data-field-view label="Last Delinquency Date " type="text" [value]="account.accountCollectionInfo?.lastDelinquencyDate | date "></core-data-field-view>
    </div>

    <div class="box box2">
        <div style="grid-column: span 4;"><h3>Behavioral</h3></div>
        <core-data-field-view label="Credit Class       " type="text" [value]="account.accountBehavioralInfo?.creditClass             "></core-data-field-view>
        <core-data-field-view label="Application Source " type="text" [value]="account.accountBehavioralInfo?.appSource               "></core-data-field-view>
        <core-data-field-view label="CB                 " type="text" [value]="account.accountBehavioralInfo?.cb                      "></core-data-field-view>
        <core-data-field-view label="Monthly Balance    " type="text" [value]="account.accountBehavioralInfo?.monthlyBalance          "></core-data-field-view>
        <core-data-field-view label="Usage              " type="text" [value]="account.accountBehavioralInfo?.usage                   "></core-data-field-view>
        <core-data-field-view label="Block Code 1       " type="text" [value]="account.accountBehavioralInfo?.blockCode1              "></core-data-field-view>
        <core-data-field-view label="Block Code 2       " type="text" [value]="account.accountBehavioralInfo?.blockCode2              "></core-data-field-view>
        <core-data-field-view label="Last Credit Class  " type="text" [value]="account.accountBehavioralInfo?.lastCreditClass         "></core-data-field-view>
        <core-data-field-view label="Misc Source        " type="text" [value]="account.accountBehavioralInfo?.miscSource              "></core-data-field-view>
        <core-data-field-view label="NSF Payments       " type="text" [value]="account.accountBehavioralInfo?.numberNsfPayment        "></core-data-field-view>
        <core-data-field-view label="Write Off Day      " type="text" [value]="account.accountBehavioralInfo?.writeOffDay             "></core-data-field-view>
        <core-data-field-view label="Month Purchases    " type="text" [value]="account.accountBehavioralInfo?.monthPurchases          "></core-data-field-view>
        <core-data-field-view label="Date Opened        " type="text" [value]="account.accountBehavioralInfo?.openDate         | date "></core-data-field-view>
        <core-data-field-view label="Store Number       " type="text" [value]="account.accountBehavioralInfo?.storeNumber             "></core-data-field-view>
        <core-data-field-view label="Status             " type="text" [value]="account.accountBehavioralInfo?.status                  "></core-data-field-view>
    </div>
</div>
