import { Component, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatSelectChange } from '@angular/material/select';
import { ButtonConfig, SelectConfig } from 'src/app/modules/core/entities/core';
import { AuthService } from 'src/app/services/auth.service';
import { CreditCard } from '../../../entities/credit.d';
import { ContextService } from '../../../services/context.service';
import { VplService } from '../../../services/vpl.service';

@Component({
  selector: 'credit-ac-maintenance004',
  templateUrl: './ac-maintenance004.component.html',
  styleUrls: ['./ac-maintenance004.component.scss']
})
export class AcMaintenance004Component implements OnInit {

  @Input() actionCode: string ='ALCO';
  @ViewChildren('.autoclick') autoClick!: HTMLInputElement;
  @ViewChild('rfc') formRfc!: MatInput;
  @ViewChild('app') formAppNum!: HTMLInputElement;

  public action!: any;

  public processStep = 'init';
  
  locateForm!: FormGroup;


  
  public card!: CreditCard;

  public locateButtonConfig!: ButtonConfig;
  public newButtonConfig!: ButtonConfig;
  public closeButtonConfig!: ButtonConfig;

  public columns = [ 'select', 'cardNumber', 'expiryDate', /*'reg', 'blockCode', 'blockCodeDesc',*/ 'st', 'stDesc', /*'gp', 'gpDesc', 'tg', 'tgDesc',*/ 'embossedName' ];
  public currCard!: CreditCard;
  public cards!: CreditCard[];

  public cardSelected(_card: CreditCard): void {};
  public cardIsDisabled = (card: CreditCard) => { 
    if ( card.st != 'A' ) {
      return true;
    }
    return false;
  };

  callDate!: Date;

  actionForm!: FormGroup;
  fieldConfigs = new Map<string, SelectConfig>();
  fieldOptions = new Map();

  date1(n: number) {
    if (n==1) return new Date('2021/08/29');
    if (n==1) return new Date('2021/08/29');
    return new Date('2020/02/14');
  }

  activationConfig: SelectConfig = { type: "smartselect", id: "activation", name: "activation", title: "Activation Method", search: true, result: true , format: "array", readonly: false, required: true, placeholder: 'X', hint: 'Activation Method', icon: 'lightning' , hideIfEmpty: false };
  activationOptions: { id: string, label: string }[] = [
    { id: "WIZARD", label: "Card Wizard" },
    { id: "SEGURO", label: "Activacion Seguro PIF" },
    { id: "INVENT", label: "Actualization Inventario" },
    { id: "ENTREG", label: "Impresion Acuse de Entrega" },
  ];
  selectedActivation: string = '';

  constructor(
    public context: ContextService,
    public auth: AuthService,
    public creditSvc: VplService
    ) { }

  ngOnInit(): void {

    // this.context.actionVarsChange.subscribe((av) => {
    //   alert('change');
    //   alert(av);
    //   this.formRfc = av.ALCO.rfc;
    // })

    this.locateForm = new FormGroup({
      rfc       : new FormControl(' ', [Validators.required]),
      appNum    : new FormControl(' ', [Validators.required]),
    });
  





    this.action = this.creditSvc.getActionCode('id'+this.actionCode);

    // this.setupForm();

    this.callDate = this.creditSvc.callTimestamp;;

    let fnClickLocate = (): boolean => {
      // this.context.actionVars.view.priorViews.push( { date: new Date(), user: this.auth.user.name, action: 'EMBO', note: this.context.actionVars.EMBO.noteValue } );
      // this.context.actionVars.EMBO.noteValue = '';
      this.processStep = 'entry';
      return true;
    }

    let fnDisabledSave = () => { return !this.validInput();}

    this.locateButtonConfig = {
      id: '',
      name: '',
      hint: 'Submit',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickLocate,
      icon: function (): string {
        return 'check_circle';
      },
      disabled: fnDisabledSave
    }

    this.cardSelected = (card: CreditCard) => 
    {
      this.processStep = 'entry';
      this.currCard = card;
      this.actionForm.controls.cardNumber.setValue(card.cardNumber);
      this.actionForm.controls.cardSeq.setValue('001');
    }

    this.cardIsDisabled = (card: CreditCard) => { 
      if ( card.st != 'N' ) {
        return true;
      }
      return false;
    };

    let fnClickClose = (): boolean => {
      this.context.removeAction(this.actionCode, 'maintenance');
      return false;
    }

    this.closeButtonConfig = {
      id: '',
      name: '',
      hint: 'Close',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickClose,
      icon: function (): string {
        return 'cancel';
      },
      disabled: function (): boolean {
        return false;
      }
    }

    let fnClickNew = (): boolean => {
      this.processStep = 'init';
      return false;
    }

    this.newButtonConfig = {
      id: '',
      name: '',
      hint: 'New ' + this.actionCode,
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickNew,
      icon: function (): string {
        return 'verified_user';
      },
      disabled: function (): boolean {
        return false;
      }
    }
  }

  validInput() {
    return ( this.locateForm.controls.rfc.valid  && this.locateForm.controls.appNum.valid );
  }

  public popFromQR($event: any) {
    const urlParams = new URLSearchParams($event.data);

    this.locateForm.controls.rfc.setValue(urlParams.get('rfc') ?? '');
    this.locateForm.controls.appNum.setValue(urlParams.get('app') ?? '');
  }

  public locateSubmit() {
    this.processStep = 'entry';
    
  }

}
 