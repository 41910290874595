<div class="vs-page">
    <div class="card-container">
        <mat-card>
            <mat-card-title>
                Home
            </mat-card-title>
            <mat-card-subtitle>
                <h2>{{this.env.tenantName}} Credit Services</h2>
            </mat-card-subtitle>
            <mat-card-content>
                <core-login></core-login>
                <!-- <core-auth0-login></core-auth0-login>
                <core-auth0-user></core-auth0-user> -->
            </mat-card-content>
            <mat-card-actions>
                <!-- <button *ngIf="!authService.isLoggedIn" mat-menu-item routerLink="/login" routerLinkActive="active">
                    <mat-icon>login</mat-icon>Login
                </button>
                <button *ngIf="authService.isLoggedIn" mat-menu-item (click)="authService.logout()" routerLink="/home" routerLinkActive="active">
                    <mat-icon>logout</mat-icon>Logout
                </button> -->
                <button *ngIf="authService.isLoggedIn" mat-menu-item (click)="getNewTicket()" routerLink="/customer" routerLinkActive="active">
                    <mat-icon>face</mat-icon>Cliente
                </button>
            </mat-card-actions>
        </mat-card>

        <mat-card *ngIf="false">
            <mat-card-title>
                TEST
            </mat-card-title>
            <mat-card-subtitle>
                <h2>{{this.env.tenantName}} Credit Services</h2>
            </mat-card-subtitle>
            <mat-card-content>
                <core-auth-fbui [loginHome]="'home'" [loginTarget]="'customer'"></core-auth-fbui>
                <!-- <core-auth0-login></core-auth0-login>
                <core-auth0-user></core-auth0-user> -->
            </mat-card-content>
            <mat-card-actions>
                <!-- <button *ngIf="!authService.isLoggedIn" mat-menu-item routerLink="/login" routerLinkActive="active">
                    <mat-icon>login</mat-icon>Login
                </button>
                <button *ngIf="authService.isLoggedIn" mat-menu-item (click)="authService.logout()" routerLink="/home" routerLinkActive="active">
                    <mat-icon>logout</mat-icon>Logout
                </button> -->
                <button *ngIf="authService.isLoggedIn" mat-menu-item (click)="getNewTicket()" routerLink="/customer" routerLinkActive="active">
                    <mat-icon>face</mat-icon>Cliente
                </button>
            </mat-card-actions>
        </mat-card>

        <mat-card *ngIf="authService.isLoggedIn">
            <mat-card-title>
                Hola {{ this.getUser().firstName }} {{ this.getUser().lastName  }}!
            </mat-card-title>
            <mat-card-subtitle>
                <h2>Avisos Generales</h2>
            </mat-card-subtitle>
            <img mat-card-image>
            <mat-card-content>
                Messages...
            </mat-card-content>
            <mat-card-actions>
                <!-- <button  class="add-button" >Add to home screen</button> -->
            </mat-card-actions>
        </mat-card>
    </div>
</div>