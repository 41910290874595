import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CreditInsuranceProduct } from '../../entities/credit.d';
import { VplService } from '../../services/vpl.service';

@Component({
  selector: 'credit-list-insurance',
  templateUrl: './list-insurance.component.html',
  styleUrls: ['./list-insurance.component.scss']
})
export class ListInsuranceComponent implements OnInit {

  constructor(public credit: VplService) { }

  ngOnInit(): void {
    this.displayedColumnsInsurance = [ 'select', 'name', 'type', 'cover' /*, 'cost' */ ];
    this.credit.getInsuranceProducts().subscribe( data => { this.dataSourceInsurance = data; });
    this.needSig = () => {return (!this.gotSig && this.selectedProducts.length > 0)};
  }

  @Output() accepted = new EventEmitter();

  public dataSourceInsurance!: CreditInsuranceProduct[];
  public selectedProducts: CreditInsuranceProduct[] = [];
  public displayedColumnsInsurance!: string[];
  public insuranceCost = 0;
  public warrantyCost = 0;
  public continue = true;
  public gotSig = false;

  onAccept($event: any) {
    this.accepted.emit(this.selectedProducts);
  }

  needSig() { return false;};

  selectInsurance(change: MatCheckboxChange, element: any) {

    if (change.checked) {
      // add element
      this.selectedProducts.push(element);
    } else {
      // remove element
      this.selectedProducts.forEach((item, index) => {
        if (item.id == element.id) {
          this.selectedProducts.splice(index, 1)
        }
      })
    }
  }

  sigOK() {
    this.gotSig = true;
  }
  
}
