<div class="container">
    <div class="local-menu">
        <!-- <mat-selection-list #localMenu [multiple]="false" (selectionChange)="onSelection($event, localMenu.selectedOptions.selected)"> -->
        <mat-selection-list #localMenu [(ngModel)]="context.selectedOptionPerformance" [multiple]="false" (selectionChange)="onSelection($event, localMenu.selectedOptions.selected)">
            <mat-list-option *ngFor="let opt of menuOptions" [value]="opt.id" [(selected)]="opt.default">
                <div class="menu-item">
                    <mat-icon>{{opt.icon}}</mat-icon>
                    <div class="menu-spacer"></div>
                    {{opt.title}}
                    <div class="menu-spacer"></div>
                    <span [matBadge]="opt.badge" matBadgeOverlap="false" matBadgeColor="warn" matBadgePosition="after"></span>
                </div>
            </mat-list-option>
            <hr/>
        </mat-selection-list>                  
    </div>
    <div class="local-content">
        <div [ngSwitch]="context.selectedOptionPerformance![0]" style="padding:0px;">
            <ng-container *ngSwitchCase="'dashboard'"> <credit-performance-dashboard></credit-performance-dashboard></ng-container>
            <ng-container *ngSwitchCase="'division'">  <flow-stat-table01></flow-stat-table01> </ng-container>
            <ng-container *ngSwitchCase="'team'">  <credit-performance-team></credit-performance-team> </ng-container>
            <!-- <ng-container *ngSwitchCase="'cards'"   > <credit-account-cards>   </credit-account-cards>   </ng-container>
            <ng-container *ngSwitchCase="'plans'"   > <credit-account-plans>   </credit-account-plans>   </ng-container>
            <ng-container *ngSwitchCase="'account'" > <credit-account>         </credit-account>         </ng-container>
            <ng-container *ngSwitchCase="'history'" > <credit-account-history> </credit-account-history> </ng-container>
            <ng-container *ngSwitchCase="'promos'"  > <credit-account-messages></credit-account-messages></ng-container> -->
        </div>        
    </div>
</div>