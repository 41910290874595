
<table mat-table [dataSource]="dsQueues">
    <ng-container matColumnDef="name"                   ><th mat-header-cell *matHeaderCellDef>Task Name       </th><td mat-cell                  *matCellDef="let element">{{element.name          }}</td></ng-container>
    <ng-container matColumnDef="status"                 ><th mat-header-cell *matHeaderCellDef>Status          </th><td mat-cell                  *matCellDef="let element">{{element.status}}</td></ng-container>
    <!-- <ng-container matColumnDef="status"                 ><th mat-header-cell *matHeaderCellDef>Status          </th><td mat-cell                  *matCellDef="let element">{{element.status}} ({{element.items?.length ?? '--'}})</td></ng-container> -->
    <ng-container matColumnDef="select"                 ><th mat-header-cell *matHeaderCellDef>Select          </th><td mat-cell                  *matCellDef="let element"><core-smart-button [config]="cfgSelectQueue(element.id)"></core-smart-button></td></ng-container>
    <!-- <ng-container matColumnDef="select"                 ><th mat-header-cell *matHeaderCellDef>Select          </th><td mat-cell                  *matCellDef="let element"><core-smart-button [config]="getActionCode(element.action)"></core-smart-button></td></ng-container> -->

    <tr mat-header-row *matHeaderRowDef="queueColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: queueColumns;"></tr>
</table>

<button mat-raised-button type="button" class="btn btn-primary" (click)="refreshQueues()">
    <mat-icon>refresh</mat-icon> Actualizar
</button>

<div [hidden]="this.currentQueue == ''">
    <h2>{{this.currentQueue}}</h2>
    <table mat-table [dataSource]="dsTasks" matSort (matSortChange)="announceSortChange($event)">
        <!-- 'subType', 'addTimeStamp', 'status', 'step', 'card', 'account', 'select'  -->
        <ng-container matColumnDef="subType"       ><th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Action " >Action   </th><td mat-cell *matCellDef="let element"            >{{element.state.subType}}                                            </td></ng-container>
        <ng-container matColumnDef="addTimestamp"  ><th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Started" >Started  </th><td mat-cell *matCellDef="let element" class="odd">{{element.addTimestamp | date : 'short' }}                           </td></ng-container>
        <ng-container matColumnDef="status"        ><th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status " >Status   </th><td mat-cell *matCellDef="let element" [class]="statusClass(element.state.stateData.status)">{{element.state.stateData.status}}</td></ng-container>
        <ng-container matColumnDef="step"          ><th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Step   " >Step     </th><td mat-cell *matCellDef="let element" class="odd">{{element.state.stateData.step}}                                     </td></ng-container>
        <ng-container matColumnDef="account"       ><th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Account" >Account  </th><td mat-cell *matCellDef="let element"            >{{element.state.stateData.account}}                                  </td></ng-container>
        <ng-container matColumnDef="card"          ><th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Card   " >Card     </th><td mat-cell *matCellDef="let element" class="odd">{{element.state.stateData.card}}                                     </td></ng-container>
        <ng-container matColumnDef="select"        ><th mat-header-cell *matHeaderCellDef                                                         >Select   </th><td mat-cell *matCellDef="let element"><core-smart-button [config]="cfgSelectTask(element.stateId, this.currentQueue)"></core-smart-button></td></ng-container>

        <tr mat-header-row *matHeaderRowDef="taskColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: taskColumns;"></tr>
    </table>
</div>




