import { SnackBarService } from './../../../../core/services/snack-bar.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ButtonConfig, SelectConfig } from 'src/app/modules/core/entities/core';
import { AuthService } from 'src/app/services/auth.service';
import { CreditCard } from '../../../entities/credit.d';
import { ContextService } from '../../../services/context.service';
import { VplService } from '../../../services/vpl.service';

@Component({
  selector: 'credit-ac-maintenance003',
  templateUrl: './ac-maintenance003.component.html',
  styleUrls: ['./ac-maintenance003.component.scss']
})
export class AcMaintenance003Component implements OnInit {

  @Input() actionCode: string ='EMBO';

  public action!: any;

  public processStep = 'init';
  public card!: CreditCard;

  public saveButtonConfig!: ButtonConfig;
  public newButtonConfig!: ButtonConfig;
  public closeButtonConfig!: ButtonConfig;

  public columns = [ 'select', 'cardNumber', 'expiryDate', /*'reg', 'blockCode', 'blockCodeDesc',*/ 'st', 'stDesc', /*'gp', 'gpDesc', 'tg', 'tgDesc',*/ 'embossedName' ];
  public currCard!: CreditCard;
  public cards!: CreditCard[];

  public cardSelected(_card: CreditCard): void {};
  public cardIsDisabled = (card: CreditCard) => { 
    if ( card.st != 'A' ) {
      return true;
    }
    return false;
  };

  callDate!: Date;

  actionForm!: FormGroup;
  fieldConfigs = new Map<string, SelectConfig>();
  fieldOptions = new Map();

  date1(n: number) {
    if (n==1) return new Date('2021/08/29');
    if (n==1) return new Date('2021/08/29');
    return new Date('2020/02/14');
  }

  activationConfig: SelectConfig = { type: "smartselect", id: "activation", name: "activation", title: "Activation Method", search: true, result: true , format: "array", readonly: false, required: true, placeholder: 'X', hint: 'Activation Method', icon: 'lightning' , hideIfEmpty: false };
  activationOptions: { id: string, label: string }[] = [
    { id: "WIZARD", label: "Card Wizard" },
    { id: "SEGURO", label: "Activacion Seguro PIF" },
    { id: "INVENT", label: "Actualization Inventario" },
    { id: "ENTREG", label: "Impresion Acuse de Entrega" },
  ];
  selectedActivation: string = '';

  constructor(
    public context: ContextService,
    public auth: AuthService,
    public creditSvc: VplService,
    public snack: SnackBarService
    ) { }

  ngOnInit(): void {

    this.action = this.creditSvc.getActionCode('id'+this.actionCode);

    this.setupForm();

    this.callDate = this.creditSvc.callTimestamp;

    let fnClickSave = (): boolean => {
      this.context.actionVars.view.priorViews.push( { date: new Date(), user: this.auth.user.name, action: 'EMBO', note: this.context.actionVars.EMBO.noteValue } );
      this.context.actionVars.EMBO.noteValue = '';
      this.processStep = 'success';
      return true;
    }

    let fnDisabledSave = () => { return !this.validInput();}

    this.saveButtonConfig = {
      id: '',
      name: '',
      hint: 'Submit',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickSave,
      icon: function (): string {
        return 'check_circle';
      },
      disabled: fnDisabledSave
    }

    this.cardSelected = (card: CreditCard) => 
    {
      this.processStep = 'entry';
      this.currCard = card;
      this.actionForm.controls.cardNumber.setValue(card.cardNumber);
      this.actionForm.controls.cardSeq.setValue('001');
    }

    this.cardIsDisabled = (card: CreditCard) => { 
      if ( card.st != 'N' ) {
        return true;
      }
      return false;
    };

    let fnClickClose = (): boolean => {
      this.context.removeAction(this.actionCode, 'maintenance');
      return false;
    }

    this.closeButtonConfig = {
      id: '',
      name: '',
      hint: 'Close',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickClose,
      icon: function (): string {
        return 'cancel';
      },
      disabled: function (): boolean {
        return false;
      }
    }

    let fnClickNew = (): boolean => {
      this.processStep = 'init';
      return false;
    }

    this.newButtonConfig = {
      id: '',
      name: '',
      hint: 'New ' + this.actionCode,
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickNew,
      icon: function (): string {
        return 'verified_user';
      },
      disabled: function (): boolean {
        return false;
      }
    }
  }

  private setupForm() {

    this.actionForm = new FormGroup({
      cardholderFlag       : new FormControl('0'),
      cardStatus           : new FormControl('A'),
      transferCardNumber   : new FormControl('1234567890123456789'),
      cardStatusDate       : new FormControl('2019/07/15'),
      cardStatusUser       : new FormControl('j.gonzalez'),
      lastMaintDate        : new FormControl('2022/01/10'),
      cardProduct          : new FormControl(''),
      maxFrequency         : new FormControl('3000.00'),
      retailPurchaseAmount : new FormControl('450.00'),
      cardExpiryDate       : new FormControl('02/22'),
      reasonForIssue       : new FormControl(''),
      retailPurchaseNumber : new FormControl('1'),
      atmCashAmount        : new FormControl('900.00'),
      lastCardIssue        : new FormControl('2019/07/15'),
      statusSms            : new FormControl('1'),
      atmCashNumber        : new FormControl('3'),
      otcCashAmount        : new FormControl('840.00'),
      cardActivationDate   : new FormControl('2019/08/01'),
      cellphoneNumber      : new FormControl('+528913328328'),
      otcCashNumber        : new FormControl('1000.00'),
      txnLimitAtm          : new FormControl('500.00'),
      lastCardExpDateAct   : new FormControl('2019/07/15'),
      amountSms            : new FormControl('1'),
      txnLimitOtc          : new FormControl('800.00'),
      txnLimitRetail       : new FormControl('1200.00'),
    });

    this.fieldConfigs.set("01cardholderFlag"      , { type: "input", id: "cardholderFlag"       , name: "cardholderFlag"      , title: "Cardholder Flag"           , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("02cardStatus"          , { type: "input", id: "cardStatus"           , name: "cardStatus"          , title: "Card Status"               , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("03transferCardNumber"  , { type: "input", id: "transferCardNumber"   , name: "transferCardNumber"  , title: "Transfer CardNumber"       , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("04cardStatusDate"      , { type: "input", id: "cardStatusDate"       , name: "cardStatusDate"      , title: "Card Status Date"          , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("05cardStatusUser"      , { type: "input", id: "cardStatusUser"       , name: "cardStatusUser"      , title: "Card Status User"          , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("06lastMaintDate"       , { type: "input", id: "lastMaintDate"        , name: "lastMaintDate"       , title: "Last Maintenance Date"     , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("07cardProduct"         , { type: "input", id: "cardProduct"          , name: "cardProduct"         , title: "Card Product"              , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("08maxFrequency"        , { type: "input", id: "maxFrequency"         , name: "maxFrequency"        , title: "Maximum Frequency"         , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("09retailPurchaseAmount", { type: "input", id: "retailPurchaseAmount" , name: "retailPurchaseAmount", title: "Retail Purchase Amount"    , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("10cardExpiryDate"      , { type: "input", id: "cardExpiryDate"       , name: "cardExpiryDate"      , title: "Current Expiry Date"       , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("11reasonForIssue"      , { type: "input", id: "reasonForIssue"       , name: "reasonForIssue"      , title: "Reason For Issue"          , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("12retailPurchaseNumber", { type: "input", id: "retailPurchaseNumber" , name: "retailPurchaseNumber", title: "Retail Purchase Number"    , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("13atmCashAmount"       , { type: "input", id: "atmCashAmount"        , name: "atmCashAmount"       , title: "ATM Cash Amount"           , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("14lastCardIssue"       , { type: "input", id: "lastCardIssue"        , name: "lastCardIssue"       , title: "Last Card Issue"           , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("15statusSms"           , { type: "input", id: "statusSms"            , name: "statusSms"           , title: "Status SMS"                , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("16atmCashNumber"       , { type: "input", id: "atmCashNumber"        , name: "atmCashNumber"       , title: "ATM Cash Number"           , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("17otcCashAmount"       , { type: "input", id: "otcCashAmount"        , name: "otcCashAmount"       , title: "OTC Cash Amount"           , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("18cardActivationDate"  , { type: "input", id: "cardActivationDate"   , name: "cardActivationDate"  , title: "Card Activation Date"      , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("19cellphoneNumber"     , { type: "input", id: "cellphoneNumber"      , name: "cellphoneNumber"     , title: "Mobile Number"             , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("20otcCashNumber"       , { type: "input", id: "otcCashNumber"        , name: "otcCashNumber"       , title: "OTC Cash Number"           , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("21txnLimitAtm"         , { type: "input", id: "txnLimitAtm"          , name: "txnLimitAtm"         , title: "Transaction Limit ATM"     , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("22lastCardExpDateAct"  , { type: "input", id: "lastCardExpDateAct"   , name: "lastCardExpDateAct"  , title: "Last Expiry Date Act"      , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("23amountSms"           , { type: "input", id: "amountSms"            , name: "amountSms"           , title: "Amount SMS"                , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("24txnLimitOtc"         , { type: "input", id: "txnLimitOtc"          , name: "txnLimitOtc"         , title: "Transaction Limit OTC"     , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("25txnLimitRetail"      , { type: "input", id: "txnLimitRetail"       , name: "txnLimitRetail"      , title: "Transaction Limit Retail"  , search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
  }

  validInput() {
    return ( this.selectedActivation != '' && this.context.actionVars.EMBO.noteValue != '');
  }

  locateAction() { 
    this.snack.openSnackBar(`Action ${this.action.title} submitted`, 'OK');  
  }

  change($event: MatSelectChange) {
    this.selectionChange($event.source.id, $event.value);
  }

  selectionChange( fieldName: string, value: any ) {
    switch(fieldName) {
      case "activation": 
        this.selectedActivation = value;
        this.actionForm.controls.firstName.setValue(this.creditSvc.client?.firstName);
        this.actionForm.controls.lastNamePaternal.setValue(this.creditSvc.client?.lastNamePaternal);
        this.actionForm.controls.lastNameMaternal.setValue(this.creditSvc.client?.lastNameMaternal);
        this.actionForm.controls.uniqueClient.setValue(this.creditSvc.client?.id);
        break;
    }
  }

  // selectAccount(selected: string) {
  //   this.selectionChange('accounts', selected);
  //   this.selectedAccount.emit({ account: this.vplService.account });
  // }


}
