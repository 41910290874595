
<div mat-dialog-content color="primary">
    <div [formGroup]="myGroup">
        <mat-grid-list cols="3" rowHeight="2em">
            <mat-grid-tile colspan=3 rowspan=2>
                <h2>Mensaje <!-- {{ data.selectedClientType }} --></h2>
            </mat-grid-tile>
            <mat-grid-tile colspan=3 rowspan=1>
                <p>Seleccione el tramite que desa realizar</p>
            </mat-grid-tile>
            <mat-radio-group name="clientType" formControlName="selectedClientType">
                <mat-grid-tile colspan=1 rowspan=2>
                    <mat-radio-button value="new">Cliente<br>Nuevo</mat-radio-button>
                </mat-grid-tile> 
                <mat-grid-tile colspan=1 rowspan=2>
                    <mat-radio-button value="refinance">Renovacio&#769;n<br>de Cre&#769;dito</mat-radio-button>
                </mat-grid-tile>
                <mat-grid-tile colspan=1 rowspan=2 [style.background]="'primary'">
                    <mat-radio-button value="guaranteed">Cre&#769;dito<br>Garantizado</mat-radio-button>
                </mat-grid-tile>
            </mat-radio-group> 
        </mat-grid-list>
        <main *ngIf="myGroup.controls.selectedClientType.value == 'refinance'"  style="grid-template-columns: 30% 30% 30%;">
            <mat-form-field appearance="fill">
                <mat-label>RFC</mat-label>
                <input matInput formControlName="rfc" required>
            </mat-form-field>
        </main>
        <main *ngIf="myGroup.controls.selectedClientType.value == 'guaranteed'" style="grid-template-columns: 50% 50%;">
            <mat-form-field appearance="fill">
                <mat-label>BIN</mat-label>
                <input matInput name="bin" formControlName="bin" required max=999999>
                <mat-error *ngIf="myGroup.controls.bin.errors?.required">
                    Se requiere valor. 
                </mat-error>  
                <mat-error *ngIf="myGroup.controls.bin.errors?.min || myGroup.controls.bin.errors?.max">
                    BIN minima es {{ binMin }}, maxima es {{ binMax }}
                </mat-error>            
                <mat-error *ngIf="myGroup.controls.bin.errors?.minLength || myGroup.controls.bin.errors?.maxLength">
                    BIN minima es {{ binMinLength }}, maxima es {{ binMaxLength }}
                </mat-error>            
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>An&#771;o de Apertura</mat-label>
                <input matInput name="openYear" formControlName="openYear" required>
            </mat-form-field>
            <mat-form-field appearance="fill" class="datepicker-form-field">
                <mat-label>Fecha de vencimiento</mat-label>
                <input matInput name="dueDate" [matDatepicker]="datepicker" formControlName="dueDate" required min="10/10/2021" autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker>
                    <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                    </mat-datepicker-actions>
                </mat-datepicker>
                <mat-error *ngIf="myGroup.controls.dueDate.value == ''">
                    Se requiere valor.
                </mat-error>  
            </mat-form-field>
                
            <mat-form-field appearance="fill">
                <mat-label>Monto de Pagar con Tarjeta</mat-label>
                <input matInput name="cardPaymentAmt" formControlName="cardPaymentAmt" required>
            </mat-form-field>
        </main> 
        <mat-grid-list cols="3" rowHeight="2em">
            <mat-grid-tile colspan=3 rowspan=1>
            </mat-grid-tile>
            <mat-grid-tile colspan=1 rowspan=1>
                <!-- <button mat-button (click)="onNoClick()">No Thanks</button> -->
            </mat-grid-tile>
            <mat-grid-tile colspan=1 rowspan=1>
                <button mat-raised-button [mat-dialog-close]="myGroup" [disabled]="!checkFormValid()" color="primary" cdkFocusInitial>Aceptar</button>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>

