import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

export interface CWStatusRecord {
  id: number,
  account: string,
  clientName: string,
  status: string,
}

@Injectable({
  providedIn: 'root'
})
export class CardWizardService {

  constructor() {
    this.resetCwStatus();
   }

  public cwStatus: CWStatusRecord[] = [];
  public fakeCount: number = 0;

  public resetCwStatus() {
    this.fakeCount = 0;
  }

  public getCWStatus(accountNumber: string = '4178490006507504', clientName: string = 'Client X'): Observable<CWStatusRecord[]> {


    switch(this.fakeCount) {
      case 0: this.cwStatus.push({ id: 3322, account: accountNumber, clientName: clientName, status: 'PET. FORZADA A ERROR'}); break;
      case 1: this.cwStatus.push({ id: 3322, account: accountNumber, clientName: clientName, status: 'MALGRABADA          '}); break;
      case 2: this.cwStatus.push({ id: 3322, account: accountNumber, clientName: clientName, status: 'PET. FORZADA A ERROR'}); break;
      case 3: this.cwStatus.push({ id: 3322, account: accountNumber, clientName: clientName, status: 'PET. FINALIZADA OK  '}); break;
    }

    this.fakeCount += 1;

    return of(this.cwStatus);
  }


}
