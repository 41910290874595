<div>
    <table mat-table [dataSource]="this.dataSource" class="mat-elevation-z8" #acctTable>
        <ng-container matColumnDef="select"    ><th mat-header-cell *matHeaderCellDef>Select Account   </th><td mat-cell *matCellDef="let element"><button class="fill-button" mat-raised-button (click)="selectAcct(element.id)">{{ element.id }}</button></td></ng-container>
        <ng-container matColumnDef="org"       ><th mat-header-cell *matHeaderCellDef>ORG       </th><td mat-cell *matCellDef="let element">{{ element.org                  }}</td></ng-container>
        <ng-container matColumnDef="logo"      ><th mat-header-cell *matHeaderCellDef>LOGO      </th><td mat-cell *matCellDef="let element">{{ element.logo                 }}</td></ng-container>
        <ng-container matColumnDef="image"     ><th mat-header-cell *matHeaderCellDef>Type      </th><td mat-cell *matCellDef="let element"><credit-image-card [cardProduct]="element.cardProduct"></credit-image-card></td></ng-container>
        <ng-container matColumnDef="balance"   ><th mat-header-cell *matHeaderCellDef>Balance   </th><td mat-cell *matCellDef="let element">{{ element.balance   | currency }}</td></ng-container>
        <ng-container matColumnDef="otb"       ><th mat-header-cell *matHeaderCellDef>OTB       </th><td mat-cell *matCellDef="let element">{{ element.otb       | currency }}</td></ng-container>
        <ng-container matColumnDef="minimo"    ><th mat-header-cell *matHeaderCellDef>Minimo    </th><td mat-cell *matCellDef="let element">{{ element.minimo    | currency }}</td></ng-container>
        <ng-container matColumnDef="vencido"   ><th mat-header-cell *matHeaderCellDef>Vencido   </th><td mat-cell *matCellDef="let element">{{ element.vencido   | currency }}</td></ng-container>
        <ng-container matColumnDef="status"    ><th mat-header-cell *matHeaderCellDef>Status    </th><td mat-cell *matCellDef="let element">{{ element.status               }}</td></ng-container>
        <ng-container matColumnDef="bc1"       ><th mat-header-cell *matHeaderCellDef>BC1       </th><td mat-cell *matCellDef="let element">{{ element.bc1                  }}</td></ng-container>
        <ng-container matColumnDef="bc2"       ><th mat-header-cell *matHeaderCellDef>BC2       </th><td mat-cell *matCellDef="let element">{{ element.bc2                  }}</td></ng-container>
        <ng-container matColumnDef="ifree"     ><th mat-header-cell *matHeaderCellDef>I. Free   </th><td mat-cell *matCellDef="let element">{{ element.ifree     | currency }}</td></ng-container>
        <ng-container matColumnDef="refinance" ><th mat-header-cell *matHeaderCellDef>M. Refinac</th><td mat-cell *matCellDef="let element">{{ element.refinance | currency }}</td></ng-container>
        <ng-container matColumnDef="payDate"   ><th mat-header-cell *matHeaderCellDef>Fecha Pago</th><td mat-cell *matCellDef="let element">{{ element.payDate   | date     }}</td></ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>