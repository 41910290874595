import { EnvironmentService } from './../../../../services/environment.service';
import { CreditCard } from './../../entities/credit.d';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, DoCheck, IterableDiffers } from '@angular/core';
import { VplService } from '../../services/vpl.service';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'credit-list-cards',
  templateUrl: './list-cards.component.html',
  styleUrls: ['./list-cards.component.scss']
})
export class ListCardsComponent implements OnInit {

  @ViewChild('cardTable') cardTable!: MatTable<CreditCard>;

  @Input() cardIsDisabled: (card: CreditCard) => boolean = (card: CreditCard) => { return true;};
  @Input() cardSelected: (card: CreditCard) => void = (card: CreditCard) => {};
  @Input() displayedColumns: string[] = [ 'select', 'cardNumber', 'product', 'expiryDate', 'reg', 'blockCode', 'blockCodeDesc', 'st', 'stDesc', 'gp', 'gpDesc', 'tg', 'tgDesc', 'embossedName' ];

  public _dataSource!: CreditCard[];
  @Input()
  public get dataSource(): CreditCard[] {
    return (this._dataSource) ? this._dataSource: [];
  }
  public set dataSource(value: CreditCard[]) {
    this._dataSource = value;
    this.dataSource.sort((a,b) => {
      return a.id?.localeCompare(b.id) ?? 0;
    });
    this.cardTable?.renderRows();
  }

  @Output() selected = new EventEmitter();

  constructor(
    public vplService: VplService,
    public env: EnvironmentService,
    ) { }

  ngOnInit(): void {
  }

  selectCard(card: CreditCard) {
    if (this.cardSelected != undefined) {
      this.cardSelected(card);
    }
    this.selected.emit(card);
  }
}
