import { VscapiService } from './../../../core/services/vscapi.service';
import { CreditAccount, CreditCustomer } from './../../entities/credit.d';
import { EnvironmentService } from 'src/app/services/environment.service';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { AuthService } from 'src/app/services/auth.service';
import { VplService } from '../../services/vpl.service';
import { CreditLocate } from '../../entities/credit.d';

@Component({
  selector: 'credit-view-account',
  templateUrl: './view-account.component.html',
  styleUrls: ['./view-account.component.scss'],
})
export class ViewAccountComponent implements OnInit {
  @Input() newSearchFunction!: () => void;
  @Input() newSearchLink: string = '';
  @Input() account: string = '';

  public accountData!: CreditAccount;
  public customerData!: CreditCustomer;

  themeP: ThemePalette;

  locator!: CreditLocate;

  classString: string = '';

  // location: Location; //FAKE

  constructor(
    public route: ActivatedRoute,
    public vplService: VplService,
    public vscapiService: VscapiService,
    public env: EnvironmentService,

    // FAKE
    public authService: AuthService,
    // @Inject(DOCUMENT) private document: Document

    // FAKE
  ) { }

  public setTheme(classString: string): void {
    this.classString = classString;
  }

  ngOnInit(): void {

    if (this.env.newAPI) {
      if (this.account != '') {
        this.vscapiService.getAccount(this.account).subscribe(result => {
            this.accountData = result;
            if (result.customerId) {
              this.vscapiService.getCustomer(result.customerId).subscribe(result => {
                this.customerData = result;
              })
            }
          })
      }
    }

    this.route.data.subscribe( (data) => {
      if (data.test && data.test == 'abc') {
      // // FAKE
      // this.locator = {
      //   accountNumber: '2',
      // };

      // // FAKE
      // this.vplService.locateClient(this.locator).subscribe( result => {
      //   this.locator = result;
      //     this.vplService.getClients();
      //     // this.fieldOptions.set("06clients"  , this.vplService.clientsList);
      //     // this.fieldOptions.set("07customers", this.vplService.customersList);
      //     // this.fieldOptions.set("08accounts" , this.vplService.accountsList);
      //     // this.fieldOptions.set("09cards"    , this.vplService.cardsList);


      //     // FAKE
      //     this.vplService.setClient('E.V. Pineda');
      //     this.vplService.setCustomer('0001000000015061250');
      //     this.vplService.setAccount('0004178490999963631');
      //     this.vplService.setThemeByOrg(this.document, this.vplService.account?.org);
      //     this.vplService.setCard('0004178490999963632');
      // });

      }
    });
  }

  public tabFocusChange($event: any) {
    console.log($event);
  }
}
