import { CreditAccountBehavioralInfo, CreditAccountCollectionInfo, CreditAccountDates } from './credit.d';

export class AccountDates implements CreditAccountDates {
    paymentDueDate           : Date;
    lastPaymentDate          : Date;
    lastPurchaseDate         : Date;
    creditLimitDate          : Date;
    lastCreditLimitDate      : Date;
    lastDelinquencyDate      : Date;
    lastReclassificationDate : Date;
    
    constructor() {
        this.paymentDueDate           = new Date();
        this.lastPaymentDate          = new Date();
        this.lastPurchaseDate         = new Date();
        this.creditLimitDate          = new Date();
        this.lastCreditLimitDate      = new Date();
        this.lastDelinquencyDate      = new Date();
        this.lastReclassificationDate = new Date();
    }
}

export class AccountCollectionInfo implements CreditAccountCollectionInfo {
    collectionDate      : Date;
    collectionType      : string = '';
    cycleDue            : string = '';
    collectionReason    : string = '';
    rcy                 : string = '';
    chargeOffDate       : Date;
    lastDelinquencyDate : Date;
    
    constructor() {
        this.collectionDate      = new Date();
        this.chargeOffDate       = new Date();
        this.lastDelinquencyDate = new Date();
    }
}

export class AccountBehavioralInfo implements CreditAccountBehavioralInfo {
    creditClass       : string = '';
    appSource         : string = '';
    cb                : string = '';
    monthlyBalance    : string = '';
    usage             : string = '';
    blockCode1        : string = '';
    blockCode2        : string = '';
    lastCreditClass   : string = '';
    miscSource        : string = '';
    numberNsfPayment  : number = 0;
    writeOffDay       : number = 0;
    monthPurchases    : number = 0;
    openDate          : Date;
    storeNumber       : string = '';
    status            : string = '';
    constructor() {
        this.openDate = new Date();
    }

}
