// import { IncodeApiService } from './../incode-api.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CbApiService, CbResult } from '../cb-api.service';

export interface Step {
  id: string,
  show: boolean,
  active: boolean,
  done: boolean,
  title?: string,
  apiResponse: string,
}

@Component({
  selector: 'cb-flow01',
  templateUrl: './cb-flow01.component.html',
  styleUrls: ['./cb-flow01.component.scss']
})
export class CbFlow01Component implements OnInit {
  public demo = false;

  public client = { 
    id: '192939',
    firstName: '',
    lastName: '',
    country: 'MX',
    id1OCR: [{}] 
   };

  public _step: number = 0;
  public steps: any[] = [
    { id: 'init'        , show: false , active: true , done: false, apiResponse: '', title: 'Begin' },
    { id: 'cb001'       , show: true  , active: false, done: false, apiResponse: '', title: 'Credit Check 1' },
    { id: 'cb002'       , show: true  , active: false, done: false, apiResponse: '', title: 'Credit Check 2' },
    { id: 'apply'       , show: true  , active: false, done: false, apiResponse: '', title: 'Submit' },
    { id: 'result'      , show: true  , active: false, done: false, apiResponse: '', title: 'Aceptar' },
    { id: 'done'        , show: false , active: false, done: false, apiResponse: '', title: 'Complete' },
  ];

  get step(): Step {
    return this.steps[this._step];
  }

  getStep(id: string): Step {
    let rs:Step = { id: 'error', show: false, active: false , done: false, apiResponse: '', title: 'Bad Step: ' + id };
    if (id == 'next') {
      if (this._step + 1 >= this.steps.length) {
        return { id: 'error', show: false , active: false, done: false, apiResponse: '', title: 'No Next Step: ' + this._step };
      } else {
        return this.steps[this._step + 1];
      }
    } else {
      this.steps.forEach((s:Step) => {
        if (id == s.id) {
          rs = s;
        }
      })
    }
    return rs;
  }

  setStep(id: string) {
    if (id == 'next') {
      this.step.active = false;
      this._step += 1;
      if (this._step >= this.steps.length) {
        this._step = -1;
        return;
      }
      this.step.active = true;
    } else {
      this._step = -1;
      this.steps.forEach((s:Step, i: number) => {
        if (id == s.id) {
          this._step = i;
          this.step.active = true;
        }
      })  
      if (this._step == -1) {
        console.error('Invalid value for setStep(): ' + id);
      }
    }
  }

  @Output() complete = new EventEmitter();

  setComplete() {
    this.complete.emit(this.appData);
  }

  public appData: CbResult = {
    results: []
  };

  public selectedFile = null;

  public displayedColumnsId1OCR = [ 'key', 'value'];

  constructor( public cbApiService: CbApiService ) { }

  ngOnInit(): void {

  }

  // handleImage(webcamImage: WebcamImage) {
  //   switch (this.step.id) {
  //     case 'getSelfie':
  //       this.step.active = false;
  //       this.appData.selfieImage = webcamImage;
  //       this.icApiService.addSelfie(
  //         this.appData.selfieImage,
  //         (response)=>{
  //           this.step.apiResponse = JSON.stringify(response.body);
  //           this.step.done = true; 
  //           this.setStep('next');   
  //         },
  //         (response)=>{
  //           this.step.apiResponse = JSON.stringify(response);
  //           alert('Image quality not sufficient. Please retry');
  //           this.step.done = false; 
  //           this.step.active = true; 
  //         }
  //       );
  //       break;
  //     case 'getID1Front':
  //       this.step.active = false;
  //       this.appData.id1ImageFront = webcamImage;
  //       this.icApiService.addId1Front(
  //         this.appData.id1ImageFront,
  //         (response)=>{
  //           this.step.apiResponse = JSON.stringify(response.body);
  //           this.step.done = true; 
  //           this.setStep('next');  
  //           // Process Selfie agains ID1 Front
  //           this.step.active = false;
  //           this.icApiService.processFace( 
  //             (response)=>{
  //               this.step.apiResponse = JSON.stringify(response.body);
  //               this.step.done = true; 
  //               this.setStep('next');   
  //             },
  //             (response)=>{
  //               // Roll it back to retake selfie and ID1Front
  //               alert('Selfie does not match ID. Please retake.');
  //               this.step.apiResponse = JSON.stringify(response);
  //               this.step.done = false; 
  //               this.step.active = false;
  //               this.setStep('getID1Front');
  //               this.step.done = false;
  //               this.step.active = false; 
  //               this.setStep('getSelfie');
  //               this.step.done = false;
  //               this.step.active = true; 
  //             },
  //           )  
  //         },
  //         (response)=>{
  //           this.step.apiResponse = JSON.stringify(response);
  //           alert('Image did not pass requirements. Please retry');
  //           this.step.done = false; 
  //           this.step.active = true; 
  //         }
  //       );
  //       break;
  //     case 'getID1Back':
  //       this.step.active = false;
  //       this.appData.id1ImageBack = webcamImage;
  //       this.icApiService.addId1Back(
  //         this.appData.id1ImageBack,
  //         (response)=>{
  //           this.step.apiResponse = JSON.stringify(response.body);
  //           this.step.done = true; 
  //           this.setStep('next');   
  //         },
  //         (response)=>{
  //           this.step.apiResponse = JSON.stringify(response);
  //           alert('Image did not pass requirements. Please retry');
  //           this.step.done = false; 
  //           this.step.active = true; 
  //         }
  //       );
  //       break;
  //     case 'getSignature':
  //       this.step.active = false;
  //       this.appData.signatureImage = webcamImage;
  //       this.icApiService.addSignature(
  //         this.appData.signatureImage,
  //         (response)=>{
  //           this.step.apiResponse = JSON.stringify(response.body);
  //           this.step.done = true;
  //           alert('Submission Signed. Contract ID = ' + response.body.additionalInformation.contractId); 
  //           this.setStep('next');   
  //           this.setComplete();
  //         },
  //         (response)=>{
  //           this.step.apiResponse = JSON.stringify(response);
  //           alert('Image quality not sufficient. Please retry');
  //           this.step.done = false; 
  //           this.step.active = true; 
  //         }
  //       );
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // // onUpload()
  // // {
  // // }

  // public formOnboard!: FormGroup;

  public submitcb001() {
    // Onboard and add Hardware ID
    this.step.active = false;
    this.cbApiService.callCb001(this.client.id, 
    () => {
      // this.step.active = true;
      this.step.apiResponse = 'Success. Score = 630';
      this.step.done = true; 
      this.step.active = false; 
      this.setStep('next'); 
    },
    () => {
      // this.step.active = true;
      this.step.apiResponse = 'Success. Score = 475';
      this.step.done = true; 
      this.step.active = false; 
      this.setStep('next'); 
    }
    );
  }

  public submitcb002() {
    // Onboard and add Hardware ID
    this.step.active = false;
    this.cbApiService.callCb002(this.client.id, 
    () => {
      // this.step.active = true;
      this.step.apiResponse = 'Success. Score = 643';
      this.step.done = true; 
      this.step.active = false; 
      this.setStep('next'); 
    },
    () => {
      // this.step.active = true;
      this.step.apiResponse = 'Success. Score = 480';
      this.step.done = true; 
      this.step.active = false; 
      this.setStep('next'); 
    }
    );
  }

  public submitApply() {
    // Onboard and add Hardware ID
    this.step.active = false;
    this.cbApiService.callApply(this.client.id, 
    () => {
      // this.step.active = true;
      this.step.apiResponse = 'Success.';
      this.step.done = true; 
      this.step.active = false; 
      this.setStep('next'); 
    },
    () => {
      // this.step.active = true;
      this.step.apiResponse = 'Fail. ';
      this.step.done = true; 
      this.step.active = false; 
      this.setStep('next'); 
    }
    );
  }

  public sign() {
    // Onboard and add Hardware ID
      this.step.apiResponse = 'Aprobado. Ref: 74722.';
      this.step.done = true; 
      this.step.active = false; 
      this.setStep('next'); 
      
  }

  // public getID1OCR() {
  //   // Fetch OCR from ID 1
  //   this.step.active = false;
  //   this.icApiService.getId1OCR( 
  //     (response)=>{
  //       this.step.apiResponse = JSON.stringify(response.body);
  //       Object.entries(response.body.ocrData).forEach(([key, value]) => { this.client.id1OCR.push({ key: key, value: value }); })
  //       this.step.done = true; 
  //       if (!this.appData.inCodeResult) {
  //         this.appData.inCodeResult = {};
  //       }
  //       this.appData.inCodeResult.id1OCR = response.body;
  //       // this.setStep('next');   
  //     },
  //     (response)=>{
  //       this.step.apiResponse = JSON.stringify(response);
  //       this.client.id1OCR = [];
  //       this.step.done = false; 
  //       this.step.active = true; 
  //     },
  //   );  
  // }

  // public processFace() {
  //   // Process Selfie agains ID1 Front
  //   this.step.active = false;
  //   this.icApiService.processFace( 
  //     (response)=>{
  //       this.step.apiResponse = JSON.stringify(response.body);
  //       Object.entries(response.body.ocrData).forEach(([key, value]) => { this.client.id1OCR.push({ key: key, value: value }); })
  //       this.step.done = true; 
  //       this.setStep('next');   
  //     },
  //     (response)=>{
  //       this.step.apiResponse = JSON.stringify(response);
  //       this.client.id1OCR = [];
  //       this.step.done = false; 
  //       this.step.active = true; 
  //     },
  //   )  
  // }


  // public async fakeIt() {
  //   this.setStep('onboard');
  //   this.icApiService.onboard(this.client.id, this.client.country, '', this.client.firstName, this.client.lastName, 
  //   () => {
  //     // this.step.active = true;
  //     // this.step.done = true; 
  //     // this.step.active = false; 
  //     // this.setStep('next'); 
  //     this.icApiService.addHardware(
  //       // Success: Move On
  //       ()=>{
  //       this.appData.selfieImage = new WebcamImage('','', new ImageData(10,10));
  //       this.icApiService.addSelfie(
  //         this.appData.selfieImage,
  //         (response)=>{
  //           this.icApiService.getId1OCR( 
  //             (response)=>{
  //               this.step.apiResponse = JSON.stringify(response.body);
  //               Object.entries(response.body.ocrData).forEach(([key, value]) => { this.client.id1OCR.push({ key: key, value: value }); })
  //               this.step.done = true; 
  //               if (!this.appData.inCodeResult) {
  //                 this.appData.inCodeResult = {};
  //               }
  //               this.appData.inCodeResult.id1OCR = response.body;
  //               this.setComplete();

  //               // this.setStep('next');   
  //             },
  //             (response)=>{
  //             },
  //           )  
  //         },
  //         (response)=>{
  //         }
  //       );
  //       },
  //       // Failure: Roll back to OnBoarding
  //       ()=>{
  //       });
  //   });
  //   // this.step.id = 'getSelfie'  ; this.handleImage(new WebcamImage('','', new ImageData(10,10)));
  //   // this.step.id = 'getID1Front'; this.handleImage(new WebcamImage('','', new ImageData(10,10)));
  //   // this.step.id = 'getID1Back' ; this.handleImage(new WebcamImage('','', new ImageData(10,10)));
  //   // this.getID1OCR(); 
  //   // this.processFace();
  //   this.steps.forEach((step: Step) => {
  //     step.done = true;
  //     this.setStep(step.id);
  //   });
  //   this.setStep('done');

  // }

}
