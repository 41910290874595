import { VplService } from 'src/app/modules/credit/services/vpl.service';
import { VscapiService } from './../../../core/services/vscapi.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { ChartOptions } from 'chart.js';
import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { CreditAccount, CreditChartOptions } from '../../entities/credit.d';
import { of } from 'rxjs';

@Component({
  selector: 'credit-account-balances',
  templateUrl: './account-balances.component.html',
  styleUrls: ['./account-balances.component.scss']
})
export class AccountBalancesComponent implements OnInit {
  
  displayedDelqColumns: string[] = ['delq', 'amount', 'count'];
  dataSource!: any[];
  callDate!: Date;
  
  public showAccountDelinquencyChart = false;
  public AccountDelinquencyChart: CreditChartOptions = {
    chartDatasets: [],
    chartLabels: [],
  };
  public showAccountProfileChart = false;
  public AccountProfileChart: CreditChartOptions = {
    chartDatasets: [],
    chartLabels: [],
  };
  
  private _account!: CreditAccount;
  @Input()
  public get account(): CreditAccount {
    if (this.env.newAPI) {
      return (this._account) ? this._account: {
        id: '',
        org: '000'
      };
    } else {
      return (this.creditSvc.account) ? this.creditSvc.account: {
        id: '',
        org: '000'
      };
    }
  }
  public set account(value: CreditAccount) {
    this._account = value;
  }
    
  public delqChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      formatProfileYAxis: {
        ticks: {
          maxTicksLimit: 10,
          stepSize: 3
        }
      }
    }
  };
  
  public profileChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    
    scales: {
      formatProfileYAxis: {
        ticks: {
          maxTicksLimit: 10,
          precision: 1,
          callback: label => ``
        }
      }
    }
  };
  
  //          callback: label => `${label}%`
  
  private numberOfTicks = 0;
  constructor(
    public creditSvc: VplService,
    public env: EnvironmentService,
    public vscapiService: VscapiService,
    private ref: ChangeDetectorRef
    ) { 
      setInterval(() => {
        if (this.numberOfTicks < 60) {
          this.numberOfTicks++;
          // require view to be updated
          this.ref.markForCheck();
          this.getAccountDelinquencyChart();
          this.getAccountProfileChart();
        }
      }, this.numberOfTicks * 1000);
    }
    
    ngOnInit(): void {
      this.dataSource = this.creditSvc.accountsArray;
      this.callDate = this.getDataTime();
    }
    
    getDataTime(){
      return this.creditSvc.callTimestamp;
    }
    
    selectAcct(element: HTMLElement) {
      
    }
    
    onDLQSelect(data: any): void {
    }
    
    onDLQActivate(data: any): void {
    }
    
    onDLQDeactivate(data: any): void {
    }
    
    formatProfileYAxis = (val: number) => { return val + '' };
    
    
    getAcctDelq() {
      if (this.env.newAPI) {
        return this.vscapiService.getAccountDelinquency(this.account.id);      
      } else {
        return of(this.creditSvc.accountDelinquencyRecords);
      }
    }
    
    getAcctProfile() {
      if (this.env.newAPI) {
        return this.vscapiService.getAccountProfile(this.account.id);
      } else {
        return of(this.creditSvc.accountProfileRecords);
      }
    }
    
    public getAccountDelinquencyChart(): void {
      if (this.env.newAPI) {
        this.vscapiService.getAccountDelinquency(this.account.id).subscribe(recs => {
          let result: CreditChartOptions = {
            chartDatasets: [],
            chartLabels: [],
          };
          result.chartDatasets.push({ data: [], label: 'Amount' });
          result.chartDatasets.push({ data: [], label: 'Count' });
          recs.forEach(record => {
            result.chartDatasets[0].data.push(record.amount);
            result.chartDatasets[1].data.push(record.count);
            result.chartLabels.push(record.delq);
          });
          this.AccountDelinquencyChart = result;
          this.showAccountDelinquencyChart = true;
        });
      } else {
        this.AccountDelinquencyChart = this.creditSvc.getAccountDelqChart();
        this.showAccountDelinquencyChart = true;
      }
    }
    
    public getAccountProfileChart(): void {
      if (this.env.newAPI) {
        this.vscapiService.getAccountProfile(this.account.id).subscribe(recs => {
          let result: CreditChartOptions = {
            chartDatasets: [],
            chartLabels: [],
          };
          result.chartDatasets.push({ data: [], label: 'Status' });
          recs.forEach(record => {
            result.chartDatasets[0].data.push(record.status);
            result.chartLabels.push(record.month);
          });
          this.AccountProfileChart = result;
          this.showAccountProfileChart = true;
        });
      } else {
        this.AccountProfileChart = this.creditSvc.getAccountProfileChart();
        this.showAccountProfileChart = true;
      }
    }
    
  }
  