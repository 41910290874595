import { SnackBarService } from 'src/app/modules/core/services/snack-bar.service';
import { AssocArray } from './../../../core/entities/core.d';
import { VscapiService } from './../../../core/services/vscapi.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { VplService } from './../../services/vpl.service';
import { CreditAccount, CreditCard, CreditLocate, MyOptsType, CreditCustomer } from './../../entities/credit.d';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AuthService } from './../../../../services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { SelectConfig } from 'src/app/modules/core/entities/core';
import { MatSelectChange } from '@angular/material/select';

import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'credit-locate-client',
  templateUrl: './locate-client.component.html',
  styleUrls: ['./locate-client.component.scss']
})
export class LocateClientComponent implements OnInit {
  locator!: CreditLocate;
  locateForm!: FormGroup;
  
  locateAccount!: CreditAccount;
  locateCard!: CreditCard;

  searchToggleText: string = 'Name Search';
  
  fieldConfigs = new Map<string, SelectConfig>();
  fieldOptions = new Map();
  fc = Array();

  accountsVisible = false;
  accounts: CreditAccount[] = [];
  
  @Output() selectedClient   = new EventEmitter();
  @Output() selectedCustomer = new EventEmitter();
  @Output() selectedAccount  = new EventEmitter();

  constructor(
    public authService: AuthService,
    public vplService: VplService,
    public vscapi: VscapiService,
    public env: EnvironmentService,
    public snack: SnackBarService,
    @Inject(DOCUMENT) private document: Document
  ) { }
  
  change($event: MatSelectChange) {
    this.selectionChange($event.source.id, $event.value);
  }

  selectAccount(selected: string) {
    this.selectionChange('accounts', selected);
    if (!this.env.newAPI) {
      this.selectedAccount.emit({ account: this.vplService.account });
    }
  }

  selectionChange( fieldName: string, value: any ) {
    switch(fieldName) {
      case "clients": 
        if (this.env.newAPI) {
          this.vscapi.getClient(value).subscribe( response => {

            let customersList:any[] = [];
            response.customers?.forEach((customer: any) => {
              customersList.push({ id: customer, label: customer });
            });

            this.fieldOptions.set("07customers"  , customersList);    

            this.locateForm.controls.firstName.setValue(response.firstName);
            this.locateForm.controls.lastNamePaternal.setValue(response.lastNamePaternal);
            this.locateForm.controls.lastNameMaternal.setValue(response.lastNameMaternal);

            if (!this.env.custInLocate && response.customers[0]) {
              this.selectionChange('customers', response.customers[0]); // Call selectionChange again to auto select first returned customer
            }

            this.selectedClient.emit({ customer: value });
          });
          break;
        }
        this.vplService.setClient(value);
        this.locateForm.controls.firstName.setValue(this.vplService.client?.firstName);
        this.locateForm.controls.lastNamePaternal.setValue(this.vplService.client?.lastNamePaternal);
        this.locateForm.controls.lastNameMaternal.setValue(this.vplService.client?.lastNameMaternal);
        // this.locateForm.controls.uniqueClient.setValue(this.vplService.client?.id);
        this.locateForm.controls.dob.setValue(this.vplService.client?.customers[0].dateOfBirth?.toLocaleDateString());

        if (!this.env.custInLocate && this.vplService.client?.customers[0]) {
          this.vplService.setCustomer(this.vplService.client?.customers[0].id);
        }
  
        break;
      case "customers": 
        if (this.env.newAPI) {
          this.accountsVisible = false;
          this.accounts = [];
          this.vscapi.getCustomer(value).subscribe( customer => {
            let accountsList:any[] = [];

            this.accountsVisible = true;

            customer.accounts?.forEach((account: any) => {
              accountsList.push({ id: account, label: account });

              this.vscapi.getAccount(account).subscribe( result => {
                this.accounts.push(result);
              });
            });

            this.vscapi.selectedCustomer = value;
            this.selectedCustomer.emit({ customer: value });

          });
          break;
        }
        this.vplService.setCustomer(value);
        break;
      case "accounts":
        if (this.env.newAPI) {
          this.selectedAccount.emit({ account: value });
          this.vscapi.getAccount().subscribe(account => {
            this.vplService.setThemeByOrg(this.document, account.org);
          })
          break;
        }      
        this.vplService.setAccount(value);  
        this.vplService.setThemeByOrg(this.document, this.vplService.account?.org);
        break;
      case "cards": 
        this.vplService.setCard(value);
        break;
    }
    if (!this.env.newAPI) {
      this.fieldOptions.set("06clients"  , this.vplService.clientsList);
      this.fieldOptions.set("07customers", this.vplService.customersList);
      this.fieldOptions.set("08accounts" , this.vplService.accountsList);
      this.fieldOptions.set("09cards"    , this.vplService.cardsList);
    }
  }

  showAccounts() {
    return (this.env.newAPI) ? this.accountsVisible : true && this.vplService.customer;
  }
  
  accountDataSource() {
    // return this.accountsDS;
    return (this.env.newAPI) ? this.accounts : true && this.vplService.accountsArray;
  }

  ngOnInit(): void {
    this.locateForm = new FormGroup({
      srchFirstName    : new FormControl('', [ /*Validators.required*/ ]),
      srchPaternalName : new FormControl('', [ /*Validators.required*/ ]),
      srchMaternalName : new FormControl('', [ /*Validators.required*/ ]),
      accountNumber    : new FormControl('', [ /*Validators.required*/ ]),
      cardNumber       : new FormControl('', [ /*Validators.required*/ ]),
      rfc              : new FormControl('', [ /*Validators.required*/ ]),
      clabeSpei        : new FormControl('', [ /*Validators.required*/ ]),
      email1            : new FormControl('', [ /*Validators.required, */ Validators.email ]),
      // NANP: https://en.wikipedia.org/wiki/North_American_Numbering_Plan
      // SO: https://stackoverflow.com/questions/123559/how-to-validate-phone-numbers-using-regex
      // phone            : new FormControl('', [ Validators.required, Validators.pattern('^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$') ]),
      phone            : new FormControl('', [ /* Validators.required*/ ]),
      firstName        : new FormControl('', [ Validators.required ]),
      lastNamePaternal : new FormControl('', [ Validators.required ]),
      lastNameMaternal : new FormControl('', [ Validators.required ]),
      // uniqueClient     : new FormControl(''),
      dob     : new FormControl(''),
    });
    // this.fieldConfigs = [];

    this.fieldConfigs.set("001srchFirstName"       , { type: "search"     , id: "srchFirstName"   , name: "srchFirstName"    , title: "First Name"      , search: true , result: false, format: "text" , readonly: false, required: true , placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: true  });
    this.fieldConfigs.set("002srchPaternalName"    , { type: "search"     , id: "srchPaternalName", name: "srchPaternalName" , title: "Paternal Name"   , search: true , result: false, format: "text" , readonly: false, required: true , placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: true  });
    this.fieldConfigs.set("003srchMaternalName"    , { type: "search"     , id: "srchMaternalName", name: "srchMaternalName" , title: "Maternal Name"   , search: true , result: false, format: "text" , readonly: false, required: true , placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: true  });
    if (!this.env.toggleNameSearch) {
      this.fieldConfigs.set("009ahr"               , { type: "divider"    , id: ""                , name: ""                 , title: ""                , search: false, result: false, format: "array", readonly: false, required: false, placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: false });
    }
    this.fieldConfigs.set("011accountNumber"       , { type: "search"     , id: "accountNumber"   , name: "accountNumber"    , title: "Account"         , search: true , result: false, format: "text" , readonly: false, required: true , placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: false });
    this.fieldConfigs.set("012cardNumber"          , { type: "search"     , id: "cardNumber"      , name: "cardNumber"       , title: "Card Number"     , search: true , result: false, format: "text" , readonly: false, required: true , placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: false });
    this.fieldConfigs.set("013rfc"                 , { type: "search"     , id: "rfc"             , name: "rfc"              , title: "RFC"             , search: true , result: false, format: "text" , readonly: false, required: true , placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: false });
    this.fieldConfigs.set("014clabeSpei"           , { type: "search"     , id: "clabeSpei"       , name: "clabeSpei"        , title: "Clabe SPEI"      , search: true , result: false, format: "text" , readonly: false, required: true , placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: false });
    this.fieldConfigs.set("015email"               , { type: "search"     , id: "email1"          , name: "email1"           , title: "Email"           , search: true , result: false, format: "text" , readonly: false, required: true , placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: false });
    this.fieldConfigs.set("016phone"               , { type: "search"     , id: "phone"           , name: "phone"            , title: "Phone"           , search: true , result: false, format: "text" , readonly: false, required: true , placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: false });
    this.fieldConfigs.set("05search"               , { type: "submit"     , id: "subsearch"       , name: "subsearch"        , title: ""                , search: false, result: true , format: "text" , readonly: true , required: false, placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: false });
    this.fieldConfigs.set("06clients"              , { type: "smartselect", id: "clients"         , name: "clients"          , title: "Select Client"   , search: false, result: true , format: "array", readonly: false, required: false, placeholder: '', hint: 'Client ID'      , icon: 'CL' , hideIfEmpty: true , inNameSearch: false });
    if (this.env.custInLocate) {
      this.fieldConfigs.set("07customers"            , { type: "smartselect", id: "customers"       , name: "customers"        , title: "Select Customer" , search: false, result: true , format: "array", readonly: false, required: false, placeholder: '', hint: 'Customer Number', icon: 'AC' , hideIfEmpty: true , inNameSearch: false });
    }
    this.fieldConfigs.set("10ahr"                  , { type: "divider"    , id: ""                , name: ""                 , title: ""                , search: false, result: false, format: "array", readonly: false, required: false, placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: false });
    this.fieldConfigs.set("11firstName"            , { type: "display"    , id: "firstName"       , name: "firstName"        , title: "First Name"      , search: false, result: true , format: "text" , readonly: true , required: false, placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: false });
    this.fieldConfigs.set("12lastNamePaternal"     , { type: "display"    , id: "lastNamePaternal", name: "lastNamePaternal" , title: "Paternal Surname", search: false, result: true , format: "text" , readonly: true , required: false, placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: false });
    this.fieldConfigs.set("13lastNameMaternal"     , { type: "display"    , id: "lastNameMaternal", name: "lastNameMaternal" , title: "Maternal Surname", search: false, result: true , format: "text" , readonly: true , required: false, placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: false });
    // this.fieldConfigs.set("14uniqueClient"         , { type: "display"    , id: "uniqueClient"    , name: "uniqueClient"     , title: "Cliente Unico"   , search: false, result: true , format: "text" , readonly: true , required: false, placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: false });
    this.fieldConfigs.set("14dob"                  , { type: "display"    , id: "dob"             , name: "dob"              , title: "Fecha de Nacimiento", search: false, result: true , format: "text" , readonly: true , required: false, placeholder: '', hint: ''               , icon: ''   , hideIfEmpty: false, inNameSearch: false });

    // this.fieldConfigs.set("08accounts"         , { type: "smartselect", id: "accounts"        , name: "accounts"         , title: "Select Account"  , search: false, result: true , format: "array", readonly: false, required: false, placeholder: '', hint: 'Account Number' , icon: 'CU' , hideIfEmpty: true });
    // this.fieldConfigs.set("09cards"            , { type: "smartselect", id: "cards"           , name: "cards"            , title: "Select Card"     , search: false, result: true , format: "array", readonly: false, required: false, placeholder: '', hint: 'Card Number'    , icon: 'CD' , hideIfEmpty: true });

    this.vplService.clear();
  }

  validInput() {
    return !(
      this.locateForm.controls.srchFirstName.value.length == 0 &&
      this.locateForm.controls.srchPaternalName.value.length == 0 &&
      this.locateForm.controls.srchMaternalName.value.length == 0 &&
      this.locateForm.controls.accountNumber.value.length == 0 &&
      this.locateForm.controls.cardNumber.value.length == 0 &&
      this.locateForm.controls.rfc.value.length == 0 &&
      this.locateForm.controls.clabeSpei.value.length == 0 &&
      this.locateForm.controls.email1.value.length == 0 &&
      this.locateForm.controls.phone.value.length == 0 

      // this.locateForm.controls.srchFirstName.errors?.required &&
      // this.locateForm.controls.srchPaternalName.errors?.required &&
      // this.locateForm.controls.srchMaternalName.errors?.required &&
      // this.locateForm.controls.accountNumber.errors?.required &&
      // this.locateForm.controls.cardNumber.errors?.required &&
      // this.locateForm.controls.rfc.errors?.required &&
      // this.locateForm.controls.clabeSpei.errors?.required &&
      // this.locateForm.controls.email1.errors?.required &&
      // this.locateForm.controls.phone.errors?.required 
     );
  }

  nameToggleStyle() {
    if (!this.env.toggleNameSearch) {
      return 'display:none';
    }
    return '';
  }

  locateSubmit(target: string = 'search', id: string = '') { 
    // this.authService.login(name, password).subscribe(result => {console.log(result); this.user = result;} );
    this.locator = {
      request: {

        firstName   : this.locateForm.controls.srchFirstName.value,  
        paternalName: this.locateForm.controls.srchPaternalName.value,
        maternalName: this.locateForm.controls.srchMaternalName.value,
  
        accountNumber   :this.locateForm.controls.accountNumber.value,
        cardNumber      :this.locateForm.controls.cardNumber.value,
        rfc             :this.locateForm.controls.rfc.value,
        clabeSpei       :this.locateForm.controls.clabeSpei.value,
        contactEmail    :this.locateForm.controls.email1.value,
        phone           :this.locateForm.controls.phone.value,
      },
      response: {}
    };

    if (this.env.newAPI) {
      switch(target)
      {
        case "search":
          this.vscapi.locateClient(this.locator.request).subscribe( result => {
            // this.locator.response = result.body.data;
            let clients: AssocArray = [];
            
            result.forEach(client => {
              clients[client.id] = client;
            });
    
            let clientsList = [];
            for (const k in clients) { 
              clientsList.push({ id: clients[k].id, label: clients[k].id });
            }
            if (clientsList.length == 0) this.snack.openSnackBar('No se encontraron clientes', 'OK', 3000);

            this.fieldOptions.set("06clients"  , clientsList);
          });
          break;
      }
    } else {
      this.vplService.locateClient(this.locator.request).subscribe( result => {
        this.locator.response = result;
        this.vplService.getClients();
        this.fieldOptions.set("06clients"  , this.vplService.clientsList);
        this.fieldOptions.set("07customers", this.vplService.customersList);
        this.fieldOptions.set("08accounts" , this.vplService.accountsList);
        this.fieldOptions.set("09cards"    , this.vplService.cardsList);
      });  
    }
  }
}