
<p>
    {{displayTime}}
    <button *ngIf="!isRunning" mat-icon-button (click)="resumeTimer()"><mat-icon>play_circle</mat-icon></button>
    <button *ngIf="isRunning" mat-icon-button (click)="pauseTimer()"><mat-icon>pause_circle</mat-icon></button>
</p>
<!-- <button mat-raised-button type="button" (click)="startTimer()" class="btn btn-primary">Start</button> -->
<!-- <button mat-raised-button type="button" (click)="pauseTimer()" class="btn btn-primary">Pause</button> -->
<!-- <button mat-raised-button type="button" (click)="resumeTimer()" class="btn btn-primary">Resume</button> -->
<!-- <button mat-raised-button type="button" (click)="stopTimer()" class="btn btn-primary">Stop</button> -->



