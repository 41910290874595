import { VscapiService } from './../../../core/services/vscapi.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { CreditCard, CreditTransaction } from './../../entities/credit.d';
import { DataSource } from '@angular/cdk/table';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { VplService } from '../../services/vpl.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'credit-list-transactions',
  templateUrl: './list-transactions.component.html',
  styleUrls: ['./list-transactions.component.scss']
})
export class ListTransactionsComponent implements OnInit {

  @ViewChild(MatSort) sort!: MatSort;

  @Input() account: string = '';
  @Output() selected = new EventEmitter();

  dataSource!: TXNDataSource;
  data: CreditTransaction[] = [];
  displayedColumns: string[] = [ /* 'select', */ 'effectiveDate', 'postedDate', 'amount', 'transactionType', /* 'logicModule', */ 'planId', 'description', 'referenceNumber', 'authorizationCode'  ];

  filter = {
    effectiveDate     : '',
    postedDate        : '',
    amount            : '',
    transactionType   : '',
    logicModule       : '',
    planId            : '',
    description       : '',
    referenceNumber   : '',
    authorizationCode : '',
  };

  constructor(
    public vplService: VplService,
    public env: EnvironmentService,
    public vscapiService: VscapiService
    ) {
      this.dataSource = new TXNDataSource();
     }

  ngOnInit(): void {
    if (this.env.newAPI){
      this.vscapiService.getAccountTransactions(this.account).subscribe(transactions => {
        transactions.forEach((item) => {
          this.data.push({
            effectiveDate    : item.effectiveDate    , 
            postedDate       : item.postedDate       , 
            amount           : item.amount           , 
            transactionType  : item.transactionType  , 
            logicModule      : item.logicModule      , 
            planId           : item.planId           , 
            description      : item.description      , 
            referenceNumber  : item.referenceNumber  , 
            authorizationCode: item.authorizationCode, 
          });
        });
        this.updateView();
      });
    } else {
      this.vplService.getTransactions().forEach((item) => {
        this.data.push({
          effectiveDate    : item.effectiveDate    , 
          postedDate       : item.postedDate       , 
          amount           : item.amount           , 
          transactionType  : item.transactionType  , 
          logicModule      : item.logicModule      , 
          planId           : item.planId           , 
          description      : item.description      , 
          referenceNumber  : item.referenceNumber  , 
          authorizationCode: item.authorizationCode, 
        });
      });
      this.updateView();
    }
  }

  ngAfterViewInit() {
//    this.dataSource.sort = this.sort;
  }

  selectTransaction(id: string) {
    this.selected.emit(id);
  }

  public updateView() {
    //    this.dataSource.use(this.STATS.slice());
    let newData = 
      this.data.slice()
      // .filter((item, i) => this.filter.effectiveDate     == '' || (item.effectiveDate     != undefined && item.effectiveDate.indexOf(    this.filter.effectiveDate    ) !== -1))
      // .filter((item, i) => this.filter.postedDate        == '' || (item.postedDate        != undefined && item.postedDate.indexOf(       this.filter.postedDate       ) !== -1))
      // .filter((item, i) => this.filter.amount            == '' || (item.amount            != undefined && item.amount.indexOf(           this.filter.amount           ) !== -1))
      .filter((item, i) => this.filter.transactionType   == '' || (item.transactionType   != undefined && item.transactionType.indexOf(  this.filter.transactionType  ) !== -1))
      .filter((item, i) => this.filter.logicModule       == '' || (item.logicModule       != undefined && item.logicModule.indexOf(      this.filter.logicModule      ) !== -1))
      .filter((item, i) => this.filter.planId            == '' || (item.planId            != undefined && item.planId.indexOf(           this.filter.planId           ) !== -1))
      .filter((item, i) => this.filter.description       == '' || (item.description       != undefined && item.description.indexOf(      this.filter.description      ) !== -1))
      .filter((item, i) => this.filter.referenceNumber   == '' || (item.referenceNumber   != undefined && item.referenceNumber.indexOf(  this.filter.referenceNumber  ) !== -1))
      .filter((item, i) => this.filter.authorizationCode == '' || (item.authorizationCode != undefined && item.authorizationCode.indexOf(this.filter.authorizationCode) !== -1));
    this.dataSource.use(
      newData
    );
  }

}

export class TXNDataSource extends DataSource<CreditTransaction> {

  dataWithTotal = new BehaviorSubject<CreditTransaction[]>([]);
  sort!: Sort;

  use(bcts: CreditTransaction[]) {
    this.dataWithTotal.next([ ...bcts]);
  }

  connect(): Observable<CreditTransaction[]> { 
    return this.dataWithTotal.asObservable();
  }

  disconnect() {}
  
}