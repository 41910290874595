import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'credit-account-history',
  templateUrl: './account-history.component.html',
  styleUrls: ['./account-history.component.scss']
})
export class AccountHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
