<div class="container" *ngIf="show()">

    <h1><ng-container *ngIf="env.showActionCodeValue">{{action.title}} - </ng-container>{{action?.hint}}</h1>
    <h4 *ngIf="this.state?.stateData.card">Card: {{this.state.stateData.card}}</h4>

    <!-- <div *ngIf="!this.context.isCustomerIdentified; else custVerified">
        <credit-customer-identify *ngIf="!this.context.isCustomerIdentified; else custVerified" [cardNumber]="this.creditSvc.customer?.clientId || ''" [requiredChecks]="2"
            [maxFailures]="4" (onSuccess)="customerIdentified($event)" (onFailure)="customerIdentified($event)"></credit-customer-identify>
    </div> -->

    <input type="hidden" #otpEmbossOK value="0">

    <!-- <ng-template #custVerified> -->
        <mat-stepper mat-vertical-stepper-scroller orientation="vertical" [linear]="true" [selectedIndex]="this.state.stateData?.step ?? 0"
            (selectionChange)="stepChange($event)" #stepper>

            <mat-step [editable]="isEditable" [completed]="checkStepComplete(0)">
                <ng-template matStepLabel>Selección de tarjeta</ng-template>
                <ng-template matStepContent>
                    <div class="box4">
                        <!-- <credit-list-cards [cardIsDisabled]="cardIsDisabled" [displayedColumns]="columns"
                            [cardSelected]="cardSelected" [dataSource]="creditSvc.cardsArray"></credit-list-cards> -->
                        <credit-list-cards [cardIsDisabled]="cardIsDisabled" [cardSelected]="cardSelected" [displayedColumns]="columns" [dataSource]="cards"></credit-list-cards>

                    </div>
                </ng-template>
            </mat-step>

            <mat-step [editable]="isEditable" [completed]="checkStepComplete(1)">
                <ng-template matStepLabel>Verifica Identidad de Cliente</ng-template>
                <ng-template matStepContent>
                    <!-- <credit-customer-identify *ngIf="!this.state.stateData.steps[this.stepper.selectedIndex].isCustomerIdentified; else custVerified" [cardNumber]="this.creditSvc.customer?.clientId || ''" [requiredChecks]="2" -->
                    <credit-customer-identify [cardNumber]="this.creditSvc.customer?.clientId || ''" [requiredChecks]="2"
                        [maxFailures]="4" (onSuccess)="customerIdentified($event)" (onFailure)="customerIdentified($event)"></credit-customer-identify>
                    <!-- <ng-template #custVerified> -->
                        <!-- <button *ngIf="this.isCustomerIdentified" mat-button 
                            (click)="stepperNext('customer identification')"
                            color="primary">Continuar</button> -->
                </ng-template>
            </mat-step>

            <mat-step [editable]="isEditable" [completed]="checkStepComplete(2)">
                <ng-template matStepLabel>Back Office</ng-template>
                <ng-template matStepContent>
                    <div class="box4">
                        <credit-check-id [requestId]="this.state.stateData.backOfficeRequestId ?? ''" authOverride="password" [refNumber]="this.state.stateData.card" [allowContinueBeforeVerified]="true" (onSuccess)="backOfficeProvided($event)" (onRequestSubmitted)="backOfficeSubmitted($event)"></credit-check-id>
                    </div>
                </ng-template>
            </mat-step>
            
            <mat-step [editable]="isEditable" [completed]="checkStepComplete(3)">
                <ng-template matStepLabel>Confirma Datos Demográficos</ng-template>
                <ng-template matStepContent>
                    <!-- <core-context-hint [hintId]="'emboDemographic'"></core-context-hint> -->
                    <core-context-hint [hintId]="'Speech2'"></core-context-hint>


                    <form [formGroup]="actionForm">
                        <div class="box box1">
                            <div class="box box6" style="grid-column: span 2;">
                                <h3>Residential Address</h3>
                                <ng-container *ngFor="let field of fieldConfigs1 | keyvalue" class="ff">
                                    <div *ngIf="field.value.type=='submit'" style="grid-column: span 4;">
                                        <button mat-raised-button type="button" class="btn btn-primary"
                                            [disabled]="!validInput()">
                                            <mat-icon *ngIf="!validInput()">edit</mat-icon>
                                            <mat-icon *ngIf="validInput()">search</mat-icon>Search
                                        </button>
                                    </div>

                                    <div *ngIf="field.value.type=='divider'" style="grid-column: span 4;">
                                        <hr>
                                    </div>

                                    <core-data-field-view *ngIf="field.value.type=='display'" label="{{field.value.title}}"
                                        type="text" [value]="field.value.name"></core-data-field-view>

                                    <core-otp-request *ngIf="field.value.type=='otp'" [config]="otp2Config">
                                    </core-otp-request>

                                    <mat-form-field *ngIf="field.value.type=='date'" class="form-control" appearance="fill">
                                        <mat-label>{{field.value.title}}</mat-label><input matInput
                                            [matDatepicker]="picker">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field *ngIf="field.value.type=='input'" appearance="fill">
                                        <mat-label>{{ field.value.title }}</mat-label>
                                        <input matInput [id]="field.value.name" [type]="field.value.format"
                                            class="form-control" [formControlName]="field.value.name"
                                            [placeholder]="field.value.placeholder" autocomplete="off"
                                            [readonly]="field.value.readonly">
                                    </mat-form-field>
                                    <ng-container *ngIf="field.value.type=='zip'">
                                        <mat-form-field appearance="fill">
                                            <mat-label>{{ field.value.title }}</mat-label>
                                            <input matInput #zip [id]="field.value.name" [type]="field.value.format"
                                                class="form-control" [formControlName]="field.value.name"
                                                [placeholder]="field.value.placeholder" autocomplete="off"
                                                [readonly]="field.value.readonly"><button *ngIf="search" matSuffix
                                                mat-icon-button aria-label="Clear"
                                                (click)="zipSearch(zip.value, field.key)">
                                                <mat-icon>search</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <core-smart-select class="smart-field" [options]="fieldOptions.get(field.key)"
                                            [config]="zipSearchFieldConfig1" (selectChange)="changeZip($event)">
                                        </core-smart-select>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="box box6" style="grid-column: span 2;">
                                <h3>Employer Address</h3>
                                <ng-container *ngFor="let field of fieldConfigs2 | keyvalue" class="ff">
                                    <div *ngIf="field.value.type=='submit'" style="grid-column: span 4;">
                                        <button mat-raised-button type="button" class="btn btn-primary"
                                            [disabled]="!validInput()">
                                            <mat-icon *ngIf="!validInput()">edit</mat-icon>
                                            <mat-icon *ngIf="validInput()">search</mat-icon>Search
                                        </button>
                                    </div>

                                    <div *ngIf="field.value.type=='divider'" style="grid-column: span 4;">
                                        <hr>
                                    </div>

                                    <core-data-field-view *ngIf="field.value.type=='display'" label="{{field.value.title}}"
                                        type="text" [value]="field.value.name"></core-data-field-view>

                                    <mat-form-field *ngIf="field.value.type=='date'" class="form-control" appearance="fill">
                                        <mat-label>{{field.value.title}}</mat-label><input matInput
                                            [matDatepicker]="picker">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field *ngIf="field.value.type=='input'" appearance="fill">
                                        <mat-label>{{ field.value.title }}</mat-label>
                                        <input matInput [id]="field.value.name" [type]="field.value.format"
                                            class="form-control" [formControlName]="field.value.name"
                                            [placeholder]="field.value.placeholder" autocomplete="off"
                                            [readonly]="field.value.readonly">
                                    </mat-form-field>
                                    <ng-container *ngIf="field.value.type=='zip'">
                                        <mat-form-field appearance="fill">
                                            <mat-label>{{ field.value.title }}</mat-label>
                                            <input matInput #zip [id]="field.value.name" [type]="field.value.format"
                                                class="form-control" [formControlName]="field.value.name"
                                                [placeholder]="field.value.placeholder" autocomplete="off"
                                                [readonly]="field.value.readonly"><button *ngIf="search" matSuffix
                                                mat-icon-button aria-label="Clear"
                                                (click)="zipSearch(zip.value, field.key)">
                                                <mat-icon>search</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <core-smart-select class="smart-field" [options]="fieldOptions.get(field.key)"
                                            [config]="zipSearchFieldConfig2" (selectChange)="changeZip($event)">
                                        </core-smart-select>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <!-- <core-smart-button [config]="saveButtonConfig"></core-smart-button> -->
                        </div>
                    </form>
                    <div>
                        <credit-ac-view001 [actionCode]="actionCode" [minimal]="true" [noList]="true"
                            (noteSaved)="stepper.selected!.completed = true" #demographicNote></credit-ac-view001>
                        <button [disabled]="!demographicNote.noteMade" mat-button 
                            (click)="stepperNext('demographic data')"
                            color="primary">Continuar</button>
                        <!-- <button [disabled]="!demographicNote.noteMade" (click)="snack.openSnackBar('Demographic Data Confirmed', 'OK')" mat-button 
                            (click)="stepperNext('demographic data')"
                            color="primary">Aceptar</button> -->
                    </div>
                </ng-template>
            </mat-step>

            <mat-step [editable]="isEditable" [completed]="checkStepComplete(4)">
                <ng-template matStepLabel>Back Office</ng-template>
                <ng-template matStepContent>
                    <!-- <core-context-hint [hintId]="'emboBackOffice'"></core-context-hint> -->
                    <core-context-hint [hintId]="'Speech3'"></core-context-hint>
                    <div class="box4">
                        <credit-check-id [requestId]="this.state.stateData.backOfficeRequestId ?? ''" authOverride="password" [allowContinueBeforeVerified]="false" (onSuccess)="backOfficeComplete($event)" (onRequestSubmitted)="backOfficeSubmitted($event)"></credit-check-id>
                    </div>
                </ng-template>
            </mat-step>            

            <mat-step [editable]="isEditable" [completed]="checkStepComplete(5)">
                <ng-template matStepLabel>Pertenencia de datos</ng-template>
                <ng-template matStepContent>
                    <!-- <core-context-hint [hintId]="'emboContact'"></core-context-hint> -->
                    <core-context-hint [hintId]="'Speech4'"></core-context-hint>

                    <div class="box9">
                            <!-- <h3>¿Desea actualizar datos de contacto?</h3> -->
                            <core-otp-request [config]="otp2Config" (otpFail)="otpFail($event)" (otpSuccess)="otpSuccessContact(0)"></core-otp-request>
                    </div>
                    <button mat-button 
                        (click)="stepperNext('update contact details')"
                        color="primary">Aceptar</button>
                </ng-template>
            </mat-step>

            <mat-step [editable]="isEditable" [completed]="checkStepComplete(6)">
                <ng-template matStepLabel>Generación de tarjeta</ng-template>
                <ng-template matStepContent>
                    <!-- <core-context-hint [hintId]="'emboCreateCard'"></core-context-hint> -->
                    <core-context-hint [hintId]="'Speech5'"></core-context-hint>
                    <div *ngIf="otpEmbossOK.value!='2'" class="box box7">
                        <div class="box2">
                            <credit-list-card-products [hasAccepted]="embossButtonEnabled.productSelected" (accepted)="selectProduct($event)"></credit-list-card-products>
                            <core-smart-select class="smart-field" [options]="motivEmisOptions" [config]="motivEmisConfig"
                                (selectChange)="changeMotiv($event)">
                            </core-smart-select>
                            <!-- <core-otp-request *ngIf="otpEmbossOK.value=='0'" [config]="otp2Config" (otpSuccess)="otpEmbossOK.value='1'" (otpFail)="otpFail($event); otpEmbossOK.value='0'"></core-otp-request> -->
                            <core-smart-button *ngIf="otpEmbossOK.value=='0'" [config]="embossButtonConfig"></core-smart-button>
                        </div>
                        <div></div>
                    </div>
                    <!-- <div *ngIf="otpEmbossOK.value=='2' && this.state.stateData.emboSent" class="box box3">
                        <credit-card-wizard [delay]="4"></credit-card-wizard>
                    </div> -->
                    <!-- <div *ngIf="otpEmbossOK.value=='2' && this.state.stateData.emboSent">
                        <button mat-button 
                            (click)="stepperNext('create card')"
                            color="primary">Aceptar</button>
                    </div> -->
                </ng-template>
            </mat-step>

            <mat-step [editable]="isEditable" [completed]="checkStepComplete(7)">
                <ng-template matStepLabel>PIF</ng-template>
                <ng-template matStepContent>
                    <!-- <core-context-hint [hintId]="'emboInsurance'"></core-context-hint> -->
                    <core-context-hint [hintId]="'Speech6'" [hintData]="commonHintData()"></core-context-hint>
                    <div class="box4" style="grid-column: span 2;">
                        <credit-list-insurance (accepted)="insuranceAccepted($event)"></credit-list-insurance>
                    </div>
                </ng-template>
            </mat-step>

            <mat-step [editable]="isEditable" [completed]="checkStepComplete(8)">
                <ng-template matStepLabel>Desbloquear Cuenta</ng-template>
                <ng-template matStepContent>
                    <div class="box box4">
                        <core-smart-button [config]="blockAccountButtonConfig"></core-smart-button>
                    </div>
                </ng-template>
            </mat-step>

            <mat-step [editable]="isEditable" [completed]="checkStepComplete(9)">
                <ng-template matStepLabel>Desbloquear Tarjeta</ng-template>
                <ng-template matStepContent>
                    <div class="box box4">
                        <core-smart-button [config]="blockCardButtonConfig"></core-smart-button>
                    </div>
                </ng-template>
            </mat-step>

            <mat-step [editable]="isEditable" [completed]="checkStepComplete(10)">
                <ng-template matStepLabel>Asignar NIP</ng-template>
                <ng-template matStepContent>
                    <core-context-hint [hintId]="'emboPIN'"></core-context-hint>

                    <!-- <div class="box9">
                        <mat-form-field appearance="outline" class="core-data-field"><mat-label>Email</mat-label>   <input matInput type="text" name="email" autocomplete="off" [readonly]="true" [value]="creditSvc.customer?.contactEmail"> </mat-form-field>
                        <mat-form-field appearance="outline" class="core-data-field"><mat-label>Cellular</mat-label><input matInput type="text" name="cell"  autocomplete="off" [readonly]="true" [value]="creditSvc.customer?.contactTelephoneCell">  </mat-form-field>
                    </div> -->
                    <!-- <div class="box box3">
                        <credit-card-wizard></credit-card-wizard>
                    </div> -->
                    <div class="box box4">
                        <core-smart-button [config]="pinButtonConfig"></core-smart-button>
                    </div>
                    <div *ngIf="pinStatus!=''" class="box9">
                        <h3>Estatus: {{pinStatus}}</h3>
                    </div>
                    <!-- <button mat-button matStepperNext color="primary" [disabled]="pinStatus!='Asignación de NIP exitoso'" -->
                    <button mat-button matStepperNext color="primary" 
                    (click)="stepperNext('pin request')">Continuar</button>

                </ng-template>
            </mat-step>

            <mat-step [editable]="isEditable" [completed]="checkStepComplete(11)">
                <ng-template matStepLabel>Firma / Consentir</ng-template>
                <ng-template matStepContent>
                    <core-context-hint [hintId]="'emboSignature'"></core-context-hint>

                    <mat-button-toggle-group name="consentType" #consentType="matButtonToggleGroup" value="print">
                        <mat-button-toggle value="print">
                            <mat-icon>print</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="signature">
                            <mat-icon>draw</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="otp">
                            <mat-icon>smartphone</mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                    <div class="box box4">
                        <div *ngIf="consentType.value == 'otp'">
                            <h3>Consent by {{env.dict.otp}}</h3>
                            <core-otp-request [config]="otp1Config" (otpSuccess)="otpSuccess($event)"
                                (otpFail)="otpFail($event)"></core-otp-request>
                        </div>
                        <div *ngIf="consentType.value == 'signature'">
                            <h3>Consent by Signature</h3>
                            <ic-signature (acceptSignature)="handleSignature($event)"></ic-signature>
                        </div>
                        <div *ngIf="consentType.value == 'incode'">
                            <h3>Incode Consent (TBD)</h3>
                        </div>
                        <div *ngIf="consentType.value == 'print'">
                            <h3>Print and Sign</h3>
                            <button mat-button color="primary" [disabled]="printOK" (click)="printSend()">Print</button>
                            <button mat-button color="primary" [disabled]="!printOK" (click)="printDone()">OK</button>
                        </div>
                    </div>
                    <div>
                        <button mat-button matStepperNext color="primary" [disabled]="!consentOK"
                            (click)="stepperNext('consent')">Continuar</button>
                    </div>
                </ng-template>
            </mat-step>

            <mat-step [editable]="isEditable" [completed]="checkStepComplete(12)">
                <ng-template matStepLabel>Consentimiento de comercialización</ng-template>
                <ng-template matStepContent>
                    <mat-button-toggle-group name="consentType" #marketing="matButtonToggleGroup" value="1">
                        <mat-button-toggle value="1">
                            <mat-icon>email</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="2">
                            <mat-icon>subscriptions</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="3">
                            <mat-icon>smartphone</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="4">
                            <mat-icon>check</mat-icon>
                        </mat-button-toggle>
                        <!-- <mat-button-toggle value="incode">
                            <mat-icon>person_pin</mat-icon>
                        </mat-button-toggle> -->
                    </mat-button-toggle-group>
                    <div class="box box4">
                        <!-- <core-context-hint [hintId]="'emboMarketing'" [hintData]="{ section: marketing.value }"></core-context-hint> -->
                        <ng-container *ngIf="marketing.value == '1'">
                            <h3 style="grid-column: span 4;">Carátula de contrato</h3>
                            <core-context-hint style="grid-column: span 4;" [hintId]="'emboMarketing1'" [hintData]="{ section: marketing.value }"></core-context-hint>
                            <div *ngIf="caratulaOK==0">
                                <p style="grid-column: span 4;">Carátula de contrato enviada exitosamente.</p>
                                <button mat-button color="primary" (click)="caratulaOK=1">Aceptar</button>
                            </div>
                            <div *ngIf="caratulaOK==1">
                                <button mat-button color="primary" (click)="caratulaOK=2; marketing.value='2'">Si, Continuar</button>
                                <button mat-button color="primary" (click)="caratulaOK=0">No</button>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="marketing.value == '2'">
                            <h3 style="grid-column: span 4;">Incremento temprano</h3>
                            <core-context-hint style="grid-column: span 4;" [hintId]="'emboMarketing2'" [hintData]="{ section: marketing.value }"></core-context-hint>
                            <ng-container *ngIf="incrementoOK==0">
                                <p style="grid-column: span 4;">¿Te gusteria ser considerado para futuras ofertas de incremento de LC?</p>  
                                <mat-radio-group style="grid-column: span 4;" [(ngModel)]="incrementoSelection">
                                    <mat-radio-button value="Y">Si</mat-radio-button>
                                    <mat-radio-button value="N">No</mat-radio-button>
                                </mat-radio-group>                     
            
                                <button mat-button color="primary" [disabled]="incrementoSelection==''" (click)="incrementoOK=1; marketing.value='3'">Aceptar</button>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="marketing.value == '3'">
                            <h3 style="grid-column: span 4;">Digitilizatión del cliente</h3>
                            <core-context-hint style="grid-column: span 4;" [hintId]="'emboMarketing3'" [hintData]="{ section: marketing.value }"></core-context-hint>
                            <div *ngIf="digiOK==0">
                                <p style="grid-column: span 4;">Entrega la tarjeta al cliente y apóyalo para el registro en Mi Crédito.  </p>
                                <button mat-button color="primary" (click)="digiOK=1">Continuar</button>
                            </div>
                            <div *ngIf="digiOK==1">
                                <p style="grid-column: span 4;">¿Cliente digitalizado exitosamente en Mi Crédito?</p>
                                <button mat-button color="primary" (click)="digiOK=2">Si, Continuar</button>
                                <button mat-button color="primary" (click)="digiOK=3; marketing.value='4'">No</button>
                            </div>
                            <div *ngIf="digiOK==2">
                                <p style="grid-column: span 4;">Oferce tarjeta adicional el titular de la cuenta</p>
                                <button mat-button color="primary" (click)="digiOK=3; marketing.value='4'">Aceptar</button>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="marketing.value == '4'">
                            <h3>Terminado</h3>
                            <p style="grid-column: span 4;">¿Estás seguro de haber completado el expediente del cliente?</p>
                        </ng-container>
                    </div>
                    <div>
                        <button mat-button matStepperNext color="primary" [disabled]="marketing.value!='4'"
                            (click)="stepperNext('marketing')">Continuar</button>
                    </div>
                </ng-template>
            </mat-step>

            <!-- <mat-step [editable]="isEditable" [completed]="checkStepComplete(11)">
                <ng-template matStepLabel>Digitilizatión del cliente</ng-template>
                <ng-template matStepContent>
                    <div class="box box1" style="grid-column: span 4;">
                        <p>Entrega la tarjeta al cliente y apóyalo para el registro en Mi Crédito</p>
                        <button mat-button matStepperNext color="primary"
                            (click)="stepperNext('consent')">Continuar</button>
                    </div>
                </ng-template>
            </mat-step> -->

            <mat-step [editable]="isEditable" [completed]="checkStepComplete(13)">
                <credit-ac-view001 [actionCode]="actionCode" [listOnly]="true"></credit-ac-view001>
                <ng-template matStepLabel>Done</ng-template>
                <ng-template matStepContent>

                <div class="box box1" style="grid-column: span 4;">
                    <!-- <core-context-hint style="grid-column: span 4;" [hintId]="'emboFinalize'"></core-context-hint> -->
                    <core-smart-button [config]="newButtonConfig"></core-smart-button>
                    <core-smart-button [config]="otherServicesButtonConfig"></core-smart-button>
                </div>
                </ng-template>
        
            </mat-step>
        </mat-stepper>
        <div class="box box1" style="grid-column: span 4;">
            <!-- <core-smart-button [config]="newButtonConfig"></core-smart-button> -->
            <!-- <core-smart-button [config]="actionSaveButtonConfig"></core-smart-button> -->
            <!-- <core-smart-button [config]="otherServicesButtonConfig"></core-smart-button> -->
            <core-smart-button [config]="closeButtonConfig" color="warn"></core-smart-button>
        </div>
    <!-- </ng-template>         -->
</div>