import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

export interface CbResult {
  results: {
    bureau: string,
    customerId: string,
    score: number,
    status: string
  }[]
} 

@Injectable({
  providedIn: 'root'
})
export class CbApiService {

  public baseUri:string = 'http://localhost:4200';
  public apiKey:string = '';
  public token:string = '';
  public interviewCode:string = '';
  public interviewId:string = '';

  constructor(private http: HttpClient) { }

  callCB(endPoint: string, headers: HttpHeaders, body: any, onSuccess:(response: HttpResponse<any>) => void, onFailure:(response: any) => void, method: string = 'POST') {

    onSuccess(new HttpResponse<any>({ body: { result: 'yay'}, status: 200 } ));

    return;

    switch(method){
      case 'GET':
        return this.http.get<any>(this.baseUri + '/' + endPoint, { headers: headers, observe: 'response' })
        .pipe(map(response => {
          if (response.status == 200) {
            onSuccess(response);
          } else {
            onFailure(response);
          }
        }));
      default:
        return this.http.post<any>(this.baseUri + '/' + endPoint, body, { headers: headers, observe: 'response' })
        .pipe(map(response => {
          if (response.status == 200) {
            onSuccess(response);
            // this._user = response.user;
            // localStorage.setItem('isLoggedIn',  JSON.stringify(true));
            // localStorage.setItem('currentUser', JSON.stringify(this.user));
            // localStorage.setItem('auth_token',  JSON.stringify(response.user.authToken));
          } else {
            onFailure(response);
          }
        }));
      }
  }


  public callCb001(id: string, onSuccess: () => void, onFailure: () => void) {
    let body = {
      "clientId": id,
    };

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('api-version', '1.0');
    headers = headers.set('x-api-key', this.apiKey);

    let success = (response: HttpResponse<any>) => {
      onSuccess();  
    };

    let failure = (response: any) => {
      console.error('CB001 Call Failed');      
      console.error(response);      
      onFailure();
    };

    this.callCB('api/CB001', headers, body, success, failure); 
    // !.subscribe((response) => {
    // }); 
  }

  public callCb002(id: string, onSuccess: () => void, onFailure: () => void) {
    let body = {
      "clientId": id,
    };

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('api-version', '1.0');
    headers = headers.set('x-api-key', this.apiKey);

    let success = (response: HttpResponse<any>) => {
      onSuccess();  
    };

    let failure = (response: any) => {
      console.error('CB002 Call Failed');      
      console.error(response);      
      onFailure();
    };

    this.callCB('api/CB002', headers, body, success, failure);
    // !.subscribe((response) => {
    // }); 
  }

  public callApply(id: string, onSuccess: () => void, onFailure: () => void) {
    let body = {
      "clientId": id,
    };

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('api-version', '1.0');
    headers = headers.set('x-api-key', this.apiKey);

    let success = (response: HttpResponse<any>) => {
      onSuccess();  
    };

    let failure = (response: any) => {
      console.error('Apply Call Failed');      
      console.error(response);      
      onFailure();
    };

    this.callCB('api/Apply', headers, body, success, failure)
    // !.subscribe((response) => {
    // }); 
  }
}

