import { TestPageComponent } from './pages/test-page/test-page.component';
import { AudioRecorderComponent } from './modules/core/components/audio-recorder/audio-recorder.component';
import { CbFlow01Component } from './modules/credit/components/ext/cb/cb-flow01/cb-flow01.component';
import { AcView001Component } from './modules/credit/components/actions/ac-view001/ac-view001.component';
import { ViewAccountComponent } from './modules/credit/components/view-account/view-account.component';
import { CustomerPageComponent } from './pages/customer-page/customer-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { CoreModule } from './modules/core/core.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { QrScanComponent } from './modules/core/components/qr-scan/qr-scan.component';
import { AcMaintenance004Component } from './modules/credit/components/actions/ac-maintenance004/ac-maintenance004.component';
import { VaDashComponent } from './modules/credit/components/va-dash/va-dash.component';
import { AcMaintenance005Component } from './modules/credit/components/actions/ac-maintenance005/ac-maintenance005.component';
import { Timer2Component } from './modules/core/components/timer2/timer2.component';
import { ViewPerformanceComponent } from './modules/credit/components/view-performance/view-performance.component';
import { TasksPageComponent } from './pages/tasks-page/tasks-page.component';

const routes: Routes = [
  { path: 'home', component: HomePageComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'customer', component: CustomerPageComponent },
  { path: 'performance', component: ViewPerformanceComponent },
  { path: 'tasks', component: TasksPageComponent },

  // TESTING
  { path: 'api/action/view', component: AcView001Component },
  { path: 'customer3', component: CustomerPageComponent, data: { test: 'abc'} },
  { path: 'customer2', component: ViewAccountComponent },
  { path: 'qr', component: QrScanComponent },
  { path: 'cb', component: CbFlow01Component },
  { path: 'dash', component: VaDashComponent },
  { path: 'alco', component: AcMaintenance004Component },
  { path: 'embo', component: AcMaintenance005Component },
  { path: 'rec', component: AudioRecorderComponent },
  { path: 'time', component: Timer2Component },
  { path: 'test', component: TestPageComponent },
  // END TESTING

  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: HomePageComponent },                   
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CoreModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
