import { EnvironmentService } from 'src/app/services/environment.service';
import { ButtonConfig } from './../../entities/core.d';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'core-smart-button',
  templateUrl: './smart-button.component.html',
  styleUrls: ['./smart-button.component.scss']
})
export class SmartButtonComponent {

  public _visible: boolean = true;
  public selectedValue!: string | undefined;

  // @Input() model: { [x: string]: any; } | undefined;
  @Input() config!: ButtonConfig;
  @Input() type: string = ''; 
  // @Output() selectChange = new EventEmitter();
  // @Output() closed = new EventEmitter();

  public click() {
    this.config.click();
  }

  // set visible(isVisible: boolean) {
  //   this._visible = isVisible;
  // }

  // get visible() {
  //   if (this._visible && (!this.config.disabled() || this.config.hideIfDisabled === false)) {
  //     return true;
  //   }
  //     return false;
  // }

  constructor( 
    public env: EnvironmentService
  ) { }

  // ngOnInit() {
  //   console.log(this.config!.hideIfDisabled);
  // }

  // ngOnDestroy() {
  //   console.log('smart button destroy');
  // }
}
