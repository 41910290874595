<div class="container">
    <h2>Embossing Progress ({{env.embosserServiceName}})</h2>

    <table *ngIf="delay==0" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID </th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>
        <ng-container matColumnDef="account">
            <th mat-header-cell *matHeaderCellDef>Cuenta </th>
            <td mat-cell class="odd" *matCellDef="let element">{{ element.account }}</td>
        </ng-container>
        <ng-container matColumnDef="nameClient">
            <th mat-header-cell *matHeaderCellDef>Nombre del Cliente </th>
            <td mat-cell *matCellDef="let element">{{ element.clientName }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Estado </th>
            <td mat-cell class="odd" *matCellDef="let element">{{ element.status }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <core-smart-button *ngIf="delay==0" [config]="refreshButtonConfig"></core-smart-button>

    <div *ngIf="delay>0">
        <p>Status: {{cwStatus}}</p>
    </div>


</div>