import { CreditCardProduct } from 'src/app/modules/credit/entities/credit.d';
const cardProducts: CreditCardProduct[] = 
[
  { id: 0,  name: 'DILISA LP'                        , coercivity: 'BAJA', status: 'ACTIVE'  , imageUrl: 'https://micredito.liverpool.com.mx:9443/cdn/app/assets/seo/img/credito/DILISA.png' },
  { id: 1,  name: 'DILISA FAB'                       , coercivity: 'ALTA', status: 'ACTIVE'  , imageUrl: 'https://micredito.liverpool.com.mx:9443/cdn/app/assets/seo/img/credito/DILISA.png' },
  { id: 2,  name: 'CONSENT Live'                     , coercivity: 'ALTA', status: 'ACTIVE'  , imageUrl: 'https://micredito.liverpool.com.mx:9443/cdn/app/assets/seo/img/credito/LPC.png'},
  { id: 3,  name: 'CONSENT FAB'                      , coercivity: 'BAJA', status: 'INACTIVE', imageUrl: 'https://micredito.liverpool.com.mx:9443/cdn/app/assets/seo/img/credito/LPC.png'},
  { id: 4,  name: 'LIVERTU'                          , coercivity: 'ALTA', status: 'ACTIVE'  , imageUrl: 'https://micredito.liverpool.com.mx:9443/cdn/app/assets/seo/img/comparador/LIVERTU.png' },
  { id: 5,  name: 'FABRICATE FAB'                    , coercivity: 'ALTA', status: 'ACTIVE'  , imageUrl: 'https://micredito.liverpool.com.mx:9443/cdn/app/assets/seo/img/credito/LPC.png'},
  { id: 6,  name: 'LPC ROSA'                         , coercivity: 'ALTA', status: 'ACTIVE'  , imageUrl: 'https://micredito.liverpool.com.mx:9443/cdn/app/assets/seo/img/credito/LPC.png' },
];

export const config = {
  tenantId: 'liverpool',
  tenantName: 'Liverpool',
  corpLogoUrl: './assets/corplogo.lp.svg',
  tenantSubsidiaries: ['Corporate', 'Suburbia' ],
  tenantAcronym: 'LP',
  tenantEmailSuffix: 'default.com.mx',
  tenantFavIcon: './assets/icons/favicon.lp.ico',
  tenantCardProducts: cardProducts,
  httpTimeout: 2000,
  tenantProducts: {
    core: true,
    workFlow: true,
    credit: true,
  },
  tenantAPIs: {
    embossing: {
      id: 'cardwizard',
      name: 'Card Wizard',
    }
  },
  tenantParams: {
    includeCustInLocate: false,
    toggleNameSearch: false,
    showActionCodeValue: false,
    newAPI: true,
    useLocalCache: false,
    language: 'es_mx',
  },
  tenantAddress: 
  [
    {
      street1: '34 Calle de N Ortiz', 
      street2: '',
      suburb: 'Santa Fe', 
      metro: 'Ciudad de México',
      zip: '02040',
      district: 'Ciudad de México', 
      state: 'CDMX',
      country: 'Mexico'
    },
    {
      street1: '101 Calle de N Ortiz', 
      street2: '',
      suburb: 'Santa Fe', 
      metro: 'Ciudad de México',
      zip: '02040',
      district: 'Ciudad de México', 
      state: 'CDMX',
      country: 'Mexico'
    }
  ],
  serviceCardParms: [
    { title: "Seguros        ", icon: "health_and_safety", buttons: 1, fields: 2 , inputs: [{ value: '04',          name: 'Producto', type: 'text'}, { value: 'C',          name: 'Status'            , type: 'text' }                                                                                                                                                                     ], class: '' },
    { title: "Hub De Pagos   ", icon: "shopping_cart    ", buttons: 1, fields: 1 , inputs: [{ value: 'No activo',          name: 'Status'                        , type: 'text'},                                                                                                                                                                     ], class: '' },
    { title: "Cellular       ", icon: "phone_iphone     ", buttons: 1, fields: 1 , inputs: [{ value: 'Activo',          name: 'Estatus'                     , type: 'text'},                                                                                                                                                                   ], class: '' }, 
    { title: "Flexipagos     ", icon: "savings          ", buttons: 1, fields: 6 , inputs: [{ value: '0', name: 'Clas Flex          '            , type: 'text'}, { value: '17/07/2020', name: 'Update          '            , type: 'text'}, { value: '05/07/2021', name: 'Acceptance      ', type: 'text' }, { value: '09', name: 'Términos', type: 'text'          }, { value: '1', name: 'PMT', type: 'text' }, { value: '158.12', name: 'PMT AMT', type: 'text' } ], class: 'rows2' },
    { title: "Ventas Cruzadas", icon: "flashlight_on    ", buttons: 1, fields: 1 , inputs: [{ value: 'Si',          name: 'Enrolado?'                       , type: 'text'},                                                                                                                                                                     ], class: '' },
    { title: "Incremento     ", icon: "trending_up      ", buttons: 1, fields: 1 , inputs: [{ value: 'Si',          name: 'Oferta Disponible'         , type: 'text' }                                                                                                                                                                     ], class: '' },
    { title: "Adicionales    ", icon: "person_add       ", buttons: 1, fields: 1 , inputs: [{ value: '2',          name: '# de Addicionales'                       , type: 'text'},                                                                                                                                                                     ], class: '' },
  ],
  dict: {
    store: "Tienda",
    otp: "OTP",
  },
  tenantRegions: [
    'CDMX', 'EDO MX'
  ],
  tenantStores: [
    { name: 'CENTRO'        , region: 'CDMX' },
    { name: 'INSURGENTES'   , region: 'CDMX' },
    { name: 'POLANCO'       , region: 'CDMX' },
    { name: 'SATELITE'      , region: 'CDMX' },
    { name: 'PERISUR'       , region: 'CDMX' },
    { name: 'COAPA'         , region: 'CDMX' },
    { name: 'SANTAFE'       , region: 'CDMX' },
    { name: 'JALAPA'        , region: 'CDMX' },
    { name: 'CORDOBA'       , region: 'CDMX' },
    { name: 'VILLAHERMOSA'  , region: 'CDMX' },
    { name: 'TUXTLA'        , region: 'EDO MX' },
    { name: 'PUEBLA'        , region: 'EDO MX' },
    { name: 'COATZACOALCOS' , region: 'EDO MX' },
    { name: 'POZARICA'      , region: 'EDO MX' },
    { name: 'TAPACHULA'     , region: 'EDO MX' },
    { name: 'MTYCENTRO'     , region: 'EDO MX' },
    { name: 'Store 1', region: 'CDMX'},    
    { name: 'Store 2', region: 'CDMX'},    
    { name: 'Store 3', region: 'CDMX'},    
    { name: 'Store 4', region: 'CDMX'},    
    { name: 'Store A', region: 'EDO MX'},    
    { name: 'Store B', region: 'EDO MX'},    
    { name: 'Store C', region: 'EDO MX'},    
    { name: 'Store D', region: 'EDO MX'},    
  ],
  tenantActions: [
    { id: 'EMBO', factor: 1 },
    { id: 'DOMI', factor: 2 },
    { id: 'ACTI', factor: 2 },
    { id: 'NOTA', factor: 15 },
    { id: 'M409', factor: 6 },
    { id: 'M456', factor: 10 },
  ],
  tenantInsuranceProducts: [
    { id: 3, name: 'PIF BASICO  ', type: 'Insurance', cover: 'Basico'     , cost: 5  },
    { id: 4, name: 'PIF PLUS    ', type: 'Insurance', cover: 'Plus'       , cost: 8  },
    { id: 5, name: 'PIF PAREJA  ', type: 'Insurance', cover: 'Pareja'     , cost: 12 },
    { id: 6, name: 'PIF SUPERIOR', type: 'Insurance', cover: 'Superior'   , cost: 17 },   
  ]

};
