import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-tasks-page',
  templateUrl: './tasks-page.component.html',
  styleUrls: ['./tasks-page.component.scss']
})
export class TasksPageComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public selectQueues(): string[] {
    return this.auth.user.profile?.taskQueues ?? [];
  }

  taskSelected(params: any) {
    const navigationExtras: NavigationExtras = {
      queryParams: { action: 'loadTask', ...params }
    };
    this.router.navigate(['/customer'], navigationExtras);
  } 

}
