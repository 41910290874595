import { CreditAccount, CreditAccountCollectionInfo, CreditDelinquencyRecord, CreditProfileRecord, CreditCard, CreditPlan, CreditTransaction, CreditPromo, CreditCustomer, CreditClient } from './../../credit/entities/credit.d';
import { EnvironmentService } from 'src/app/services/environment.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { throwError, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CreditLocateReq } from '../../credit/entities/credit.d';
import { ContextHintData } from '../entities/core';

@Injectable({
  providedIn: 'root'
})
export class VscapiService {

  constructor(
    public env: EnvironmentService,
    private http: HttpClient
  ) { 
    this.context.selected = new Map<string, any>();
  }

  public baseUri: string = this.env.apiUrl + this.env.apiBaseConsole;
  private context: any = {};
  private cache: Map<string, { key: string, url: string, updated: Date, body: any, response: HttpResponse<any>, hits: number }> = new Map();

  public set selectedCustomer(id: string) {
    this.context.selected.set('customer', id);
  }
  public get selectedCustomer() {
    return this.context.selected.get('customer');
  }
  public set selectedAccount(id: string) {
    this.context.selected.set('account', id);
  }
  public get selectedAccount() {
    return this.context.selected.get('account');
  }
  public set selectedCard(id: string) {
    this.context.selected.set('card', id);
  }
  public get selectedCard() {
    return this.context.selected.get('card');
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  private checkLocalCache(url: string, method: string, body: any = {}):HttpResponse<any> | boolean {
    const bodyhash = (body) ? this.cacheHash(JSON.stringify(body)) : '';
    const key = `${method}:${url}:${bodyhash}`;

    const cacheEntry = this.cache.get(key);
    if (cacheEntry) {
      return new HttpResponse({
        body: cacheEntry.response.body,
        headers: cacheEntry.response.headers,
        status: 201,
        statusText: 'Local Cache Response',
        url: cacheEntry.response.url ?? undefined,
      });
    }
    return false;
  }

  private cacheHash(text: string) {
      var hash = 0;
      for (var i = 0; i < text.length; i++) {
          var code = text.charCodeAt(i);
          hash = ((hash<<5)-hash)+code;
          hash = hash & hash; // Convert to 32bit integer
      }
      return Math.abs(hash);
  }

  private updateLocalCache(method: string, body: any, response: HttpResponse<any>) {
    if (!this.env.useLocalCache) {
      return;
    }
    if (method == 'GET' && typeof(response.url) == 'string' && response.url.trim().length > 0 && response.status == 200) {
      const url = response.url;
      const bodyhash = (body) ? this.cacheHash(JSON.stringify(body)) : '';
      const key = `${method}:${response.url}:${bodyhash}`;
      console.log('setCache', key, response);
      this.cache.set(key, {
        key: key,
        url: url,
        updated: new Date(),
        body: body,
        response: response,
        hits: 0
      });
    }
  }

  private callAPI(endPoint: string, headers: any, body: any, onSuccess: (response: HttpResponse<any>) => HttpResponse<any>, onFailure: (response: any) => HttpResponse<any>, method: string = 'POST') {

    console.log(`callAPI request ${method} (${endPoint})`, body);

    const allHeaders = { ...this.env.apiHeaders, ...headers };
    const url = this.baseUri + endPoint;
    const handleResponse = (response: HttpResponse<any>): HttpResponse<any> => {
      console.log(`callAPI response ${method} (${endPoint})`, response);
      // Update the local cache
      this.updateLocalCache(method, body, response);
      if (response.status == 200) {
        return onSuccess(response);
      } else {
        return onFailure(response);
      }
    }

    // Check the local cache
    const cacheResponse = this.checkLocalCache(url, method, body);
    if (typeof(cacheResponse) !== 'boolean') {
      return of(handleResponse(cacheResponse));
    }

    switch (method) {
      case 'GET':
        return this.http.get<any>(url, { headers: allHeaders, observe: 'response' })
          .pipe(
            catchError(this.handleError),
            map(handleResponse)
          );
      case 'PUT':
        return this.http.put<any>(url, body, { headers: allHeaders, observe: 'response' },)
          .pipe(
            catchError(this.handleError),
            map(handleResponse)
          );
      case 'POST':
        return this.http.post<any>(url, body, { headers: allHeaders, observe: 'response' },)
          .pipe(
            catchError(this.handleError),
            map(handleResponse)
          );
      default:
        return this.http.post<any>(url, body, { headers: allHeaders, observe: 'response' },)
          .pipe(
            catchError(this.handleError),
            map(handleResponse)
          );
    }
  }

  public locateClient(locateReq: CreditLocateReq): Observable<CreditClient[]> {
    let search = { ...locateReq };
    var method = 'POST';
    var body = { search: search, tree: false };
    var successCall = (response: HttpResponse<any>) => {
      if (response.body.status == 'success') {
      } else {
        console.error('locateClient FAIL');
      }
      return response;
    }

    return this.callAPI(
      '/clients',
      {},
      body,
      successCall,
      (r: HttpResponse<any>) => { return r },
      method
    ).pipe(
      catchError(this.handleError),
      map(response => {
        if (response.ok) {
          if (response.body.status != 'success') {
            console.error('No clients returned', response.body.status);
            return [];
          }
          return response.body.data.clients;
        } else {
          console.error('failed api call', response);
          return [];
        }
      })
    );
  }

  public getClient(id: string): Observable<CreditClient> {
    var method = 'GET';
    var body = {};

    return this.callAPI(
      `/clients/${id}`,
      {},
      body,
      (r: HttpResponse<any>) => { return r },
      (r: HttpResponse<any>) => { return r },
      method
    ).pipe(
      catchError(this.handleError),
      map(response => {
        if (response.ok) {
          if (response.body.status != 'success') {
            console.error('No client returned', response.body.status);
            return {};
          }
          return response.body.data.clients[0];
        } else {
          console.error('failed api call', response);
          return {};
        }
      })
    );
  }

  public getCustomer(id?: string): Observable<CreditCustomer> {
    var method = 'GET';
    var body = {};
    if (!id) {
      if (this.selectedCustomer) {
        id = this.selectedCustomer;
      }
      else {
        return of({
          id: ''
        });
      }
    }

    return this.callAPI(
      `/customers/${id}`,
      {},
      body,
      (r: HttpResponse<any>) => { return r },
      (r: HttpResponse<any>) => { return r },
      method
    ).pipe(
      catchError(this.handleError),
      map(response => {
        if (response.ok) {
          if (response.body.status != 'success') {
            console.error('No customer returned', response.body.status);
            return {};
          }
          return response.body.data.customers[0];
        } else {
          console.error('failed api call', response);
          return {};
        }
      })
    );
  }

  public getAccount(id?: string, dataId: string = ''): Observable<CreditAccount> {
    var method = 'GET';
    var body = {};

    if (!id) {
      id = this.selectedAccount;
    }

    return this.callAPI(
      `/accounts/${id}/${dataId}`,
      {},
      body,
      (r: HttpResponse<any>) => { return r },
      (r: HttpResponse<any>) => { return r },
      method
    ).pipe(
      catchError(this.handleError),
      map(response => {
        if (response.ok) {
          if (response.body.status != 'success') {
            console.error('No account returned', response.body.status);
            return {};
          }
          return response.body.data.accounts[0];
        } else {
          console.error('failed api call', response);
          return {};
        }
      })
    );
  }

  public getAccountCards(id?: string): Observable<CreditCard[]> {
    if (!id) {
      if (this.selectedAccount) {
        id = this.selectedAccount;
      }
      else {
        return of([]);
      }
    }
    return this.getAccount(id, 'cards').pipe(
      map(response => {
        if (!response.cards) {
          return [];
        }
        return response.cards;
      })
    )
  }

  public getAccountCollections(id: string): Observable<CreditAccountCollectionInfo | undefined> {
    return this.getAccount(id, 'collections')
      .pipe(
        map(response => {
          return response.accountCollectionInfo;
        })
      );
  }

  public getAccountDelinquency(id: string): Observable<CreditDelinquencyRecord[]> {
    if (id.trim() == '') {
      return of([]);
    }
    if (!this.context[id]) this.context[id] = {};
    // Check every 10 minutes.
    if (!this.context[id].delinquency || (this.context[id].lastFetchDelinquency && Date.now() - this.context[id].lastFetchDelinquency > 600000)) {
      if (!this.context[id].delinquency || this.context[id].delinquency.length == 0) {
        this.context[id].delinquency = [];
        this.context[id].lastFetchDelinquency = Date.now();
        this.getAccount(id, 'delinquency').subscribe(response => {
          let def: CreditDelinquencyRecord[] = [];
          this.context[id].delinquency = response.accountDelinquencyInfo ? response.accountDelinquencyInfo : def;
        })
      }
    }
    return of(this.context[id].delinquency);
  }

  public getAccountProfile(id: string): Observable<CreditProfileRecord[]> {
    if (id.trim() == '') {
      return of([]);
    }
    if (!this.context[id]) this.context[id] = {};
    // Check every 10 minutes.
    if (!this.context[id].profile || (this.context[id].lastFetchProfile && Date.now() - this.context[id].lastFetchProfile > 600000)) {
      if (!this.context[id].profile || this.context[id].profile.length == 0) {
        this.context[id].profile = [];
        this.context[id].lastFetchProfile = Date.now();
        this.getAccount(id, 'profile').subscribe(response => {
          let def: CreditProfileRecord[] = [];
          this.context[id].profile = response.accountProfileInfo ? response.accountProfileInfo : def;
        })
      }
    }
    return of(this.context[id].profile);
  }

  public getAccountPlans(id: string): Observable<CreditPlan[]> {
    if (id.trim() == '') {
      return of([]);
    }
    if (!this.context[id]) this.context[id] = {};
    // Check every 10 minutes.
    if (!this.context[id].plans || (this.context[id].lastFetchPlans && Date.now() - this.context[id].lastFetchPlans > 600000)) {
      if (!this.context[id].plans || this.context[id].plans.length == 0) {
        this.context[id].plans = [];
        this.context[id].lastFetchPlans = Date.now();
        this.getAccount(id, 'plans').subscribe(response => {
          let def: CreditPlan[] = [];
          this.context[id].plans = response.accountPlansInfo ? response.accountPlansInfo : def;
        })
      }
    }
    return of(this.context[id].plans);
  }

  public getCard(id: string, dataId: string = ''): Observable<CreditCard> {
    var method = 'GET';
    var body = {};

    return this.callAPI(
      `/cards/${id}/${dataId}`,
      {},
      body,
      (r: HttpResponse<any>) => { return r },
      (r: HttpResponse<any>) => { return r },
      method
    ).pipe(
      catchError(this.handleError),
      map(response => {
        if (response.ok) {
          if (response.body.status != 'success') {
            console.error('No card returned', response.body.status);
            return {};
          }
          return response.body.data.cards[0];
        } else {
          return {};
        }
      })
    );
  }

  public getAccountTransactions(id: string):Observable<CreditTransaction[]> {
    var method = 'POST';
    var body = { search: {
      period: 'current'
    } };

    return this.callAPI(
      `/transactions/${id}`,
      {},
      body,
      (r: HttpResponse<any>) => { return r },
      (r: HttpResponse<any>) => { return r },
      method
    ).pipe(
      catchError(this.handleError),
      map(response => {
        if (response.ok) {
          if (response.body.status != 'success') {
            console.error('No transactions returned', response.body.status);
            return {};
          }
          return response.body.data.transactions;
        } else {
          console.error('failed api call', response);
          return {};
        }
      })
    );
  }

  public getPromoCount(accountNumber?: string ): Observable<number> {
    if (!accountNumber) {
      if (this.selectedAccount) {
        accountNumber = this.selectedAccount;
      } else {
        return of(0);
      }
    }
    return this.getAccountPromos(accountNumber)
    .pipe(map(promos => {
      return promos.length;
    }))
  }


  public getAccountPromos(account: string):Observable<CreditPromo[]> {
    var method = 'GET';
    var body = {};

    return this.callAPI(
      `/promos/${account}`,
      {},
      body,
      (r: HttpResponse<any>) => { return r },
      (r: HttpResponse<any>) => { return r },
      method
    ).pipe(
      catchError(this.handleError),
      map(response => {
        if (response.ok) {
          if (response.body.status != 'success') {
            console.error('No promos returned', response.body.status);
            return {};
          }
          return response.body.data.promos;
        } else {
          console.error('failed api call', response);
          return {};
        }
      })
    );
  }

  public accountUnblock(id?: string) : Observable<{ status: string, account: CreditAccount}>{
    var method = 'POST';
    var body = {};

    if (!id) {
      id = this.selectedAccount;
    }

    return this.callAPI(
      `/accounts/${id}/unblock`,
      {},
      body,
      (r: HttpResponse<any>) => { return r },
      (r: HttpResponse<any>) => { return r },
      method
    ).pipe(
      catchError(this.handleError),
      map(response => {
        if (response.ok) {
          if (response.body.status != 'success') {
            console.error('Account Unblock Failed', response.body.status);
            return { status: 'failed', account: { id: id } };
            // return {};
          }
          return { status: response.body.status, account: response.body.data.accounts[0] };
          // return response.body.data.accounts[0];
        } else {
          console.error('failed api call', response);
          return { status: 'failed', account: { id: id } };
          // return {};
        }
      })
    );
  }

  public cardUnblock(id?: string) : Observable<{ status: string, card: CreditCard}>{
    var method = 'POST';
    var body = {};

    if (!id) {
      id = this.selectedCard;
    }

    return this.callAPI(
      `/cards/${id}/unblock`,
      {},
      body,
      (r: HttpResponse<any>) => { return r },
      (r: HttpResponse<any>) => { return r },
      method
    ).pipe(
      catchError(this.handleError),
      map(response => {
        if (response.ok) {
          if (response.body.status != 'success') {
            console.error('Card Unblock Failed', response.body.status);
            return { status: 'failed', card: { id: id } };
            // return {};
          }
          return { status: response.body.status, card: response.body.data.cards[0] };
          // return response.body.data.cards[0];
        } else {
          console.error('failed api call', response);
          return { status: 'failed', card: { id: id } };
          // return {};
        }
      })
    );
  }

  public getHint(id: string, language: string = ''): Observable<ContextHintData> {
    var method = 'GET';
    var body = {};

    return this.callAPI(
      `/hints/${id}?language=${language}`,
      {},
      body,
      (r: HttpResponse<any>) => { return r },
      (r: HttpResponse<any>) => { return r },
      method
    ).pipe(
      catchError(this.handleError),
      map(response => {
        if (response.ok) {
          if (response.body.status != 'success') {
            console.error('No hint returned for id ' + id, response.body.status);
            return null;
          }
          return response.body.data;
        } else {
          console.error('failed api call', response);
          return null;
        }
      })
    );
  }
}
