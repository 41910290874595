<meta charset="utf-8">
<div class="container">
    <mat-card *ngFor="let svc of serviceCards;" class={{svc.class}}>
        <mat-card-title>{{svc.title}}</mat-card-title>
        <mat-card-content>
            <div class="card-grid">
                <div class="card-icon-column">
                    <div class="card-icon-container">
                        <mat-icon class="card-icon" inline="true">{{svc.icon}}</mat-icon>
                    </div>
                </div>
                <div class="card-icon-column">
                    <ng-container *ngFor="let fld of svc.fields">
                        <core-data-field-view *ngIf="fld.type=='text'" class="svc-input" type='{{fld.type}}' label="{{fld.label}}" value="{{fld.value}}" [readonly]="false"></core-data-field-view>
                        <div *ngIf="fld.type=='none'" class="svc-input"></div>
                        <mat-form-field *ngIf="fld.type=='date'" class="svc-input" appearance="fill"><mat-label>{{fld.label}}</mat-label><input matInput [matDatepicker]="picker"><mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle><mat-datepicker #picker></mat-datepicker></mat-form-field>
                    </ng-container>
                    <core-smart-button *ngFor="let but of svc.buttons" [config]="but" ></core-smart-button>
                </div>
            </div>
        </mat-card-content>
        <!-- <mat-card-actions>
            <div></div>
            <core-smart-button *ngFor="let but of svc.buttons" [config]="but" ></core-smart-button>
        </mat-card-actions> -->
    </mat-card>
</div>
