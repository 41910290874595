<div id="signature-pad" class="signature-pad">
    <div class="signature-pad--body">
        <canvas style="touch-action: none; user-select: none;" ></canvas>
    </div>
    <div class="signature-pad--footer">
        <div class="description">Sign above</div>
        
        <div class="signature-pad--actions">
            <button type="button" mat-raised-button color=""
                (click)="undo()"
                matTooltip="Undo"
                matTooltipPosition="below"
                matTooltipShowDelay="200"
                matTooltipHideDelay="500"
                aria-label="Undo">
                <mat-icon>undo</mat-icon>
            </button>                    
            <button type="button" mat-raised-button 
                (click)="clear()"
                matTooltip="Clear the signature"
                matTooltipPosition="below"
                [matTooltipShowDelay]="200"
                [matTooltipHideDelay]="500"
                aria-label="Clear">
                <mat-icon>clear</mat-icon>
            </button>                    
            <div class="button-spacer"></div>
            <button type="button" mat-raised-button color="primary"
                (click)="accept()"
                matTooltip="Accept the signature"
                matTooltipPosition="below"
                [matTooltipShowDelay]="200"
                [matTooltipHideDelay]="500"
                aria-label="Accept">
                <mat-icon>verified_user</mat-icon>Accept
            </button>                    
            <div class="button-spacer"></div>
            <button type="button" mat-raised-button color="warn"
                (click)="cancel()"
                matTooltip="Cancel"
                matTooltipPosition="below"
                [matTooltipShowDelay]="200"
                [matTooltipHideDelay]="500"
                aria-label="Cancel">
                <mat-icon>cancel</mat-icon>Cancel
            </button>                    
        </div>
    </div>
</div>
