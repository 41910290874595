import { VscapiService } from './../../../core/services/vscapi.service';
import { CreditAccount, CreditCard } from './../../entities/credit.d';
import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';

@Component({
  selector: 'credit-account-cards',
  templateUrl: './account-cards.component.html',
  styleUrls: ['./account-cards.component.scss']
})
export class AccountCardsComponent implements OnInit, OnChanges {

  @Input() account: CreditAccount = {
    id: '',
    org: ''
  };
  public cards: CreditCard[] = [];
  
  public displayedColumns: string[] = [ 'select', /* 'cardNumber',*/ 'product', 'expiryDate', 'reg', 'blockCode', 'blockCodeDesc', 'st', 'stDesc', 'gp', 'gpDesc', 'tg', 'tgDesc', 'embossedName' ];

  constructor(
    public vscapiService: VscapiService
  ) { }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.account) {
      // this.cards = changes.account.currentValue.cards ?? [];
      this.cards = [];
      if (changes.account.currentValue.cards) {
        changes.account.currentValue.cards.forEach((cardNumber: string) => {
          this.vscapiService.getCard(cardNumber).subscribe(card => {
            this.cards.push(card);
            this.cards = [...this.cards];
          })
        });
      }
    }
  }

}
