import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appFontImage]'
})
export class FontImageDirective implements OnInit {

  @Input() character!: string; 

  constructor(private el: ElementRef, private renderer: Renderer2) { }


  ngOnInit() {
    if (this.el) {
      this.renderer.setAttribute(this.el.nativeElement, 'src', 'https://assetspwa.liverpool.com.mx/assets/images/logos/liverpool-logo.svg');
      this.renderer.setAttribute(this.el.nativeElement, 'color', 'yellow');
    }  
  }

  updateGravatar(email: string): void {
    if (!email || !this.el.nativeElement) {
      return;
    }

    this.renderer.setAttribute(this.el.nativeElement, 'background-color', 'blue');    
//    this.renderer.set
  }
  
}
