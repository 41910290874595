import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'core-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {

  @Input() isRunning: boolean = false;

  timeSpent: number = 0;
  displayTime: string = '00:00';

  startTimer() {
    setInterval(() => {
      if (this.isRunning) {
        this.timeSpent++;
        this.displayTime = new Date(this.timeSpent * 1000).toISOString().substring(14,19);
      }
    },1000)
  }

  //1970-01-01T00:00:18.000Z

  pauseTimer() {
    this.isRunning = false;
    //clearInterval(this.interval);
  }

  constructor() { }

  ngOnInit(): void {
    this.isRunning = true;
    this.startTimer();
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   console.log(changes);
  // }

}
