import { EnvironmentService } from 'src/app/services/environment.service';
import { Component, Input, OnInit } from '@angular/core';
import { CreditAccount } from '../../entities/credit.d';
import { VplService } from '../../services/vpl.service';

@Component({
  selector: 'credit-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  callDate!: Date;

  constructor(
    public creditSvc: VplService,
    public env: EnvironmentService
  ) { }

  private _account!: CreditAccount;
  @Input()
  public get account(): CreditAccount {
    if (this.env.newAPI) {
      return (this._account) ? this._account: {
        id: '',
        org: '000'
      };
    } else {
      return (this.creditSvc.account) ? this.creditSvc.account: {
        id: '',
        org: '000'
      };
    }
  }
  public set account(value: CreditAccount) {
    this._account = value;
  }


  ngOnInit(): void {
    this.callDate = this.getDataTime();
  }

  getDataTime(){
    return this.creditSvc.callTimestamp;
  }

}
