    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name"                   ><th mat-header-cell *matHeaderCellDef>Promo Name      </th><td mat-cell                  *matCellDef="let element">{{ element.name          }}</td></ng-container>
        <ng-container matColumnDef="status"                 ><th mat-header-cell *matHeaderCellDef>Status          </th><td mat-cell class="odd"      *matCellDef="let element">{{ element.status        }}</td></ng-container>
        <ng-container matColumnDef="desc"                   ><th mat-header-cell *matHeaderCellDef>Description     </th><td mat-cell                  *matCellDef="let element">{{ element.desc          }}</td></ng-container>
        <ng-container matColumnDef="select"                 ><th mat-header-cell *matHeaderCellDef>Select          </th><td mat-cell                  *matCellDef="let element"><core-smart-button [config]="getActionCode(element.action)"></core-smart-button></td></ng-container>
        <ng-container matColumnDef="note"                   ><th mat-header-cell *matHeaderCellDef>Note            </th><td mat-cell                  *matCellDef="let element">{{ element.note          }}</td></ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

