import { SnackBarService } from './../../core/services/snack-bar.service';
import { StateDataEMBO } from './../components/actions/ac-maintenance005/ac-maintenance005.component';
import { FlowQueueObject, FlowStateObject } from './../../workflow/entities/workflow.d';
import { FlowQueueService } from './../../workflow/services/flow-queue.service';
import { Injectable } from '@angular/core';
import { Observable, of, scheduled, Subject } from 'rxjs';

export interface DialogData {
  selectedClientType: string;
  rfc: string;
  bin: string;
  openYear: string;
  dueDate: string;
  cardPaymentAmt: string;
}

export interface AddressData {
  streetNumber: string;
  streetName: string;
  city: string;
  zip: string;
  country: string;

  suburb        ?: string,
  state         ?: string,
  nearestCorner ?: string,
  duration      ?: string,
  homeType      ?: string,
}

export interface CustomerData {
  firstName: string;
  firstName2: string;
  lastName: string;
  lastName2: string;
  dateOfBirth: Date;
  gender: string;
  rfc?: string;
  income?: number;
  nationality: string;
  birthCountry: string;
  curp: string;

  home: AddressData;
  employer: EmployerData;
  references: ReferenceData[];
}

export interface EmployerData {
  department : string, 
  industry   : string, 
  name       : string, 
  phone      : string, 
  address    : AddressData,
}

export interface ReferenceData {
  name         : string,
  relationship : string,
  cellPhone    : string,
  homePhone    : string,
}

export interface ProductData {
  sku: string;
  price: number;
  description: string;
  category: string;
  salesPerson?: string;
}

export interface ApplicationData {
  applicationNumber: string;
  branch: string;
  marketingSource?: string;
  customer: CustomerData;
  product?: ProductData;
  downPercent?: number;
  financeAmount?: number;
  loanDestination?: string;
  plan: PaymentPlanDetail;
}

export interface PaymentPlanDetail {
  paymentNumber: number;
  paymentDate: Date;
  paymentAmount: number;
  principal: number;
}

export interface ActionQueueObject extends FlowQueueObject {
  menuItem:
  { 
    id:string, 
    title:string, 
    icon:string, 
    default:boolean,
    tag:string,
    uniqueTag?:string, 
  }
}

@Injectable({
  providedIn: 'root'
})

export class ContextService {

  public activeActions!: any[]; 
  public _selectedOption!: string[];
  public get selectedOption() {
    return this._selectedOption;
  }
  public set selectedOption(value) {
    this._selectedOption = value;
    // console.error(`SET selectedOption = ${value}`);
  }
  public selectedOptionAcct!: string[];
  public selectedState?: FlowStateObject;

  // public actionIdToLoad?: { taskId: string, account: string};

  public customer?: CustomerData;
  public isCustomerIdentified: boolean = false;

  public accountActions!: ActionQueueObject[];

  // Performance View Context
  public selectedOptionPerformance?: string[];

  public actionVars = {
    view: {
      noteValue: '',
      priorViews: [
        { date: new Date('2022/01/14'), user: 'f.morales' , action: 'NOTA', note: 'The account has been reviewed. Credit line increase approved.' },
        { date: new Date('2022/01/11'), user: 'd.smith' , action: 'NOTA', note: 'Credit Line increase requested.' },
      ]  
    },
    ACTI: { noteValue: '' },
    DOMI: { noteValue: '' },
    EMBO: { noteValue: '' },
    M409: { noteValue: '' },
    ALCO: { rfc: '', appNum: '' },
    OTP: {
      noteValue: '',
      priorViews: [
        { date: new Date('2022/01/22'), user: 'j.gonzalez', action: 'DOM1', note: 'DOM1. 0004178490999963636: Address confirmed.' },
        { date: new Date('2022/01/20'), user: 'j.gonzalez', action: 'DOM1', note: 'DOM1. 0004178490999963637: Address confirmed.' },
        { date: new Date('2022/01/14'), user: 'j.gonzalez', action: 'DOM1', note: 'DOM1. 0004178490999963640: Address confirmed.' },
      ]  
    },
  };

  // public actionVarsChange: Subject<any> = new Subject<any>();

  constructor(
    private queue: FlowQueueService,
    private snack: SnackBarService
  ) { 
    this.clear();
  }

  checkCurrentActionOnCard(action: string, cardIn: string) {
    var ut = `${action}-${cardIn}`;
    for (let i = 0; i < this.accountActions.length; i++) {
      const aa = this.accountActions[i];
      if (aa.state?.stateData.uniqueTag === ut ) {
        return true;
      }
    }
    return false;
  }

  loadAccountActions(account: string = '') {
    this.accountActions = [];
    if (account != '') {
      this.queue.getQueueObjects(account).subscribe(actions => {
        actions.forEach(action => {
          if (action.state?.type == 'action' && (action.state?.stateData.status == 'new' || action.state?.stateData.status == 'inprogress') ) {
            let m = {
              id: action.state.id,
              title: action.state.subType,
              icon: 'pending',
              default: false,
              tag: action.state.stateData.tag,
            }
            let aqo: ActionQueueObject = { ...action, menuItem: m };
            this.accountActions.push(aqo);
          }
        });
      });
    }
  }

  loadActionState(queueId: string, stateId: string, stateUpdated: (state: FlowStateObject) => void) {
    this.queue.actQueueObject(queueId, stateId, 'get').subscribe(queueObject => {
      if (queueObject?.state && queueObject.state.type == 'action' && queueObject.state.stateData.status != 'complete') {
        this.selectedState = queueObject.state;
        stateUpdated(queueObject.state);
      }
    });
  }

  initState(actionCode: string) {
    var state: FlowStateObject = {
      id: '',
      logId: '',
      createTimestamp: new Date(),
      type: 'action',
      subType: actionCode,
      stateData: {},
      timerId: ''
    }
    
    switch (actionCode) {
      case "EMBO": 
        let stateData: StateDataEMBO = {
          account: '',
          card: '',
          step: 0
        };
        state.stateData = stateData;
        break;
    }

    return state;
  }

  saveState(account: string, state: FlowStateObject, additionalQueues: string[], callBack: (response: { data: FlowStateObject, status: string }) => void) {
    this.queue.pushStateToQueue(account, state, additionalQueues).subscribe(response => {
      if (response.status) {
        if (response.status == 'success'){
          callBack(response);
        } 
      } 
    });
  }

  addAction(id: string, icon?: string, stateId?: string, timerId: string = '') {
    if (this.activeActions.length >= 1) {
      // alert('Please complete and close current action before starting a new one. ' + this.activeActions.length );
      // this.snack.openSnackBar('Please complete and close current action before starting a new one.', 'OK');
    } else {
      // if (this.activeActions.filter(item => item.id != undefined && item.id == id).length == 0) {
        // Remove current action from accountActions
        this.accountActions.forEach((action,index,array) => {
          if (action.menuItem.id == stateId) {
            array.splice(index, 1);
          }
        });

        // Create the active slot and switch to it
        // console.error(id);
        this.activeActions.push({ id: id, title: id, icon: icon ?? 'question_mark', status: 'init', timerId: timerId});    
        this.selectedOption = [id];
      // }
      // else {
      //   // alert('An action ' + id + ' is already in progress.');
      //   this.snack.openSnackBar('An action ' + id + ' is already in progress.', 'OK');
      //   return true;
      // }
    }
    return true;
  }

  removeAction(id: string, menu: string = 'view') {
    this.selectedOption = ['view'];
    let f = this.activeActions.map(function(e) { return e.id; });
    let pos = f.indexOf(id);

    if (pos > -1) {
      this.activeActions.splice(pos, 1);
    }
    else {
      // alert('No action ' + id + ' found.');
      this.snack.openSnackBar('No action ' + id + ' found.', 'OK');
    }

    this.selectedOption = [menu];
    delete this.selectedState;
    return true;
  }

  clearActions() {
    this.activeActions = [];
    this.accountActions = [];
    delete this.selectedState;
  }

  public getNotes(filter: { action: string } ): Observable<{ date: Date, user: string, action: string, note: string }[]> {
    return of(this.actionVars.view.priorViews.filter(item => (!filter.action) || item.action == filter.action ).sort((obj1, obj2) => {
      if (obj1.date > obj2.date) {
          return -1;
      }
  
      if (obj1.date < obj2.date) {
          return 1;
      }
  
      return 0;
    }));
  }

  clear() {
    this.activeActions = []; 
    this.selectedOption = ['view'];
    this.selectedOptionAcct = ['balances'];
    delete this.selectedState;
    delete this.customer;
    
    this.clearActions();
  
    this.selectedOptionPerformance = ['dashboard'];

    this.isCustomerIdentified = false;
  
  }

}

