<div>
<!-- <div *ngIf="this.vplService.customer"> -->
    <table mat-table [dataSource]="this._dataSource" class="mat-elevation-z8" #cardTable>
        <ng-container matColumnDef="select"       ><th mat-header-cell *matHeaderCellDef>Select Card                     </th><td mat-cell             *matCellDef="let element"><button class="fill-button" mat-raised-button (click)="selectCard(element)" disabled="{{cardIsDisabled(element)}}">{{ element.id }}</button></td></ng-container>
        <ng-container matColumnDef="cardNumber"   ><th mat-header-cell *matHeaderCellDef>Card Number                     </th><td mat-cell class="odd" *matCellDef="let element">{{ element.cardNumber                    }}</td></ng-container>
        <ng-container matColumnDef="expiryDate"   ><th mat-header-cell *matHeaderCellDef>Expiry Date                     </th><td mat-cell             *matCellDef="let element">{{ element.expiryDate   | date : 'MM/YY' }}</td></ng-container>
        <ng-container matColumnDef="reg"          ><th mat-header-cell *matHeaderCellDef>Card Seq                        </th><td mat-cell class="odd" *matCellDef="let element">{{ element.reg                           }}</td></ng-container>
        <ng-container matColumnDef="product"      ><th mat-header-cell *matHeaderCellDef>Product                         </th><td mat-cell class="center" *matCellDef="let element"><credit-image-card [cardProduct]="element.cardProduct"></credit-image-card></td></ng-container>
        <ng-container matColumnDef="blockCode"    ><th mat-header-cell *matHeaderCellDef [attr.colspan]="2">Block Code   </th><td mat-cell             *matCellDef="let element">{{ element.blockCode                     }}</td></ng-container>
        <ng-container matColumnDef="blockCodeDesc"><th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th><td mat-cell             *matCellDef="let element">{{ element.blockCodeDesc                 }}</td></ng-container>
        <ng-container matColumnDef="st"           ><th mat-header-cell *matHeaderCellDef [attr.colspan]="2">Status       </th><td mat-cell class="odd" *matCellDef="let element">{{ element.st                            }}</td></ng-container>
        <ng-container matColumnDef="stDesc"       ><th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th><td mat-cell class="odd" *matCellDef="let element">{{ element.stDesc                        }}</td></ng-container>
        <ng-container matColumnDef="gp"           ><th mat-header-cell *matHeaderCellDef [attr.colspan]="2">CF           </th><td mat-cell             *matCellDef="let element">{{ element.gp                            }}</td></ng-container>
        <ng-container matColumnDef="gpDesc"       ><th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th><td mat-cell             *matCellDef="let element">{{ element.gpDesc                        }}</td></ng-container>
        <ng-container matColumnDef="tg"           ><th mat-header-cell *matHeaderCellDef [attr.colspan]="2">Cardholder   </th><td mat-cell class="odd"  *matCellDef="let element">{{ element.tg                           }}</td></ng-container>
        <ng-container matColumnDef="tgDesc"       ><th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th><td mat-cell class="odd" *matCellDef="let element">{{ element.tgDesc                        }}</td></ng-container>
        <ng-container matColumnDef="embossedName" ><th mat-header-cell *matHeaderCellDef>Embossed Name                   </th><td mat-cell             *matCellDef="let element">{{ element.embossedName                  }}</td></ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

