<div class="container {{this.vplService.themeClass}}" >
    <mat-toolbar class="va-toolbar">
        Vista Integral 360&deg;<span class="example-spacer"></span>Sistema de Credito {{env.tenantName}}
    </mat-toolbar>
    <mat-toolbar color="primary" style='flex: auto;'>
        <button mat-raised-button (click)="newSearchFunction()" [routerLink]="this.newSearchLink" routerLinkActive="active">New Search</button><span class="example-spacer"></span><credit-navbar-acct [account]="accountData"></credit-navbar-acct>
        <span class="example-spacer"></span>
    </mat-toolbar>
    <div class="tab-container">
        <mat-tab-group backgroundColor="accent" [(selectedIndex)]="vplService.accountTabSelectedIndex" (focusChange)="tabFocusChange($event)">
            <mat-tab label="Estado de Cuenta">     <credit-va-acct-status [account]="accountData"></credit-va-acct-status> </mat-tab>
            <mat-tab label="Datos Demograficos">   <credit-va-demographic [account]="accountData" [customer]="customerData"></credit-va-demographic> </mat-tab>
            <mat-tab label="Puntos">               <credit-va-points></credit-va-points>           </mat-tab>
            <mat-tab label="Servicios">            <credit-va-services></credit-va-services>       </mat-tab>
            <mat-tab label="Menu Action">          <credit-va-action></credit-va-action>           </mat-tab>
            <!-- <mat-tab label="Dashboard">            <credit-va-dash></credit-va-dash>               </mat-tab> -->
        </mat-tab-group>
    </div>    
</div>


  

