import { CoreModule } from './../core/core.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgChartsModule } from 'ng2-charts';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { StatTable01Component } from './components/stat-table01/stat-table01.component';
import { TestQueueComponent } from './components/test-queue/test-queue.component';
import { TaskListComponent } from './components/task-list/task-list.component';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [
    StatTable01Component,
    TestQueueComponent,
    TaskListComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    NgChartsModule,
    MatIconModule,
    MatButtonModule,
    CoreModule,
    MatSortModule
  ],
  exports: [
    StatTable01Component,
    TestQueueComponent,
    TaskListComponent
  ]
})
export class WorkflowModule { }
