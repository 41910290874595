<div class="container">

    <h1>{{action.title}} - {{action?.hint}}</h1>

    <mat-stepper orientation="vertical" [linear]="isLinear" [selectedIndex]="this.state.stateData.step"
        (selectionChange)="stepChange($event)" #stepper>

        <mat-step [editable]="isEditable" [completed]="checkStepComplete(0)">
            <ng-template matStepLabel>Select Card</ng-template>
            <ng-template matStepContent>
                <div class="box4">
                    <credit-list-cards [cardIsDisabled]="cardIsDisabled" [displayedColumns]="columns"
                        [cardSelected]="cardSelected"></credit-list-cards>
                </div>
            </ng-template>
        </mat-step>

        <mat-step [editable]="isEditable" [completed]="checkStepComplete(1)">
            <ng-template matStepLabel>Remove Account Block</ng-template>
            <ng-template matStepContent>
                <div class="box box4">
                    <core-smart-button [config]="blockAccountButtonConfig"></core-smart-button>
                </div>
            </ng-template>
        </mat-step>

        <mat-step [editable]="isEditable" [completed]="checkStepComplete(2)">
            <ng-template matStepLabel>Remove Card Block</ng-template>
            <ng-template matStepContent>
                <div class="box box4">
                    <core-smart-button [config]="blockCardButtonConfig"></core-smart-button>
                </div>
            </ng-template>
        </mat-step>

        <mat-step [editable]="isEditable" [completed]="checkStepComplete(3)">
            <ng-template matStepLabel>Verify Customer Identity</ng-template>
            <ng-template matStepContent>
                <credit-customer-identify [cardNumber]="this.creditSvc.customer?.clientId || ''" [requiredChecks]="2"
                    [maxFailures]="4" (onSuccess)="customerIdentified($event)" (onFailure)="customerIdentified($event)"></credit-customer-identify>
            </ng-template>
        </mat-step>

        <mat-step [editable]="isEditable" [completed]="checkStepComplete(4)">
            <ng-template matStepLabel>Insurance</ng-template>
            <ng-template matStepContent>
                <div class="box4" style="grid-column: span 2;">
                    <credit-list-insurance (accepted)="insuranceAccepted($event)"></credit-list-insurance>
                </div>
            </ng-template>
        </mat-step>

        <mat-step [editable]="isEditable" [completed]="checkStepComplete(5)">
            <ng-template matStepLabel>Confirm Demographic Data</ng-template>
            <ng-template matStepContent>

                <form [formGroup]="actionForm">
                    <div class="box box1">
                        <div class="box box6" style="grid-column: span 2;">
                            <h3>Residential Address</h3>
                            <ng-container *ngFor="let field of fieldConfigs1 | keyvalue" class="ff">
                                <div *ngIf="field.value.type=='submit'" style="grid-column: span 4;">
                                    <button mat-raised-button type="button" class="btn btn-primary"
                                        [disabled]="!validInput()">
                                        <mat-icon *ngIf="!validInput()">edit</mat-icon>
                                        <mat-icon *ngIf="validInput()">search</mat-icon>Search
                                    </button>
                                </div>

                                <div *ngIf="field.value.type=='divider'" style="grid-column: span 4;">
                                    <hr>
                                </div>

                                <core-data-field-view *ngIf="field.value.type=='display'" label="{{field.value.title}}"
                                    type="text" [value]="field.value.name"></core-data-field-view>

                                <core-otp-request *ngIf="field.value.type=='otp'" [config]="otp2Config">
                                </core-otp-request>

                                <mat-form-field *ngIf="field.value.type=='date'" class="form-control" appearance="fill">
                                    <mat-label>{{field.value.title}}</mat-label><input matInput
                                        [matDatepicker]="picker">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field *ngIf="field.value.type=='input'" appearance="fill">
                                    <mat-label>{{ field.value.title }}</mat-label>
                                    <input matInput [id]="field.value.name" [type]="field.value.format"
                                        class="form-control" [formControlName]="field.value.name"
                                        [placeholder]="field.value.placeholder" autocomplete="off"
                                        [readonly]="field.value.readonly">
                                </mat-form-field>
                                <ng-container *ngIf="field.value.type=='zip'">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ field.value.title }}</mat-label>
                                        <input matInput #zip [id]="field.value.name" [type]="field.value.format"
                                            class="form-control" [formControlName]="field.value.name"
                                            [placeholder]="field.value.placeholder" autocomplete="off"
                                            [readonly]="field.value.readonly"><button *ngIf="search" matSuffix
                                            mat-icon-button aria-label="Clear"
                                            (click)="zipSearch(zip.value, field.key)">
                                            <mat-icon>search</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <core-smart-select class="smart-field" [options]="fieldOptions.get(field.key)"
                                        [config]="zipSearchFieldConfig1" (selectChange)="changeZip($event)">
                                    </core-smart-select>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="box box6" style="grid-column: span 2;">
                            <h3>Employer Address</h3>
                            <ng-container *ngFor="let field of fieldConfigs2 | keyvalue" class="ff">
                                <div *ngIf="field.value.type=='submit'" style="grid-column: span 4;">
                                    <button mat-raised-button type="button" class="btn btn-primary"
                                        [disabled]="!validInput()">
                                        <mat-icon *ngIf="!validInput()">edit</mat-icon>
                                        <mat-icon *ngIf="validInput()">search</mat-icon>Search
                                    </button>
                                </div>

                                <div *ngIf="field.value.type=='divider'" style="grid-column: span 4;">
                                    <hr>
                                </div>

                                <core-data-field-view *ngIf="field.value.type=='display'" label="{{field.value.title}}"
                                    type="text" [value]="field.value.name"></core-data-field-view>

                                <mat-form-field *ngIf="field.value.type=='date'" class="form-control" appearance="fill">
                                    <mat-label>{{field.value.title}}</mat-label><input matInput
                                        [matDatepicker]="picker">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field *ngIf="field.value.type=='input'" appearance="fill">
                                    <mat-label>{{ field.value.title }}</mat-label>
                                    <input matInput [id]="field.value.name" [type]="field.value.format"
                                        class="form-control" [formControlName]="field.value.name"
                                        [placeholder]="field.value.placeholder" autocomplete="off"
                                        [readonly]="field.value.readonly">
                                </mat-form-field>
                                <ng-container *ngIf="field.value.type=='zip'">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ field.value.title }}</mat-label>
                                        <input matInput #zip [id]="field.value.name" [type]="field.value.format"
                                            class="form-control" [formControlName]="field.value.name"
                                            [placeholder]="field.value.placeholder" autocomplete="off"
                                            [readonly]="field.value.readonly"><button *ngIf="search" matSuffix
                                            mat-icon-button aria-label="Clear"
                                            (click)="zipSearch(zip.value, field.key)">
                                            <mat-icon>search</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <core-smart-select class="smart-field" [options]="fieldOptions.get(field.key)"
                                        [config]="zipSearchFieldConfig2" (selectChange)="changeZip($event)">
                                    </core-smart-select>
                                </ng-container>
                            </ng-container>
                        </div>
                        <core-smart-button [config]="saveButtonConfig"></core-smart-button>
                    </div>
                </form>
                <div>
                    <credit-ac-view001 [actionCode]="actionCode" [minimal]="true" [noList]="true"
                        (noteSaved)="stepper.selected!.completed = true" #demographicNote></credit-ac-view001>
                    <button [disabled]="!demographicNote.noteMade" mat-button matStepperNext
                        (click)="stepperNext()"
                        color="primary">Aceptar</button>
                </div>
            </ng-template>
        </mat-step>

        <mat-step [editable]="isEditable" [completed]="checkStepComplete(6)">
            <ng-template matStepLabel>Signature / Consent</ng-template>
            <ng-template matStepContent>
                <mat-button-toggle-group name="consentType" #consentType="matButtonToggleGroup" value="print">
                    <mat-button-toggle value="print">
                        <mat-icon>print</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="signature">
                        <mat-icon>draw</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="otp">
                        <mat-icon>smartphone</mat-icon>
                    </mat-button-toggle>
                    <!-- <mat-button-toggle value="incode">
                        <mat-icon>person_pin</mat-icon>
                    </mat-button-toggle> -->
                </mat-button-toggle-group>
                <div class="box box4">
                    <div *ngIf="consentType.value == 'otp'">
                        <h3>Consent by {{env.dict.otp}}</h3>
                        <core-otp-request [config]="otp1Config" (otpSuccess)="otpSuccess($event)"
                            (otpFail)="otpFail($event)"></core-otp-request>
                    </div>
                    <div *ngIf="consentType.value == 'signature'">
                        <h3>Consent by Signature</h3>
                        <ic-signature (acceptSignature)="handleSignature($event)"></ic-signature>
                    </div>
                    <div *ngIf="consentType.value == 'incode'">
                        <h3>Incode Consent (TBD)</h3>
                    </div>
                    <div *ngIf="consentType.value == 'print'">
                        <h3>Print and Sign</h3>
                        <button mat-button color="primary" [disabled]="printOK" (click)="printSend()">Print</button>
                        <button mat-button color="primary" [disabled]="!printOK" (click)="printDone()">OK</button>
                    </div>
                </div>
                <div>
                    <!-- <credit-ac-view001 [actionCode]="actionCode" [minimal]="true"  [noList]="true" #sigNote></credit-ac-view001> -->
                    <button mat-button matStepperNext color="primary" [disabled]="!consentOK"
                        (click)="stepperNext()">Continue</button>
                </div>
            </ng-template>
        </mat-step>

        <mat-step [editable]="isEditable" [completed]="checkStepComplete(7)">
            <ng-template matStepLabel>Create Card</ng-template>
            <ng-template matStepContent>
                <div class="box box7">
                    <div class="box2">
                        <credit-list-card-products (accepted)="selectProduct($event)"></credit-list-card-products>
                        <!-- <div class="box2"> -->
                        <core-smart-select class="smart-field" [options]="motivEmisOptions" [config]="motivEmisConfig"
                            (selectChange)="changeMotiv($event)">
                        </core-smart-select>
                        <!-- </div> -->
                        <core-smart-button [config]="embossButtonConfig"></core-smart-button>
                    </div>
                    <div></div>
                </div>
            </ng-template>
        </mat-step>

        <!-- <mat-step [editable]="isEditable" [completed]="false">
            <ng-template matStepLabel>Create Card</ng-template>
            <ng-template matStepContent>
                <div class="box box4">
                    <core-smart-button [config]="embossButtonConfig"></core-smart-button>
                </div>
            </ng-template>
        </mat-step> -->

        <mat-step [editable]="isEditable" [completed]="checkStepComplete(8)">
            <ng-template matStepLabel>Issue PIN</ng-template>
            <ng-template matStepContent>
                <!-- <div class="box9">
                    <mat-form-field appearance="outline" class="core-data-field"><mat-label>Email</mat-label>   <input matInput type="text" name="email" autocomplete="off" [readonly]="true" [value]="creditSvc.customer?.contactEmail"> </mat-form-field>
                    <mat-form-field appearance="outline" class="core-data-field"><mat-label>Cellular</mat-label><input matInput type="text" name="cell"  autocomplete="off" [readonly]="true" [value]="creditSvc.customer?.contactTelephoneCell">  </mat-form-field>
                </div> -->
                <div class="box box3">
                    <credit-card-wizard></credit-card-wizard>
                </div>
                <div class="box box4">
                    <core-smart-button [config]="pinButtonConfig"></core-smart-button>
                </div>
            </ng-template>
        </mat-step>

        <mat-step [editable]="isEditable" [completed]="checkStepComplete(9)">
            <credit-ac-view001 [actionCode]="actionCode" [listOnly]="true"></credit-ac-view001>
            <ng-template matStepLabel>Done</ng-template>
        </mat-step>
    </mat-stepper>
    <div class="box box1" style="grid-column: span 4;">
        <core-smart-button [config]="newButtonConfig"></core-smart-button>
        <core-smart-button [config]="actionSaveButtonConfig"></core-smart-button>
        <core-smart-button [config]="closeButtonConfig" color="warn"></core-smart-button>
    </div>
</div>