<!-- <div *ngIf="this.vplService.account"> -->
    <mat-table [dataSource]="dataSource" matSort>
    <!-- <table mat-table [dataSource]="dataSource" matSort> -->

        <!-- <ng-container matColumnDef="group">       <mat-header-cell class="odd" *matHeaderCellDef mat-sort-header> Group     <p><input type="text" class="search" [(ngModel)]="filter.group"   (keyup)="updateView()"></p></mat-header-cell><mat-cell class="odd" *matCellDef="let row"> {{ row.group }}     </mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container> -->

        <ng-container matColumnDef="select"            ><mat-header-cell *matHeaderCellDef>Select TXN                                                                                                                                                             </mat-header-cell><mat-cell *matCellDef="let element"><button mat-raised-button (click)="selectTransaction(element.id)" disabled>{{ element.id }}</button></mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container>
        <ng-container matColumnDef="effectiveDate"     ><mat-header-cell *matHeaderCellDef mat-sort-header><p>Effective Date    </p><p>&nbsp;</p>                                                                                                                 </mat-header-cell><mat-cell class="odd" *matCellDef="let element">{{ element.effectiveDate      | date             }}                                     </mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container>
        <ng-container matColumnDef="postedDate"        ><mat-header-cell *matHeaderCellDef mat-sort-header><p>Post Date         </p><p>&nbsp;</p>                                                                                                                 </mat-header-cell><mat-cell             *matCellDef="let element">{{ element.postedDate         | date             }}                                     </mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container>
        <ng-container matColumnDef="amount"            ><mat-header-cell *matHeaderCellDef mat-sort-header><p>Amount            </p><p>&nbsp;</p>                                                                                                                 </mat-header-cell><mat-cell class="amt" *matCellDef="let element">{{ element.amount             | number : '1.2-2' }}                                     </mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container>
        <ng-container matColumnDef="transactionType"   ><mat-header-cell *matHeaderCellDef mat-sort-header><p>Transaction Code  </p><p><input type="text" class="search" [(ngModel)]="filter.transactionType"     (keyup)="updateView()" autocomplete="false"></p></mat-header-cell><mat-cell             *matCellDef="let element">{{ element.transactionType                       }}                                     </mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container>
        <ng-container matColumnDef="logicModule"       ><mat-header-cell *matHeaderCellDef mat-sort-header><p>Logic Module      </p><p><input type="text" class="search" [(ngModel)]="filter.logicModule"         (keyup)="updateView()" autocomplete="false"></p></mat-header-cell><mat-cell class="odd" *matCellDef="let element">{{ element.logicModule                           }}                                     </mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container>
        <ng-container matColumnDef="planId"            ><mat-header-cell *matHeaderCellDef mat-sort-header><p>Plan              </p><p><input type="text" class="search" [(ngModel)]="filter.planId"              (keyup)="updateView()" autocomplete="false"></p></mat-header-cell><mat-cell             *matCellDef="let element">{{ element.planId                                }}                                     </mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container>
        <ng-container matColumnDef="description"       ><mat-header-cell *matHeaderCellDef mat-sort-header><p>Description       </p><p><input type="text" class="search" [(ngModel)]="filter.description"         (keyup)="updateView()" autocomplete="false"></p></mat-header-cell><mat-cell class="odd" *matCellDef="let element">{{ element.description                           }}                                     </mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container>
        <ng-container matColumnDef="referenceNumber"   ><mat-header-cell *matHeaderCellDef mat-sort-header><p>Reference Number  </p><p><input type="text" class="search" [(ngModel)]="filter.referenceNumber"     (keyup)="updateView()" autocomplete="false"></p></mat-header-cell><mat-cell             *matCellDef="let element">{{ element.referenceNumber                       }}                                     </mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container>
        <ng-container matColumnDef="authorizationCode" ><mat-header-cell *matHeaderCellDef mat-sort-header><p>Authorization Code</p><p><input type="text" class="search" [(ngModel)]="filter.authorizationCode"   (keyup)="updateView()" autocomplete="false"></p></mat-header-cell><mat-cell class="odd" *matCellDef="let element">{{ element.authorizationCode                     }}                                     </mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container>
    
        <!-- <ng-container matColumnDef="select"       ><th mat-header-cell *matHeaderCellDef>Select TXN       </th><td mat-cell *matCellDef="let element"><button mat-raised-button (click)="selectTransaction(element.id)" disabled>{{ element.id }}</button></td></ng-container>
        <ng-container matColumnDef="effectiveDate"     ><th mat-header-cell *matHeaderCellDef mat-sort-header><p>Effective Date    </p><p>&nbsp;</p></th><td mat-cell class="odd" *matCellDef="let element">{{ element.effectiveDate      | date             }}</td></ng-container>
        <ng-container matColumnDef="postedDate"        ><th mat-header-cell *matHeaderCellDef mat-sort-header><p>Post Date         </p><p>&nbsp;</p></th><td mat-cell             *matCellDef="let element">{{ element.postedDate         | date             }}</td></ng-container>
        <ng-container matColumnDef="amount"            ><th mat-header-cell *matHeaderCellDef mat-sort-header><p>Amount            </p><p>&nbsp;</p></th><td mat-cell class="amt" *matCellDef="let element">{{ element.amount             | number : '1.2-2' }}</td></ng-container>
        <ng-container matColumnDef="transactionType"   ><th mat-header-cell *matHeaderCellDef mat-sort-header><p>Transaction Code  </p><p><input type="text" class="search" [(ngModel)]="filter.transactionType"     (keyup)="updateView()" autocomplete="false"></p></th><td mat-cell             *matCellDef="let element">{{ element.transactionType                       }}</td></ng-container>
        <ng-container matColumnDef="logicModule"       ><th mat-header-cell *matHeaderCellDef mat-sort-header><p>Logic Module      </p><p><input type="text" class="search" [(ngModel)]="filter.logicModule"         (keyup)="updateView()" autocomplete="false"></p></th><td mat-cell class="odd" *matCellDef="let element">{{ element.logicModule                           }}</td></ng-container>
        <ng-container matColumnDef="planId"            ><th mat-header-cell *matHeaderCellDef mat-sort-header><p>Plan              </p><p><input type="text" class="search" [(ngModel)]="filter.planId"              (keyup)="updateView()" autocomplete="false"></p></th><td mat-cell             *matCellDef="let element">{{ element.planId                                }}</td></ng-container>
        <ng-container matColumnDef="description"       ><th mat-header-cell *matHeaderCellDef mat-sort-header><p>Description       </p><p><input type="text" class="search" [(ngModel)]="filter.description"         (keyup)="updateView()" autocomplete="false"></p></th><td mat-cell class="odd" *matCellDef="let element">{{ element.description                           }}</td></ng-container>
        <ng-container matColumnDef="referenceNumber"   ><th mat-header-cell *matHeaderCellDef mat-sort-header><p>Reference Number  </p><p><input type="text" class="search" [(ngModel)]="filter.referenceNumber"     (keyup)="updateView()" autocomplete="false"></p></th><td mat-cell             *matCellDef="let element">{{ element.referenceNumber                       }}</td></ng-container>
        <ng-container matColumnDef="authorizationCode" ><th mat-header-cell *matHeaderCellDef mat-sort-header><p>Authorization Code</p><p><input type="text" class="search" [(ngModel)]="filter.authorizationCode"   (keyup)="updateView()" autocomplete="false"></p></th><td mat-cell class="odd" *matCellDef="let element">{{ element.authorizationCode                     }}</td></ng-container>
     -->
        <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></mat-footer-row>
      
    </mat-table>
<!-- </div> --> 


