import { VscapiService } from './../../../core/services/vscapi.service';
import { ActionMenuComponent } from './../action-menu/action-menu.component';
import { VplService } from './../../services/vpl.service';

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatListOption } from '@angular/material/list';
import { ContextService } from '../../services/context.service';

@Component({
  selector: 'credit-va-action',
  templateUrl: './va-action.component.html',
  styleUrls: ['./va-action.component.scss']
})
export class VaActionComponent implements OnInit {

  @ViewChild('actionMenu') actionMenu!: ActionMenuComponent; 

  public menuOptions = [
    { id: 'view'          , title: 'Consulta'           , icon: 'info'                    , default: true},
    { id: 'maintenance'   , title: 'Mantenimiento'      , icon: 'build_circle'            , default: false},
    { id: 'monetary'      , title: 'Monetarios'         , icon: 'monetization_on'         , default: false},
  ];

  constructor(
    public context: ContextService,
    public credit: VplService,
    public vscapiService: VscapiService
    
  ) { }

  ngOnInit(): void {
    // if (this.context.actionIdToLoad?.account) {
    //   this.context.clearActions();
    //   this.loadState(this.context.actionIdToLoad.account, this.context.actionIdToLoad.account, this.context.actionIdToLoad.taskId);
    // }
    this.context.selectedOption = [this.menuOptions[0].id];
    // this.credit.loadAccountActions();
    this.context.loadAccountActions(this.vscapiService.selectedAccount);

    this.context.initState(this.context.selectedState?.subType || '')
    if (this.credit.account && this.context.selectedState) {
      this.loadState(this.credit.account.id, this.credit.account.id, this.context.selectedState.id); //X999
    }
    // this.context.loadTaskToAction = this.loadState;
    // this.context.loadAccountActions(this.credit.account?.id ?? '');
  }

  onSelection($event: any, v: MatListOption[]) { 
    if (this.context.selectedOption.indexOf('view') > -1 || this.context.selectedOption.indexOf('maintenance') > -1 || this.context.selectedOption.indexOf('monetary') > -1 ) {
      //
    } 
    else {
      // if (this.credit.account?.id) {
      if (this.vscapiService.selectedAccount) {
        if (this.context.selectedState?.id) { //X998
          this.loadState(this.vscapiService.selectedAccount, this.vscapiService.selectedAccount, this.context.selectedState.id);
        } else {
          this.loadState(this.vscapiService.selectedAccount, this.vscapiService.selectedAccount, this.context.selectedOption[0]);
        }
        // delete this.context.actionIdToLoad;
      }
    }
  }

  public loadState(account: string, queueId: string, stateId: string) {
    if (account) {
      this.context.loadActionState(queueId, stateId, state => {
        this.context.addAction(state.subType, 'maintenance', state.id, state.timerId); // Adds the task to the active slot
        this.context.selectedOption = [state.subType];        // Sets the left menu to "EMBO" (e.g). Causes load of <credit-ac-maintenance005> on the right side
        this.actionMenu.stateId = state.id;                   // Sets the right content to the returned state ID e.g. "dnsadkfbsjfkdbsakfdsfsd"
      })  
    }
  }
}
