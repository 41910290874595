import { SnackBarService } from './../../../../core/services/snack-bar.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { AuthService } from 'src/app/services/auth.service';
import { ButtonConfig, OtpConfig, SelectConfig, Suburb } from './../../../../core/entities/core.d';
import { ContextService } from './../../../services/context.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { VplService } from '../../../services/vpl.service';
import { CreditCard } from './../../../entities/credit.d';
import { FormControl, FormGroup } from '@angular/forms';
import { CatalogService } from 'src/app/services/catalog.service';
import { MatSelectChange } from '@angular/material/select';
// import { MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'credit-ac-maintenance002',
  templateUrl: './ac-maintenance002.component.html',
  styleUrls: ['./ac-maintenance002.component.scss']
})
export class AcMaintenance002Component implements OnInit {

  @Input() actionCode!: string;
  @Output() close = new EventEmitter();

  constructor(
    public creditSvc: VplService,
    public context: ContextService,
    public auth: AuthService,
    public catalog: CatalogService,
    public env: EnvironmentService,
    public snack: SnackBarService
    ) { }

  public action!: any;

  public processStep = 'init';
  public card!: CreditCard;

  public otp1Config!: OtpConfig;

  public cardSelected(_card: CreditCard): void {};
  public cardIsDisabled = (card: CreditCard) => { 
    if ( card.st != 'A' && card.st != 'N') {
      return true;
    }
    return false;
  };

  public newButtonConfig!: ButtonConfig;
  public saveButtonConfig!: ButtonConfig;
  public closeButtonConfig!: ButtonConfig;

  public columns = [ 'select', 'cardNumber', 'expiryDate', /*'reg', 'blockCode', 'blockCodeDesc',*/ 'st', 'stDesc', /*'gp', 'gpDesc', 'tg', 'tgDesc',*/ 'embossedName' ];

  public search: boolean = true;
  public zips1: Suburb[] = [];
  public zips2: Suburb[] = [];
  public zipSearchFieldConfig1: SelectConfig = { type: "smartselect", id: "zipSearch1"         , name: "zipSearch1"          , title: "Suburb Selection"   , search: false, result: true , format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: 'car' , hideIfEmpty: false };
  public zipSearchFieldConfig2: SelectConfig = { type: "smartselect", id: "zipSearch2"         , name: "zipSearch2"          , title: "Suburb Selection"   , search: false, result: true , format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: 'car' , hideIfEmpty: false };
                                    //  let x =  { type: "zip"  , id: "contactZip"           , name: "contactZip"           , title: "Contact Zip"      , search: false, result: true , format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false};

  callDate!: Date;

  actionForm!: FormGroup;
  fieldConfigs1 = new Map<string, SelectConfig>();
  fieldConfigs2 = new Map<string, SelectConfig>();
  fieldOptions = new Map();


  date1(n: number) {
    if (n==1) return new Date('2021/08/29');
    if (n==1) return new Date('2021/08/29');
    return new Date('2020/02/14');
  }

  ngOnInit(): void {

    this.setupForm();

    this.action = this.creditSvc.getActionCode('id'+this.actionCode);

    this.otp1Config = {
      email: this.creditSvc.customer?.contactEmail,
      cell: this.creditSvc.customer?.contactTelephoneCell,
      update: true,
      disabled: (() => { return !this.validInput(); })
    }

    this.cardSelected = (card: CreditCard) => 
    {
      this.processStep = 'otp1';
      this.card = card;
      this.creditSvc.card = card;
    }

    let fnClickNew = (): boolean => {
      this.processStep = 'init';
      return false;
    }

    this.newButtonConfig = {
      id: '',
      name: '',
      hint: 'New ' + this.actionCode,
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickNew,
      icon: function (): string {
        return 'verified_user';
      },
      disabled: function (): boolean {
        return false;
      }
    }

    let fnClickClose = (): boolean => {
      this.context.removeAction(this.actionCode, 'maintenance');
      return false;
    }

    this.closeButtonConfig = {
      id: '',
      name: '',
      hint: 'Close',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickClose,
      icon: function (): string {
        return 'cancel';
      },
      disabled: function (): boolean {
        return false;
      }
    }

    let fnClickSave = (): boolean => {
      this.context.actionVars.view.priorViews.push( { date: new Date(), user: this.auth.user.name, action: 'DOMI', note: this.context.actionVars.DOMI.noteValue } );
      this.context.actionVars.DOMI.noteValue = '';
      this.processStep = 'success';
      return true;
    }

    let fnDisabledSave = () => { return !this.validInput();}

    this.saveButtonConfig = {
      id: '',
      name: '',
      hint: 'Submit',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickSave,
      icon: function (): string {
        return 'check_circle';
      },
      disabled: fnDisabledSave
    }
  }

  public otpFail(event: any) {
    // alert(`${this.env.dict.otp} verification failed`);
    this.snack.openSnackBar(`Error ${this.env.dict.otp} Failed`, 'OK');  
    this.processStep = 'otp1';
    return true;
  }

  public otpSuccess(otp: number) {
    this.processStep = 'entry';
    return true;
  }

  private setupForm() {

    this.actionForm = new FormGroup({

      contactStreet        : new FormControl(this.creditSvc.customer!.contactStreet     ),
      contactSuburb        : new FormControl(this.creditSvc.customer!.contactSuburb     ),
      contactCity          : new FormControl(this.creditSvc.customer!.contactCity       ),
      contactState         : new FormControl(this.creditSvc.customer!.contactState      ),
      contactZip           : new FormControl(this.creditSvc.customer!.contactPostalCode ),
      contactCountry       : new FormControl(this.creditSvc.customer!.contactCountry    ),
      contactEmail         : new FormControl(this.creditSvc.customer!.contactEmail          ),
      contactTelephoneHome : new FormControl(this.creditSvc.customer!.contactTelephoneHome  ),
      contactTelephoneCell : new FormControl(this.creditSvc.customer!.contactTelephoneCell  ),

      workStreet     : new FormControl(this.creditSvc.customer!.workStreet     ),
      workSuburb     : new FormControl(this.creditSvc.customer!.workSuburb     ),
      workCity       : new FormControl(this.creditSvc.customer!.workCity       ),
      workState      : new FormControl(this.creditSvc.customer!.workState      ),
      workZip        : new FormControl(this.creditSvc.customer!.workPostalCode ),
      workCountry    : new FormControl(this.creditSvc.customer!.workCountry    ),
      workTelephone  : new FormControl(this.creditSvc.customer!.workTelephone  ),

    });

    this.fieldConfigs1.set("01contactStreet"        , { type: "input", id: "contactStreet"        , name: "contactStreet"        , title: "Contact Street"        , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs1.set("02contactSuburb"        , { type: "input", id: "contactSuburb"        , name: "contactSuburb"        , title: "Contact Suburb"        , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs1.set("03contactCity"          , { type: "input", id: "contactCity"          , name: "contactCity"          , title: "Contact City"          , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs1.set("04contactState"         , { type: "input", id: "contactState"         , name: "contactState"         , title: "Contact State"         , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs1.set("05contactZip"           , { type: "zip"  , id: "contactZip"           , name: "contactZip"           , title: "Contact Zip"           , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs1.set("06contactCountry"       , { type: "input", id: "contactCountry"       , name: "contactCountry"       , title: "Contact Country"       , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs1.set("07contactEmail"         , { type: "input", id: "contactEmail"         , name: "contactEmail"         , title: "Contact Email"         , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs1.set("08contactTelephoneHome" , { type: "input", id: "contactTelephoneHome" , name: "contactTelephoneHome" , title: "Contact TelephoneHome" , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs1.set("09contactTelephoneCell" , { type: "input", id: "contactTelephoneCell" , name: "contactTelephoneCell" , title: "Contact TelephoneCell" , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});

    this.fieldConfigs2.set("11workStreet"           , { type: "input", id: "workStreet"           , name: "workStreet"           , title: "Work Street"           , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs2.set("12workSuburb"           , { type: "input", id: "workSuburb"           , name: "workSuburb"           , title: "Work Suburb"           , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs2.set("13workCity"             , { type: "input", id: "workCity"             , name: "workCity"             , title: "Work City"             , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs2.set("14workState"            , { type: "input", id: "workState"            , name: "workState"            , title: "Work State"            , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs2.set("15workZip"              , { type: "zip"  , id: "workZip"              , name: "workZip"              , title: "Work Zip"              , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs2.set("16workCountry"          , { type: "input", id: "workCountry"          , name: "workCountry"          , title: "Work Country"          , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs2.set("17workTelephone"        , { type: "input", id: "workTelephone"        , name: "workTelephone"        , title: "Work Telephone"        , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});

  }

  validInput() {
    return ( this.context.actionVars.DOMI.noteValue != '');
  }

  public zipSearch(zip: string, source: string) {
    this.catalog.search(Number(zip)).subscribe( result => {
      if (source == "05contactZip") {
        this.zips1 = result;
      }
      if (source == "15workZip") {
        this.zips2 = result;
      }

      let options: { id: string, label: string }[] = [];
      let i = 0;
      for (const suburb in result) { 
        options.push({ id: i+'', label: result[i].city });
        i++;
      }
      this.fieldOptions.set(source, options);
    });
  }

  public changeZip($event: MatSelectChange) {
    if ($event.source.id == 'zipSearch1') {
      let i = Number($event.value);
      this.actionForm.controls.contactSuburb.setValue(this.zips1[i].city);
      this.actionForm.controls.contactCity.setValue(this.zips1[i].community);
      this.actionForm.controls.contactState.setValue(this.zips1[i].state);
      this.actionForm.controls.contactCountry.setValue(this.zips1[i].country);
    }
    if ($event.source.id == 'zipSearch2') {
      let i = Number($event.value);
      this.actionForm.controls.workSuburb.setValue(this.zips2[i].city);
      this.actionForm.controls.workCity.setValue(this.zips2[i].community);
      this.actionForm.controls.workState.setValue(this.zips2[i].state);
      this.actionForm.controls.workCountry.setValue(this.zips2[i].country);
    }
  }

}

