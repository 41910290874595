import { SnackBarService } from './../../../../core/services/snack-bar.service';
import { Observable, of } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment.service';
import { CreditInsuranceProduct, CreditCardProduct, CreditLocateResp } from './../../../entities/credit.d';
import { ButtonConfig, OtpConfig, SelectConfig, Suburb } from 'src/app/modules/core/entities/core';
import { CatalogService } from 'src/app/services/catalog.service';
import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { AuthService } from 'src/app/services/auth.service';
import { CreditCard } from '../../../entities/credit.d';
import { ContextService } from '../../../services/context.service';
import { VplService } from '../../../services/vpl.service';
import { FlowStateObject } from 'src/app/modules/workflow/entities/workflow.d';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

export interface StateDataEMBO {
  /**
   * @var {string} account
   * @var {string} card
   * @var {number} step Save stepper step of EMBO process
   */
  account: string,
  card: string,
  step: number,
}

@Component({
  selector: 'credit-ac-maintenance005',
  templateUrl: './ac-maintenance005.component.html',
  styleUrls: ['./ac-maintenance005.component.scss']
})
export class AcMaintenance005Component implements OnInit, AfterViewInit {

  @Input() actionCode: string ='EMBO';
  @Input() stateId!: string;
  @ViewChild('stepper') stepper!: MatStepper; 

  public state!: FlowStateObject;
  
  public testMode = false;
  public action!: any;
  public card!: CreditCard;

  // Buttons
  public saveButtonConfig!: ButtonConfig;
  public newButtonConfig!: ButtonConfig;
  public actionSaveButtonConfig!: ButtonConfig;
  public closeButtonConfig!: ButtonConfig;
  public blockAccountButtonConfig!: ButtonConfig;
  public blockCardButtonConfig!: ButtonConfig;
  public pinButtonConfig!: ButtonConfig;
  public embossButtonConfig!: ButtonConfig;

  public columns = [ 'select', 'product', 'cardNumber', 'expiryDate', /*'reg', 'blockCode', 'blockCodeDesc',*/ 'st', 'stDesc', /*'gp', 'gpDesc', 'tg', 'tgDesc',*/ 'embossedName' ];
  public cards!: CreditCard[];

  // Stepper
  public isLinear = true;
  public isEditable = false;

  public stepperNext() {
    let stepNumber = this.stepper.selectedIndex;
    this.state.stateData.steps[stepNumber].completed = true;

    this.stepper.selected!.completed = true;

    this.stepper.next();
  }

  public fieldConfigs1 = new Map<string, SelectConfig>();
  public fieldConfigs2 = new Map<string, SelectConfig>();
  public fieldOptions = new Map();

  public search: boolean = true;
  public zips1: Suburb[] = [];
  public zips2: Suburb[] = [];
  public zipSearchFieldConfig1: SelectConfig = { type: "smartselect", id: "zipSearch1", name: "zipSearch1", title: "Suburb Selection"   , search: false, result: true , format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: 'car' , hideIfEmpty: false };
  public zipSearchFieldConfig2: SelectConfig = { type: "smartselect", id: "zipSearch2", name: "zipSearch2", title: "Suburb Selection"   , search: false, result: true , format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: 'car' , hideIfEmpty: false };
  public motivEmisConfig:       SelectConfig = { type: "smartselect", id: "motivEmis", name: "motivEmis", title: "Motivation de Emision"   , search: false, result: true , format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: 'mail' , hideIfEmpty: false };
  public motivEmisOptions: { id: string, label: string }[] = 
  [
    { id: '1', label: 'Apertura de Cuenta' },
    { id: '2', label: 'Robo o Extravio' },
    { id: '3', label: 'Tarjeta Dañada' },
    { id: '4', label: 'Mal Grabada' },
    { id: '5', label: 'Traspaso de Cuenta' },
  ];

  public cardSelected(_card: CreditCard): void {};
  public cardIsDisabled = (card: CreditCard) => { 
    if ( card.st != 'A' ) {
      return true;
    }
    return false;
  };

  public customerIdentified($result: any) {
    this.state.stateData.steps[this.stepper.selectedIndex].idResult = $result;
    this.state.stateData.steps[this.stepper.selectedIndex].isCustomerIdentified = false;
    if ($result.result == 'success') {
      this.state.stateData.steps[this.stepper.selectedIndex].isCustomerIdentified = true;
      this.stepperNext();
    }
  }
  
  public insuranceAccepted(insuranceProducts: CreditInsuranceProduct[] = []) {
    console.log(insuranceProducts);
    console.log('add update here'); //Apply Insurance product info
    this.stepperNext();
    return true
  }

  public selectProduct(product: CreditCardProduct) {
    this.embossButtonEnabled.productSelected = true;
  }

  public isCustomerIdentified: boolean = false;
  public emboSent = false;
  public printOK: boolean = false;
  public consentOK: boolean = false;
  public cwActivity: string = '';
  public callDate!: Date;
  public actionForm!: FormGroup;
  public otp1Config!: OtpConfig;
  public otp2Config!: OtpConfig;
  public activationConfig!: SelectConfig;
  public activationOptions!: { id: string, label: string }[];
  public selectedActivation!: string;
  public embossButtonEnabled!: { motivSelected: boolean, productSelected: boolean };

  constructor(
    public context: ContextService,
    public auth: AuthService,
    public creditSvc: VplService,
    public catalog: CatalogService,
    public env: EnvironmentService,
    public snack: SnackBarService
  ) { }

  load(): Observable<string> {
    this.state = this.context.selectedState!;
    // this.creditSvc.setAccount(this.state.stateData.account);
    this.creditSvc.setCard(this.state.stateData.card);
    // this.cardSelected(this.state.stateData.card);

    this.setupForm();

    return of('done');
  }

  stepChange($event:StepperSelectionEvent) {
    console.log(`BEFORE ${this.state.stateData.step}`);
    this.state.stateData.step = $event.selectedIndex;
    console.log(`AFTER ${this.state.stateData.step}`);
  }

  ngOnInit() {
    console.log(this.state);
    console.log(this.context.selectedState);

    this.action = this.creditSvc.getActionCode('id'+this.actionCode);

    if (this.context.selectedState) {
      this.load().subscribe(rc => {
        this.init();
      });
    } else {
      this.init();
      this.state = {
        id: '',
        logId: '12345',
        createTimestamp: new Date(),
        type: 'action',
        subType: this.actionCode,
        stateData: {
          step: 0,
        },
        timerId: ''
      };
      console.log('FFFFF');
      console.log(this.state);
    }
  }

  ngAfterViewInit() {
    console.log('AAAA');
    console.log(this.stepper.steps);
    // if(!this.state.stateData.steps) {
    //   this.state.stateData.steps = [];
    //   Array.from(Array(this.stepper.steps.length)).forEach((value, index, array) => {
    //     this.state.stateData.steps.push({
    //       completed: false
    //     });
    //   });
    // }
  }

  public init(): void {
    console.log('load3');

    this.printOK = false;
    this.activationConfig = { type: "smartselect", id: "activation", name: "activation", title: "Activation Method", search: true, result: true , format: "array", readonly: false, required: true, placeholder: 'X', hint: 'Activation Method', icon: 'lightning' , hideIfEmpty: false };
    this.activationOptions = [
      { id: "WIZARD", label: "Card Wizard" },
      { id: "SEGURO", label: "Activacion Seguro PIF" },
      { id: "INVENT", label: "Actualization Inventario" },
      { id: "ENTREG", label: "Impresion Acuse de Entrega" },
    ];
    this.selectedActivation = '';
    this.embossButtonEnabled = { motivSelected: false, productSelected: false };

    if (this.testMode)
    {
      // FAKE

    } else {
      // NOT FAKE
      // this.load();

      this.otp1Config = {
        email: this.creditSvc.customer?.contactEmail,
        cell: this.creditSvc.customer?.contactTelephoneCell,
        update: false,
        disabled: (() => { return false; })
      };
  
      this.otp2Config = {
        email: this.creditSvc.customer?.contactEmail,
        cell: this.creditSvc.customer?.contactTelephoneCell,
        update: true,
        disabled: (() => { return false; })
      };
  
    }

    let fnClickSave = (): boolean => {
      // Fire update of customer demo data
      //  - success: stepper.next 

      let updateNote = 'Update: Demographic Data for Card ' + this.creditSvc.card?.cardNumber;
      this.context.actionVars.view.priorViews.push( { date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote } );
      this.context.actionVars.EMBO.noteValue = '';

      // this.stepper.selected!.completed = true;
      // this.stepper.next();
      return true;
    }

    let fnDisabledSave = () => { return false; };

    this.saveButtonConfig = {
      id: '',
      name: '',
      hint: 'Update',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickSave,
      icon: function (): string {
        return 'check_circle';
      },
      disabled: fnDisabledSave
    }

    let fnClickBlockAccount = (): boolean => {
      // Fire unblock service to VPL
      //  - success: stepper.next 

      if (this.creditSvc.unblockAccount()){
        let updateNote = 'Update: N Block Removed for Account ' + this.creditSvc.account?.id;
        this.context.actionVars.view.priorViews.push( { date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote } );

        this.state.stateData.steps[this.stepper.selectedIndex].result = 'account unblocked';
        this.stepperNext();
        return true;
      }
      return false;
    }    

    let fnClickBlockCard = (): boolean => {
      // Fire unblock service to VPL
      //  - success: stepper.next 

      this.creditSvc.unblockCard();

      let updateNote = 'Update: N Block Removed for Card ' + this.creditSvc.card?.cardNumber;
      this.context.actionVars.view.priorViews.push( { date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote } );

      this.state.stateData.steps[this.stepper.selectedIndex].result = 'card unblocked';
      this.stepperNext();
      return true;
    }    

    this.blockAccountButtonConfig = {
      id: '',
      name: '',
      hint: 'Unblock Account',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickBlockAccount,
      icon: function (): string {
        return 'check_circle';
      },
      disabled: () => { return false; }
    }

    this.blockCardButtonConfig = {
      id: '',
      name: '',
      hint: 'Unblock Card',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickBlockCard,
      icon: function (): string {
        return 'check_circle';
      },
      disabled: () => { return false; }
    }

    let fnClickPin = (): boolean => {
      // Fire pin service request to B24
      //  - success: stepper.next 

      this.creditSvc.requestPIN();

      let updateNote = 'PIN Requested for Card ' + this.creditSvc.card?.cardNumber;
      this.context.actionVars.view.priorViews.push( { date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote } );

      this.stepperNext();
      return true;
    }    

    this.pinButtonConfig = {
      id: '',
      name: '',
      hint: 'Issue PIN',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickPin,
      icon: function (): string {
        return 'check_circle';
      },
      disabled: () => { return false; }
    }

    let fnClickEmboss = (): boolean => {
      // Fire embossing data service
      //  - success: stepper.next 

      this.creditSvc.embossCard();

      let updateNote = 'Embossing Requested for Card ' + this.creditSvc.card?.cardNumber;
      this.context.actionVars.view.priorViews.push( { date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote } );

      this.stepperNext();
      return true;
    }    

    this.embossButtonEnabled.motivSelected = false;
    this.embossButtonEnabled.productSelected = false;
    this.embossButtonConfig = {
      id: '',
      name: '',
      hint: 'Emboss Card',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickEmboss,
      icon: function (): string {
        return 'check_circle';
      },
      disabled: () => { return !(this.embossButtonEnabled.motivSelected && this.embossButtonEnabled.productSelected); }
    }    

    this.cardSelected = (card: CreditCard) => 
    {
      this.creditSvc.setCard(card.id);
      this.stepperNext();
      this.setupForm();
      this.state.stateData.account = this.creditSvc.account?.id || '';
      this.state.stateData.card = card.id;
    }

    this.cardIsDisabled = (card: CreditCard) => { 
      if ( card.st != 'N' ) {
        return true;
      }
      return false;
    };

    let fnClickClose = (): boolean => {
      this.context.removeAction(this.actionCode, 'maintenance');
      return false;
    }

    this.closeButtonConfig = {
      id: '',
      name: '',
      hint: 'Close',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickClose,
      icon: function (): string {
        return 'cancel';
      },
      disabled: function (): boolean {
        return false;
      }
    }

    let fnClickNew = (): boolean => {
      this.ngOnInit();
      this.context.removeAction(this.actionCode, 'maintenance');
      this.context.addAction(this.actionCode, 'maintenance');
      this.stepper.reset();
      return false;
    }

    this.newButtonConfig = {
      id: '',
      name: '',
      hint: 'New ' + this.actionCode,
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickNew,
      icon: function (): string {
        return 'verified_user';
      },
      disabled: function (): boolean {
        return false;
      }
    }

    let fnClickActionSave = (): boolean => {

      let queueList = 
      { 
        ...this.creditSvc.selectQueues("account"), 
        ...this.auth.selectQueues("account") 
      };
      this.context.saveState(this.state.stateData.account, this.state, queueList, (response) => {
        let state = response.data;
        if (response.status == 'success') {
          console.log(`updated state id: ${state.id}`);
          this.state = state;
        } else {
          console.error(response.status);
        }
      });          
    // this.ngOnInit();
      // this.context.removeAction(this.actionCode, 'maintenance');
      // this.stepper.reset();
      return false;
    }

    this.actionSaveButtonConfig = {
      id: '',
      name: '',
      hint: 'Save ' + this.actionCode,
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickActionSave,
      icon: function (): string {
        return 'star';
      },
      disabled: function (): boolean {
        return false;
      }
    }
    
    // this.load();






  }



  private setupForm() {

    this.actionForm = new FormGroup({

      contactStreet        : new FormControl(this.creditSvc.customer!.contactStreet     ),
      contactSuburb        : new FormControl(this.creditSvc.customer!.contactSuburb     ),
      contactCity          : new FormControl(this.creditSvc.customer!.contactCity       ),
      contactState         : new FormControl(this.creditSvc.customer!.contactState      ),
      contactZip           : new FormControl(this.creditSvc.customer!.contactPostalCode ),
      contactCountry       : new FormControl(this.creditSvc.customer!.contactCountry    ),
      contactEmail         : new FormControl(this.creditSvc.customer!.contactEmail          ),
      contactTelephoneHome : new FormControl(this.creditSvc.customer!.contactTelephoneHome  ),
      contactTelephoneCell : new FormControl(this.creditSvc.customer!.contactTelephoneCell  ),

      workStreet     : new FormControl(this.creditSvc.customer!.workStreet     ),
      workSuburb     : new FormControl(this.creditSvc.customer!.workSuburb     ),
      workCity       : new FormControl(this.creditSvc.customer!.workCity       ),
      workState      : new FormControl(this.creditSvc.customer!.workState      ),
      workZip        : new FormControl(this.creditSvc.customer!.workPostalCode ),
      workCountry    : new FormControl(this.creditSvc.customer!.workCountry    ),
      workTelephone  : new FormControl(this.creditSvc.customer!.workTelephone  ),

    });

    this.fieldConfigs1.set("01contactStreet"        , { type: "input", id: "contactStreet"        , name: "contactStreet"        , title: "Contact Street"        , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs1.set("02contactSuburb"        , { type: "input", id: "contactSuburb"        , name: "contactSuburb"        , title: "Contact Suburb"        , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs1.set("03contactCity"          , { type: "input", id: "contactCity"          , name: "contactCity"          , title: "Contact City"          , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs1.set("04contactState"         , { type: "input", id: "contactState"         , name: "contactState"         , title: "Contact State"         , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs1.set("05contactZip"           , { type: "zip"  , id: "contactZip"           , name: "contactZip"           , title: "Contact Zip"           , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs1.set("06contactCountry"       , { type: "input", id: "contactCountry"       , name: "contactCountry"       , title: "Contact Country"       , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});

    this.fieldConfigs1.set("07otp"                  , { type: "otp"  , id: "otp"                  , name: "otp"                  , title: "Contact Phone/Email"   , search: false, result: true, format: ""    , readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    // this.fieldConfigs1.set("07contactEmail"         , { type: "input", id: "contactEmail"         , name: "contactEmail"         , title: "Contact Email"         , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs1.set("08contactTelephoneHome" , { type: "input", id: "contactTelephoneHome" , name: "contactTelephoneHome" , title: "Contact Home Phone"    , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    // this.fieldConfigs1.set("09contactTelephoneCell" , { type: "input", id: "contactTelephoneCell" , name: "contactTelephoneCell" , title: "Contact TelephoneCell" , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});

    this.fieldConfigs2.set("11workStreet"           , { type: "input", id: "workStreet"           , name: "workStreet"           , title: "Work Street"           , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs2.set("12workSuburb"           , { type: "input", id: "workSuburb"           , name: "workSuburb"           , title: "Work Suburb"           , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs2.set("13workCity"             , { type: "input", id: "workCity"             , name: "workCity"             , title: "Work City"             , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs2.set("14workState"            , { type: "input", id: "workState"            , name: "workState"            , title: "Work State"            , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs2.set("15workZip"              , { type: "zip"  , id: "workZip"              , name: "workZip"              , title: "Work Zip"              , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs2.set("16workCountry"          , { type: "input", id: "workCountry"          , name: "workCountry"          , title: "Work Country"          , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs2.set("17workTelephone"        , { type: "input", id: "workTelephone"        , name: "workTelephone"        , title: "Work Telephone"        , search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
  }

  validInput() {
    return ( this.selectedActivation != '' && this.context.actionVars.EMBO.noteValue != '');
  }

  public zipSearch(zip: string, source: string) {
    this.catalog.search(Number(zip)).subscribe( result => {
      if (source == "05contactZip") {
        this.zips1 = result;
      }
      if (source == "15workZip") {
        this.zips2 = result;
      }

      let options: { id: string, label: string }[] = [];
      let i = 0;
      for (const suburb in result) { 
        options.push({ id: i+'', label: result[i].city });
        i++;
      }
      this.fieldOptions.set(source, options);
    });
  }

  public changeZip($event: MatSelectChange) {
   console.log($event.value);
    if ($event.source.id == 'zipSearch1') {
      let i = Number($event.value);
      this.actionForm.controls.contactSuburb.setValue(this.zips1[i].city);
      this.actionForm.controls.contactCity.setValue(this.zips1[i].community);
      this.actionForm.controls.contactState.setValue(this.zips1[i].state);
      this.actionForm.controls.contactCountry.setValue(this.zips1[i].country);
      console.log('selected: ' + i + ': ' + this.zips1[i].city);
    }
    if ($event.source.id == 'zipSearch2') {
      let i = Number($event.value);
      this.actionForm.controls.workSuburb.setValue(this.zips2[i].city);
      this.actionForm.controls.workCity.setValue(this.zips2[i].community);
      this.actionForm.controls.workState.setValue(this.zips2[i].state);
      this.actionForm.controls.workCountry.setValue(this.zips2[i].country);
      console.log('selected: ' + i + ': ' + this.zips2[i].city);
    }
  }
  
  public changeMotiv($event: MatSelectChange) {
   console.log($event.value);
   this.embossButtonEnabled.motivSelected = true;
  }

  public otpSuccess(otp: number) {
    this.consentOK = true;

    let updateNote = 'Customer Consented By OTP for ' + this.creditSvc.card?.cardNumber;
    this.context.actionVars.view.priorViews.push( { date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote } );

    return true;
  }

  public otpFail(event: any) {
    // alert(` ${this.env.dict.otp} verification failed`);
    this.snack.openSnackBar(`${this.env.dict.otp} verification failed`, 'OK');  

    return false;
  }

  handleSignature(img: any) {
    console.log('image');
    console.log(img);
    this.consentOK = true;

    let updateNote = 'Customer Consented By Signature for ' + this.creditSvc.card?.cardNumber;
    this.context.actionVars.view.priorViews.push( { date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote } );
  }

  public printSend() {
    let updateNote = 'Customer Consent Documents printed for ' + this.creditSvc.card?.cardNumber;
    this.context.actionVars.view.priorViews.push( { date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote } );

    // Call PRINT API here
    console.log('Call Print API for ' + this.creditSvc.card?.cardNumber);
    this.printOK = true;
    return true;
  }

  public printDone() {
    this.consentOK = true;

    let updateNote = 'Customer Consented By Paper Signature for ' + this.creditSvc.card?.cardNumber;
    this.context.actionVars.view.priorViews.push( { date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote } );

    return true;
  }

  public checkStepComplete(step: number) {
    if (!this.state.stateData.steps) {
      this.state.stateData.steps = [];
    }

    if (!this.state.stateData.steps[step]) {
      this.state.stateData.steps.push({
        completed: false
      })
    }
    return (this.state.stateData.steps[step]?.completed ?? false);
  }

}

