import { VplService } from './../../../services/vpl.service';
import { ContextService } from './../../../services/context.service';
import { Component, Input, OnInit } from '@angular/core';
import { ButtonConfig } from 'src/app/modules/core/entities/core';
import { CreditInsuranceProduct } from '../../../entities/credit.d';

@Component({
  selector: 'credit-ac-maintenance006',
  templateUrl: './ac-maintenance006.component.html',
  styleUrls: ['./ac-maintenance006.component.scss']
})
export class AcMaintenance006Component implements OnInit {

  @Input() actionCode: string ='ERRR';

  public action!: any;
  public actionComplete!: boolean;
  public selectedProducts!:CreditInsuranceProduct[];

  // public card!: CreditCard;

  // Buttons
  public saveButtonConfig!: ButtonConfig;
  public newButtonConfig!: ButtonConfig;
  public closeButtonConfig!: ButtonConfig;

  constructor(
    public context: ContextService,
    public creditSvc: VplService
  ) { }

  ngOnInit(): void {

    this.action = this.creditSvc.getActionCode('id'+this.actionCode);

    this.actionComplete = false;
    this.selectedProducts = [];

    let fnClickClose = (): boolean => {
      this.context.removeAction(this.actionCode, 'maintenance');
      return false;
    }
  
    this.closeButtonConfig = {
      id: '',
      name: '',
      hint: 'Close',
      hideIfDisabled: false,
  
      type: 'text',
      title: '',
      click: fnClickClose,
      icon: function (): string {
        return 'cancel';
      },
      disabled: function (): boolean {
        return false;
      }
    }
  
    let fnClickNew = (): boolean => {
      this.context.removeAction(this.actionCode, 'maintenance');
      this.context.addAction(this.actionCode, 'maintenance');
      this.ngOnInit();
      return false;
    }
  
    this.newButtonConfig = {
      id: '',
      name: '',
      hint: 'New ' + this.actionCode,
      hideIfDisabled: false,
  
      type: 'text',
      title: '',
      click: fnClickNew,
      icon: function (): string {
        return 'verified_user';
      },
      disabled: function (): boolean {
        return false;
      }
    }
  }

  public insuranceAccepted(insuranceProducts: CreditInsuranceProduct[] = []) {
    console.log(insuranceProducts);
    console.log('add update here'); //Apply Insurance product info
    this.selectedProducts = insuranceProducts;
    this.actionComplete = true;
    return true;
  }

}
