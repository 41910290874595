<div class="vs-page">
    <div *ngIf="wfService.getTicketStatus() == 'LOOKUP'" class="card-container">
        <mat-card *ngIf="wfService.getTicketStatus() == 'LOOKUP'">
            <mat-card-title>
                Lookup Client
            </mat-card-title>
            <!-- <mat-card-subtitle>
                <h2>Ticket# {{ wfService.getTicket() }}</h2>
            </mat-card-subtitle> -->
            <mat-card-content>
                <core-context-hint [hintId]="'Speech1'"></core-context-hint>
                <credit-locate-client (selectedAccount)="openAccountView($event)"></credit-locate-client>
            </mat-card-content>
            <mat-card-actions>
                <!-- <button mat-menu-item (click)="getNewTicket()" routerLink="/customer" routerLinkActive="active">
                    <mat-icon>surfing</mat-icon>New Ticket
                </button> -->

                <!-- <button *ngIf="!authService.isLoggedIn()" mat-menu-item routerLink="/login" routerLinkActive="active">
                    <mat-icon>login</mat-icon>Login
                </button>
                <button *ngIf="authService.isLoggedIn()" mat-menu-item (click)="authService.logout()" routerLink="/home" routerLinkActive="active">
                    <mat-icon>logout</mat-icon>Logout
                </button>
                <button *ngIf="authService.isLoggedIn()" mat-menu-item (click)="getNewTicket()" routerLink="/customer" routerLinkActive="active">
                    <mat-icon>surfing</mat-icon>Customer
                </button> -->
            </mat-card-actions>
        </mat-card>
    </div>
    <credit-view-account [account]="account" class="customer-view" *ngIf="wfService.getTicketStatus() == 'VIEW'" [newSearchFunction]="newSearchFunction" newSearchLink="/customer"></credit-view-account>
    <!-- <credit-view-account *ngIf="authService.isLoggedIn && wfService.getTicketStatus() == 'VIEW'" class="customer-view" [newSearchFunction]="newSearchFunction" newSearchLink="/customer"></credit-view-account> -->
</div>