import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { config } from './environments/tenant';

if (environment.production) {
  enableProdMode();
}

// https://reactgo.com/angular-change-favicon-dynamically/
let favIcon: HTMLLinkElement = document.querySelector('#appIcon') ?? new HTMLLinkElement();

if (favIcon) {
  // favIcon.href = 'https://www.google.com/favicon.ico';
  favIcon.href = config.tenantFavIcon;
} 

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
