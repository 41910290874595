<ng-container [ngSwitch]="menu">
    <div *ngSwitchDefault class="container">
        <ng-container [ngSwitch]="menu">
            <credit-ac-view001        *ngSwitchCase="'NOTA'" [actionCode]="menu"></credit-ac-view001>
            <credit-ac-maintenance001 *ngSwitchCase="'ACTI'" [actionCode]="menu"></credit-ac-maintenance001>
            <credit-ac-maintenance002 *ngSwitchCase="'DOMI'" [actionCode]="menu"></credit-ac-maintenance002>
            <credit-ac-maintenance004 *ngSwitchCase="'ALCO'" [actionCode]="menu"></credit-ac-maintenance004>
            <credit-ac-maintenance105 *ngSwitchCase="'EMBO'" [actionCode]="menu"></credit-ac-maintenance105>
            <credit-ac-maintenance006 *ngSwitchCase="'INS1'" [actionCode]="menu"></credit-ac-maintenance006>
            <credit-ac-maintenance006 *ngSwitchCase="'INS2'" [actionCode]="menu"></credit-ac-maintenance006>
            <credit-ac-monetary001    *ngSwitchCase="'M409'" [actionCode]="menu"></credit-ac-monetary001>
            <credit-ac-monetary001    *ngSwitchCase="'M456'" [actionCode]="menu"></credit-ac-monetary001>

            <!-- <credit-ac-maintenance005 *ngSwitchCase="'EMB1'" [actionCode]="menu" [stateId]="_stateId"></credit-ac-maintenance005> -->
            <credit-ac-maintenance105 *ngSwitchCase="'EMB1'" [actionCode]="menu"></credit-ac-maintenance105>
            <credit-ac-maintenance001 *ngSwitchCase="'ACT1'" [actionCode]="menu"></credit-ac-maintenance001>
            <credit-ac-maintenance002 *ngSwitchCase="'DOM1'" [actionCode]="menu"></credit-ac-maintenance002>
            <credit-ac-maintenance004 *ngSwitchCase="'ALC1'" [actionCode]="menu"></credit-ac-maintenance004>
            <credit-ac-monetary001    *ngSwitchCase="'M309'" [actionCode]="menu"></credit-ac-monetary001>
            <credit-ac-monetary001    *ngSwitchCase="'M246'" [actionCode]="menu"></credit-ac-monetary001>

        </ng-container>
    </div>

    <div *ngSwitchCase="'view'" class="container">
        <div class="box box1">
            <div style="grid-column: span 2;"><h1>CONSULTA</h1><core-context-hint [hintId]="'0002'" [hintData]="{section: 'CONSULTA' }"></core-context-hint><h3>Favoritos</h3></div>
            <core-smart-button *ngFor="let but of buttons | myFilter:{ options: { type: menu, favorite: 1 }}" [config]="but" ></core-smart-button>
        </div>
        <div class="box box2">
            <div style="grid-column: span 2;"><h3>Todos</h3></div>
            <core-smart-button *ngFor="let but of buttons | myFilter:{ options: { type: menu }}" [config]="but" ></core-smart-button>
        </div>
    </div>

    <div *ngSwitchCase="'maintenance'" class="container">
        <div class="box box1">
            <div style="grid-column: span 2;"><h1>MANTENIMIENTO</h1><core-context-hint [hintId]="'0002'" [hintData]="{section: 'MANTENIMIENTO'}"></core-context-hint><h3>Favoritos</h3></div>
            <core-smart-button *ngFor="let but of buttons | myFilter:{ options: { type: menu, favorite: 1 }}" [config]="but" ></core-smart-button>
        </div>
        <div class="box box2">
            <div style="grid-column: span 2;"><h3>Todos</h3></div>
            <core-smart-button *ngFor="let but of buttons | myFilter:{ options: { type: menu }}" [config]="but" ></core-smart-button>
        </div>
    </div>
    
    <div *ngSwitchCase="'monetary'" class="container">
        <div class="box box1">
            <div style="grid-column: span 2;"><h1>MONETARIOS</h1><core-context-hint [hintId]="'0002'" [hintData]="{section: 'MONETARIOS'}"></core-context-hint><h3>Favoritos</h3></div>
            <core-smart-button *ngFor="let but of buttons | myFilter:{ options: { type: menu, favorite: 1 }}" [config]="but" ></core-smart-button>
        </div>
        <div class="box box2">
            <div class="box box3">
                <div style="grid-column: span 1;"><h3>Cargos</h3></div>
                <core-smart-button *ngFor="let but of buttons | myFilter:{ options: { type: menu, txnType: 'D' }}" [config]="but" ></core-smart-button>
            </div>
            <div class="box box3">
                <div style="grid-column: span 1;"><h3>Abonos</h3></div>
                <core-smart-button *ngFor="let but of buttons | myFilter:{ options: { type: menu, txnType: 'C' }}" [config]="but" ></core-smart-button>
            </div>
        </div>
    </div>    
</ng-container>
