import { FlowStateObject } from 'src/app/modules/workflow/entities/workflow.d';
import { ButtonConfig } from 'src/app/modules/core/entities/core';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContextService } from 'src/app/modules/credit/services/context.service';
import { VplService } from 'src/app/modules/credit/services/vpl.service';
import { FlowQueueObject } from '../../entities/workflow.d';
import { FlowQueue } from '../../entities/workflow.d';
import { FlowQueueService } from '../../services/flow-queue.service';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'flow-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit, AfterViewInit {
  private _actionSelection!: (state: FlowStateObject) => void;

  @Input() set queueList(queues: string[]) {
    this._queueList = queues;
    this.refreshQueues();
  } 
  @Input() set actionSelection(fn: (state: FlowStateObject) => void) {
    this._actionSelection = fn;
  }

  public get queueList() {
    return this._queueList;
  }

  @Output() queueSelected = new EventEmitter();
  @Output() taskSelected = new EventEmitter();

  @ViewChild(MatSort) sort!: MatSort;
  ngAfterViewInit() {
    // https://stackoverflow.com/questions/48891174/angular-material-2-datatable-sorting-with-nested-objects
    this.dsTasks.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'subType'     : return item.state?.subType           ?? '';
          case 'addTimestamp': return item.addTimestamp             ?? '';
          case 'status'      : return item.state?.stateData.status  ?? '';
          case 'step'        : return item.state?.stateData.step    ?? '';
          case 'account'     : return item.state?.stateData.account ?? '';
          case 'card'        : return item.state?.stateData.card    ?? '';

          default: throw new Error(`Unknown Column ${property}. Update sortingDataAccessor()`);
        }
    };
    this.dsTasks.sort = this.sort;
  }

  public _queueList: string[] = [];
  public dsQueues!: Observable<FlowQueue[]>;
  public currentQueue: string = '';
  public queueColumns: string[] = [ 'name', 'status', 'select' ];

  public dsTasks: MatTableDataSource<FlowQueueObject> = new MatTableDataSource();
  // public currentTask: FlowStateObject = '';
  public taskColumns: string[] = [ 'subType', 'addTimestamp', 'status', 'step', 'account', 'card', 'select' ];

  public cfgSelectQueue(id: string): ButtonConfig {

    let fnClick = () => {
      this.refreshQueueObjects(id);
      this.currentQueue = id;
      return  false;
    };

    let fnIcon = () => { return 'queue';};
    let fnDisabled = () => { return false; };
    
    // let fnSwitchTabToAction = () => { this.accountTabSelectedIndex = this.ACTION_TAB_INDEX; };
    // let fnCVIEW = () => { fnSwitchTabToAction(); return this.context.addAction('VIEW', 'info'); };    
    return {
      type: 'text',
      id: `bsq-${id}`,
      name: `bsq-${id}`,
      title: 'Select Queue',
      hint: '',
      hideIfDisabled: false,
      click: fnClick,
      icon: fnIcon,
      disabled: fnDisabled,
    };
  }

  public cfgSelectTask(id: string, queueId: string ): ButtonConfig {

    let fnClick = () => { 
      // alert(`click ${id}`);
      this.taskSelected.emit({ taskId: id, queueId: queueId });
      return true;
    };

    let fnIcon = () => { return 'build_circle';};
    let fnDisabled = () => { return false;};
    
    // let fnSwitchTabToAction = () => { this.accountTabSelectedIndex = this.ACTION_TAB_INDEX; };
    // let fnCVIEW = () => { fnSwitchTabToAction(); return this.context.addAction('VIEW', 'info'); };    

    return {
      type: 'text',
      id: `bsa-${id}`,
      name: `bsa-${id}`,
      title: 'Select Task',
      hint: '',
      hideIfDisabled: false,
      click: fnClick,
      icon: fnIcon,
      disabled: fnDisabled,
    }
  }

  // public filter!: { accountNumber: string };

  public queues!: FlowQueue[];
  public queueObjects!: FlowQueueObject[];

  constructor(
    public flow: FlowQueueService,
    public credit: VplService,
    public context: ContextService
    ) { }

  ngOnInit(): void {
    this.refreshQueues();
  }

  refreshQueues() {
    this.dsQueues = this.flow.getQueues(true, this._queueList);
  }

  // public selectPromo(id: string = '', action: string = '') {
  //   this.promoSelected.emit({id: id, action: action});
  // }

  getActionCode(action: string) {
    return this.credit.getActionCode('id' + action);
  }

  refreshQueueObjects(queueId: string) {
    this.flow.getQueueObjects(queueId).subscribe(data => {
      // this.dsTasks.sort = this.sort;
      // this.dsTasks.sort.sort = (sort: MatSortable) => { console.log(sort)};
      // DEBUG this.dsTasks.sortData = (data: FlowQueueObject[], sort: MatSort) => { console.log(data); return [data[1], data[0]];};
      this.dsTasks.data = data;
    })
  }

  announceSortChange($event: any) {
  }

  statusClass(status: string) {
    switch(status) {
      case "new": return "status-new";
      case "inprogress": return "status-inprogress";
      case "complete": return "status-complete";
      default: return "";
    }
  }
}
