<div class="container">
    ORG: 
    <input class="navbar-display-field org" type="text" [value]="account.org" readonly>
    LOGO: 
    <input class="navbar-display-field logo" type="text" [value]="account.logo" readonly>
    Cuenta: 
    <input class="navbar-display-field account" type="text" [value]="account.id" readonly>
    Nombre: 
    <input class="navbar-display-field name" type="text" [value]="account.shortName" readonly>
</div>
