<!-- <p>Card Number: {{cardNumber}}</p> -->
<!-- <p>Step: {{this.step}}</p> -->
<div class="box box3">
    <div class="idstatus"><h3>Customer Identification<span class="pass"><mat-icon>check_circle_outline</mat-icon> {{successCount}} / {{requiredChecks}} </span> <span class="fail"> <mat-icon>highlight_off</mat-icon> {{failCount}} / {{maxFailures}}</span></h3></div>
    <!-- <div class="box box2"></div> -->

    <div *ngIf="successCount < requiredChecks; else customerVerified">
        <div *ngFor="let method of idMethods; let i = index">
            <div *ngIf="this.step == i" class="box box4">
                <h3 style="grid-column: span 4;">Identity Check {{i + 1}}</h3>
        
                <div *ngIf="method.type == 'compare'">
                    <p>Prompt: {{method.prompt}} [{{method.value}}]</p>
                    <mat-form-field appearance="fill"><mat-label>{{method.name}}</mat-label><input matInput type="text" autocomplete="off" #responseValue></mat-form-field>
                    <core-smart-button [config]="compareButtonConfig"></core-smart-button>     
                </div>

                <div *ngIf="method.type == 'check'">
                    <p>Check: {{method.prompt}}</p>
                    <p class="checkValue" *ngIf="method.value == 'customerName' && this.creditSvc.account?.shortName">Value: {{this.creditSvc.account?.shortName}}</p>
                    <core-smart-button [config]="confirmButtonConfig"></core-smart-button>     
                    <core-smart-button [config]="denyButtonConfig"></core-smart-button>     
                </div>

                <div *ngIf="method.type == 'otp'">
                    <core-otp-request [config]="otp1Config" (otpSuccess)="otpSuccess($event)" (otpFail)="otpFail($event)"></core-otp-request>
                </div>
        
                <div *ngIf="method.type == 'visual'">
                    <img class="selfie" src="./../../../../../../assets/mm22.png">
                    <p>Prompt: {{method.prompt}}</p>
                    <core-smart-button [config]="confirmButtonConfig"></core-smart-button>     
                    <core-smart-button [config]="denyButtonConfig"></core-smart-button>     
                </div>

                <div *ngIf="method.type == 'call'">
                    <p>Prompt: {{method.prompt}}</p>
                    <mat-radio-group [(ngModel)]="callSelection" style="grid-column: span 2;">
                        <div *ngFor="let t of method.value">
                            <mat-radio-button [value]="t">{{t}}</mat-radio-button>
                        </div>
                    </mat-radio-group>                     

                    <mat-form-field appearance="fill"><mat-label>{{method.name}}</mat-label><input matInput type="text" autocomplete="off" #responseIDType></mat-form-field>
                    <core-smart-button [config]="callButtonConfig"></core-smart-button>     
                </div>

            </div>
        </div>
    </div>
    <ng-template #customerVerified>
        <div>
            <mat-icon>check_circle_outline</mat-icon>Customer Verified
            <core-smart-button [config]="confirmButtonConfig"></core-smart-button>     
        </div>
    </ng-template>
</div>


