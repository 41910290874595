<div class="container">
    <div class="box box4">
        <h1>{{action.title}} - {{action!.hint}}</h1>
        <credit-list-cards style="grid-column: span 4;" *ngIf="processStep == 'init'" [cardIsDisabled]="cardIsDisabled" [displayedColumns]="columns" [cardSelected]="cardSelected"></credit-list-cards>
    </div>

    <ng-container *ngIf="processStep == 'entry'" style="grid-column: span 4;" >
        <form>
            <div class="box box1">
                <core-data-field-view label="Card Number"     type="text" [value]="creditSvc.card?.cardNumber"></core-data-field-view>
                <core-data-field-view label="Card Sequence"   type="text" value="001"></core-data-field-view>
                <core-data-field-view label="Block Code"      type="text" [value]="creditSvc.card?.blockCode"></core-data-field-view>
                <core-data-field-view label="Block Date"      type="text" [value]="date1(1) | date"></core-data-field-view>
                <core-data-field-view label="Last Maintenance" type="text" [value]="date1(2) | date"></core-data-field-view>
                <core-data-field-view label="Last Maint User"  type="text" value="j.gonzalez"></core-data-field-view>
                <core-data-field-view label="Embossed Name"   type="text" [value]="creditSvc.card?.embossedName"></core-data-field-view>
                <div></div>
            </div>
            <hr style="grid-column: span 4;">
            <div class="box box1">
                <core-smart-select [config]="activationConfig" [options]="activationOptions" (selectChange)="change($event)"></core-smart-select>
                <mat-form-field style="grid-column: span 3;" appearance="fill" class="note">
                    <mat-label>NOTA for {{action.title}} on {{currCard.cardNumber}}</mat-label>
                    <textarea matInput rows="3" [(ngModel)]="context.actionVars.EMBO.noteValue" name="noteValue"></textarea>
                </mat-form-field>

                <core-smart-button [config]="saveButtonConfig"></core-smart-button>    


                <!-- <core-data-field-view label="Short Name"     type="text" [value]="creditSvc.account?.shortName"></core-data-field-view>
                <core-data-field-view label="Current Balance" type="text" [value]="creditSvc.account?.balance       | currency"></core-data-field-view>
                <core-data-field-view label="Open To Buy"     type="text" [value]="creditSvc.account?.otb           | currency"></core-data-field-view>
                <core-data-field-view label="Cycle Due"       type="text" [value]="creditSvc.account?.cycleDue      | currency"></core-data-field-view>
        
                <core-data-field-view label="Memo Balance"    type="text" [value]="creditSvc.account?.memoBalance   | currency"></core-data-field-view>
                <core-data-field-view label="Debit Balance"   type="text" [value]="creditSvc.account?.debitBalance  | currency"></core-data-field-view>
                <core-data-field-view label="Credit Balance"  type="text" [value]="creditSvc.account?.creditBalance | currency"></core-data-field-view>
                <core-data-field-view label="Memo Pay"        type="text" [value]="creditSvc.account?.memoPay       | currency"></core-data-field-view> -->
            </div>
            <!-- <div  class="box3">
                <mat-form-field appearance="fill" class="note">
                    <mat-label>Comment for {{action}} on {{currCard.cardNumber}}</mat-label>
                    <textarea matInput rows="3" [(ngModel)]="context.actionVars.EMBO.noteValue" name="noteValue"></textarea>
                </mat-form-field>
            </div> -->
    
        </form>

        <form [formGroup]="actionForm">
            <div class="box box2">
                <ng-container *ngFor="let field of fieldConfigs | keyvalue" class="ff">
                    <div *ngIf="field.value.type=='submit'" style="grid-column: span 4;">
                        <button mat-raised-button type="button" (click)="locateAction()" class="btn btn-primary" [disabled]="!validInput()">
                            <mat-icon *ngIf="!validInput()">edit</mat-icon><mat-icon *ngIf="validInput()">search</mat-icon>Search
                        </button>
                    </div>
                    
                    <div *ngIf="field.value.type=='divider'" style="grid-column: span 4;"><hr></div>
    
                    <core-smart-select *ngIf="field.value.type=='smartselect'" class="smart-field" [options]="fieldOptions.get(field.key)" [config]="field.value" (selectChange)="change($event)"></core-smart-select>
    
                    <core-data-field-view *ngIf="field.value.type=='display'" label="{{field.value.title}}" type="text" [value]="field.value.name"></core-data-field-view>

                    <mat-form-field *ngIf="field.value.type=='date'" class="form-control" appearance="fill"><mat-label>{{field.value.title}}</mat-label><input matInput [matDatepicker]="picker"><mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle><mat-datepicker #picker></mat-datepicker></mat-form-field>
                    <mat-form-field *ngIf="field.value.type=='input'" appearance="fill">
                        <mat-label>{{ field.value.title }}</mat-label>
                        <input matInput 
                        [id]="field.value.name" 
                        [type]="field.value.format" 
                        class="form-control" 
                        [formControlName]="field.value.name" 
                        [placeholder]="field.value.placeholder"
                        autocomplete="off"
                        [readonly]="field.value.readonly"
                        >
                    </mat-form-field>
                </ng-container>
            </div>
        </form>

    </ng-container>

    <div class="box box1" style="grid-column: span 4;">
        <!-- <core-smart-button *ngIf="processStep == 'entry'" [config]="saveButtonConfig"></core-smart-button>     -->
        <core-smart-button *ngIf="processStep == 'success'" [config]="newButtonConfig"></core-smart-button>    
        <core-smart-button [config]="closeButtonConfig" color="warn"></core-smart-button>    
    </div>
</div>

