import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import QrScanner from 'qr-scanner';

@Component({
  selector: 'core-qr-scan',
  templateUrl: './qr-scan.component.html',
  styleUrls: ['./qr-scan.component.scss']
})
export class QrScanComponent implements AfterViewInit {

  @ViewChild('v1') video!: ElementRef<HTMLVideoElement>;
  @ViewChild('vcontainer') videoContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('u1') url!: ElementRef<HTMLParagraphElement>;

  @Output() qrFound = new EventEmitter();

  public qrScanner!: QrScanner;

  constructor() { }

  ngAfterViewInit(): void {
    let funcResult = (result: QrScanner.ScanResult) => { 
      // console.log(result); 
      // const urlParams = new URLSearchParams(result.data);
      // const rfc = urlParams.get('rfc');
      // const app = urlParams.get('app');
      // urlParams.forEach((p, k)=>{console.log(k + '->' + p)});
      // console.log(urlParams);
      // this.url.nativeElement.innerHTML = 'RFC: ' + rfc + '<br>Application#: ' + app;
      // this.url.nativeElement.style.backgroundColor = 'yellow';
      this.qrFound.emit(result);
      this.qrScanner.stop();
      this.videoContainer.nativeElement.innerHTML = "<h1>QR Code read successfully.</h1>";
    }
    let options = {
      onDecodeError: (error: string | Error) => { },
      highlightScanRegion: true,
      // overlay: this.videoContainer.nativeElement
    };
    this.qrScanner = new QrScanner(this.video.nativeElement, funcResult, options);

  //   constructor(video: HTMLVideoElement, onDecode: (result: QrScanner.ScanResult) => void, options: {
  //     onDecodeError?: (error: Error | string) => void;
  //     calculateScanRegion?: (video: HTMLVideoElement) => QrScanner.ScanRegion;
  //     preferredCamera?: QrScanner.FacingMode | QrScanner.DeviceId;
  //     maxScansPerSecond?: number;
  //     highlightScanRegion?: boolean;
  //     highlightCodeOutline?: boolean;
  //     overlay?: HTMLDivElement;
  //     /** just a temporary flag until we switch entirely to the new api */
  //     returnDetailedScanResult?: true;
  // });

    this.qrScanner.start();
  }

  ngOnDestroy() {
    this.qrScanner.stop();
    this.qrScanner.destroy();
  }


}
