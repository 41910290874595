<div class="container">
    <div class="local-menu">
        <!-- <mat-selection-list #localMenu [multiple]="false" (selectionChange)="onSelection($event, localMenu.selectedOptions.selected)"> -->
        <mat-selection-list #localMenu [(ngModel)]="context.selectedOptionAcct" [multiple]="false" (selectionChange)="onSelection($event, localMenu.selectedOptions.selected)">
            <mat-list-option *ngFor="let opt of menuOptions" [value]="opt.id" [(selected)]="opt.default">
                <div class="menu-item">
                    <mat-icon>{{opt.icon}}</mat-icon>
                    <div class="menu-spacer"></div>
                    {{opt.title}}
                    <div class="menu-spacer"></div>
                    <span [matBadge]="opt.badge" matBadgeOverlap="false" matBadgeColor="warn" matBadgePosition="after"></span>
                </div>
            </mat-list-option>
            <hr/>
        </mat-selection-list>                  
    </div>
    <div class="local-content">
        <div [ngSwitch]="context.selectedOptionAcct[0]" style="padding:0px;">
            <ng-container *ngSwitchCase="'balances'"> <credit-account-balances  [account]="account">      </credit-account-balances> </ng-container>
            <ng-container *ngSwitchCase="'cards'"   > <credit-account-cards     [account]="account">      </credit-account-cards>    </ng-container>
            <ng-container *ngSwitchCase="'plans'"   > <credit-list-plans        [account]="account.id">   </credit-list-plans>       </ng-container>
            <ng-container *ngSwitchCase="'account'" > <credit-account           [account]="account">      </credit-account>          </ng-container>
            <ng-container *ngSwitchCase="'history'" > <credit-list-transactions [account]="account.id">   </credit-list-transactions></ng-container>
            <!-- <ng-container *ngSwitchCase="'promos'"  > <credit-account-messages></credit-account-messages></ng-container> -->
            <ng-container *ngSwitchCase="'promos'"  > <credit-list-messages     [account]="account.id">   </credit-list-messages>    </ng-container>
            
        </div>        
    </div>
</div>