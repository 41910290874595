import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'core-auth0-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class Auth0LoginComponent implements OnInit {

  constructor(@Inject(DOCUMENT) public document: Document, public auth: AuthService) {}

  ngOnInit(): void {

  }

  login() {
    this.auth.loginWithPopup();
  }

  check() {
    this.auth.loginWithPopup().subscribe((data) => {
    })
  }
}
