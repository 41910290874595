<div class="container">
    <div *ngIf="hasAccepted">
        <div>
            PRODUCT: {{selectedProduct.name}}
        </div>
        <div>
            <credit-image-card [cardProduct]="selectedProduct.id"></credit-image-card>
        </div>
    </div>

    <div *ngIf="!hasAccepted">
        <table mat-table [dataSource]="dataSource">
            <!-- Position Column -->
            <!-- <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef> Select </th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox [value]="element.id" (change)="selectProduct($event, element)"></mat-checkbox>
                </td>
            </ng-container> -->
    
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
            </ng-container>
    
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Product </th>
                <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
            </ng-container>
    
            <ng-container matColumnDef="coercivity">
                <th mat-header-cell *matHeaderCellDef> Coercivity </th>
                <td mat-cell *matCellDef="let element"> {{element.coercivity}} </td>
            </ng-container>
    
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
            </ng-container>
           
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <p>
            <core-smart-select [config]="cardProductConfig" [options]="cardProductOptions" [selectedOption]="productId" (selectChange)="selectProduct($event)"></core-smart-select>
        </p>
        <p>
            <button mat-raised-button type="button" class="btn btn-primary" (click)="onAccept()" [disabled]="!selectedProduct">
                <mat-icon>check_circle</mat-icon>Select
            </button>    
        </p>
    </div>


</div>