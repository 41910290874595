import { EnvironmentService } from 'src/app/services/environment.service';
import { CreditAccount } from './../../entities/credit.d';
import { MatTable } from '@angular/material/table';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, OnChanges, DoCheck, IterableDiffers } from '@angular/core';
import { VplService } from './../../services/vpl.service';


@Component({
  selector: 'credit-list-acct',
  templateUrl: './list-acct.component.html',
  styleUrls: ['./list-acct.component.scss']
})
export class ListAcctComponent implements OnInit, OnChanges, DoCheck {
  // @Input() targetLink: string = '';

  @Input() dataSource: CreditAccount[] = [];
  @Output() selected = new EventEmitter();

  @ViewChild('acctTable') acctTable!: MatTable<CreditAccount>;

  public iterableDiffer: any;

  displayedColumns: string[] = [ 'select', /* 'image', */ 'org', 'logo', 'balance', 'otb', 'minimo', 'vencido', 'status', 'bc1', 'bc2', 'ifree', 'refinance', 'payDate'];

  constructor(
    public vplService: VplService, 
    public env: EnvironmentService,
    private iterableDiffers: IterableDiffers
    ) {
      this.iterableDiffer = this.iterableDiffers.find([]).create();
  }

  ngOnInit(): void {
    if (!this.env.newAPI) {
      this.vplService.getAccounts().subscribe(data => { this.dataSource = data;})
    }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource) {
      this.acctTable?.renderRows();
    }
  }

  ngDoCheck(): void {
    if (this.env.newAPI){
      const changes = this.iterableDiffer.diff(this.dataSource);
      if (changes) {
        // Default sort: account number
        this.dataSource.sort((a,b)=> {
          return a.id.localeCompare(b.id);
        });
        this.acctTable?.renderRows();
      }
    }
  }

  selectAcct(id: string) {
    this.selected.emit(id);
  }

}
