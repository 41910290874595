import { AuthService } from './../../../../../services/auth.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonConfig } from 'src/app/modules/core/entities/core';
import { ContextService } from '../../../services/context.service';
import { VplService } from '../../../services/vpl.service';

@Component({
  selector: 'credit-ac-view001',
  templateUrl: './ac-view001.component.html',
  styleUrls: ['./ac-view001.component.scss']
})
export class AcView001Component implements OnInit {

  @Input() actionCode: string = 'NOTA';
  @Input() minimal: boolean = false;
  @Input() listOnly: boolean = false;
  @Input() noList: boolean = false;

  @Output() close = new EventEmitter();
  @Output() noteSaved = new EventEmitter();


  public action!: any;

  constructor(
    public creditSvc: VplService,
    public context: ContextService,
    private auth: AuthService
    ) { }

  public processStep = 'init';
  public noteMade = false;

  public saveButtonConfig!: ButtonConfig;
  public closeButtonConfig!: ButtonConfig;

  ngOnInit(): void {

    this.action = this.creditSvc.getActionCode('id'+this.actionCode);

    let fnClickSave = (): boolean => {
      if(this.context.actionVars.view.noteValue.trim() != '') {
        let note = { date: new Date(), user: this.auth.user.name, action: this.actionCode, note: this.context.actionVars.view.noteValue };
        this.context.actionVars.view.priorViews.push( note );
        this.context.actionVars.view.noteValue = '';
        this.noteMade = true;
        this.noteSaved.emit(note);
        return true;
      } else {
        return false;
      }
    }

    this.saveButtonConfig = {
      id: '',
      name: '',
      hint: 'Save Note',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickSave,
      icon: function (): string {
        return 'check_circle';
      },
      disabled: function (): boolean {
        return false;
      }
    }

    let fnClickClose = (): boolean => {
      this.context.removeAction(this.actionCode, 'view');
      return false;
    }

    this.closeButtonConfig = {
      id: '',
      name: '',
      hint: 'Close',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickClose,
      icon: function (): string {
        return 'cancel';
      },
      disabled: function (): boolean {
        return false;
      }
    }

  }

}
