<div class="container theme-100" >
    <mat-toolbar class="va-toolbar">
        Vista Integral 360&deg;<span class="example-spacer"></span>Sistema de Credito {{env.tenantName}}
    </mat-toolbar>
    <mat-toolbar color="primary" style='display:block;'>
        <h1>Actuación</h1>
    </mat-toolbar>

    <credit-vp-dash></credit-vp-dash>
</div>
