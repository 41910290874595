<div class="vs-main-container">
  <mat-toolbar color="primary">
    <button mat-icon-button class="menu-icon" aria-label="menu icon-button with menu icon"
    [matMenuTriggerFor]="menu"> 
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="/home" routerLinkActive="active">
          <mat-icon>home</mat-icon>Home
      </button>
      <button *ngIf="authService.isLoggedIn" mat-menu-item routerLink="/customer" routerLinkActive="active">
        <mat-icon>face</mat-icon>Cliente
      </button>
      <button *ngIf="authService.hasAccess('performancePage', 'read')" mat-menu-item routerLink="/performance" routerLinkActive="active">
        <mat-icon>speed</mat-icon>Actuación
      </button>
      <button *ngIf="authService.hasAccess('tasksPage', 'read')"mat-menu-item routerLink="/tasks" routerLinkActive="active">
        <mat-icon>task</mat-icon>Tareas
      </button>
      <button *ngIf="authService.isLoggedIn" mat-menu-item routerLink="/promotions" routerLinkActive="active" disabled>
        <mat-icon>shop</mat-icon>Promociones
      </button>
      <button *ngIf="!authService.isLoggedIn" mat-menu-item routerLink="/login" routerLinkActive="active">
        <mat-icon>login</mat-icon>Iniciar Sesión
      </button>
      <button *ngIf="authService.isLoggedIn" mat-menu-item (click)="authService.logout()" routerLink="/home" routerLinkActive="active">
        <mat-icon>logout</mat-icon>Cerrar Sesión
      </button>
      <!-- <button mat-menu-item>
        <mat-icon>test</mat-icon>Test
      </button> -->
    </mat-menu>    
    <span>VSConsole
    </span>
    <span class="toolbar-spacer"></span>
    <img [src]="env.corpLogoUrl" class="corp-logo" [alt]="env.tenantName + ' Logo'" [title]="env.tenantName + ' ;Logo'">
    <span class="toolbar-spacer"></span>
    <!-- <button mat-icon-button class="menu-icon favorite-icon" aria-label="menu icon-button with heart icon">
      <mat-icon>favorite</mat-icon>
    </button>
    <button mat-icon-button class="menu-icon" aria-label="menu icon-button with share icon">
      <mat-icon>share</mat-icon>
    </button> -->

    <div *ngIf="authService.isLoggedIn">
      <!-- <core-user></core-user> -->
        {{authService.user.name}}
        <button *ngIf="authService.isLoggedIn" mat-icon-button class="menu-icon" aria-label="help icon-button with help icon" routerLink="/profile" routerLinkActive="active">
            <mat-icon>person</mat-icon>
        </button>
    </div>
    <button mat-icon-button class="menu-icon" aria-label="help icon-button with help icon" routerLink="/help" routerLinkActive="active">
      <mat-icon>help</mat-icon>
    </button>

  </mat-toolbar>
  <router-outlet></router-outlet>
  <!-- Running: {{env.tenantName}} -->
  <div>
    <!-- <app-service-worker></app-service-worker> -->
  </div>
</div>

