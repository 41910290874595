import { AuthService } from 'src/app/services/auth.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { VscapiService } from './vscapi.service';
import { HighContrastMode } from '@angular/cdk/a11y';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ContextHintData } from '../entities/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContextHintService {

  constructor(
    public vscapiService: VscapiService,
    public env: EnvironmentService,
    public auth: AuthService
  ) {  }

  public getHint(id: string, data: any) : Observable<ContextHintData> {

    return this.vscapiService.getHint(id, this.env.language)
    .pipe(
      map(hintData => {
        if (hintData) {
          data.agentName = this.auth.user.firstName;
          data.id = hintData.id;
          data.title = hintData.title;
          data.description = hintData.description;
          for (let key in data) {
            if (data.hasOwnProperty(key)) {
               if (key != 'title') hintData.title = hintData.title.replace(`[${key}]`, data[key]);
               if (key != 'description') hintData.description = hintData.description.replace(`[${key}]`, data[key]);
               if (key != 'body') hintData.body = hintData.body.replace(`[${key}]`, data[key]);
               hintData.steps?.forEach(step => {
                step.title = step.title.replace(`[${key}]`, data[key]);
                step.text = step.text.replace(`[${key}]`, data[key]);
               })
            }
          }
        }

        return hintData;
      })
    );

    // let hintTemplate: ContextHintData = this.hints.get(id) ?? {
    //   id: "0000",
    //   title: "Default",
    //   description: "Desc",
    //   body: "Body"
    // };

    // let hintData: ContextHintData = this.customizeMessage({
    //   id: hintTemplate.id,
    //   title: hintTemplate.title,
    //   description: hintTemplate.description,
    //   body: hintTemplate.body,
    //   steps: hintTemplate.steps,
    // }, data);

    // for (let key in data) {
    //   if (data.hasOwnProperty(key)) {
    //      hintData.title = hintData.title.replace(`[${key}]`, data[key]);
    //      hintData.description = hintData.description.replace(`[${key}]`, data[key]);
    //      hintData.body = hintData.body.replace(`[${key}]`, data[key]);
    //   }
    // }
    // hintData.title = hintData.title.replace(`[id]`, hintData.id);
    // hintData.description = hintData.description.replace(`[id]`, hintData.id);
    // hintData.body = hintData.body.replace(`[id]`, hintData.id);
    // hintData.body = hintData.body.replace(`[title]`, hintData.title);
    // hintData.body = hintData.body.replace(`[description]`, hintData.description);

    // return of(hintData);    
  }

  private customizeMessage(hintData: ContextHintData, data: any) : ContextHintData {
    switch (hintData.id) {
      case "emboMarketing":
        if (data.section == '1') {
          hintData.description = 'Speech para solicitar firma carátula';
        }
        if (data.section == '2') {
          hintData.description = 'Speech para ofrecer incremento temprano';
        }
        if (data.section == '3') {
          hintData.description = 'Speech para entregar tarjeta, explicar beneficios y digitalizar';
        }
        if (data.section == '4') {
          hintData.description = 'Terminado';
        }
        break;
    }

    return hintData;
  }

}
