<div class="container" style="max-width: 550px">
    <div *ngIf="isLoggedIn()">
    </div>
  
    <form [formGroup]="loginForm" (ngSubmit)="loginSubmit()">
        <div class="login-grid" *ngIf="isLoggedIn() === true; else loggedOutBlock">
            <mat-form-field appearance="fill">
                <mat-label>First Name</mat-label>
                <input matInput type="text" class="form-control" [value]="user.firstName" id="firstname" readonly>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Last Name</mat-label>
                <input matInput type="text" class="form-control" [value]="user.lastName" id="lastname" readonly>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input matInput type="text" class="form-control" [value]="user.email" id="email" readonly>
            </mat-form-field>
            <button mat-raised-button type="submit" class="btn btn-primary">Cerrar Sesión</button>
        </div>
        <ng-template #loggedOutBlock>
            <div class="login-grid">
                <mat-form-field appearance="fill">
                    <mat-label>User Name</mat-label>
                    <input matInput type="text" formControlName="name"  id="username">
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" id="password">
                </mat-form-field>
                <button mat-raised-button color="primary" type="submit" class="btn btn-primary">Iniciar Sesión</button>
                
            </div>
        </ng-template>
    </form>
</div>