import { EnvironmentService } from 'src/app/services/environment.service';
import { CWStatusRecord } from './../../../services/card-wizard.service';
import { VplService } from './../../../services/vpl.service';
import { AuthService } from './../../../../../services/auth.service';
import { ContextService } from './../../../services/context.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ButtonConfig } from 'src/app/modules/core/entities/core';
import { CardWizardService } from '../../../services/card-wizard.service';
import { from, Observable, of, Subscription, timer } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'credit-card-wizard',
  templateUrl: './card-wizard.component.html',
  styleUrls: ['./card-wizard.component.scss']
})
export class CardWizardComponent implements OnInit {

  @Input() activityId = '';
  @Input() cardNumber = '';
  @Input() actionCode = '';
  @Input() delay = 0;
  @Output() activityCompleted = new EventEmitter();

  public refreshButtonConfig!: ButtonConfig;

  public dataSource!: Observable<CWStatusRecord[]>;
  displayedColumns: string[] = ['id', 'account', 'nameClient', 'status'];

  public cwStatus: string = '';
  public timer!: Observable<number>;
  public timerSub!: Subscription;

  constructor(
    public cw: CardWizardService,
    public creditSvc: VplService,
    public context: ContextService,
    public auth: AuthService,
    public env: EnvironmentService
  ) { }

  ngOnInit(): void {
    switch (this.delay) {
      case 0:
        this.dataSource = this.cw.getCWStatus(this.creditSvc.card?.cardNumber, this.creditSvc.card?.embossedName);

        let fnClickRefresh = (): boolean => {

          this.dataSource = this.cw.getCWStatus(this.creditSvc.card?.cardNumber, this.creditSvc.card?.embossedName);

          let updateNote = 'Embossing Status Update Request ' + this.creditSvc.card?.cardNumber;
          this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });

          return true;
        }

        this.refreshButtonConfig = {
          id: '',
          name: '',
          hint: 'Refresh',
          hideIfDisabled: false,

          type: 'text',
          title: '',
          click: fnClickRefresh,
          icon: function (): string {
            return 'refresh';
          },
          disabled: () => { return false; }
        }
        break;

      default:
        this.cwStatus = `Embossing ${this.cardNumber} In Progress`;
        this.timer = timer(0, 1000);
        this.timerSub = this.timer.subscribe(val => {
          this.cwStatus = `Embossing ${this.cardNumber} In Progress. (${val})`;
          if (val > this.delay) {
            this.cwStatus = `Embossing ${this.cardNumber} Complete`;
            this.timerSub.unsubscribe();
          }
        });
    }

  }
  ngOnDestroy() {
    this.timerSub.unsubscribe();
  }
}
