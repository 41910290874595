import { AuthService } from './../../../../services/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginUser } from '../../entities/core';

import {Router} from '@angular/router';

@Component({
  selector: 'core-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input() loginTarget = '';
  
  loginForm!: FormGroup;
  win!: string;
  user!: LoginUser;
  password: string = '';
  isAuthenticated: boolean = false;
  
  constructor(
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.loginForm = new FormGroup({
      name          : new FormControl('', [Validators.required]),
      password      : new FormControl('', [Validators.required, Validators.minLength(8)]),
    });

    this.user = this.authService.user;
  }

  loginSubmit() {
    if (this.authService.isLoggedIn) {
      this.authService.logout();
    } else {
      let name = this.loginForm.value["name"];
      let password = this.loginForm.value["password"];

      this.authService.login(name, password).subscribe(result => {this.user = result;} );
    }
    if (this.loginTarget != '') {
      this.router.navigate([this.loginTarget]);
    }
  }

  isLoggedIn() {
    return this.authService.isLoggedIn;
  }
}

