import { SnackBarService } from './../../../../core/services/snack-bar.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { AuthService } from './../../../../../services/auth.service';
import { CreditCard } from './../../../entities/credit.d';
import { VplService } from './../../../services/vpl.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonConfig, OtpConfig } from 'src/app/modules/core/entities/core';
import { ContextService } from '../../../services/context.service';
import { ObservableInput, of } from 'rxjs';

@Component({
  selector: 'credit-ac-maintenance001',
  templateUrl: './ac-maintenance001.component.html',
  styleUrls: ['./ac-maintenance001.component.scss']
})
export class AcMaintenance001Component implements OnInit {

  @Input() actionCode!: string;
  @Output() close = new EventEmitter();

  public action!: any;

  constructor(public creditSvc: VplService,
              public context: ContextService,
              public auth: AuthService,
              public env: EnvironmentService,
              public snack: SnackBarService) { }

  public processStep = 'init';
  public otp1Config!: OtpConfig;

  public cardIsDisabled(card: CreditCard): boolean { return false};
  public cardSelected(card: CreditCard): void {};

  public columns = [ 'select', 'cardNumber', 'expiryDate', /*'reg', 'blockCode', 'blockCodeDesc',*/ 'st', 'stDesc', /*'gp', 'gpDesc', 'tg', 'tgDesc',*/ 'embossedName' ];
  public columns2 = [ 'cardNumber', 'expiryDate', /*'reg', 'blockCode', 'blockCodeDesc',*/ 'st', 'stDesc', /*'gp', 'gpDesc', 'tg', 'tgDesc',*/ 'embossedName' ];

  public newButtonConfig!: ButtonConfig;
  public closeButtonConfig!: ButtonConfig;

  public currCard!: CreditCard;


  ngOnInit(): void {

    this.action = this.creditSvc.getActionCode('id'+this.actionCode);

    this.otp1Config = {
      email: this.creditSvc.customer?.contactEmail,
      cell: this.creditSvc.customer?.contactTelephoneCell,
      update: false
    }

    this.cardSelected = (card: CreditCard) => 
    {
      this.processStep = 'otp1';
      this.currCard = card;
      // this.creditSvc.actionACTI(card.id);
      // alert('Card ' + card.id + ' with card number ' + card.cardNumber + ' has been submitted for activation.');
    }

    this.cardIsDisabled = (card: CreditCard) => { 
      if ( card.st != 'N' ) {
        return true;
      }
      return false;
    };

    let fnClickClose = (): boolean => {
      this.context.removeAction(this.actionCode, 'maintenance');
      return false;
    }

    this.closeButtonConfig = {
      id: '',
      name: '',
      hint: 'Close',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickClose,
      icon: function (): string {
        return 'cancel';
      },
      disabled: function (): boolean {
        return false;
      }
    }

    let fnClickNew = (): boolean => {
      this.processStep = 'init';
      return false;
    }

    this.newButtonConfig = {
      id: '',
      name: '',
      hint: 'New ' + this.actionCode,
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickNew,
      icon: function (): string {
        return 'verified_user';
      },
      disabled: function (): boolean {
        return false;
      }
    }
  }

  public otpFail(event: any) {
    // alert(`${this.env.dict.otp} verification failed`);
    this.snack.openSnackBar(`Error ${this.env.dict.otp} Failed`, 'OK');  
    this.processStep = 'otp1';
    return true;
  }

  public otpSuccess(otp: number) {
    this.processStep = 'success';
    this.creditSvc.actionACTI(this.currCard.id);
    this.context.actionVars.view.priorViews.push( { date: new Date(), user: this.auth.user.name, action: this.actionCode, note: 'Card ' + this.currCard.id + ':'+ this.context.actionVars.ACTI.noteValue } );
    this.context.actionVars.ACTI.noteValue = '';


    return true;
  }


}
