import { EnvironmentService } from 'src/app/services/environment.service';
import { Component, OnInit } from '@angular/core';
import { LoginUser } from 'src/app/modules/core/entities/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public env: EnvironmentService
  ) { }

  public user!: LoginUser;
  ngOnInit(): void {
    this.user = this.authService.user;
  }

}
 