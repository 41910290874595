import { Component, EventEmitter, OnInit, Output, ElementRef, ViewChild, Input } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'ic-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {
  @Input() enabledTakeSnapshot = true;
  @Input() camButtonTitle = '';
  @Output() getPicture = new EventEmitter<WebcamImage>();
  @ViewChild('webcamContainer')
  container!: ElementRef;

  showWebcam = true;
  isCameraExist = true;
  myWidth = 100;
  
  @Input() aspect:number = 1.5;

  errors: WebcamInitError[] = [];

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  constructor() { }

  ngOnInit(): void {
  WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.isCameraExist = mediaDevices && mediaDevices.length > 0;
      }).catch((e) => {
        console.error('Could not get Camera device. Please ensure https protocol.');
        console.error(e);
      });
  }

  takeSnapshot(): void {
    this.trigger.next();
  }

  onOffWebCame() {
    this.showWebcam = !this.showWebcam;
  }

  handleInitError(error: WebcamInitError) {
    this.errors.push(error);
  }

  changeWebCame(directionOrDeviceId: boolean | string) {
    this.nextWebcam.next(directionOrDeviceId);
  }

  handleImage(webcamImage: WebcamImage) {
    this.getPicture.emit(webcamImage);
//    this.showWebcam = false;
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {
      width:{min:10, ideal: (this.myWidth), max: this.myWidth},
      aspectRatio:{ideal: this.aspect}
    };
    return result;
  }
    


}
