import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'core-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {

  constructor(
    public env: EnvironmentService
  ) { }

  public printOK = false;
  public consentOK = false;

  @Output() onSignatureOK = new EventEmitter();

  ngOnInit(): void {
  }


  handleSignature(img: any) {
    this.consentOK = true;
    this.onSignatureOK.emit()
  }

  public printSend() {
    this.printOK = true;
    return true;
  }

  public printDone() {
    this.consentOK = true;
    this.onSignatureOK.emit()
    return true;
  } 
}
