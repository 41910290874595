import { CreditAccount } from './../modules/credit/entities/credit.d';
import {environment} from "../../environments/environment";
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  public ticketNumber!: number;
  public status: string = 'LOOKUP';
  public creditAccount!: CreditAccount;
  constructor(
    private http: HttpClient,
  ) {}

  getNewTicket(username: string) {
    let max = 1000;
    this.ticketNumber = Math.floor(Math.random() * max);
//    alert('New Ticket for ' + username + ': ' + this.ticketNumber);
    this.status = 'LOOKUP';
    return this.ticketNumber;
  }

  getTicket() {
    return this.ticketNumber;
  }

  getTicketStatus() {
    return this.status;
  }

  setTicketStatus(status: string) {
    this.status = status;
  }
  
  setTicketAccount(account: CreditAccount) {
    this.creditAccount = account;
  }

}
