import { EnvironmentService } from 'src/app/services/environment.service';
import { Router } from '@angular/router';
import { ButtonConfig } from './../../../core/entities/core.d';
import { Component, OnInit } from '@angular/core';
import { index } from 'd3';

@Component({
  selector: 'credit-va-services',
  templateUrl: './va-services.component.html',
  styleUrls: ['./va-services.component.scss']
})
export class VaServicesComponent implements OnInit {

  serviceCards = [
    {
      title: "Seguros",
      icon: "health_and_safety",
      fields: [
        { type: 'text', label: "Input1", value:"" },
        { type: 'text', label: "Input1", value:"" },
      ],
      buttons: [
        { type: 'maintenance', id: 'idVIEW', name: 'acVIEW', title: '', hint: 'Consulta de Aclaración', hideIfDisabled: true, click: () => {this.router.navigate(['/api/service/VIEW']);return true;}, icon: () => { return 'check_circle';}, disabled: () => { return false;}, favorite: 0 },
      ], 
      class: ''
    }  
  ];

  // serviceCardParms = [
  //   { title: "Seguros        ", icon: "health_and_safety", buttons: 1, fields: 2 , inputs: [{ name: 'in 1' }, { name: 'in 2' }] },
  //   { title: "Hub De Pagos   ", icon: "shopping_cart    ", buttons: 1, fields: 2 , inputs: [{ name: 'in 1' }, { name: 'in 2' }] },
  //   { title: "Cellular       ", icon: "phone_iphone     ", buttons: 1, fields: 2 , inputs: [{ name: 'in 1' }, { name: 'in 2' }] },
  //   { title: "Ventas Cruzadas", icon: "flashlight_on    ", buttons: 1, fields: 2 , inputs: [{ name: 'in 1' }, { name: 'in 2' }] },
  //   { title: "Incremento     ", icon: "trending_up      ", buttons: 1, fields: 2 , inputs: [{ name: 'in 1' }, { name: 'in 2' }] },
  //   { title: "Adicionales    ", icon: "person_add       ", buttons: 1, fields: 2 , inputs: [{ name: 'in 1' }, { name: 'in 2' }] },
  //   { title: "Flexipagos     ", icon: "savings          ", buttons: 1, fields: 4 , inputs: [{ name: 'in 1' }, { name: 'in 2' }] },
  // ];
  // serviceCardParms = [
  //   { title: "Seguros        ", icon: "health_and_safety", buttons: 1, fields: 2 , inputs: [{ value: 'X',          name: 'Protección Integral Familiar', type: 'text'}, { value: 'X',          name: 'Plan'            , type: 'text' }                                                                                                                                                                     ], class: '' },
  //   { title: "Hub De Pagos   ", icon: "shopping_cart    ", buttons: 1, fields: 1 , inputs: [{ value: 'X',          name: 'Plan'                        , type: 'text'}, { value: 'X',          name: ''                , type: 'none' }                                                                                                                                                                     ], class: '' },
  //   { title: "Cellular       ", icon: "phone_iphone     ", buttons: 1, fields: 1 , inputs: [{ value: 'X',          name: 'Estatus'                     , type: 'text'}, { value: 'X',          name: ''                , type: 'none' }                                                                                                                                                                     ], class: '' }, 
  //   { title: "Flexipagos     ", icon: "savings          ", buttons: 1, fields: 5 , inputs: [{ value: '2022/01/18', name: 'Update          '            , type: 'text'}, { value: '2021/01/01', name: 'Acceptance      ', type: 'text' }, { value: '2022/12/01', name: 'Término', type: 'text'          }, { value: '24', name: 'Numero de Pago', type: 'text' }, { value: 'X', name: 'Pago', type: 'text' } ], class: 'rows2' },
  //   { title: "Ventas Cruzadas", icon: "flashlight_on    ", buttons: 1, fields: 2 , inputs: [{ value: 'X',          name: 'Monto'                       , type: 'text'}, { value: 'X',          name: 'Estatus'         , type: 'text' }                                                                                                                                                                     ], class: '' },
  //   { title: "Incremento     ", icon: "trending_up      ", buttons: 1, fields: 2 , inputs: [{ value: 'X',          name: 'Monto'                       , type: 'text'}, { value: 'X',          name: 'Estatus'         , type: 'text' }                                                                                                                                                                     ], class: '' },
  //   { title: "Adicionales    ", icon: "person_add       ", buttons: 1, fields: 2 , inputs: [{ value: 'X',          name: 'Monto'                       , type: 'text'}, { value: 'X',          name: 'Total'           , type: 'text' }                                                                                                                                                                     ], class: '' },
  // ];

  serviceCardParms = [
    { title: "Seguros        ", icon: "health_and_safety", buttons: 1, fields: 2 , inputs: [{ value: '04',          name: 'Producto', type: 'text'}, { value: 'C',          name: 'Status'            , type: 'text' }                                                                                                                                                                     ], class: '' },
    { title: "Hub De Pagos   ", icon: "shopping_cart    ", buttons: 1, fields: 1 , inputs: [{ value: 'No activo',          name: 'Status'                        , type: 'text'},                                                                                                                                                                     ], class: '' },
    { title: "Cellular       ", icon: "phone_iphone     ", buttons: 1, fields: 1 , inputs: [{ value: 'Activo',          name: 'Estatus'                     , type: 'text'},                                                                                                                                                                   ], class: '' }, 
    { title: "Flexipagos     ", icon: "savings          ", buttons: 1, fields: 6 , inputs: [{ value: '0', name: 'Clas Flex          '            , type: 'text'}, { value: '17/07/2020', name: 'Update          '            , type: 'text'}, { value: '05/07/2021', name: 'Acceptance      ', type: 'text' }, { value: '09', name: 'Términos', type: 'text'          }, { value: '1', name: 'PMT', type: 'text' }, { value: '158.12', name: 'PMT AMT', type: 'text' } ], class: 'rows2' },
    { title: "Ventas Cruzadas", icon: "flashlight_on    ", buttons: 1, fields: 1 , inputs: [{ value: 'Si',          name: 'Enrolado?'                       , type: 'text'},                                                                                                                                                                     ], class: '' },
    { title: "Incremento     ", icon: "trending_up      ", buttons: 1, fields: 1 , inputs: [ { value: 'Si',          name: 'Oferta Disponible'         , type: 'text' }                                                                                                                                                                     ], class: '' },
    { title: "Adicionales    ", icon: "person_add       ", buttons: 1, fields: 1 , inputs: [{ value: '2',          name: '# de Addicionales'                       , type: 'text'},                                                                                                                                                                     ], class: '' },
  ];
   
  serviceCardTemplate = 
  {
    title: "Seguros",
    icon: "health_and_safety",
    fields: [
      // { type: 'text', label: "Input1", value:"A" },
      // { type: 'text', label: "Input2", value:"B" },
    ],
    buttons: [
      // { type: 'maintenance', id: 'idVIEW', name: 'acVIEW', title: '', hint: 'Submit', hideIfDisabled: true, click: () => {this.router.navigate(['/api/service/VIEW']);return true;}, icon: () => { return 'check_circle';}, disabled: () => { return false;}, favorite: 'false' },
    ], 
  };

  constructor( 
    private router: Router,
    public env: EnvironmentService
  ) { }

  ngOnInit(): void {
    this.serviceCards = [];

    this.serviceCardParms = this.env.serviceCardParms;
  
    this.serviceCardParms.forEach((tmp, i) => {
      let x = {
        title: tmp.title,
        icon: tmp.icon,
        fields: [
          { type: 'text', label: "Input0", value:"" },
          // { type: 'text', label: "Input2", value:"" },
        ],
        buttons: [
          { type: 'maintenance', id: 'idVIEW', name: 'acVIEW', title: '', hint: 'Submit', hideIfDisabled: true, click: () => {this.router.navigate(['/api/service/VIEW']);return true;}, icon: () => { return 'check_circle';}, disabled: () => { return false;}, favorite: 0 },
        ], 
        class: '',
      };
      x.fields = [];
      tmp.inputs.forEach(fld => {
        x.fields.push({ type: fld.type, label: fld.name, value: fld.value });
      });
      x.buttons = [];
      x.buttons.push({ type: 'maintenance', id: '', name: '', title: '', hint: 'Update', hideIfDisabled: true, click: () => {this.router.navigate(['/api/service/TEST']);return true;}, icon: () => { return 'check_circle';}, disabled: () => { return false;}, favorite: 0 });
      x.class = tmp.class;
      this.serviceCards.push(x);
    });
  }     

}
