import { EnvironmentService } from 'src/app/services/environment.service';
import { VscapiService } from './../../modules/core/services/vscapi.service';
import { FlowQueueService } from './../../modules/workflow/services/flow-queue.service';
import { ContextService } from './../../modules/credit/services/context.service';
import { VplService } from './../../modules/credit/services/vpl.service';
import { CreditAccount, CreditLocate } from './../../modules/credit/entities/credit.d';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { WorkflowService } from 'src/app/services/workflow.service';

import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ViewAccountComponent } from 'src/app/modules/credit/components/view-account/view-account.component';

@Component({
  selector: 'app-customer-page',
  templateUrl: './customer-page.component.html',
  styleUrls: ['./customer-page.component.scss']
})
export class CustomerPageComponent implements OnInit {
  constructor(
    public route : ActivatedRoute,
    public authService: AuthService,
    public wfService: WorkflowService, /** @todo get rid of this and merge needed functions with FlowQueueService */
    public flow: FlowQueueService,
    public vplService: VplService,
    public vscapiService: VscapiService,
    public context: ContextService,
    public env: EnvironmentService,
    @Inject(DOCUMENT) private document: Document
    ) { }

  // @ViewChild('CVA') viewAccountComponent!: ViewAccountComponent;
  public account!: string;
  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      if (data.test && data.test == 'abc') {
        // this.vplService.setAccount('0004178490999963631');
        this.wfService.setTicketStatus('VIEW');
      }
    });

    this.route.queryParams.subscribe(params => {
      if (params.action) {
        switch(params.action) {
          case "loadTask": this.loadTask(params.queueId, params.taskId); break;
        }
      }
    });
  }

  public loadTask(queueId: string, taskId: string) {
    this.flow.actQueueObject(queueId, taskId, 'get').subscribe(fqo => {
      if (fqo?.state) {
        let state = fqo.state;

        this.context.selectedState = fqo.state;

        this.loadClient(state.stateData.account, state.stateData.card);
      }
    }); 
  }

  public loadClient(account: string, card: string = '', queueId: string = '', taskId: string = '') {
    let locator: CreditLocate = {
      request: {
        accountNumber   :account,
        cardNumber      :card,
      },
      response: {}
    };

    let selectedAccount = account;
    let selectedCard = card;

    this.vplService.locateClient(locator.request).subscribe( result => {

      if (!result.clients || result.clients.length == 0) {
        throw new Error('No Client Found'); /** @todo add error handling */
      }
      this.vplService.setClient(result.clients[0].id); 
      this.vplService.client?.customers.forEach(customer => {
        customer.accounts?.forEach(account => {
          if (account.id == selectedAccount) {
            this.vplService.setCustomer(customer.id);
            this.vplService.setAccount(selectedAccount);  
            this.vplService.setThemeByOrg(this.document, this.vplService.account?.org);
            this.vplService.setCard(selectedCard);  
            this.wfService.setTicketStatus('VIEW');
            this.vplService.accountTabSelectedIndex = this.vplService.ACTION_TAB_INDEX;
          }
        });
      }); 
    });    
  }

  getNewTicket() {
    this.wfService.getNewTicket(this.authService.user.name);
  }

  getUser() {
    return this.authService.user;
  }

  openAccountView(event: any) {

    if (this.env.newAPI) { // newAPI: event.account is a string. Originally was a CreditAccount object
      this.account = event.account;
      this.vscapiService.selectedAccount = this.account;
      this.vscapiService.getAccount(event.account).subscribe((response) => {
        // if (response.body.accounts && Array.isArray(response.body.accounts)) {
          // let accountRecord = response.body.accounts[0];
          let accountRecord = response;

          // this.wfService.setTicketAccount(accountRecord);
          this.wfService.setTicketStatus('VIEW');
      
          // this.vplService.setAccount(account);  
          // this.vplService.account = accountRecord;
          this.vplService.setThemeByOrg(this.document, accountRecord.org);
          // if (this.viewAccountComponent) {
          //   this.viewAccountComponent.account = accountRecord;
          // }
        // }
      })
    } else {
      this.wfService.setTicketAccount(event.account);
      this.wfService.setTicketStatus('VIEW');      
    }
  }

  newSearchFunction = () => {
    this.vplService.setThemeByOrg(this.document); // Clear theme for no ORG
    this.vplService.accountTabSelectedIndex = this.vplService.INITIAL_TAB_INDEX; // Reset tab to initial
    this.wfService.getNewTicket(this.authService.user.name);
    this.context.clear();
  }
}

