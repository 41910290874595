<div class="container">
    <div class="box box1">
        <div style="grid-column: span 4;"><h1>Datos Generales</h1><h3> {{ callDate | date }} - {{ callDate | date : 'shortTime' }}</h3></div>
        <core-data-field-view label="User Data            " type="text" [value]="account.userData           "></core-data-field-view>
        <core-data-field-view label="Relationship Number  " type="text" [value]="account.relationshipNumber "></core-data-field-view>
        <core-data-field-view label="Employee Code        " type="text" [value]="account.employeeCode       "></core-data-field-view>
        <core-data-field-view label="Status               " type="text" [value]="account.status             "></core-data-field-view>
        <core-data-field-view label="Block Code 1         " type="text" [value]="account.blockCode1         "></core-data-field-view>
        <core-data-field-view label="Reason 1             " type="text" [value]="account.blockReason1       "></core-data-field-view>
        <core-data-field-view label="Block Code 2         " type="text" [value]="account.blockCode2         "></core-data-field-view>
        <core-data-field-view label="Reason 2             " type="text" [value]="account.blockReason2       "></core-data-field-view>
        <core-data-field-view label="V.I.P                " type="text" [value]="account.vip                "></core-data-field-view>
        <core-data-field-view label="Flex Bill            " type="text" [value]="account.flexBill           "></core-data-field-view>
        <core-data-field-view label="Card Scheme          " type="text" [value]="account.cardScheme         "></core-data-field-view>
        <core-data-field-view label="Display Request      " type="text" [value]="account.displayRequest     "></core-data-field-view>
        <core-data-field-view label="Bill Level           " type="text" [value]="account.billLevel          "></core-data-field-view>
        <core-data-field-view label="Bill Cycle           " type="text" [value]="account.billCycle          "></core-data-field-view>
        <core-data-field-view label="Statement Frequency  " type="text" [value]="account.statementFrequency "></core-data-field-view>
        <core-data-field-view label="Statement Request    " type="text" [value]="account.statementRequest   "></core-data-field-view>
        <core-data-field-view label="Statement Flag       " type="text" [value]="account.statementFlag      "></core-data-field-view>
        <core-data-field-view label="Return Mail          " type="text" [value]="account.returnMail         "></core-data-field-view>
        <core-data-field-view label="Owner                " type="text" [value]="account.owner              "></core-data-field-view>
        <core-data-field-view label="Charge Off Status    " type="text" [value]="account.chargeOffStatus    "></core-data-field-view>
        <core-data-field-view label="Letter Request       " type="text" [value]="account.letterRequest      "></core-data-field-view>
        <core-data-field-view label="Memo Bill Currency   " type="text" [value]="account.memoBillCurrency   "></core-data-field-view>
        <core-data-field-view label="Customer Statement   " type="text" [value]="account.customerStatement  "></core-data-field-view>
        <core-data-field-view label="Letter               " type="text" [value]="account.letter             "></core-data-field-view>
        <core-data-field-view label="Collect Card Request " type="text" [value]="account.collectCardRequest "></core-data-field-view>
        <core-data-field-view label="Reissue Scheme       " type="text" [value]="account.reissueScheme      "></core-data-field-view>
        <core-data-field-view label="Perm Collector       " type="text" [value]="account.permCollector      "></core-data-field-view>
        <core-data-field-view label="Restructure Flag     " type="text" [value]="account.restructureFlag    "></core-data-field-view>
        <core-data-field-view label="Collateral Code      " type="text" [value]="account.collateralCode     "></core-data-field-view>
    </div>

    <div class="box box2">
        <div style="grid-column: span 4;"><h3>Fechas</h3></div>
        <core-data-field-view label="Block 1               " type="text" [value]="account.dateBlock1               | date"></core-data-field-view>
        <core-data-field-view label="Block 2               " type="text" [value]="account.dateBlock2               | date"></core-data-field-view>
        <core-data-field-view label="Last Maintenance      " type="text" [value]="account.dateLastMaint            | date"></core-data-field-view>
        <core-data-field-view label="Last Cycle            " type="text" [value]="account.dateLastCycle            | date"></core-data-field-view>
        <core-data-field-view label="Next Statement        " type="text" [value]="account.dateNextStatement        | date"></core-data-field-view>
        <core-data-field-view label="Notification Received " type="text" [value]="account.dateNotificationReceived | date"></core-data-field-view>
        <core-data-field-view label="Days                  " type="text" [value]="account.days                           "></core-data-field-view>
        <core-data-field-view label="Days Card Expiry      " type="text" [value]="account.daysCardExpiry           | date: 'YY/MM' "></core-data-field-view>
        <core-data-field-view label="Date Expiry           " type="text" [value]="account.dateExpiry               | date"></core-data-field-view>
        <core-data-field-view label="Date CardFee          " type="text" [value]="account.dateCardFee              | date"></core-data-field-view>
        <core-data-field-view label="Date Applied          " type="text" [value]="account.dateApplied              | date"></core-data-field-view>
        <core-data-field-view label="Date Opened           " type="text" [value]="account.dateOpened               | date"></core-data-field-view>
        <core-data-field-view label="Date Closed           " type="text" [value]="account.dateClosed               | date"></core-data-field-view>
        <core-data-field-view label="Income TH             " type="text" [value]="account.incomeTH                 | currency "></core-data-field-view>
        <core-data-field-view label="High Balance          " type="text" [value]="account.highBalance              | currency "></core-data-field-view>
        <core-data-field-view label="Credit Limit          " type="text" [value]="account.creditLimit              | currency "></core-data-field-view>
    </div>   
</div>
