<div class="container">
    <div class="box box1">
        <div style="grid-column: span 4;"><h1>Datos Demograficos</h1><h3> {{ callDate | date }} - {{ callDate | date : 'shortTime' }}</h3></div>
        <core-data-field-view label="Nombre             " type="text" [value]="customer.firstName            "></core-data-field-view>
        <core-data-field-view label="Nombre Paterno     " type="text" [value]="customer.lastName             "></core-data-field-view>
        <core-data-field-view label="Nombre Materno     " type="text" [value]="customer.lastNameMaternal     "></core-data-field-view>
        <core-data-field-view label="Fecha de Nacimiento" type="text" [value]="customer.dateOfBirth | date   "></core-data-field-view>
        <div class="box box2" style="grid-column: span 2;">
            <div style="grid-column: span 2;"><h3>Datos De Contacto</h3></div>
            <core-data-field-view label="Calle              " type="text" [value]="customer.contactStreet        "></core-data-field-view>
            <core-data-field-view label="Colonia            " type="text" [value]="customer.contactSuburb        "></core-data-field-view>
            <core-data-field-view label="Delg/Munc          " type="text" [value]="customer.contactCity          "></core-data-field-view>
            <core-data-field-view label="Estado             " type="text" [value]="customer.contactState         "></core-data-field-view>
            <core-data-field-view label="CP                 " type="text" [value]="customer.contactCountry       "></core-data-field-view>
            <core-data-field-view label="Correo Electronico " type="text" [value]="customer.contactEmail         "></core-data-field-view>
            <core-data-field-view label="Telefono Casa      " type="text" [value]="customer.contactTelephoneHome "></core-data-field-view>
            <core-data-field-view label="Telefono Cellular  " type="text" [value]="customer.contactTelephoneCell "></core-data-field-view>
        </div>
        <div class="box box2" style="grid-column: span 2;">
            <div style="grid-column: span 2;"><h3>Datos De Empleo</h3></div>
            <core-data-field-view label="Calle              " type="text" [value]="customer.workStreet           "></core-data-field-view>
            <core-data-field-view label="Colonia            " type="text" [value]="customer.workSuburb           "></core-data-field-view>
            <core-data-field-view label="Delg/Munc          " type="text" [value]="customer.workCity             "></core-data-field-view>
            <core-data-field-view label="Estado             " type="text" [value]="customer.workState            "></core-data-field-view>
            <core-data-field-view label="CP                 " type="text" [value]="customer.workCountry          "></core-data-field-view>
            <core-data-field-view label="Telefono Trabajo   " type="text" [value]="customer.workTelephone        "></core-data-field-view>
            </div>
    </div>

    <div class="box box2">
        <div style="grid-column: span 4;"><h2>Referencias</h2></div>        
        <ng-container *ngFor="let ref of customer.references">
            <div style="grid-column: span 4;"><h4>Referencia</h4></div>        
            <core-data-field-view label="Nombre             " type="text" [value]="ref.refName         "></core-data-field-view>
            <core-data-field-view label="Referencia         " type="text" [value]="ref.refRelationship "></core-data-field-view>
            <core-data-field-view label="Telefono           " type="text" [value]="ref.refTelephone    "></core-data-field-view>
        </ng-container>
    </div>   
</div>