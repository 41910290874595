  <mat-stepper orientation="vertical" [linear]="isLinear" #stepper>
    <mat-step [editable]="isEditable">
        <ng-template matStepLabel>Retrieve Application</ng-template>
        <ng-template matStepContent>
            <ic-flow02 (complete)="icApp02Complete($event, stepper)"></ic-flow02>
            <div>
            </div>
        </ng-template>
    </mat-step>
    <mat-step [editable]="isEditable">
        <form [formGroup]="fgIdentification">
            <ng-template matStepLabel>Datos</ng-template>
            <ng-template matStepContent>
                <div class="container">
                    <div class="box box1">
                        <div style="grid-column: span 3;"><h3>Datos Personales</h3></div>
                        <core-data-field-view type="text" [readonly]=true label="FirstName    " [value]="context.customer?.firstName    ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=true label="LastName     " [value]="context.customer?.lastName     ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=true label="LastName2    " [value]="context.customer?.lastName2    ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=true label="DateOfBirth  " [value]="context.customer?.dateOfBirth  ?? 0 | date "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=true label="Gender       " [value]="context.customer?.gender       ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=true label="RFC          " [value]="context.customer?.rfc          ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=true label="Nationality  " [value]="context.customer?.nationality  ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=true label="BirthCountry " [value]="context.customer?.birthCountry ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=true label="Curp         " [value]="context.customer?.curp         ?? '' "></core-data-field-view>
                    </div>

                    <div class="box box2">
                        <div style="grid-column: span 3;"><h3>Informacion Domicilaria</h3></div>
                        <core-data-field-view type="text" [readonly]=false label="Calle                             " [value]="context.customer?.home?.streetName      ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Numero                            " [value]="context.customer?.home?.streetNumber    ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Codigo Postal                     " [value]="context.customer?.home?.zip             ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Colonia                           " [value]="context.customer?.home?.suburb          ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Estado                            " [value]="context.customer?.home?.state           ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Municipio / Alcaldia              " [value]="context.customer?.home?.city            ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Entre Calles                      " [value]="context.customer?.home?.nearestCorner   ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Duracion en este domicilio (Años) " [value]="context.customer?.home?.duration        ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Tipo de casa                      " [value]="context.customer?.home?.homeType        ?? '' "></core-data-field-view>    
                    </div>   

                    <div class="box box2">
                        <div style="grid-column: span 3;"><h3>Informacion del Empleo</h3></div>
                        <core-data-field-view type="text" [readonly]=false label="Cargo o Departamento              " [value]="context.customer?.employer?.department      ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Linea de Negocio                  " [value]="context.customer?.employer?.industry        ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Nombre de Impresa                 " [value]="context.customer?.employer?.name            ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Telefono de Impresa               " [value]="context.customer?.employer?.phone           ?? '' "></core-data-field-view>
                        
                        <core-data-field-view type="text" [readonly]=false label="Calle                             " [value]="context.customer?.employer?.address?.streetName      ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Numero                            " [value]="context.customer?.employer?.address?.streetNumber    ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Codigo Postal                     " [value]="context.customer?.employer?.address?.zip             ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Colonia                           " [value]="context.customer?.employer?.address?.suburb          ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Estado                            " [value]="context.customer?.employer?.address?.state           ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Municipio / Alcaldia              " [value]="context.customer?.employer?.address?.city            ?? '' "></core-data-field-view>
                        <core-data-field-view type="text" [readonly]=false label="Entre Calles                      " [value]="context.customer?.employer?.address?.nearestCorner   ?? '' "></core-data-field-view>
                    </div>   

                    <div class="box box2">
                        <div style="grid-column: span 3;"><h3>Referencias Personales</h3></div>
                        <div *ngFor="let ref of context.customer?.references">
                            <core-data-field-view type="text" [readonly]=false label="Nombre                             " [value]="ref.name         "></core-data-field-view>
                            <core-data-field-view type="text" [readonly]=false label="Relation                           " [value]="ref.relationship "></core-data-field-view>
                            <core-data-field-view type="text" [readonly]=false label="Numero de Celular                  " [value]="ref.cellPhone    "></core-data-field-view>
                            <core-data-field-view type="text" [readonly]=false label="Numero de Casa                     " [value]="ref.homePhone    "></core-data-field-view>
                        </div>
                    </div>   
                </div>
                <div>
                    <button mat-button matStepperNext>Continuar</button>
                </div>
            </ng-template>
        </form>
    </mat-step>
    <mat-step [editable]="isEditable">
        <form [formGroup]="fgPersonal">
            <ng-template matStepLabel>Solicidud</ng-template>
            <cb-flow01 (complete)="cbApp01Complete($event, stepper)"></cb-flow01>
            <div>
                <!-- <button mat-button matStepperPrevious>Back</button> -->
                <button *ngIf="this.cbDone" mat-button matStepperNext>Continuar</button>
            </div>
        </form>
    </mat-step>
    <mat-step [editable]="isEditable">
        <ng-template matStepLabel>Done</ng-template>
        <!-- <p>Muchas Gracias 🙏.</p> -->
    </mat-step>
</mat-stepper>
  