<div class="container">
    <div class="box box4">
        <h1>{{action.title}} - {{action!.hint}} {{processStep}}</h1>
        <!-- <credit-list-cards style="grid-column: span 4;" *ngIf="processStep == 'init'" [cardIsDisabled]="cardIsDisabled" [displayedColumns]="columns" [cardSelected]="cardSelected"></credit-list-cards> -->
        <div class="box box2" *ngIf="processStep == 'init'">
            <core-qr-scan (qrFound)="popFromQR($event)"></core-qr-scan>
                <form [formGroup]="locateForm">
                    <div class="box box3">
                    <mat-form-field appearance="fill" class="note">
                        <mat-label>RFC</mat-label>
                        <input matInput [formControlName]="'rfc'" autocomplete="off" class="autoclick">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="note">
                        <mat-label>Application #</mat-label>
                        <input matInput [formControlName]="'appNum'" autocomplete="off" class="autoclick">
                    </mat-form-field>
                    <core-smart-button [config]="locateButtonConfig"></core-smart-button>    
                </div>
            </form>
        </div>
    </div>

    <ng-container *ngIf="processStep == 'entry'" style="grid-column: span 4;" >


        <app-apply2></app-apply2>


        <!-- <form [formGroup]="actionForm">
            <div class="box box2">
                <ng-container *ngFor="let field of fieldConfigs | keyvalue" class="ff">
                    <div *ngIf="field.value.type=='submit'" style="grid-column: span 4;">
                        <button mat-raised-button type="button" (click)="locateAction()" class="btn btn-primary" [disabled]="!validInput()">
                            <mat-icon *ngIf="!validInput()">edit</mat-icon><mat-icon *ngIf="validInput()">search</mat-icon>Search
                        </button>
                    </div>
                    
                    <div *ngIf="field.value.type=='divider'" style="grid-column: span 4;"><hr></div>
    
                    <core-smart-select *ngIf="field.value.type=='smartselect'" class="smart-field" [options]="fieldOptions.get(field.key)" [config]="field.value" (selectChange)="change($event)"></core-smart-select>
    
                    <core-data-field-view *ngIf="field.value.type=='display'" label="{{field.value.title}}" type="text" [value]="field.value.name"></core-data-field-view>

                    <mat-form-field *ngIf="field.value.type=='date'" class="form-control" appearance="fill"><mat-label>{{field.value.title}}</mat-label><input matInput [matDatepicker]="picker"><mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle><mat-datepicker #picker></mat-datepicker></mat-form-field>
                    <mat-form-field *ngIf="field.value.type=='input'" appearance="fill">
                        <mat-label>{{ field.value.title }}</mat-label>
                        <input matInput 
                        [id]="field.value.name" 
                        [type]="field.value.format" 
                        class="form-control" 
                        [formControlName]="field.value.name" 
                        [placeholder]="field.value.placeholder"
                        autocomplete="off"
                        [readonly]="field.value.readonly"
                        >
                    </mat-form-field>
                </ng-container>
            </div>
        </form> -->

    </ng-container>

    <div class="box box1" style="grid-column: span 4;">
        <!-- <core-smart-button *ngIf="processStep == 'entry'" [config]="saveButtonConfig"></core-smart-button>     -->
        <core-smart-button *ngIf="processStep == 'success'" [config]="newButtonConfig"></core-smart-button>    
        <core-smart-button [config]="closeButtonConfig" color="warn"></core-smart-button>    
    </div>
</div>

