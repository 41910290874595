<!-- <div *ngIf="this.vplService.account"> -->
    <table mat-table [dataSource]="getPlans()" class="mat-elevation-z8">
        <ng-container matColumnDef="select"                 ><th mat-header-cell *matHeaderCellDef>Select Plan     </th><td mat-cell class="center"   *matCellDef="let element"><button mat-raised-button (click)="selectPlan(element.id)" disabled>{{ element.id }}</button></td></ng-container>
        <ng-container matColumnDef="segment"                ><th mat-header-cell *matHeaderCellDef>Segment         </th><td mat-cell class="odd"      *matCellDef="let element">{{ element.segment                                    }}</td></ng-container>
        <ng-container matColumnDef="plan"                   ><th mat-header-cell *matHeaderCellDef>Plan            </th><td mat-cell                  *matCellDef="let element">{{ element.plan                                       }}</td></ng-container>
        <ng-container matColumnDef="balance"                ><th mat-header-cell *matHeaderCellDef>Balance         </th><td mat-cell class="amt"      *matCellDef="let element">{{ element.balance                 | number : '1.2-2' }}</td></ng-container>
        <ng-container matColumnDef="openDate"               ><th mat-header-cell *matHeaderCellDef>Date Opened     </th><td mat-cell                  *matCellDef="let element">{{ element.openDate                | date             }}</td></ng-container>
        <ng-container matColumnDef="active"                 ><th mat-header-cell *matHeaderCellDef>Active          </th><td mat-cell class="odd center" *matCellDef="let element"><mat-icon *ngIf="element.active==true" color="primary">check_circle</mat-icon></td></ng-container>
        <ng-container matColumnDef="requiredPayment"        ><th mat-header-cell *matHeaderCellDef>Payment         </th><td mat-cell class="amt"      *matCellDef="let element">{{ element.requiredPayment         | number : '1.2-2' }}</td></ng-container>
        <ng-container matColumnDef="minimumRequiredPayment" ><th mat-header-cell *matHeaderCellDef>Minimum Payment </th><td mat-cell class="odd amt"  *matCellDef="let element">{{ element.minimumRequiredPayment  | number : '1.2-2' }}</td></ng-container>
        <ng-container matColumnDef="sec"                    ><th mat-header-cell *matHeaderCellDef>SEC             </th><td mat-cell                  *matCellDef="let element">{{ element.sec                                        }}</td></ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
<!-- </div> -->
