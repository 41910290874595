import { SnackBarService } from './../../../../core/services/snack-bar.service';
import { CreditCard } from './../../../entities/credit.d';
import { VplService } from './../../../services/vpl.service';
import { AuthService } from './../../../../../services/auth.service';
import { ContextService } from './../../../services/context.service';
import { Component, Input, OnInit } from '@angular/core';

import { ButtonConfig, SelectConfig } from 'src/app/modules/core/entities/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'credit-ac-monetary001',
  templateUrl: './ac-monetary001.component.html',
  styleUrls: ['./ac-monetary001.component.scss']
})
export class AcMonetary001Component implements OnInit {

  @Input() actionCode: string ='M409';
  @Input() cust: string ='';

  public action!: any;

  public processStep = 'init';
  public card!: CreditCard;

  public saveButtonConfig!: ButtonConfig;
  public newButtonConfig!: ButtonConfig;
  public closeButtonConfig!: ButtonConfig;

  public columns = [ 'select', 'cardNumber', 'expiryDate', /*'reg', 'blockCode', 'blockCodeDesc',*/ 'st', 'stDesc', /*'gp', 'gpDesc', 'tg', 'tgDesc',*/ 'embossedName' ];
  public currCard!: CreditCard;
  public cards!: CreditCard[];

  public cardSelected(_card: CreditCard): void {};
  public cardIsDisabled = (card: CreditCard) => { 
    if ( card.st != 'A' ) {
      return true;
    }
    return false;
  };

  callDate!: Date;

  actionForm!: FormGroup;
  fieldConfigs = new Map<string, SelectConfig>();
  fieldOptions = new Map();

  constructor(
    public context: ContextService,
    public auth: AuthService,
    public creditSvc: VplService,
    public snack: SnackBarService
    ) { }

  ngOnInit(): void {

    this.action = this.creditSvc.getActionCode('id'+this.actionCode);

    this.setupForm();

    this.callDate = this.creditSvc.callTimestamp;;

    let fnClickSave = (): boolean => {
      this.context.actionVars.view.priorViews.push( { date: new Date(), user: this.auth.user.name, action: this.actionCode, note: this.context.actionVars.M409.noteValue } );
      this.context.actionVars.M409.noteValue = '';
      this.processStep = 'success';
      return true;
    }

    let fnDisabledSave = () => { return !this.validInput();}

    this.saveButtonConfig = {
      id: '',
      name: '',
      hint: 'Submit',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickSave,
      icon: function (): string {
        return 'check_circle';
      },
      disabled: fnDisabledSave
    }

    this.cardSelected = (card: CreditCard) => 
    {
      this.processStep = 'entry';
      this.currCard = card;
      this.actionForm.controls.cardNumber.setValue(card.cardNumber);
      this.actionForm.controls.cardSeq.setValue('001');
    }

    this.cardIsDisabled = (card: CreditCard) => { 
      if ( card.st != 'N' ) {
        return true;
      }
      return false;
    };

    let fnClickClose = (): boolean => {
      this.context.removeAction(this.actionCode, 'monetary');
      return false;
    }

    this.closeButtonConfig = {
      id: '',
      name: '',
      hint: 'Close',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickClose,
      icon: function (): string {
        return 'cancel';
      },
      disabled: function (): boolean {
        return false;
      }
    }

    let fnClickNew = (): boolean => {
      this.processStep = 'init';
      return false;
    }

    this.newButtonConfig = {
      id: '',
      name: '',
      hint: 'New ' + this.actionCode,
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickNew,
      icon: function (): string {
        return 'verified_user';
      },
      disabled: function (): boolean {
        return false;
      }
    }
  }

  private setupForm() {
    this.actionForm = new FormGroup({
      cardNumber       : new FormControl(''),
      cardSeq          : new FormControl(''),
      store            : new FormControl('', [ Validators.required ]),
      effectiveDate    : new FormControl('', [ Validators.required ]),
      amount           : new FormControl('', [ Validators.required ]),
      department       : new FormControl(''),
      plan             : new FormControl(''),
      planSeq          : new FormControl(''),
      ticketNumber     : new FormControl(''),
      authNumber       : new FormControl(''),
      sku              : new FormControl(''),
      clerk            : new FormControl(''),
      refNumber        : new FormControl(''),
      purchaseOrder    : new FormControl(''),
      resultCode       : new FormControl('', [ Validators.required ]),
    });

    this.fieldConfigs.set("01cardNumber"    , { type: "input", id: "cardNumber"   , name: "cardNumber"   , title: "cardNumber"   , search: true , result: false, format: "text" , readonly: true , required: true , placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("02cardSeq"       , { type: "input", id: "cardSeq"      , name: "cardSeq"      , title: "cardSeq"      , search: true , result: false, format: "text" , readonly: false, required: true , placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("03store"         , { type: "input", id: "store"        , name: "store"        , title: "store"        , search: true , result: false, format: "text" , readonly: false, required: true , placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("04effectiveDate" , { type: "date" , id: "effectiveDate", name: "effectiveDate", title: "effectiveDate", search: true , result: false, format: "text" , readonly: false, required: true , placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("05amount"        , { type: "input", id: "amount"       , name: "amount"       , title: "amount"       , search: false, result: true , format: "text" , readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("06department"    , { type: "input", id: "department"   , name: "department"   , title: "department"   , search: false, result: true , format: "array", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: true });
    this.fieldConfigs.set("07plan"          , { type: "input", id: "plan"         , name: "plan"         , title: "plan"         , search: false, result: true , format: "array", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: true });
    this.fieldConfigs.set("08planSeq"       , { type: "input", id: "planSeq"      , name: "planSeq"      , title: "planSeq"      , search: false, result: true , format: "array", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: true });
    this.fieldConfigs.set("09ticketNumber"  , { type: "input", id: "ticketNumber" , name: "ticketNumber" , title: "ticketNumber" , search: false, result: true , format: "array", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: true });
    this.fieldConfigs.set("10authNumber"    , { type: "input", id: "authNumber"   , name: "authNumber"   , title: "authNumber"   , search: false, result: false, format: "array", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("11sku"           , { type: "input", id: "sku"          , name: "sku"          , title: "sku"          , search: false, result: true , format: "text" , readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("12clerk"         , { type: "input", id: "clerk"        , name: "clerk"        , title: "clerk"        , search: false, result: true , format: "text" , readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("13refNumber"     , { type: "input", id: "refNumber"    , name: "refNumber"    , title: "refNumber"    , search: false, result: true , format: "text" , readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("14purchaseOrder" , { type: "input", id: "purchaseOrder", name: "purchaseOrder", title: "purchaseOrder", search: false, result: true , format: "text" , readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
    this.fieldConfigs.set("15resultCode"    , { type: "input", id: "resultCode"   , name: "resultCode"   , title: "resultCode"   , search: false, result: true , format: "text" , readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});

  }

  validInput() {
    return !(
             this.actionForm.controls.store.errors?.required ||
            //  this.actionForm.controls.effectiveDate.errors?.required ||
             this.actionForm.controls.amount.errors?.required ||
             this.actionForm.controls.resultCode.errors?.required 
     );
  }

  locateAction() { 
    this.snack.openSnackBar(`Action ${this.action.title} submitted`, 'OK');  
  }

  change($event: MatSelectChange) {
    this.selectionChange($event.source.id, $event.value);
  }

  selectionChange( fieldName: string, value: any ) {
    switch(fieldName) {
      case "cards": 
        this.creditSvc.setCard(value);
        break;
    }
    // this.fieldOptions.set("09cards"    , this.creditSvc.cardsList);

  }

}
