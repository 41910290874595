<section>
    <div class="example-button-row">
        <div class="example-flex-container">
            <div class="example-button-container">
                <button mat-fab [color]="recButtonColor()" (click)="recToggle()" #recButton>
                    <mat-icon>{{recButtonIcon()}}</mat-icon>
                </button>
                <button *ngIf="showDownloadButton" mat-fab [color]="recButtonColor()" (click)="download($event)" #dlButton>
                    <mat-icon>{{dlButtonIcon()}}</mat-icon>
                </button>
            </div>
        </div>
    </div>
</section>
