<div class="barChart01">
  <div>
    <h3>Year To Date</h3>
    <canvas baseChart
        [data]="pieChartData2"
        [type]="pieChartType"
        [options]="pieChartOptions"
    >
    </canvas>
  </div>

  <div>
    <h3>Month To Date</h3>
      <canvas baseChart
          [data]="pieChartData"
          [type]="pieChartType"
          [options]="pieChartOptions"
      >
      </canvas>
  </div>

</div>


<mat-table [dataSource]="dataSource" matSort>

  <!-- Columns -->
  
  <ng-container matColumnDef="group">       <mat-header-cell class="odd" *matHeaderCellDef mat-sort-header> Group     <p><input type="text" class="search" [(ngModel)]="filter.group"   (keyup)="updateView()"></p></mat-header-cell><mat-cell class="odd" *matCellDef="let row"> {{ row.group }}     </mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container>
  <ng-container matColumnDef="company">     <mat-header-cell             *matHeaderCellDef mat-sort-header> Company   <p><input type="text" class="search" [(ngModel)]="filter.company" (keyup)="updateView()"></p></mat-header-cell><mat-cell             *matCellDef="let row"> {{ row.company }}   </mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container>
  <ng-container matColumnDef="zone">        <mat-header-cell class="odd" *matHeaderCellDef mat-sort-header> Zone      <p><input type="text" class="search" [(ngModel)]="filter.zone"    (keyup)="updateView()"></p></mat-header-cell><mat-cell class="odd" *matCellDef="let row"> {{ row.zone }}      </mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container>
  <ng-container matColumnDef="region">      <mat-header-cell             *matHeaderCellDef mat-sort-header> Region    <p><input type="text" class="search" [(ngModel)]="filter.region"  (keyup)="updateView()"></p></mat-header-cell><mat-cell             *matCellDef="let row"> {{ row.region }}    </mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container>
  <ng-container matColumnDef="store">       <mat-header-cell class="odd" *matHeaderCellDef mat-sort-header> {{env.dict.store}}<p><input type="text" class="search" [(ngModel)]="filter.store"   (keyup)="updateView()"></p></mat-header-cell><mat-cell class="odd" *matCellDef="let row"> {{ row.store }}     </mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container>
  <ng-container matColumnDef="action">      <mat-header-cell             *matHeaderCellDef mat-sort-header> Action    <p><input type="text" class="search" [(ngModel)]="filter.action"  (keyup)="updateView()"></p></mat-header-cell><mat-cell             *matCellDef="let row"> {{ row.action }}    </mat-cell><mat-footer-cell *matFooterCellDef></mat-footer-cell></ng-container>
  <ng-container matColumnDef="ltd">         <mat-header-cell class="odd" *matHeaderCellDef mat-sort-header> LTD       </mat-header-cell>                                                                        <mat-cell class="odd" *matCellDef="let row"> {{ row.ltd }}       </mat-cell><mat-footer-cell *matFooterCellDef>{{ totals.ltd }}</mat-footer-cell></ng-container>
  <ng-container matColumnDef="ytd">         <mat-header-cell             *matHeaderCellDef mat-sort-header> YTD       </mat-header-cell>                                                                        <mat-cell             *matCellDef="let row"> {{ row.ytd }}       </mat-cell><mat-footer-cell *matFooterCellDef>{{ totals.ytd }}</mat-footer-cell></ng-container>
  <ng-container matColumnDef="mtd">         <mat-header-cell class="odd" *matHeaderCellDef mat-sort-header> MTD       </mat-header-cell>                                                                        <mat-cell class="odd" *matCellDef="let row"> {{ row.mtd }}       </mat-cell><mat-footer-cell *matFooterCellDef>{{ totals.mtd }}</mat-footer-cell></ng-container>
  <ng-container matColumnDef="remainMonth"> <mat-header-cell             *matHeaderCellDef mat-sort-header> Remaining </mat-header-cell>                                                                        <mat-cell             *matCellDef="let row"> {{ row.goalMonth - row.mtd }} </mat-cell><mat-footer-cell *matFooterCellDef>{{ totals.goalMonth }}</mat-footer-cell></ng-container>
  <ng-container matColumnDef="goalMonth">   <mat-header-cell class="odd" *matHeaderCellDef mat-sort-header> GoalMonth </mat-header-cell>                                                                        <mat-cell class="hi"  *matCellDef="let row"> {{ row.goalMonth }} </mat-cell><mat-footer-cell *matFooterCellDef>{{ totals.goalMonth }}</mat-footer-cell></ng-container>

  <!-- Rows -->
  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  <mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></mat-footer-row>

</mat-table>
