import { EnvironmentService } from 'src/app/services/environment.service';
import { VscapiService } from './../../../core/services/vscapi.service';
import { VplService } from './../../services/vpl.service';
import { ContextService } from './../../services/context.service';
import { Component, Input, OnInit } from '@angular/core';
import { MatListOption } from '@angular/material/list';
import { CreditAccount } from '../../entities/credit.d';

@Component({
  selector: 'credit-va-acct-status',
  templateUrl: './va-acct-status.component.html',
  styleUrls: ['./va-acct-status.component.scss']
})
export class VaAcctStatusComponent implements OnInit {

  public promoCount: number = 0;

  public menuOptions: { id: string, title: string, icon: string, badge: string, default: boolean}[] = [];
 
  // public selectedOption!:string; 

  private _account!: CreditAccount;
  @Input()
  public get account(): CreditAccount {
    if (this.env.newAPI) {
      return (this._account) ? this._account: {
        id: '',
        org: '000'
      };
    } else {
      return (this.credit.account) ? this.credit.account: {
        id: '',
        org: '000'
      };
    }
  }
  public set account(value: CreditAccount) {
    this._account = value;
  }

  constructor(
    public context: ContextService,
    public credit: VplService,
    public vscapiService: VscapiService,
    public env: EnvironmentService,
  ) { }

  ngOnInit(): void {
    // this.selectedOption = this.menuOptions[0].id;
    this.menuOptions = [
      { id: 'balances', title: 'Saldos'             , icon: 'money'                    , badge: '', default: true},
      { id: 'cards'   , title: 'Tarjetas'           , icon: 'credit_card'              , badge: '', default: false},
      { id: 'plans'   , title: 'Saldo Plan'         , icon: 'account_balance_wallet'   , badge: '', default: false},
      { id: 'account' , title: 'Datos Cuenta'       , icon: 'info'                     , badge: '', default: false},
      { id: 'history' , title: 'Ultimos Movimientos', icon: 'show_chart'               , badge: '', default: false},
      { id: 'promos'  , title: 'Promociones'        , icon: 'campaign'                 , badge: '', default: false},
    ];
    this.vscapiService.getPromoCount().subscribe(cnt => {
      this.menuOptions[5].badge = ''+cnt;
    })
  
    this.context.selectedOptionAcct = [this.menuOptions[0].id];
  }

  onSelection(e: any, v: MatListOption[]) { 
    // this.selectedOptionAcct = v[0].value;
    this.context.selectedOptionAcct = [v[0].value];
  }

}
