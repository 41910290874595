<div class="container" style="max-width: 550px">
    <form [formGroup]="loginForm" (ngSubmit)="overrideSubmit()">
        <!-- <ng-template> -->
            <div class="login-grid">
                <mat-form-field appearance="fill">
                    <mat-label>User Name</mat-label>
                    <input matInput type="text" formControlName="name"  id="username">
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" id="password">
                </mat-form-field>
                <button mat-raised-button color="primary" type="submit" class="btn btn-primary">Override</button>
            </div>
        <!-- </ng-template> -->
    </form>
</div>