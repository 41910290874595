<div *ngIf="!listOnly; else showList" [ngClass]="minimal ? 'container-minimal' : 'container'">
    <div *ngIf="!minimal" class="box box4" style="grid-column: span 4;">
        <h1 style="grid-column: span 4;">{{action.title}} - {{action!.hint}}</h1>
        <!-- <credit-list-cards style="grid-column: span 4;" *ngIf="processStep == 'init'" [cardIsDisabled]="cardIsDisabled" [displayedColumns]="columns" [cardSelected]="cardSelected"></credit-list-cards> -->
    </div>

    <div class="box">
        <form [ngClass]="minimal ? 'span1-minimal' : 'span'">
            <mat-form-field class="box1" appearance="fill">
                <mat-label>Leave a comment</mat-label>
                <textarea matInput placeholder="Please enter a note..." [rows]="minimal?2:10" [(ngModel)]="context.actionVars.view.noteValue" name="noteValue"></textarea>
            </mat-form-field>
        </form>
        <span *ngIf="!minimal" style="grid-column: span 4;"><hr></span>
        <div class="box box3" [ngClass]="minimal ? 'span2-minimal' : 'span'">
            <core-smart-button [config]="saveButtonConfig"></core-smart-button>    
            <core-smart-button  *ngIf="!minimal" [config]="closeButtonConfig"></core-smart-button>    
        </div>
        <credit-list-notes *ngIf="!noList" style="grid-column: span 4;"></credit-list-notes>
    </div>
</div>

<ng-template #showList>
    <div class="box">
        <credit-list-notes style="grid-column: span 4;"></credit-list-notes>
    </div>
</ng-template>

