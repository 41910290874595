import { WorkflowService } from './../../../../../services/workflow.service';
import { VscapiService } from './../../../../core/services/vscapi.service';
import { CardWizardService } from './../../../services/card-wizard.service';
import { SnackBarService } from './../../../../core/services/snack-bar.service';
import { from, Observable, of } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment.service';
import { CreditInsuranceProduct, CreditCardProduct, CreditLocateResp } from './../../../entities/credit.d';
import { ButtonConfig, OtpConfig, SelectConfig, Suburb } from 'src/app/modules/core/entities/core';
import { CatalogService } from 'src/app/services/catalog.service';
import { Component, Input, OnInit, ViewChild, AfterViewInit, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { AuthService } from 'src/app/services/auth.service';
import { CreditCard } from '../../../entities/credit.d';
import { ContextService } from '../../../services/context.service';
import { VplService } from '../../../services/vpl.service';
import { FlowStateObject } from 'src/app/modules/workflow/entities/workflow.d';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { concatMap, delay } from 'rxjs/operators';

export interface StateDataEMBO {
  /**
   * @var {string} account
   * @var {string} card
   * @var {number} step Save stepper step of EMBO process
   * @var {'new'|'inprogress'|'complete'} status
   */
  account: string,
  card: string,
  step: number,
  status: string,
  customerName: string
}

@Component({
  selector: 'credit-ac-maintenance105',
  templateUrl: './ac-maintenance105.component.html',
  styleUrls: ['./ac-maintenance105.component.scss']
})
export class AcMaintenance105Component implements OnInit, AfterViewInit {

  @Input() actionCode: string = 'EMBO';
  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild('otpEmbossOK') otpEmbossOK!: ElementRef<HTMLInputElement>;

  public state: FlowStateObject ={
    id: '',
    logId: '',
    createTimestamp: new Date(),
    type: '',
    subType: '',
    stateData: {},
    timerId: ''
  };

  public action!: any;
  public card!: CreditCard;

  // Buttons
  public saveButtonConfig!: ButtonConfig;
  public newButtonConfig!: ButtonConfig;
  public actionSaveButtonConfig!: ButtonConfig;
  public closeButtonConfig!: ButtonConfig;
  public blockAccountButtonConfig!: ButtonConfig;
  public blockCardButtonConfig!: ButtonConfig;
  public pinButtonConfig!: ButtonConfig;
  public embossButtonConfig!: ButtonConfig;

  public otherServicesButtonConfig!: ButtonConfig;

  public columns = ['select', 'product', 'cardNumber', 'expiryDate', /*'reg', 'blockCode', 'blockCodeDesc',*/ 'st', 'stDesc', /*'gp', 'gpDesc', 'tg', 'tgDesc',*/ 'embossedName'];
  public cards: CreditCard[] = [];

  // Stepper
  public isLinear = true;
  public isEditable = false;

  public stepperNext(stepLabel?: string) {
    let stepNumber = this.stepper.selectedIndex;
    this.state.stateData.steps[stepNumber].completed = true;
    this.state.stateData.steps[stepNumber].stepLabel = stepLabel;
    this.stepper.selected!.completed = true;
    this.stepper.next();
    this.snack.dismiss();
    this.saveState();
  }

  public saveState() {
    /* Save the state */
    let queueList =
      [
        ...this.creditSvc.selectQueues("account"),
        ...this.auth.selectQueues("account")
      ];
    this.context.saveState(this.state.stateData.account, this.state, queueList, (response) => {
      if (response.status == 'success') {
        this.state = response.data;
        this.vscapiService.selectedCard = this.state.stateData.card;
        // this.snack.openSnackBar('State Saved: ' + stepLabel, 'OK');
      } else {
        this.snack.openSnackBar('State Save Error: ' + response.status ?? 'No Status', 'OK');
      }
    });

  }

  public fieldConfigs1 = new Map<string, SelectConfig>();
  public fieldConfigs2 = new Map<string, SelectConfig>();
  public fieldOptions = new Map();

  public search: boolean = true;
  public zips1: Suburb[] = [];
  public zips2: Suburb[] = [];
  public zipSearchFieldConfig1: SelectConfig = { type: "smartselect", id: "zipSearch1", name: "zipSearch1", title: "Suburb Selection", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: 'car', hideIfEmpty: false };
  public zipSearchFieldConfig2: SelectConfig = { type: "smartselect", id: "zipSearch2", name: "zipSearch2", title: "Suburb Selection", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: 'car', hideIfEmpty: false };
  public motivEmisConfig: SelectConfig = { type: "smartselect", id: "motivEmis", name: "motivEmis", title: "Motivation de Emision", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: 'mail', hideIfEmpty: false };
  public motivEmisOptions: { id: string, label: string }[] =
    [
      { id: '1', label: 'Apertura de Cuenta' },
      { id: '2', label: 'Robo o Extravio' },
      { id: '3', label: 'Tarjeta Dañada' },
      { id: '4', label: 'Mal Grabada' },
      { id: '5', label: 'Traspaso de Cuenta' },
    ];

  public isCustomerIdentified: boolean = false;
  public printOK: boolean = false;
  public caratulaOK = 0;
  public digiOK = 0;
  public incrementoOK = 0;
  public incrementoSelection = "";
  public pinStatus = '';
  public consentOK: boolean = false;
  public cwActivity: string = '';
  public callDate!: Date;
  public actionForm!: FormGroup;
  public otp1Config!: OtpConfig;
  public otp2Config!: OtpConfig;
  public activationConfig!: SelectConfig;
  public activationOptions!: { id: string, label: string }[];
  public selectedActivation!: string;
  public embossButtonEnabled: { motivSelected: boolean, productSelected: boolean } = { motivSelected: false, productSelected: false };

  public cardSelected(_card: CreditCard): void { };
  public cardIsDisabled = (cardIn: CreditCard) => {
    if (!cardIn.cardNumber || this.context.checkCurrentActionOnCard(this.actionCode, cardIn.cardNumber) || cardIn.st != 'N') {
      return true;
    }
    return false;
  };

  backOfficeSubmitted($e: any) {
    this.state.stateData.backOfficeRequestId = $e.requestId;
    this.state.stateData.backOfficeStatus = $e.requestStatus;

    let updateNote = $e.note;
    this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });

    this.saveState();
  }

  backOfficeProvided($e: any) {
    this.state.stateData.backOfficeRequestId = $e.requestId;
    this.state.stateData.backOfficeStatus = $e.requestStatus;

    let updateNote = $e.note;
    this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });

    this.stepperNext('back office 1');
  }

  backOfficeComplete($e: any) {
    this.state.stateData.backOfficeRequestId = $e.requestId;
    this.state.stateData.backOfficeStatus = $e.requestStatus;

    let updateNote = $e.note;
    this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });

    this.stepperNext('back office 2');
  }

  public customerIdentified($result: any) {
    this.isCustomerIdentified = false;
    if ($result.result == 'success') {
      this.isCustomerIdentified = true;
      let updateNote = '';
      if ($result.log && $result.log.length > 0) {
        updateNote = $result.log[$result.log.length-1];
      }
      this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });
      this.stepperNext('customer identification');
    }
  }

  public insuranceAccepted(insuranceProducts: CreditInsuranceProduct[] = []) {
    this.stepperNext('insurance');
    insuranceProducts.forEach(ip => {
      this.snack.openSnackBar('Insurance Product Selected: ' + ip.name, 'OK');
    })
    return true;
  }

  public selectProduct(product: CreditCardProduct) {
    this.embossButtonEnabled.productSelected = true;
  }

  constructor(
    public context: ContextService,
    public auth: AuthService,
    public creditSvc: VplService,
    public vscapiService: VscapiService,
    public catalog: CatalogService,
    public env: EnvironmentService,
    public snack: SnackBarService,
    public cw: CardWizardService,
    public workflow: WorkflowService,
  ) { }

  ngOnInit() {
    this.action = this.creditSvc.getActionCode('id' + this.actionCode);
    this.setupForm();
    // this.creditSvc.loadAccountActions();
    if (this.context?.selectedState) {
      this.state = this.context.selectedState;
      this.vscapiService.selectedAccount = this.state.stateData.account;
      this.vscapiService.selectedCard = this.state.stateData.card;
      this.init();
    } else {
      this.init();
      this.state = {
        id: '',
        logId: '12345',
        createTimestamp: new Date(),
        type: 'action',
        subType: this.actionCode,
        stateData: {
          step: 0,
          status: 'new',
          emboSent: false,
        },
        timerId: ''
      };

      this.cards = [];
      this.vscapiService.getAccountCards().subscribe(cards => {
        this.cards = cards;
      })
    }
  }

  ngAfterViewInit(): void {
    this.otpEmbossOK.nativeElement.value = '0';
  }

  public show() {
    return true;
  }

  public commonHintData() {
    return {
      clientName: this.state.stateData.clientName
    }
  }

  public init(): void {

    this.printOK = false;
    this.caratulaOK = 0;
    this.digiOK = 0;
    this.incrementoOK = 0;
    this.incrementoSelection = "";
    this.pinStatus = "";

    this.activationConfig = { type: "smartselect", id: "activation", name: "activation", title: "Activation Method", search: true, result: true, format: "array", readonly: false, required: true, placeholder: 'X', hint: 'Activation Method', icon: 'lightning', hideIfEmpty: false };
    this.activationOptions = [
      { id: "WIZARD", label: "Card Wizard" },
      { id: "SEGURO", label: "Activacion Seguro PIF" },
      { id: "INVENT", label: "Actualization Inventario" },
      { id: "ENTREG", label: "Impresion Acuse de Entrega" },
    ];
    this.selectedActivation = '';
    this.embossButtonEnabled = { motivSelected: false, productSelected: false };

    if (this.otpEmbossOK) {
      this.otpEmbossOK.nativeElement.value = '0';
    }

    this.otp1Config = {
      email: this.creditSvc.customer?.contactEmail,
      cell: this.creditSvc.customer?.contactTelephoneCell,
      update: false,
      disabled: (() => { return false; })
    };

    this.otp2Config = {
      email: this.creditSvc.customer?.contactEmail,
      cell: this.creditSvc.customer?.contactTelephoneCell,
      update: true,
      disabled: (() => { return false; })
    };

    let fnClickSave = (): boolean => {
      // Fire update of customer demo data
      //  - success: stepper.next 

      let updateNote = 'Update: Demographic Data for Card ' + this.vscapiService.selectedCard;
      this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });
      this.context.actionVars.EMBO.noteValue = '';
      this.snack.openSnackBar(updateNote, 'OK');

      // this.stepper.selected!.completed = true;
      // this.stepper.next();
      return true;
    }

    let fnDisabledSave = () => { return false; };

    this.saveButtonConfig = {
      id: '',
      name: '',
      hint: 'Update',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickSave,
      icon: function (): string {
        return 'check_circle';
      },
      disabled: fnDisabledSave
    }

    let fnClickBlockAccount = (): void => {
      // Fire unblock service to VPL
      //  - success: stepper.next 

      if (this.env.newAPI) {
        this.vscapiService.accountUnblock().subscribe(response => {
          if (response.status != 'success') {
            let updateNote = 'Error: N Block Removal Failed for Account ' + response.account.id;
            this.snack.openSnackBar(updateNote, 'OK');
            return false;      
          }
          let updateNote = 'Update: N Block Removed for Account ' + response.account.id;
          this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });
  
          this.state.stateData.steps[this.stepper.selectedIndex].result = 'account unblocked';
          this.stepperNext('unblock account');
          this.snack.openSnackBar(updateNote, 'OK');  
          return true;
        });
        return;
      }

      if (this.creditSvc.unblockAccount()) {
        let updateNote = 'Update: N Block Removed for Account ' + this.creditSvc.account?.id;
        this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });

        this.state.stateData.steps[this.stepper.selectedIndex].result = 'account unblocked';
        this.stepperNext('unblock account');
        this.snack.openSnackBar(updateNote, 'OK');
        return;
      }
      let updateNote = 'Error: N Block Removal Failed for Account ' + this.creditSvc.account?.id;
      this.snack.openSnackBar(updateNote, 'OK');
      return;
    }

    let fnClickBlockCard = (): void => {
      // Fire unblock service to VPL
      //  - success: stepper.next 

      if (this.env.newAPI) {
        this.vscapiService.cardUnblock().subscribe(response => {
          if (response.status != 'success') {
            let updateNote = 'Error: N Block Removal Failed for Card ' + response.card.id;
            this.snack.openSnackBar(updateNote, 'OK');
            return false;      
          }
          let updateNote = 'Update: N Block Removed for Card ' + response.card.id;
          this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });
  
          this.state.stateData.steps[this.stepper.selectedIndex].result = 'card unblocked';
          this.stepperNext('unblock card');
          this.snack.openSnackBar(updateNote, 'OK');  
          return true;
        });
        return;
      }

      this.creditSvc.unblockCard();

      let updateNote = 'Update: N Block Removed for Card ' + this.vscapiService.selectedCard;
      this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });

      this.state.stateData.steps[this.stepper.selectedIndex].result = 'card unblocked';
      this.stepperNext('unblock card');
      this.snack.openSnackBar(updateNote, 'OK');
      return;
    }

    this.blockAccountButtonConfig = {
      id: '',
      name: '',
      hint: 'Desbloquear Cuenta',
      hideIfDisabled: false,
      type: 'text',
      title: '',
      click: fnClickBlockAccount,
      icon: function (): string {
        return 'check_circle';
      },
      disabled: () => { return false; }
    }

    this.blockCardButtonConfig = {
      id: '',
      name: '',
      hint: 'Desbloquear Tarjeta',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickBlockCard,
      icon: function (): string {
        return 'check_circle';
      },
      disabled: () => { return false; }
    }

    let fnClickPin = (): boolean => {
      // Fire pin service request to B24
      //  - success: stepper.next 

      this.creditSvc.requestPIN();

      let updateNote = 'PIN Requested for Card ' + this.vscapiService.selectedCard;
      this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });

      let pinStatuses = [
        'Inicializando...',
        'Listo',
        'Asignación de NIP 1',
        'Asignación de NIP 2',
        'Asignación de NIP 3',
        'Asignación de NIP exitoso'
      ];

      this.pinStatus = '...';

      from(pinStatuses).pipe(concatMap(item => of(item).pipe(delay(2500)))).subscribe(val => { this.pinStatus = val;});
      // this.stepperNext('pin request');
      return true;
    }

    this.pinButtonConfig = {
      id: '',
      name: '',
      hint: 'Aceptar',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickPin,
      icon: function (): string {
        return 'check_circle';
      },
      disabled: () => { return false; }
    }

    let fnClickEmboss = (): boolean => {
      // Fire embossing data service
      //  - success: stepper.next 

      this.otpEmbossOK.nativeElement.value = '2';
      this.creditSvc.embossCard();

      let updateNote = 'Embossing Requested for Card ' + this.vscapiService.selectedCard;
      this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });

      this.state.stateData.emboSent = true;
      this.stepperNext('create card');
      this.snack.openSnackBar(updateNote, 'OK');

      return true;
    }

    // this.embossButtonEnabled.motivSelected = false;
    // this.embossButtonEnabled.productSelected = false;
    this.embossButtonConfig = {
      id: '',
      name: '',
      hint: 'Emboss Card',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickEmboss,
      icon: function (): string {
        return 'check_circle';
      },
      disabled: () => { return !(/* this.otpEmbossOK.nativeElement.value == '1' && */ this.embossButtonEnabled.motivSelected && this.embossButtonEnabled.productSelected); }
    }

    this.cardSelected = (card: CreditCard) => {
      this.state.stateData.account = this.vscapiService.selectedAccount;
      this.state.stateData.card = card.id;
      this.state.stateData.tag = card.id;
      this.state.stateData.uniqueTag = `${this.actionCode}-${card.id}`;
      this.state.stateData.clientName = card.embossedName ?? '';


      /* Workaround for 06-22 changes (card select step is not being saved, causing queue conflicts): This process needs to be 
         improved so that the saveState() call happens inside stepperNext() and is executed on every step. 
      */
      this.stepperNext('card select');

      // let queueList = 
      // [ 
      //   ...this.creditSvc.selectQueues("account"), 
      //   ...this.auth.selectQueues("account") 
      // ];      
      // this.context.saveState(this.state.stateData.account, this.state, queueList, (response) => {
      //   if (response.status == 'success') {
      //     this.state = response.data;
      //     this.creditSvc.setCard(card.id);
      //     // this.stepperNext('card select');
      //     // this.setupForm();    
      //   } 
      // });          
    }

    let fnClickClose = (): boolean => {
      if (this.state.stateData.step >= this.stepper.steps.length - 1) {
        this.state.stateData.status = 'complete';
      } else {
        this.state.stateData.status = 'inprogress';
      }

      let queueList =
        [
          ...this.creditSvc.selectQueues("account"),
          ...this.auth.selectQueues("account")
        ];
      this.context.saveState(this.state.stateData.account, this.state, queueList, (response) => {
        let state = response.data;
        if (response.status == 'success') {
          // this.state = state;
        } else {
          console.error(response.status);
        }
      });
      this.context.removeAction(this.actionCode, 'maintenance');
      this.context.loadAccountActions(this.vscapiService.selectedAccount);

      return false;
    }

    this.closeButtonConfig = {
      id: '',
      name: '',
      hint: 'Cerrar',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickClose,
      icon: function (): string {
        return 'cancel';
      },
      disabled: function (): boolean {
        return false;
      }
    }

    let fnClickNew = (): boolean => {
      this.ngOnInit();
      // this.context.removeAction(this.actionCode, 'maintenance');
      fnClickClose();
      this.context.addAction(this.actionCode, 'maintenance');
      this.stepper.reset();
      // this.creditSvc.loadAccountActions();


      return false;
    }

    this.newButtonConfig = {
      id: '',
      name: '',
      hint: 'Generar Tarjeta Adicional',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickNew,
      icon: function (): string {
        return 'add';
      },
      disabled: function (): boolean {
        return false;
      }
    }

    let fnClickActionSave = (): boolean => {
      if (this.state.stateData.step >= this.stepper.steps.length) {
        this.state.stateData.status = 'complete';
      } else {
        this.state.stateData.status = 'inprogress';
      }

      let queueList =
        [
          ...this.creditSvc.selectQueues("account"),
          ...this.auth.selectQueues("account")
        ];
      this.context.saveState(this.state.stateData.account, this.state, queueList, (response) => {
        let state = response.data;
        if (response.status == 'success') {
          this.state = state;
        } else {
          console.error(response.status);
        }
        this.context.loadAccountActions(this.vscapiService.selectedAccount);
      });
      return false;
    }

    this.actionSaveButtonConfig = {
      id: '',
      name: '',
      hint: 'Guardar Progreso',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickActionSave,
      icon: function (): string {
        return 'star';
      },
      disabled: function (): boolean {
        return false;
      }
    }

    let fnClickOtherServices = (): boolean => {        
      return false;
    }

    this.otherServicesButtonConfig = {
      id: '',
      name: '',
      hint: 'Otros Servicios',
      hideIfDisabled: false,

      type: 'text',
      title: '',
      click: fnClickOtherServices,
      icon: function (): string {
        return 'density_medium';
      },
      disabled: function (): boolean {
        return false;
      }
    }
  }

  private setupForm() {

    this.vscapiService.getCustomer().subscribe(customer => {
      this.actionForm = new FormGroup({
  
        contactStreet: new FormControl(customer?.contactStreet ?? ''),
        contactSuburb: new FormControl(customer?.contactSuburb ?? ''),
        contactCity: new FormControl(customer?.contactCity ?? ''),
        contactState: new FormControl(customer?.contactState ?? ''),
        contactZip: new FormControl(customer?.contactPostalCode ?? ''),
        contactCountry: new FormControl(customer?.contactCountry ?? ''),
        contactEmail: new FormControl(customer?.contactEmail ?? ''),
        contactTelephoneHome: new FormControl(customer?.contactTelephoneHome ?? ''),
        contactTelephoneCell: new FormControl(customer?.contactTelephoneCell ?? ''),
  
        workStreet: new FormControl(customer?.workStreet ?? ''),
        workSuburb: new FormControl(customer?.workSuburb ?? ''),
        workCity: new FormControl(customer?.workCity ?? ''),
        workState: new FormControl(customer?.workState ?? ''),
        workZip: new FormControl(customer?.workPostalCode ?? ''),
        workCountry: new FormControl(customer?.workCountry ?? ''),
        workTelephone: new FormControl(customer?.workTelephone ?? ''),
  
      });
  
      this.fieldConfigs1.set("01contactStreet", { type: "input", id: "contactStreet", name: "contactStreet", title: "Contact Street", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false });
      this.fieldConfigs1.set("02contactSuburb", { type: "input", id: "contactSuburb", name: "contactSuburb", title: "Contact Suburb", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false });
      this.fieldConfigs1.set("03contactCity", { type: "input", id: "contactCity", name: "contactCity", title: "Contact City", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false });
      this.fieldConfigs1.set("04contactState", { type: "input", id: "contactState", name: "contactState", title: "Contact State", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false });
      this.fieldConfigs1.set("05contactZip", { type: "zip", id: "contactZip", name: "contactZip", title: "Contact Zip", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false });
      this.fieldConfigs1.set("06contactCountry", { type: "input", id: "contactCountry", name: "contactCountry", title: "Contact Country", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false });
  
      // this.fieldConfigs1.set("07otp"                  , { type: "otp"  , id: "otp"                  , name: "otp"                  , title: "Contact Phone/Email"   , search: false, result: true, format: ""    , readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false});
      this.fieldConfigs1.set("07contactEmail", { type: "input", id: "contactEmail", name: "contactEmail", title: "Contact Email", search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false });
      this.fieldConfigs1.set("08contactTelephoneCell", { type: "input", id: "contactTelephoneCell", name: "contactTelephoneCell", title: "Contact TelephoneCell", search: false, result: true, format: "text", readonly: true, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false });
      this.fieldConfigs1.set("09contactTelephoneHome", { type: "input", id: "contactTelephoneHome", name: "contactTelephoneHome", title: "Contact Home Phone", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false });
  
      this.fieldConfigs2.set("11workStreet", { type: "input", id: "workStreet", name: "workStreet", title: "Work Street", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false });
      this.fieldConfigs2.set("12workSuburb", { type: "input", id: "workSuburb", name: "workSuburb", title: "Work Suburb", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false });
      this.fieldConfigs2.set("13workCity", { type: "input", id: "workCity", name: "workCity", title: "Work City", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false });
      this.fieldConfigs2.set("14workState", { type: "input", id: "workState", name: "workState", title: "Work State", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false });
      this.fieldConfigs2.set("15workZip", { type: "zip", id: "workZip", name: "workZip", title: "Work Zip", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false });
      this.fieldConfigs2.set("16workCountry", { type: "input", id: "workCountry", name: "workCountry", title: "Work Country", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false });
      this.fieldConfigs2.set("17workTelephone", { type: "input", id: "workTelephone", name: "workTelephone", title: "Work Telephone", search: false, result: true, format: "text", readonly: false, required: false, placeholder: '', hint: '', icon: '', hideIfEmpty: false });
    })
  }

  stepChange($event: StepperSelectionEvent) {
    this.state.stateData.step = $event.selectedIndex;
  }

  validInput() {
    return (this.selectedActivation != '' && this.context.actionVars.EMBO.noteValue != '');
  }

  public zipSearch(zip: string, source: string) {
    this.catalog.search(Number(zip)).subscribe(result => {
      if (source == "05contactZip") {
        this.zips1 = result;
      }
      if (source == "15workZip") {
        this.zips2 = result;
      }

      let options: { id: string, label: string }[] = [];
      let i = 0;
      for (const suburb in result) {
        options.push({ id: i + '', label: result[i].city });
        i++;
      }
      this.fieldOptions.set(source, options);
    });
  }

  public changeZip($event: MatSelectChange) {
    if ($event.source.id == 'zipSearch1') {
      let i = Number($event.value);
      this.actionForm.controls.contactSuburb.setValue(this.zips1[i].city);
      this.actionForm.controls.contactCity.setValue(this.zips1[i].community);
      this.actionForm.controls.contactState.setValue(this.zips1[i].state);
      this.actionForm.controls.contactCountry.setValue(this.zips1[i].country);
    }
    if ($event.source.id == 'zipSearch2') {
      let i = Number($event.value);
      this.actionForm.controls.workSuburb.setValue(this.zips2[i].city);
      this.actionForm.controls.workCity.setValue(this.zips2[i].community);
      this.actionForm.controls.workState.setValue(this.zips2[i].state);
      this.actionForm.controls.workCountry.setValue(this.zips2[i].country);
    }
  }

  public changeMotiv($event: MatSelectChange) {
    this.embossButtonEnabled.motivSelected = true;
  }

  public otpSuccess(otp: number) {
    this.consentOK = true;

    let updateNote = 'Customer Consented By OTP for ' + this.vscapiService.selectedCard;
    this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });

    return true;
  }

  public otpSuccessContact(otp: number) {

    let updateNote = 'Customer Contact details Updated (Verified By OTP) for ' + this.vscapiService.selectedCard;
    this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });

    this.snack.openSnackBar(`Contact details updated.`, 'OK');

    return true;
  }

  public otpFail(event: any) {
    // alert(` ${this.env.dict.otp} verification failed`);
    this.snack.openSnackBar(`${this.env.dict.otp} verification failed`, 'OK');
    return false;
  }

  handleSignature(img: any) {
    this.consentOK = true;

    let updateNote = 'Customer Consented By Signature for ' + this.vscapiService.selectedCard;
    this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });
  }

  public printSend() {
    let updateNote = 'Customer Consent Documents printed for ' + this.vscapiService.selectedCard;
    this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });

    // Call PRINT API here
    console.log('Call Print API for ' + this.vscapiService.selectedCard);
    this.printOK = true;
    return true;
  }

  public printDone() {
    this.consentOK = true;

    let updateNote = 'Customer Consented By Signature for ' + this.vscapiService.selectedCard;
    this.context.actionVars.view.priorViews.push({ date: new Date(), user: this.auth.user.name, action: this.actionCode, note: updateNote });
    this.snack.openSnackBar(updateNote, 'OK');

    return true;
  }

  public checkStepComplete(step: number) {
    if (!this.state.stateData.steps) {
      this.state.stateData.steps = [];
    }

    if (!this.state.stateData.steps[step]) {
      this.state.stateData.steps.push({
        completed: false
      });
    }
    return (this.state.stateData.steps[step]?.completed ?? false);
  }
}

