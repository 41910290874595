import { AuthService } from 'src/app/services/auth.service';
import { Injectable } from '@angular/core';

import { EnvironmentService } from 'src/app/services/environment.service';
import { LoginUser } from '../entities/core';

import { of } from 'rxjs';
import { FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {
  private _user!: LoginUser;

  constructor(
    private env: EnvironmentService,
    private localAuth: AuthService
  ) { }

  login(username: string, password: string) {
      console.error("Firebase Auth is in use. login() should not be called");
      console.trace();
      return of(this._user);
  }

  loginSuccess(response: FirebaseUISignInSuccessWithAuthResult) {

    let userEmail = response.authResult.user?.email ?? '';
    let userToken = response.authResult.user?.getIdToken() ?? '';

    if (userEmail != '') {
      response.authResult.user?.getIdToken().then((token) => {
        this.localAuth.login(userEmail, token, true);
      });
    }

    this._user = {
      provider: '',
      id: '',
      email: '',
      name: ''
    };

    this._user.id = response.authResult.additionalUserInfo?.username ?? '';
    this._user.email = response.authResult.user?.email ?? '';
    this._user.name = response.authResult.user?.displayName ?? '';

    localStorage.setItem('isLoggedIn', JSON.stringify(true));
    localStorage.setItem('currentUser', JSON.stringify(this.user));
    localStorage.setItem('auth_token', JSON.stringify(userToken));
  }

  logout() { 
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('auth_token ');
    localStorage.removeItem('isLoggedIn');
  }

  public get user(): LoginUser {
    let localUser = localStorage.getItem("currentUser");
    if (!this._user && localUser) {
      this._user = JSON.parse(localUser);
    }
    return this._user; 
  }

  public get isLoggedIn() {
    if (localStorage.getItem("isLoggedIn") == null) {
      return false;
    }
    return true;
  }

  public hasAccess(resourceId: string, permission: string): boolean {
    let result = false;
    if (this.isLoggedIn && this.user.permissions){
      this.user.permissions.forEach(perm => {
        if (perm.resourceId == resourceId && perm.grant == permission) {
          result = true;
        }
      });
    }
    return result;
  }

  selectQueues(action: string, tags?: object) {
    let queues: string[] = [];
    switch(action) {
      case "account": {
        queues.push("user:"+this.user.name);
        this.user.profile?.teamMemberships?.forEach(team => {
          queues.push("team:"+team);
        })
        break;
      }
    }
    return queues;
  }
}
