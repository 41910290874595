import { VscapiService } from './../../core/services/vscapi.service';
import { SnackBarService } from 'src/app/modules/core/services/snack-bar.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { CreditIdMethod } from './../components/customer/identify/identify.component';
import { CreditLocate, CreditAccount, CreditCustomer, CreditCard, CreditClient, CreditAccountBalances, CreditDelinquencyRecord, CreditProfileRecord, CreditPlan, CreditTransaction, CreditChartOptions, CreditCardProduct, CreditPromo, CreditInsuranceProduct, CreditCardProductCoercivity, CreditCardProductStatus, CreditLocateReq, CreditLocateResp } from './../entities/credit.d';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, timeout } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { AssocArray } from '../../core/entities/core';
import { AccountBehavioralInfo, AccountCollectionInfo, AccountDates } from '../entities/credit';
import { ContextService } from './context.service';

@Injectable({
  providedIn: 'root'
})
export class VplService 
{
  constructor(
    private http: HttpClient,
    private context: ContextService,
    private env: EnvironmentService,
    private snack: SnackBarService,
    ) {}
    
    public locator!: CreditLocate;
    
    public found = new BehaviorSubject(false);
    public themeClass = '';
    
    // GENERAL
    public get callTimestamp() {
      return this.locator?.response.callTimestamp ?? new Date();
    }
    
    // CLIENTS
    public clients!: AssocArray;  
    public clientsList!: { id: string, label: string }[] | undefined;
    public client!: CreditClient | undefined;
    
    // CUSTOMERS
    private customers!: AssocArray;  
    public customersList!: { id: string, label: string }[] | undefined;
    public customer!: CreditCustomer | undefined;
    
    // ACCOUNTS
    public accounts!: AssocArray;  
    public accountsArray!: CreditAccount[];  
    public accountsList!: { id: string, label: string }[] | undefined;
    public account!: CreditAccount | undefined;
    
    // CARDS
    private cards!: AssocArray;  
    public cardsArray!: CreditCard[];  
    public cardsList!: { id: string, label: string }[] | undefined;
    public card!: CreditCard | undefined;

    // PLANS
    public plansArray!: CreditPlan[];

    // TRANSACTIONS
    public transactionsArray!: CreditTransaction[];
    
    // ACCOUNT RELATED
    public accountBalances!: CreditAccountBalances;
    public accountDates!: AccountDates;
    public accountCollectionInfo!: AccountCollectionInfo;
    public accountBehavioralInfo!: AccountBehavioralInfo;
    public accountDelinquencyRecords: CreditDelinquencyRecord[] = [];
    public accountDelinquencyChart!: CreditChartOptions;
    public accountProfileRecords: CreditProfileRecord[] = [];
    public accountProfileChart!: CreditChartOptions;
    
    public customerIdMethods: CreditIdMethod[] = [];

    // CARD RELATED
    public cardProducts!: CreditCardProduct[];

    // OTHER
    public insuranceProducts!: CreditInsuranceProduct[];

    setThemeByOrg(doc: Document, org?: string) {
      this.themeClass = '';

      switch(org) {
        case "100": this.themeClass = 'theme-100'; /* doc.documentElement.classList.add('theme-100'); */ break;
        case "200": this.themeClass = 'theme-200'; /* doc.documentElement.classList.add('theme-200'); */ break;
        default: break;
      }
    }
    
    selectQueues(action: string, tags?: object) {
      let queues = [];
      switch(action) {
        case "account": {
          queues.push("accounts:org"+this.account?.org);
          if (this.account?.vip == 'Y') {
            queues.push("accounts:vip");
          }
          break;
        }
      }
      return queues;
    }

    public getAccounts(): Observable<CreditAccount[]> {
      return of(this.accountsArray);
    }
    
    public loadAccountActions() {
      this.context.loadAccountActions(this.account?.id);
    }

    public getCards(): Observable<CreditCard[]> {
      return of(this.cardsArray);
    }
    
    public getCardProducts(): Observable<CreditCardProduct[]> {
      if (!this.cardProducts) {
        this.cardProducts = [];

        return this.http.get<any>(this.env.apiUrl + '/credit/cards/product', { headers: this.env.apiHeaders })
        .pipe(
          map(response => {
            if (response.status == 'success' && response.products) {
              this.cardProducts = response.products;
            }
            return this.env.getTenantCardProducts();
            return this.cardProducts;
          }))
        .pipe(timeout(2000),
          catchError(e => {
            console.error(e);
            this.cardProducts = this.env.getTenantCardProducts();   
            // do something on a timeout
            return of(this.cardProducts);
          }));
      } else {
        return of(this.cardProducts);
      } 
    }

    public getInsuranceProducts(): Observable<CreditInsuranceProduct[]> {
      if (!this.insuranceProducts) {
        this.insuranceProducts = [];

        return this.http.get<any>(this.env.apiUrl + '/credit/insurance/product', { headers: this.env.apiHeaders })
        .pipe(
          map(response => {
            if (response.status == 'success' && response.insuranceProducts) {
              this.insuranceProducts = response.insuranceProducts;
            }
            return this.insuranceProducts;
          }))
          .pipe(timeout(2000),
          catchError(e => {
            // do something on a timeout

            this.insuranceProducts.push(...this.env.tenantInsuranceProducts);
            // this.insuranceProducts.push({ id: 3, name: 'PIF BASICO  ', type: 'Insurance', cover: 'Basico'     , cost: 5  });
            // this.insuranceProducts.push({ id: 4, name: 'PIF PLUS    ', type: 'Insurance', cover: 'Plus'       , cost: 8 });
            // this.insuranceProducts.push({ id: 5, name: 'PIF PAREJA  ', type: 'Insurance', cover: 'Pareja'     , cost: 12 });
            // this.insuranceProducts.push({ id: 6, name: 'PIF SUPERIOR', type: 'Insurance', cover: 'Superior'   , cost: 17 });
            return of(this.insuranceProducts);
          }));
        } else {
          return of(this.insuranceProducts);
        }
    }

    public getTransactions():CreditTransaction[] {
      return this.transactionsArray;
    }
    
    public clear() {
      this.clientsList = undefined;
      this.client = undefined;
      this.customersList = undefined;
      this.customer = undefined;
      this.accountsList = undefined;
      this.accountsArray = [];
      this.account = undefined;
      this.cardsList = undefined;
      this.cardsArray = [];
      this.card = undefined;
      this.plansArray = [];
      this.transactionsArray = [];
      
      this.context.clearActions();
    }  
    
    public getClients() {
      let result = [];
      for (const k in this.clients) { 
        result.push({ id: this.clients[k].id, label: this.clients[k].id });
      }
      this.clientsList = result;
      this.client = undefined;
      this.customersList = undefined;
      this.customer = undefined;
      this.accountsList = undefined;
      this.accountsArray = [];
      this.account = undefined;
      this.cardsList = undefined;
      this.cardsArray = [];
      this.card = undefined;
      this.plansArray = [];
      this.transactionsArray = [];
    }  
    
    public setClient(client?: string) {
      let result = [];
      if (client == undefined) {
        this.client = undefined;
        // for (const k in this.customers) { 
        //   result.push({ id: this.customers[k].id, label: this.customers[k].id });
        // }
      } else {
        this.client = this.clients[client];
        for (const k in this.customers) { 
          if (this.customers[k].clientId == client) {
            result.push({ id: this.customers[k].id, label: this.customers[k].id });
          }
        }
      }
      this.customersList = result;
      this.customer = undefined;
      this.accountsList = undefined;
      this.accountsArray = [];
      this.account = undefined;
      this.cardsList = undefined;
      this.cardsArray = [];
      this.card = undefined;
    }
    
    public setCustomer(customer: string) {
      this.accountsArray = [];
      let result = [];
      if (customer == undefined) {
        for (const k in this.accounts) { 
          this.accountsArray.push(this.accounts[k]);
          result.push({ id: this.accounts[k].id, label: this.accounts[k].id });
        }
      } else {
        for (const k in this.accounts) { 
          if (this.accounts[k].customerId == customer) {
            this.accountsArray.push(this.accounts[k]);
            result.push({ id: this.accounts[k].id, label: this.accounts[k].id });
          }
        }
      }
      this.customer = this.customers[customer];
      this.accountsList = result;
      this.account = undefined;
      this.cardsList = undefined;
      this.card = undefined;
    }
    
    public setAccount(account: string) {
      // newAPI: This function will be removed once nothing uses it anymore
      this.account = this.accounts[account];
      this.cardsArray = [];
      this.plansArray = [];
      this.transactionsArray = [];
      let result = [];
      if (account == undefined) {
        for (const k in this.cards) { 
          this.cardsArray.push(this.cards[k]);
          result.push({ id: this.cards[k].id, label: this.cards[k].id });
        }
      } else {
        for (const k in this.cards) { 
          if (this.cards[k].accountId == account) {
            this.cardsArray.push(this.cards[k]);
            result.push({ id: this.cards[k].id, label: this.cards[k].id });
          }
        }
      }
      this.cardsList = result;
      this.card = undefined;
      
      this.getAccountBalances(account);
      this.getAccountDates(account);
      // this.getAccountCollectionInfo(account); // newAPI phase out
      this.getAccountBehavioralInfo(account);
      this.getAccountDelinquency(account); 
      this.getAccountProfile(account);     
      this.getAccountPlans(account);
      this.getAccountTransactions(account);
      
      this.getAccountDelqChart();    // not testing services. Need these to translate the Profile records.
      this.getAccountProfileChart(); // not testing services. Need these to translate the Profile records.
    }
    
    public setCard(card: string) {
      this.card = this.cards[card];
    }
    
    locateClient(locateReq: CreditLocateReq) 
    {
      this.setClient();
      this.locator = {
        request: locateReq,
        response: {}
      };
      return this.http.post<any>(this.env.apiUrl + '/credit/locateclient', locateReq, { headers: this.env.apiHeaders })
      .pipe(
        map(response => {
          if (response.status == 'success' && response.locate) {
            this.locator.response = response.locate;
          }
          this.updateClientFromLocate(this.locator.response);
          return this.locator.response;
        }))
        .pipe(timeout(2000),
        catchError(e => {
          // do something on a timeout
          this.locator.response = this.testDummy(locateReq);
          this.updateClientFromLocate(this.locator.response);
          return of(this.locator.response);
          // return of(this.updateClientFromLocate(this.testDummy()));
        }));
      }

      getCustomerIdMethods(locate: CreditLocateReq) 
      {
        
        let id = locate.cardNumber ? locate.cardNumber : '181818';
        let idType = 'cardNumber';
        
        let headers: HttpHeaders = this.env.apiHeaders;        
        return this.http.get<any>(this.env.apiUrl + '/credit/customer/idMethod/' + id + '/' + idType, { headers: this.env.apiHeaders, observe: 'response' })
        .pipe(
          map(response => {
            if (response.body.status == 'success' && response.body.methods) {
              return response.body.methods;
            }
            return [];
          }))
          .pipe(timeout(2000),
          catchError(e => {
            // do something on a timeout
            return of({ 
              methods: [ 
                { name: 'Emboss Name', type: 'check', prompt: 'Does provided ID match Embosser Name?', value: ''},
                { name: 'RFC', type: 'compare', prompt: 'What is your customer number?', value: 'abcrfc'},
                // { name: 'Address', type: 'compare', prompt: 'What is your address?', value: 'address'},
                { name: 'Email', type: 'compare', prompt: 'Compare provided email?', value: 'email'},
                { name: this.env.dict.otp, type: 'otp', value: ''},
                // { name: 'Identification Number', type: 'compare', prompt: 'What is your Identification Number?', value: '123'},
                // { name: 'Visual', type: 'visual', prompt: 'Does this image match the customer?', value: ''},
              ]
            });
          }));
        }

        // checkDocVerify
        // requestDocVerify
  
  requestDocVerify(locate: CreditLocateReq, docType: string, docValue: string) {

    let headers: HttpHeaders = new HttpHeaders();
    // headers = headers.set('api-version', '1.0');


    return this.http.post<any>(this.env.apiUrl + '/credit/checkID/requestDocVerify',
      { id: locate.refNumber, docType: docType, docValue: docValue },
      { headers: this.env.apiHeaders, observe: 'response' }
    )
      .pipe(
        map(response => {
          // if (response.body.status == 'success' && response.body.methods) {
          //   return response.body;
          // }
          return response.body;
        }))
      .pipe(timeout(2000),
        catchError(e => {
          // do something on a timeout
          return of({
            result: 'fail',
            note: 'system error',
            id: ''
          });
        }));
  }

  checkDocVerify(requestId: string) {

    let headers: HttpHeaders = new HttpHeaders();
    // headers = headers.set('api-version', '1.0');


    return this.http.post<any>(this.env.apiUrl + '/credit/checkID/checkDocVerify',
      { id: requestId },
      { headers: this.env.apiHeaders, observe: 'response' }
    )
      .pipe(
        map(response => {
          // if (response.body.status == 'success' && response.body.methods) {
          //   return response.body;
          // }
          return response.body;
        }))
      .pipe(timeout(2000),
        catchError(e => {
          // do something on a timeout
          return of({
            result: 'fail',
            note: 'system error'
          });
        }));
  }

  updateDocVerify(requestId: string, newStatus: string, securityToken: string = '') {
    let headers = { ...this.env.apiHeaders, 'api-security-override-token': securityToken };

    return this.http.post<any>(this.env.apiUrl + '/credit/checkID/updateDocVerify',
      { id: requestId, status: newStatus },
      { headers: headers, observe: 'response' }
    ).pipe(
      map(response => {
        // if (response.body.status == 'success' && response.body.methods) {
        //   return response.body;
        // }
        return response.body;
      }))
      .pipe(timeout(2000),
        catchError(e => {
          // do something on a timeout
          return of({
            result: 'fail',
            note: 'system error'
          });
        }));
  }

  verifyCustomerCall(locate: CreditLocateReq, docType: string, docValue: string) {
    let id = locate.cardNumber ? locate.cardNumber : '181818';
    let idType = 'cardNumber';

    let headers: HttpHeaders = new HttpHeaders();
    // headers = headers.set('api-version', '1.0');


    return this.http.post<any>(this.env.apiUrl + '/credit/customer/idVerify',
      { idType: 'cardNumber', id: locate.cardNumber, docType: docType, docValue: docValue },
      { headers: this.env.apiHeaders, observe: 'response' }
    )
      .pipe(
        map(response => {
          // if (response.body.status == 'success' && response.body.methods) {
          //   return response.body;
          // }
          return response.body;
        }))
      .pipe(timeout(2000),
        catchError(e => {
          // do something on a timeout
          return of({
            result: 'fail'
          });
        }));
  }
        
        
        public updateClientFromLocate(locate: CreditLocateResp) {
          
          this.clients = [];
          this.customers = [];
          this.accounts = [];
          this.accountsArray = [];
          this.cards = [];
          this.cardsArray = [];
          this.locator.response.callTimestamp = new Date();
          
          locate.clients?.forEach(client => {
            this.clients[client.id] = client;
            client.customers.forEach((customer, i) => {
              customer.clientId = client.id;
              this.customers[customer.id] = customer;
              customer.accounts?.forEach((account, i) => {
                account.customerId = customer.id;
                this.accounts[account.id] = account;
                this.accountsArray.push(account);
                account.cards?.forEach(card => {
                  card.accountId = account.id;
                  if (card.tg == '0' ) {
                    card.tgDesc = 'Primary';
                  }
                  if (card.tg == '1' ) {
                    card.tgDesc = 'Secondary';
                  }
                  this.cards[card.id] = card;
                  this.cardsArray = [];
                });
              });
            });
          });
        }
        
        public actionACTI(cardId: string) {
          if (this.cards[cardId].st == 'N') {
            this.cards[cardId].st = 'A';
            this.cards[cardId].stDesc = 'ACTIVA';
            this.cards[cardId].blockCode = 'A';
            this.cards[cardId].blockCodeDesc = 'ACTIVA';
            // alert('card ' + cardId + " activated.");
            this.snack.openSnackBar('card ' + cardId + " activated.", 'OK');
            return true;
          } else {
            // alert('card ' + cardId + " can not be activated. Must be status 'N', not '" + this.cards[cardId].st + "'.");
            this.snack.openSnackBar('card ' + cardId + " can not be activated. Must be status 'N', not '" + this.cards[cardId].st + "'.", 'OK');
            return false;
          }
        }

        public unblockAccount() {
          if (!this.card) {
            // alert("No account selected. Can not be unblocked.");
            this.snack.openSnackBar("No account selected. Can not be unblocked.", 'OK');
          } else {
            if (this.account?.blockCode1 == 'N') {
              this.account!.blockCode1 = 'A';
              this.snack.openSnackBar('Success: Account ' + this.account!.id + " Block removed.", 'OK'); /* #doc-CC-20220624-SNACK */
              return true;
            } else {
              // alert('Account ' + this.account!.id + " already unblocked. Block Code : '" + this.account?.blockCode1 + "'.");
              this.snack.openSnackBar('Account ' + this.account!.id + " already unblocked. Block Code : '" + this.account?.blockCode1 + "'.", 'OK');
              return true;
            }
          }
          return false;
        }
        
        public unblockCard() {
          if (!this.card) {
            // alert("No card selected. Can not be unblocked.");
            this.snack.openSnackBar("No card selected. Can not be unblocked.", 'OK');
          } else {
            if (this.card.st == 'N') {
              this.card.st = 'E';
              this.card.stDesc = 'EMBOSS';
              this.card.blockCode = 'E';
              this.card.blockCodeDesc = 'EMBOSS';
              this.snack.openSnackBar('Success: Card ' + this.card.cardNumber + " Block removed.", 'OK'); /* #doc-CC-20220624-SNACK */
              return true;
            } else {
              // alert('card ' + this.card.cardNumber + " can not be unblocked. Must be status 'N', not '" + this.card.st + "'.");
              this.snack.openSnackBar('card ' + this.card.cardNumber + " can not be unblocked. Must be status 'N', not '" + this.card.st + "'.", 'OK');
            }
          }
          return false;
        }
        
        public embossCard() {
          if (!this.card) {
            // alert("No card selected. Can not be embossed.");
            this.snack.openSnackBar("No card selected. Can not be embossed.", 'OK');
          } else {
            // if (this.card.st == 'N') {
              this.card.st = 'A';
              // this.card.stDesc = 'EMBOSS';
              // this.card.blockCode = 'E';
              // this.card.blockCodeDesc = 'EMBOSS';
              // alert('card ' + this.card.cardNumber + " submitted for embossing.");
              // this.snack.openSnackBar('card ' + this.card.cardNumber + " submitted for embossing.", 'OK');
              return true;
            // } else {
              // alert('card ' + this.card.cardNumber + " can not be unblocked. Must be status 'N', not '" + this.card.st + "'.");
            //   this.snack.openSnackBar('card ' + this.card.cardNumber + " can not be unblocked. Must be status 'N', not '" + this.card.st + "'.", 'OK');
            // }
          }
          return false;
        }

        public requestPIN() {
          if (!this.card) {
            // alert("No card selected. Can not be embossed.");
            this.snack.openSnackBar("No card selected. Can not be embossed.", 'OK');
          } else {
              // alert('PIN request for ' + this.card.cardNumber + " submitted.");
              this.snack.openSnackBar('PIN request for ' + this.card.cardNumber + " submitted.", 'OK');
              return true;
          }
          return false;
        }

        public getAccountBalances(account: string) {
          switch(account) {
            case "0004178490999963631":
            this.accountBalances = {
              totalAmtDue       : 1287.74,
              lastPmtAmt        : 19078.86  ,
              lastPurchaseAmt   : 12224.44  ,
              creditLimit       : 0.00,
              lastCreditLimit   : 198000.00 ,
              highBalance       : 37650.20 ,
              totalOtbOverLimit : -8681.30,
              totalDisp         : 0.00 ,
              mindPlusTPD       : 1287.74 ,
              totalCurrDue      : 180.00 ,
              paymentDue        : 1287.74 ,
              interestFree      : 8681.30 ,
            }
            break;
            case "13000090015624":
            this.accountBalances = {
              totalAmtDue       : 573.00 ,
              lastPmtAmt        : 0.00  ,
              lastPurchaseAmt   : 500.00 ,
              creditLimit       : 12000.00 ,
              lastCreditLimit   : 0.00 ,
              highBalance       : 5724.25 ,
              totalOtbOverLimit : 6275.75 ,
              totalDisp         : 0.00 ,
              mindPlusTPD       : 573.00 ,
              totalCurrDue      : 573.00 ,
              paymentDue        : 573.00 ,
              interestFree      : 5724.25 ,
            }
            break;
            case "0004008490999988100":
            this.accountBalances = {
              totalAmtDue       : 573.00 ,
              lastPmtAmt        : 0.00  ,
              lastPurchaseAmt   : 500.00 ,
              creditLimit       : 12000.00 ,
              lastCreditLimit   : 0.00 ,
              highBalance       : 5724.25 ,
              totalOtbOverLimit : 6275.75 ,
              totalDisp         : 0.00 ,
              mindPlusTPD       : 573.00 ,
              totalCurrDue      : 573.00 ,
              paymentDue        : 573.00 ,
              interestFree      : 5724.25 ,
            }
            break;
            case "0000013000090015624":        
            this.accountBalances = {
              totalAmtDue       : 573.00 ,
              lastPmtAmt        : 0.00  ,
              lastPurchaseAmt   : 500.00 ,
              creditLimit       : 12000.00 ,
              lastCreditLimit   : 0.00 ,
              highBalance       : 5724.25 ,
              totalOtbOverLimit : 6275.75 ,
              totalDisp         : 0.00 ,
              mindPlusTPD       : 573.00 ,
              totalCurrDue      : 573.00 ,
              paymentDue        : 573.00 ,
              interestFree      : 5724.25 ,
            }
            break;
            
            case "0004001000999963631":        
            this.accountBalances = {
              totalAmtDue       : 573.00 ,
              lastPmtAmt        : 0.00  ,
              lastPurchaseAmt   : 500.00 ,
              creditLimit       : 12000.00 ,
              lastCreditLimit   : 0.00 ,
              highBalance       : 5724.25 ,
              totalOtbOverLimit : 6275.75 ,
              totalDisp         : 0.00 ,
              mindPlusTPD       : 573.00 ,
              totalCurrDue      : 573.00 ,
              paymentDue        : 573.00 ,
              interestFree      : 5724.25 ,
            }
            break;
            
            case "0000013000090015624":        
            this.accountBalances = {
              totalAmtDue       : 573.00 ,
              lastPmtAmt        : 0.00  ,
              lastPurchaseAmt   : 500.00 ,
              creditLimit       : 12000.00 ,
              lastCreditLimit   : 0.00 ,
              highBalance       : 5724.25 ,
              totalOtbOverLimit : 6275.75 ,
              totalDisp         : 0.00 ,
              mindPlusTPD       : 573.00 ,
              totalCurrDue      : 573.00 ,
              paymentDue        : 573.00 ,
              interestFree      : 5724.25 ,
            }
            break;      
          }
          if (this.account) this.account.accountBalances = this.accountBalances; // newapi: fix for changes made to CreditAccount as part of newAPI
        }
        
        public getAccountDates(account: string) {
          this.accountDates = new AccountDates();
          
          switch(account) {
            case "0004178490999963631":
            this.accountDates.paymentDueDate           = new Date('2022/01/20');
            this.accountDates.lastPaymentDate          = new Date('2022/11/01');
            this.accountDates.lastPurchaseDate         = new Date('2022/01/21');
            this.accountDates.creditLimitDate          = new Date('2021/10/31');
            this.accountDates.lastCreditLimitDate      = new Date('2021/10/31');
            this.accountDates.lastDelinquencyDate      = new Date('2021/10/31');
            this.accountDates.lastReclassificationDate = new Date('2024/12/6');
            break;
            case "0000013000090015624":
            this.accountDates.paymentDueDate           = new Date('2022/02/05');
            this.accountDates.lastPaymentDate          = new Date('2022/01/05');;
            this.accountDates.lastPurchaseDate         = new Date('2022/12/24');
            this.accountDates.creditLimitDate          = new Date('2021/10/21');
            this.accountDates.lastCreditLimitDate      = new Date('2026/01/21');
            this.accountDates.lastDelinquencyDate      = new Date('2021/02/21');
            this.accountDates.lastReclassificationDate = new Date('2024/12/24');
            break;
            case "0004008490999988100": 
            this.accountDates.paymentDueDate           = new Date('2022/02/05');
            this.accountDates.lastPaymentDate          = new Date('2022/01/05');;
            this.accountDates.lastPurchaseDate         = new Date('2022/12/24');
            this.accountDates.creditLimitDate          = new Date('2021/10/21');
            this.accountDates.lastCreditLimitDate      = new Date('2026/01/21');
            this.accountDates.lastDelinquencyDate      = new Date('2021/02/21');
            this.accountDates.lastReclassificationDate = new Date('2024/12/24');
            break;
            case "0000013000090015624": 
            this.accountDates.paymentDueDate           = new Date('2022/02/05');
            this.accountDates.lastPaymentDate          = new Date('2022/01/05');;
            this.accountDates.lastPurchaseDate         = new Date('2022/12/24');
            this.accountDates.creditLimitDate          = new Date('2021/10/21');
            this.accountDates.lastCreditLimitDate      = new Date('2026/01/21');
            this.accountDates.lastDelinquencyDate      = new Date('2021/02/21');
            this.accountDates.lastReclassificationDate = new Date('2024/12/24');
            break;
            case "0004001000999963631": 
            this.accountDates.paymentDueDate           = new Date('2022/02/05');
            this.accountDates.lastPaymentDate          = new Date('2022/01/05');;
            this.accountDates.lastPurchaseDate         = new Date('2022/12/24');
            this.accountDates.creditLimitDate          = new Date('2021/10/21');
            this.accountDates.lastCreditLimitDate      = new Date('2026/01/21');
            this.accountDates.lastDelinquencyDate      = new Date('2021/02/21');
            this.accountDates.lastReclassificationDate = new Date('2024/12/24');
            break;
            case "0000013000090015624": 
            this.accountDates.paymentDueDate           = new Date('2022/02/05');
            this.accountDates.lastPaymentDate          = new Date('2022/01/05');;
            this.accountDates.lastPurchaseDate         = new Date('2022/12/24');
            this.accountDates.creditLimitDate          = new Date('2021/10/21');
            this.accountDates.lastCreditLimitDate      = new Date('2026/01/21');
            this.accountDates.lastDelinquencyDate      = new Date('2021/02/21');
            this.accountDates.lastReclassificationDate = new Date('2024/12/24');
            break;
          }
          if (this.account) this.account.accountDates = this.accountDates; // newapi: fix for changes made to CreditAccount as part of newAPI
        }

        public getPromoCount(accountNumber?: string ): number {

          if (!accountNumber) {
            accountNumber = this.account?.id;
          }
          if (!accountNumber) {
            return 0;
          }

          let promoVars = {
            promos: [
              {
                accountNumber: '0004178490999963631',
                id: 'P0001',
                name: 'Promo MAIN',
        
                status: 'active',
                date: new Date(),
                desc: 'Insurance Promotion for all accounts',
                note: 'Suggest this to all customers',
                action: 'INS1',
                select: true,
              },
              {
                accountNumber: '0004178490999963631',
                id: 'P0002',
                name: 'Promo 100 Private Label',

                org: '100',
                product: 1,
        
                status: 'active',
                date: new Date(),
                desc: 'Insurance Promotion for all ORG 100 Private Label accounts',
                note: 'Suggest this to new product customers',
                action: 'INS2',
                select: true,
              },
              {
                accountNumber: '0004178490999963631',
                id: 'P0001',
                name: 'New Account Embosser',
        
                status: 'active',
                date: new Date(),
                desc: 'This is a new account. Issue a physical card.',
                note: 'Suggest issuing a new card on this account',
                action: 'EMBO',
                select: false,
              },
              {
                accountNumber: '0000013000090015624',
                id: 'P0003',
                name: 'Promo 200 Gray',

                org: '200',
                product: 2,
        
                status: 'active',
                date: new Date(),
                desc: '',
                note: '',
                select: false,
                action: '',
              },
              {
                accountNumber: '0000013000090015624',
                id: 'P0004',
                name: 'Promo 200 Gray 2',
                org: '200',
                product: 2,
        
                status: 'active',
                date: new Date(),
                desc: '',
                note: '',
                select: false,
                action: '',
              },
              {
                accountNumber: '0000013000090015624',
                id: 'P0005',
                name: 'Promo 200 Gray',
                org: '200',
                product: 2,
        
                status: 'active',
                date: new Date(),
                desc: '',
                note: '',
                select: true,
                action: '',
              }
            ]
          };

          return promoVars.promos.filter(item => (
              (!accountNumber) || item.accountNumber == accountNumber) && 
              item.status == 'active' 
            ).length;
        }        

        public getPromos(accountNumber?: string ): Observable<CreditPromo[]> {

          if (!accountNumber) {
            accountNumber = this.account?.id;
          }
          if (!accountNumber) {
            return of([]);
          }

          let promoVars;
          switch (this.env.tenantId) {
            case "liverpool":
              promoVars = {
                promos: [
                  {
                    accountNumber: '0004178490999963631',
                    id: 'P0001',
                    name: 'Promo MAIN',
            
                    status: 'active',
                    date: new Date(),
                    desc: 'Insurance Promotion for all accounts',
                    note: 'Suggest this to all customers',
                    action: 'INS1',
                    select: true,
                  },
                  {
                    accountNumber: '0004178490999963631',
                    id: 'P0002',
                    name: 'Promo 100 Private Label',
    
                    org: '100',
                    product: 1,
            
                    status: 'active',
                    date: new Date(),
                    desc: 'Insurance Promotion for all ORG 100 Private Label accounts',
                    note: 'Suggest this to new product customers',
                    action: 'INS2',
                    select: true,
                  },
                  {
                    accountNumber: '0004178490999963631',
                    id: 'P0001',
                    name: 'New Account Embosser',
            
                    status: 'active',
                    date: new Date(),
                    desc: 'This is a new account. Issue a physical card.',
                    note: 'Suggest issuing a new card on this account',
                    action: 'EMBO',
                    select: false,
                  },
                  {
                    accountNumber: '0000013000090015624',
                    id: 'P0003',
                    name: 'Promo 200 Gray',
    
                    org: '200',
                    product: 2,
            
                    status: 'active',
                    date: new Date(),
                    desc: '',
                    note: '',
                    select: false,
                    action: '',
                  },
                  {
                    accountNumber: '0000013000090015624',
                    id: 'P0004',
                    name: 'Promo 200 Gray 2',
                    org: '200',
                    product: 2,
            
                    status: 'active',
                    date: new Date(),
                    desc: '',
                    note: '',
                    select: false,
                    action: '',
                  },
                  {
                    accountNumber: '0000013000090015624',
                    id: 'P0005',
                    name: 'Promo 200 Gray',
                    org: '200',
                    product: 2,
            
                    status: 'active',
                    date: new Date(),
                    desc: '',
                    note: '',
                    select: true,
                    action: '',
                  }
                ]
              };
              break;
            case "banorte": 
              promoVars = {
                promos: [
                  {
                    accountNumber: '0004178490999963631',
                    id: 'P0001',
                    name: 'Promo MAIN',
            
                    status: 'active',
                    date: new Date(),
                    desc: 'Insurance Promotion for all accounts',
                    note: 'Suggest this to all customers',
                    action: 'INS1',
                    select: true,
                  },
                  {
                    accountNumber: '0004178490999963631',
                    id: 'P0002',
                    name: 'Promo 100 Private Label',
    
                    org: '100',
                    product: 1,
            
                    status: 'active',
                    date: new Date(),
                    desc: 'Insurance Promotion for all ORG 100 Private Label accounts',
                    note: 'Suggest this to new product customers',
                    action: 'INS2',
                    select: true,
                  },
                  {
                    accountNumber: '0004178490999963631',
                    id: 'P0001',
                    name: 'New Account Embosser',
            
                    status: 'active',
                    date: new Date(),
                    desc: 'This is a new account. Issue a physical card.',
                    note: 'Suggest issuing a new card on this account',
                    action: 'EMB1',
                    select: false,
                  },
                  {
                    accountNumber: '0000013000090015624',
                    id: 'P0003',
                    name: 'Promo 200 Gray',
    
                    org: '200',
                    product: 2,
            
                    status: 'active',
                    date: new Date(),
                    desc: '',
                    note: '',
                    select: false,
                    action: '',
                  },
                  {
                    accountNumber: '0000013000090015624',
                    id: 'P0004',
                    name: 'Promo 200 Gray 2',
                    org: '200',
                    product: 2,
            
                    status: 'active',
                    date: new Date(),
                    desc: '',
                    note: '',
                    select: false,
                    action: '',
                  },
                  {
                    accountNumber: '0000013000090015624',
                    id: 'P0005',
                    name: 'Promo 200 Gray',
                    org: '200',
                    product: 2,
            
                    status: 'active',
                    date: new Date(),
                    desc: '',
                    note: '',
                    select: true,
                    action: '',
                  }
                ]
              };
            break;
            default:
              promoVars = {
                promos: [
                  {
                    accountNumber: '0004178490999963631',
                    id: 'P0001',
                    name: 'Promo MAIN',
            
                    status: 'active',
                    date: new Date(),
                    desc: 'Insurance Promotion for all accounts',
                    note: 'Suggest this to all customers',
                    action: 'INS1',
                    select: true,
                  }
                ]
              };
              break;
          }

        
          return of(promoVars.promos.filter(item => (
              (!accountNumber) || item.accountNumber == accountNumber) && 
              item.status == 'active' 
            ).sort((obj1, obj2) => {
            if (obj1.date > obj2.date) {
                return -1;
            }
            if (obj1.date < obj2.date) {
                return 1;
            }   
            return 0;
          }));
        }

        
        public getAccountBehavioralInfo(account: string) {
          this.accountBehavioralInfo = new AccountBehavioralInfo();
          
          switch(account) {
            case "0004178490999963631":
            this.accountBehavioralInfo.creditClass       = 'M1';
            this.accountBehavioralInfo.appSource         = '743';
            this.accountBehavioralInfo.cb                = '6';
            this.accountBehavioralInfo.monthlyBalance    = '106';
            this.accountBehavioralInfo.usage             = '4';
            this.accountBehavioralInfo.blockCode1        = '';
            this.accountBehavioralInfo.blockCode2        = '';
            this.accountBehavioralInfo.lastCreditClass   = 'M1';
            this.accountBehavioralInfo.miscSource        = '743';
            this.accountBehavioralInfo.numberNsfPayment  = 0;
            this.accountBehavioralInfo.writeOffDay       = 0;
            this.accountBehavioralInfo.monthPurchases    = 33;
            this.accountBehavioralInfo.openDate          = new Date('2024/10/29');
            this.accountBehavioralInfo.storeNumber       = '110';
            this.accountBehavioralInfo.status            = 'ACTIVA';
            break;
            case "0000013000090015624":
            this.accountBehavioralInfo.creditClass       = 'N6';
            this.accountBehavioralInfo.appSource         = '';
            this.accountBehavioralInfo.cb                = '';
            this.accountBehavioralInfo.monthlyBalance    = '1';
            this.accountBehavioralInfo.usage             = '2';
            this.accountBehavioralInfo.blockCode1        = '';
            this.accountBehavioralInfo.blockCode2        = '';
            this.accountBehavioralInfo.lastCreditClass   = 'N6';
            this.accountBehavioralInfo.miscSource        = '';
            this.accountBehavioralInfo.numberNsfPayment  = 0;
            this.accountBehavioralInfo.writeOffDay       = 0;
            this.accountBehavioralInfo.monthPurchases    = 1;
            this.accountBehavioralInfo.openDate          = new Date('2022/01/07');
            this.accountBehavioralInfo.storeNumber       = '079';
            this.accountBehavioralInfo.status            = 'ACTIVA';
            break;
            case "0004008490999988100": 
            this.accountBehavioralInfo.creditClass       = 'M1';
            this.accountBehavioralInfo.appSource         = '743';
            this.accountBehavioralInfo.cb                = '6';
            this.accountBehavioralInfo.monthlyBalance    = '106';
            this.accountBehavioralInfo.usage             = '4';
            this.accountBehavioralInfo.blockCode1        = '';
            this.accountBehavioralInfo.blockCode2        = '';
            this.accountBehavioralInfo.lastCreditClass   = 'M1';
            this.accountBehavioralInfo.miscSource        = '743';
            this.accountBehavioralInfo.numberNsfPayment  = 0;
            this.accountBehavioralInfo.writeOffDay       = 0;
            this.accountBehavioralInfo.monthPurchases    = 33;
            this.accountBehavioralInfo.openDate          = new Date('2024/10/29');
            this.accountBehavioralInfo.storeNumber       = '110';
            this.accountBehavioralInfo.status            = 'ACTIVA';
            break;
            case "0000013000090015624": 
            this.accountBehavioralInfo.creditClass       = 'M1';
            this.accountBehavioralInfo.appSource         = '743';
            this.accountBehavioralInfo.cb                = '6';
            this.accountBehavioralInfo.monthlyBalance    = '106';
            this.accountBehavioralInfo.usage             = '4';
            this.accountBehavioralInfo.blockCode1        = '';
            this.accountBehavioralInfo.blockCode2        = '';
            this.accountBehavioralInfo.lastCreditClass   = 'M1';
            this.accountBehavioralInfo.miscSource        = '743';
            this.accountBehavioralInfo.numberNsfPayment  = 0;
            this.accountBehavioralInfo.writeOffDay       = 0;
            this.accountBehavioralInfo.monthPurchases    = 33;
            this.accountBehavioralInfo.openDate          = new Date('2024/10/29');
            this.accountBehavioralInfo.storeNumber       = '110';
            this.accountBehavioralInfo.status            = 'ACTIVA';
            break;
            case "0004001000999963631": 
            this.accountBehavioralInfo.creditClass       = 'M1';
            this.accountBehavioralInfo.appSource         = '743';
            this.accountBehavioralInfo.cb                = '6';
            this.accountBehavioralInfo.monthlyBalance    = '106';
            this.accountBehavioralInfo.usage             = '4';
            this.accountBehavioralInfo.blockCode1        = '';
            this.accountBehavioralInfo.blockCode2        = '';
            this.accountBehavioralInfo.lastCreditClass   = 'M1';
            this.accountBehavioralInfo.miscSource        = '743';
            this.accountBehavioralInfo.numberNsfPayment  = 0;
            this.accountBehavioralInfo.writeOffDay       = 0;
            this.accountBehavioralInfo.monthPurchases    = 33;
            this.accountBehavioralInfo.openDate          = new Date('2024/10/29');
            this.accountBehavioralInfo.storeNumber       = '110';
            this.accountBehavioralInfo.status            = 'ACTIVA';
            break;
            case "0000013000090015624": 
            this.accountBehavioralInfo.creditClass       = 'M1';
            this.accountBehavioralInfo.appSource         = '743';
            this.accountBehavioralInfo.cb                = '6';
            this.accountBehavioralInfo.monthlyBalance    = '106';
            this.accountBehavioralInfo.usage             = '4';
            this.accountBehavioralInfo.blockCode1        = '';
            this.accountBehavioralInfo.blockCode2        = '';
            this.accountBehavioralInfo.lastCreditClass   = 'M1';
            this.accountBehavioralInfo.miscSource        = '743';
            this.accountBehavioralInfo.numberNsfPayment  = 0;
            this.accountBehavioralInfo.writeOffDay       = 0;
            this.accountBehavioralInfo.monthPurchases    = 33;
            this.accountBehavioralInfo.openDate          = new Date('2024/10/29');
            this.accountBehavioralInfo.storeNumber       = '110';
            this.accountBehavioralInfo.status            = 'ACTIVA';
            break;
          }
          if (this.account) this.account.accountBehavioralInfo = this.accountBehavioralInfo; // newapi: fix for changes made to CreditAccount as part of newAPI
        }
        
        public getAccountDelinquency(account: string) {
          
          switch(account) {
            case "0004178490999963631":
            this.accountDelinquencyRecords = [];
            this.accountDelinquencyRecords.push({ delq: '0'      , amount: 30.00, count: 3 });
            this.accountDelinquencyRecords.push({ delq: '30'     , amount: 110.00, count: 11 });
            this.accountDelinquencyRecords.push({ delq: '60'     , amount: 50.00, count: 5 });
            this.accountDelinquencyRecords.push({ delq: '90'     , amount: 600.00, count: 8 });
            this.accountDelinquencyRecords.push({ delq: '120'    , amount: 180.00, count: 9 });
            this.accountDelinquencyRecords.push({ delq: '150'    , amount: 50.00, count: 6 });
            this.accountDelinquencyRecords.push({ delq: '180'    , amount: 200.00, count: 3 });
            this.accountDelinquencyRecords.push({ delq: '210'    , amount: 50.00, count: 1 });
            break;
            case "0000013000090015624":
            this.accountDelinquencyRecords = [];
            this.accountDelinquencyRecords.push({ delq: '0'      , amount:     0.00, count: 0 });
            this.accountDelinquencyRecords.push({ delq: '30'     , amount:   250.00, count: 1 });
            this.accountDelinquencyRecords.push({ delq: '60'     , amount:   280.00, count: 1 });
            this.accountDelinquencyRecords.push({ delq: '90'     , amount:   280.00, count: 1 });
            this.accountDelinquencyRecords.push({ delq: '120'    , amount:   350.00, count: 2 });
            this.accountDelinquencyRecords.push({ delq: '150'    , amount:   400.00, count: 2 });
            this.accountDelinquencyRecords.push({ delq: '180'    , amount:   400.00, count: 2 });
            this.accountDelinquencyRecords.push({ delq: '210'    , amount:   700.00, count: 3 });
            break;;
            default:
            this.accountDelinquencyRecords = [];
            this.accountDelinquencyRecords.push({ delq: '0'      , amount:   60.00, count: 1 });
            this.accountDelinquencyRecords.push({ delq: '30'     , amount:   0.00, count: 0 });
            this.accountDelinquencyRecords.push({ delq: '60'     , amount:   0.00, count: 0 });
            this.accountDelinquencyRecords.push({ delq: '90'     , amount:   1000.00, count: 1 });
            this.accountDelinquencyRecords.push({ delq: '120'    , amount:    0.00, count: 0 });
            this.accountDelinquencyRecords.push({ delq: '150'    , amount:   500.00, count: 2 });
            this.accountDelinquencyRecords.push({ delq: '180'    , amount:   1350.00, count: 2 });
            this.accountDelinquencyRecords.push({ delq: '210'    , amount:   6700.00, count: 3 });
            break;;    }
            if (this.account) this.account.accountDelinquencyInfo = this.accountDelinquencyRecords; // newapi: fix for changes made to CreditAccount as part of newAPI
          }
          
          public getAccountProfile(account: string) {
            
            switch(account) {
              case "0004178490999963631":
              this.accountProfileRecords = [];
              this.accountProfileRecords.push({ month: '1 ' , status: 1 });
              this.accountProfileRecords.push({ month: '2 ' , status: 1 });
              this.accountProfileRecords.push({ month: '3 ' , status: 1 });
              this.accountProfileRecords.push({ month: '4 ' , status: 1 });
              this.accountProfileRecords.push({ month: '5 ' , status: 1 });
              this.accountProfileRecords.push({ month: '6 ' , status: 1 });
              this.accountProfileRecords.push({ month: '7 ' , status: 1 });
              this.accountProfileRecords.push({ month: '8 ' , status: 1 });
              this.accountProfileRecords.push({ month: '9 ' , status: 0 });
              this.accountProfileRecords.push({ month: '10' , status: 0 });
              this.accountProfileRecords.push({ month: '11' , status: 1 });
              this.accountProfileRecords.push({ month: '12' , status: 2 });
              this.accountProfileRecords.push({ month: '13' , status: 2 });
              this.accountProfileRecords.push({ month: '14' , status: 3 });
              this.accountProfileRecords.push({ month: '15' , status: 3 });
              this.accountProfileRecords.push({ month: '16' , status: 2 });
              this.accountProfileRecords.push({ month: '17' , status: 1 });
              this.accountProfileRecords.push({ month: '18' , status: 1 });
              this.accountProfileRecords.push({ month: '19' , status: 0 });
              this.accountProfileRecords.push({ month: '20' , status: 0 });
              this.accountProfileRecords.push({ month: '21' , status: 0 });
              this.accountProfileRecords.push({ month: '22' , status: 0 });
              this.accountProfileRecords.push({ month: '23' , status: 0 });
              this.accountProfileRecords.push({ month: '24' , status: 0 });
              break;
              case "0000013000090015624":
              this.accountProfileRecords = [];
              this.accountProfileRecords.push({ month: '1 ' , status: 1 });
              this.accountProfileRecords.push({ month: '2 ' , status: 2 });
              this.accountProfileRecords.push({ month: '3 ' , status: 2 });
              this.accountProfileRecords.push({ month: '4 ' , status: 3 });
              this.accountProfileRecords.push({ month: '5 ' , status: 2 });
              this.accountProfileRecords.push({ month: '6 ' , status: 2 });
              this.accountProfileRecords.push({ month: '7 ' , status: 3 });
              this.accountProfileRecords.push({ month: '8 ' , status: 4 });
              this.accountProfileRecords.push({ month: '9 ' , status: 5 });
              this.accountProfileRecords.push({ month: '10' , status: 5 });
              this.accountProfileRecords.push({ month: '11' , status: 5 });
              this.accountProfileRecords.push({ month: '12' , status: 5 });
              this.accountProfileRecords.push({ month: '13' , status: 4 });
              this.accountProfileRecords.push({ month: '14' , status: 4 });
              this.accountProfileRecords.push({ month: '15' , status: 5 });
              this.accountProfileRecords.push({ month: '16' , status: 5 });
              this.accountProfileRecords.push({ month: '17' , status: 3 });
              this.accountProfileRecords.push({ month: '18' , status: 2 });
              this.accountProfileRecords.push({ month: '19' , status: 3 });
              this.accountProfileRecords.push({ month: '20' , status: 4 });
              this.accountProfileRecords.push({ month: '21' , status: 5 });
              this.accountProfileRecords.push({ month: '22' , status: 6 });
              this.accountProfileRecords.push({ month: '23' , status: 7 });
              this.accountProfileRecords.push({ month: '24' , status: 7 });
              break;;
              default:
              this.accountProfileRecords = [];
              this.accountProfileRecords.push({ month: '1 ' , status: 1 });
              this.accountProfileRecords.push({ month: '2 ' , status: 0 });
              this.accountProfileRecords.push({ month: '3 ' , status: 0 });
              this.accountProfileRecords.push({ month: '4 ' , status: 0 });
              this.accountProfileRecords.push({ month: '5 ' , status: 0 });
              this.accountProfileRecords.push({ month: '6 ' , status: 0 });
              this.accountProfileRecords.push({ month: '7 ' , status: 0 });
              this.accountProfileRecords.push({ month: '8 ' , status: 0 });
              this.accountProfileRecords.push({ month: '9 ' , status: 0 });
              this.accountProfileRecords.push({ month: '10' , status: 0 });
              this.accountProfileRecords.push({ month: '11' , status: 0 });
              this.accountProfileRecords.push({ month: '12' , status: 0 });
              this.accountProfileRecords.push({ month: '13' , status: 0 });
              this.accountProfileRecords.push({ month: '14' , status: 0 });
              this.accountProfileRecords.push({ month: '15' , status: 0 });
              this.accountProfileRecords.push({ month: '16' , status: 0 });
              this.accountProfileRecords.push({ month: '17' , status: 0 });
              this.accountProfileRecords.push({ month: '18' , status: 0 });
              this.accountProfileRecords.push({ month: '19' , status: 0 });
              this.accountProfileRecords.push({ month: '20' , status: 0 });
              this.accountProfileRecords.push({ month: '21' , status: 0 });
              this.accountProfileRecords.push({ month: '22' , status: 0 });
              this.accountProfileRecords.push({ month: '23' , status: 0 });
              this.accountProfileRecords.push({ month: '24' , status: 0 });
              break;;
            }
            if (this.account) this.account.accountProfileInfo = this.accountProfileRecords; // newapi: fix for changes made to CreditAccount as part of newAPI
          }
          
          public getAccountPlans(account: string) {
            
            switch(account) {
              case "0004178490999963631":
              this.plansArray = [];
              this.plansArray.push({ id: '20001', accountId: '0004178490999963631', segment: '1', plan: '20001', balance: 8681.30, openDate: new Date('2016/02/20'), active: true, requiredPayment: 1287.74, minimumRequiredPayment: 1287.74, sec: 1 });
              this.plansArray.push({ id: '53524', accountId: '0004178490999963631', segment: '2', plan: '53524', balance:  0.00, openDate: new Date('2019/08/26'), active: true, requiredPayment:  0.00, minimumRequiredPayment: 0.00, sec: 2 });
              break;
              case "0000013000090015624":
              this.plansArray = [];
              this.plansArray.push({ id: '20025', accountId: '0000013000090015624', segment: '1', plan: '20025', balance:  5724.25, openDate: new Date('2022/01/07'), active: true, requiredPayment: 573.00, minimumRequiredPayment: 573.00, sec: 1 });
              break;;
              default:
              this.plansArray = [];
              this.plansArray.push({ id: 'A.PLAN121', accountId: account, segment: '1', plan: '20001', balance:  5724.25, openDate: new Date('2022/01/07'), active: true, requiredPayment: 573.00, minimumRequiredPayment: 573.00, sec: 1 });
              break;;
            }
          }
          
          public getAccountTransactions(account: string) {
            
            // switch(account) {
            //   case "A.ACCT11":
            this.transactionsArray = [];
            let dt = new Date();
            let dt1 = new Date();
            let desc = '';
            let plan = '';
            let amt = 0;
            for(let i = 1; i<100; i=i+1) {
              dt = new Date(this.randomDate(dt, -5)); 
              dt1 = new Date(this.randomDate(dt, -15)); 
              plan = '20001';
              amt = Math.floor(Math.random()*5000)/100;

              switch(Math.floor(Math.random()*12)) {
                case 9 : desc = 'Finance Charge'; break;
                case 10: desc = 'Payment'; amt = amt * -100; plan = '53524'; break;
                case 11: desc = 'Adjustment'; break;
                default: desc = 'Purchase'; break;
              }
              this.transactionsArray.push(
                { 
                  id: '', 
                  postedDate: dt, 
                  effectiveDate: dt1, 
                  amount: amt, 
                  transactionType: '' + Math.floor(5000 + Math.random()*300), 
                  logicModule: Math.floor(Math.random()*99) + '', 
                  planId: plan, 
                  description: desc, 
                  referenceNumber: 'REF0000000' + Math.floor(10000 + Math.random()*10000), 
                  authorizationCode: 'XYZ00' + Math.floor(1000000 + Math.random() * 1000000) }
              );
            }
            //   break;
            //   case "A.ACCT12":
            //     this.plansArray = [];
            //     this.plansArray.push({ id: 'A.PLAN121', accountId: 'A.ACCT12', segment: '1', plan: '20001', balance:  630.45, openDate: new Date('2006/05/12'), active: true, requiredPayment: 320.12, minimumRequiredPayment: 33.00, sec: 1 });
            //     this.plansArray.push({ id: 'A.PLAN122', accountId: 'A.ACCT12', segment: '2', plan: '20003', balance:   90.00, openDate: new Date('2018/11/24'), active: true, requiredPayment:  30.00, minimumRequiredPayment: 44.00, sec: 2 });
            //     this.plansArray.push({ id: 'A.PLAN123', accountId: 'A.ACCT12', segment: '3', plan: '20005', balance:  460.40, openDate: new Date('2019/03/22'), active: true, requiredPayment: 100.12, minimumRequiredPayment: 55.00, sec: 3 });
            //     break;;
            // }
          }
          
          randomDate(start: Date, rangeDays: number ) {
            start.setDate(start.getDate() + Math.floor(Math.random()*rangeDays));
            return start;
          }
          
          public getAcctDelq(): Observable<CreditDelinquencyRecord[]> {
            return of(this.accountDelinquencyRecords);
          }
          
          public getAccountDelqChart(): CreditChartOptions {
            let result: CreditChartOptions = {
              chartDatasets: [],
              chartLabels: [],
            };
            
            result.chartDatasets.push({ data: [], label: 'Amount'});
            result.chartDatasets.push({ data: [], label: 'Count'});
            
            this.accountDelinquencyRecords.forEach( record => {
              result.chartDatasets[0].data.push(record.amount);
              result.chartDatasets[1].data.push(record.count);
              result.chartLabels.push(record.delq);
            })
            
            this.accountDelinquencyChart = result;
            return result; // newapi: fix for changes made to CreditAccount as part of newAPI
          }
          
          public getAccountProfileChart(): CreditChartOptions {
            let result: CreditChartOptions = {
              chartDatasets: [],
              chartLabels: [],
            };
            
            result.chartDatasets.push({ data: [], label: 'Status'});
            
            this.accountProfileRecords.forEach( record => {
              result.chartDatasets[0].data.push(record.status);
              result.chartLabels.push(record.month);
            })
            
            this.accountProfileChart = result;
            return result; // newapi: fix for changes made to CreditAccount as part of newAPI
          }

          private actionERROR = { type: 'error', id: 'idERROR', name: 'acERROR', title: 'ERROR', hint: 'Not Found', hideIfDisabled: true, click: () => { alert('bad action id');return false; }, icon: () => { return 'error';}, disabled: () => { return true;}, favorite: 0 };

          getActionCode(id: string) {
            let actions = this.getActionCodes().filter(item => item.id == id);
            if (actions.length > 0) {
              return actions[0];
            }
            return this.actionERROR;
            
            // filter(item => item.type != undefined && item.type.indexOf(filter.options.type) !== -1);
            
          }
          
          public accountTabSelectedIndex = 0;
          public get ACTION_TAB_INDEX() { return 4; }
          public get INITIAL_TAB_INDEX() { return 0; }

          getActionCodes() {
            
            // let fnClick = () => {if (this.customer!.id == undefined) {alert('bad customer id');return false;}this.router.navigate(['/api/action/view', { id: 'VIEW', cust: this.customer!.id }]);return true;};
            let fnClick = () => {alert("Undefined Action"); return false;};

            let fnSwitchTabToAction = () => { this.accountTabSelectedIndex = this.ACTION_TAB_INDEX; };

            let fnIcon1 = () => { return 'info';};
            let fnIcon2 = () => { return 'build_circle';};
            let fnIcon3 = () => { return 'monetization_on';};
            let fnIcon4 = () => { return 'work';};
            let fnDisabled = () => { return true;};
            let fnNotDisabled = () => { return false;};
            
            let fnCVIEW = () => { fnSwitchTabToAction(); return this.context.addAction('VIEW', 'info'); };
            let fnCACLC = () => { fnSwitchTabToAction(); return this.context.addAction('VIEW', 'info'); };
            let fnCNOTA = () => { fnSwitchTabToAction(); return this.context.addAction('NOTA', 'info'); };
            
            let fnCINS1 = () => { fnSwitchTabToAction(); return this.context.addAction('INS1', fnIcon4()); };

            let tenantActions;
            if (this.env.tenantId == 'liverpool') {
              let fnCEMBO = () => { fnSwitchTabToAction(); return this.context.addAction('EMBO', 'build_circle'); };
              let fnCACTI = () => { fnSwitchTabToAction(); return this.context.addAction('ACTI', 'build_circle'); };
              let fnCDOMI = () => { fnSwitchTabToAction(); return this.context.addAction('DOMI', 'build_circle'); };
              let fnCALCO = () => { fnSwitchTabToAction(); return this.context.addAction('ALCO', 'build_circle'); };
              
              let fnCM409 = () => { fnSwitchTabToAction(); return this.context.addAction('M409', 'monetization_on'); };
              let fnCM456 = () => { fnSwitchTabToAction(); return this.context.addAction('M456', 'monetization_on'); };
  
              tenantActions = 
              [ 
                { type: 'maintenance', id: 'idEMBO', name: 'acEMBO', title: 'EMBO', hint: 'Grabar Tarjeta', hideIfDisabled: true, click: fnCEMBO, icon: fnIcon2, disabled: fnNotDisabled, favorite: 1 },

                { type: 'maintenance', id: 'idACTI', name: 'acACTI', title: 'ACTI', hint: 'Activacion de Tarjeta',            hideIfDisabled: true, click: fnCACTI, icon: fnIcon2, disabled: fnNotDisabled, favorite: 1 },
                { type: 'maintenance', id: 'idALCO', name: 'acALCO', title: 'ALCO', hint: 'Credito al Consumo',               hideIfDisabled: true, click: fnCALCO, icon: fnIcon2, disabled: fnNotDisabled, favorite: 1 },
                { type: 'maintenance', id: 'idDOMI', name: 'acDOMI', title: 'DOMI', hint: 'Actualizacion de Domicilio',       hideIfDisabled: true, click: fnCDOMI, icon: fnIcon2, disabled: fnNotDisabled, favorite: 1 },

                { type: 'monetary', id: 'idM456', name: 'acM456', title: 'M456', hint: 'Cancelacion de Cuota Annual',         hideIfDisabled: true, click: fnCM456, icon: fnIcon3, disabled: fnNotDisabled, favorite: 1, txnType: 'C' },
                { type: 'monetary', id: 'idM409', name: 'acM409', title: 'M409', hint: 'Interes Moratorio',                   hideIfDisabled: true, click: fnCM409, icon: fnIcon3, disabled: fnNotDisabled, favorite: 1, txnType: 'D' },

                { type: 'monetary', id: 'idM354', name: 'acM354', title: 'M354', hint: 'Indemnizacion Cheque Devuelto',           hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'D' },
                { type: 'monetary', id: 'idM356', name: 'acM356', title: 'M356', hint: 'Cuota Annual',                            hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'D' },
                { type: 'monetary', id: 'idM360', name: 'acM360', title: 'M360', hint: 'Comision de Reposicion de Plastico',      hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'D' },
                { type: 'monetary', id: 'idM460', name: 'acM460', title: 'M460', hint: 'Cancelacion Comision Plastico',           hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'C' },
                { type: 'monetary', id: 'idM302', name: 'acM302', title: 'M302', hint: 'Cancelacion Bonificacion por Aclaracion', hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'D' },
                { type: 'monetary', id: 'idM402', name: 'acM402', title: 'M402', hint: 'Bonificacion por Aclaracion',             hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'C' },
                { type: 'monetary', id: 'idM304', name: 'acM304', title: 'M304', hint: 'Traspaso Cargo',                          hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'D' },
                { type: 'monetary', id: 'idM305', name: 'acM305', title: 'M305', hint: 'Interes Financiero',                      hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'D' },
                { type: 'monetary', id: 'idM306', name: 'acM306', title: 'M306', hint: 'Traspaso cargo Reestructura',             hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'D' },
                { type: 'monetary', id: 'idM405', name: 'acM405', title: 'M405', hint: 'Interes Financiero',                      hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'C' },
                { type: 'monetary', id: 'idM406', name: 'acM406', title: 'M406', hint: 'Traspaso Abono Reestructura',             hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'C' },
                { type: 'monetary', id: 'idM309', name: 'acM309', title: 'M309', hint: 'Interes Moratorio',                       hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'D' },
                { type: 'monetary', id: 'idM399', name: 'acM399', title: 'M399', hint: 'Recuperacion de Saldo',                   hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'D' },
                { type: 'monetary', id: 'idM404', name: 'acM404', title: 'M404', hint: 'Trespaso Abono',                          hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'C' },
                { type: 'monetary', id: 'idM427', name: 'acM427', title: 'M427', hint: 'Abono inmediato',                         hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'C' },
                { type: 'monetary', id: 'idM426', name: 'acM426', title: 'M426', hint: 'Gastos de Cobranza',                      hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'C' },
                { type: 'monetary', id: 'idM423', name: 'acM423', title: 'M423', hint: 'Cancelacion de Gastos de Cobranza',       hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'D' },
                { type: 'monetary', id: 'idM350', name: 'acM350', title: 'M350', hint: 'Cancelacion de Pago',                     hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'D' },
                { type: 'monetary', id: 'idM353', name: 'acM353', title: 'M353', hint: 'Cargo saldo reembolso',                   hideIfDisabled: true, click: fnClick, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'D' },
  
              ];
            } else {
              let fnCEMBO = () => { fnSwitchTabToAction(); return this.context.addAction('EMB1', 'build_circle'); };
              let fnCACTI = () => { fnSwitchTabToAction(); return this.context.addAction('ACT1', 'build_circle'); };
              let fnCDOMI = () => { fnSwitchTabToAction(); return this.context.addAction('DOM1', 'build_circle'); };
              let fnCALCO = () => { fnSwitchTabToAction(); return this.context.addAction('ALC1', 'build_circle'); };
              
              let fnCM309 = () => { fnSwitchTabToAction(); return this.context.addAction('M309', 'monetization_on'); };
              let fnCM246 = () => { fnSwitchTabToAction(); return this.context.addAction('M246', 'monetization_on'); };

              let fnCM301 = () => { fnSwitchTabToAction(); return this.context.addAction('M301', 'monetization_on'); };
              let fnCM303 = () => { fnSwitchTabToAction(); return this.context.addAction('M303', 'monetization_on'); };
              let fnCM305 = () => { fnSwitchTabToAction(); return this.context.addAction('M305', 'monetization_on'); };
              let fnCM307 = () => { fnSwitchTabToAction(); return this.context.addAction('M307', 'monetization_on'); };
              let fnCM411 = () => { fnSwitchTabToAction(); return this.context.addAction('M411', 'monetization_on'); };
              let fnCM407 = () => { fnSwitchTabToAction(); return this.context.addAction('M407', 'monetization_on'); };
              let fnCM371 = () => { fnSwitchTabToAction(); return this.context.addAction('M371', 'monetization_on'); };
              let fnCM511 = () => { fnSwitchTabToAction(); return this.context.addAction('M511', 'monetization_on'); };
              let fnCM408 = () => { fnSwitchTabToAction(); return this.context.addAction('M408', 'monetization_on'); };

              tenantActions = 
              [ 
                { type: 'maintenance', id: 'idEMB1', name: 'acEMB1', title: 'EMB1', hint: 'Grabar Tarjeta', hideIfDisabled: true, click: fnCEMBO, icon: fnIcon2, disabled: fnNotDisabled, favorite: 1 },

                { type: 'maintenance', id: 'idACTI', name: 'acACTI', title: 'ACT1', hint: 'Activacion de Tarjeta',            hideIfDisabled: true, click: fnCACTI, icon: fnIcon2, disabled: fnNotDisabled, favorite: 1 },
                { type: 'maintenance', id: 'idALCO', name: 'acALCO', title: 'ALC1', hint: 'Credito al Consumo',               hideIfDisabled: true, click: fnCALCO, icon: fnIcon2, disabled: fnNotDisabled, favorite: 1 },
                { type: 'maintenance', id: 'idDOMI', name: 'acDOMI', title: 'DOM1', hint: 'Actualizacion de Domicilio',       hideIfDisabled: true, click: fnCDOMI, icon: fnIcon2, disabled: fnNotDisabled, favorite: 1 },

                { type: 'monetary', id: 'idM301', name: 'acM301', title: 'M301', hint: 'Vale - On Us',                        hideIfDisabled: true, click: fnCM301, icon: fnIcon3, disabled: fnNotDisabled, favorite: 1, txnType: 'D' },
                { type: 'monetary', id: 'idM303', name: 'acM303', title: 'M303', hint: 'Vale - Off Us',                       hideIfDisabled: true, click: fnCM303, icon: fnIcon3, disabled: fnNotDisabled, favorite: 1, txnType: 'D' },
                { type: 'monetary', id: 'idM305', name: 'acM305', title: 'M305', hint: 'Vale - Auth Especial',                hideIfDisabled: true, click: fnCM305, icon: fnIcon3, disabled: fnNotDisabled, favorite: 0, txnType: 'D' },
                { type: 'monetary', id: 'idM307', name: 'acM307', title: 'M307', hint: 'Vale - VIP',                          hideIfDisabled: true, click: fnCM307, icon: fnIcon3, disabled: fnNotDisabled, favorite: 0, txnType: 'D' },

                { type: 'monetary', id: 'idM309', name: 'acM309', title: 'M309', hint: 'Cancelacion de Cuota Annual',         hideIfDisabled: true, click: fnCM246, icon: fnIcon3, disabled: fnNotDisabled, favorite: 1, txnType: 'C' },
                { type: 'monetary', id: 'idM246', name: 'acM246', title: 'M246', hint: 'Interes Moratorio',                   hideIfDisabled: true, click: fnCM309, icon: fnIcon3, disabled: fnNotDisabled, favorite: 1, txnType: 'D' },

                { type: 'monetary', id: 'idM411', name: 'acM411', title: 'M411', hint: 'Interes Financiero',                      hideIfDisabled: true, click: fnCM411, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'C' },
                { type: 'monetary', id: 'idM407', name: 'acM407', title: 'M407', hint: 'Traspaso Abono Reestructura',             hideIfDisabled: true, click: fnCM407, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'C' },
                { type: 'monetary', id: 'idM371', name: 'acM371', title: 'M371', hint: 'Interes Moratorio',                       hideIfDisabled: true, click: fnCM371, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'D' },
                { type: 'monetary', id: 'idM511', name: 'acM511', title: 'M511', hint: 'Recuperacion de Saldo',                   hideIfDisabled: true, click: fnCM511, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'D' },
                { type: 'monetary', id: 'idM408', name: 'acM408', title: 'M408', hint: 'Trespaso Abono',                          hideIfDisabled: true, click: fnCM408, icon: fnIcon3, disabled: fnDisabled   , favorite: 0, txnType: 'C' },
              ];
            }

            return [
              this.actionERROR,
              ...tenantActions,
              { type: 'view', id: 'idNOTA', name: 'acNOTA', title: 'NOTA', hint: 'Nota Informativa',                        hideIfDisabled: true, click: fnCNOTA, icon: fnIcon1, disabled: fnNotDisabled, favorite: 1 },
              { type: 'view', id: 'idACLC', name: 'acACLC', title: 'ACLC', hint: 'Consulta de Aclaracion',                  hideIfDisabled: true, click: fnCACLC, icon: fnIcon1, disabled: fnDisabled   , favorite: 0 },
              { type: 'view', id: 'idACUM', name: 'acACUM', title: 'ACUM', hint: 'Consulta de Movimientos Acumulados',      hideIfDisabled: true, click: fnClick, icon: fnIcon1, disabled: fnDisabled   , favorite: 0 },
              { type: 'view', id: 'idCADI', name: 'acCADI', title: 'CADI', hint: 'Consulta de Datos de los Adicionales',    hideIfDisabled: true, click: fnClick, icon: fnIcon1, disabled: fnDisabled   , favorite: 0 },
              { type: 'view', id: 'idCINC', name: 'acCINC', title: 'CINC', hint: 'Limite de Credito pre-autorizado',        hideIfDisabled: true, click: fnClick, icon: fnIcon1, disabled: fnDisabled   , favorite: 0 },
              { type: 'view', id: 'idCORT', name: 'acCORT', title: 'CORT', hint: 'Fecha de Corte',                          hideIfDisabled: true, click: fnClick, icon: fnIcon1, disabled: fnDisabled   , favorite: 0 },
              { type: 'view', id: 'idESTA', name: 'acESTA', title: 'ESTA', hint: 'Estado de Cuenta',                        hideIfDisabled: true, click: fnClick, icon: fnIcon1, disabled: fnDisabled   , favorite: 0 },
              { type: 'view', id: 'idCNIP', name: 'acCNIP', title: 'CNIP', hint: 'Historico de NIP',                        hideIfDisabled: true, click: fnClick, icon: fnIcon1, disabled: fnDisabled   , favorite: 0 },
              { type: 'view', id: 'idNUMA', name: 'acNUMA', title: 'NUMA', hint: 'Numero Anterior',                         hideIfDisabled: true, click: fnClick, icon: fnIcon1, disabled: fnDisabled   , favorite: 0 },
              { type: 'view', id: 'idSALC', name: 'acSALC', title: 'SALC', hint: 'Saldo De Cuenta',                         hideIfDisabled: true, click: fnClick, icon: fnIcon1, disabled: fnDisabled   , favorite: 0 },
              { type: 'view', id: 'idSALP', name: 'acSALP', title: 'SALP', hint: 'Saldo Por Plan',                          hideIfDisabled: true, click: fnClick, icon: fnIcon1, disabled: fnDisabled   , favorite: 0 },
              { type: 'view', id: 'idARCS', name: 'acARCS', title: 'ARCS', hint: 'Consulta de Servicio',                    hideIfDisabled: true, click: fnClick, icon: fnIcon1, disabled: fnDisabled   , favorite: 0 },
              { type: 'view', id: 'idSPRE', name: 'acSPRE', title: 'SPRE', hint: 'Saldo de Puntos LPC',                     hideIfDisabled: true, click: fnClick, icon: fnIcon1, disabled: fnDisabled   , favorite: 0 },
              { type: 'view', id: 'idSEGU', name: 'acSEGU', title: 'SEGU', hint: 'Consulta del Estatus del Seguro',         hideIfDisabled: true, click: fnClick, icon: fnIcon1, disabled: fnDisabled   , favorite: 0 },
              { type: 'view', id: 'idULMO', name: 'acULMO', title: 'ULMO', hint: 'Ultimos Movimientos',                     hideIfDisabled: true, click: fnClick, icon: fnIcon1, disabled: fnDisabled   , favorite: 0 },
              { type: 'view', id: 'idUSUA', name: 'acUSUA', title: 'USUA', hint: 'Usuarios',                                hideIfDisabled: true, click: fnClick, icon: fnIcon1, disabled: fnDisabled   , favorite: 0 },
              
              { type: 'maintenance', id: 'idACTU', name: 'acACTU', title: 'ACTU', hint: 'Actualizacion',                    hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idALTA', name: 'acALTA', title: 'ALTA', hint: 'Alta de Usuario',                  hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idBLOQ', name: 'acBLOQ', title: 'BLOQ', hint: 'Bloqueo',                          hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idCORR', name: 'acCORR', title: 'CORR', hint: 'Correccion',                       hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idCARA', name: 'acCARA', title: 'CARA', hint: 'Caratula de Contrato',             hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idDECR', name: 'acDECR', title: 'DECR', hint: 'Decremento',                       hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idTARJ', name: 'acTARJ', title: 'TARJ', hint: 'Grabar Tarjeta',                   hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idDEMO', name: 'acDEMO', title: 'DEMO', hint: 'Mantenimiento datos Demograficos', hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idMANT', name: 'acMANT', title: 'MANT', hint: 'Mantenimiento',                    hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idMNOM', name: 'acMNOM', title: 'MNOM', hint: 'Actualizacion de Nombre',          hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idLCMC', name: 'acLCMC', title: 'LCMC', hint: 'Rechazo de Incremento de LC',      hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idSNIP', name: 'acSNIP', title: 'SNIP', hint: 'Servicios NIP',                    hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idQUEJ', name: 'acQUEJ', title: 'QUEJ', hint: 'Consulta de Queja',                hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idREPO', name: 'acREPO', title: 'REPO', hint: 'Reposicion de Tarjeta',            hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idINAU', name: 'acINAU', title: 'INAU', hint: 'Incremento de linea de Credito',   hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idTLIM', name: 'acTLIM', title: 'TLIM', hint: 'Transferir Limite de Credito',     hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idTPRO', name: 'acTPRO', title: 'TPRO', hint: 'Tarjeta Provisional',              hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idTRAC', name: 'acTRAC', title: 'TRAC', hint: 'Traspaso de Cuenta',               hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              { type: 'maintenance', id: 'idVALE', name: 'acVALE', title: 'VALE', hint: 'Vale de Compra del Dia',           hideIfDisabled: true, click: fnClick, icon: fnIcon2, disabled: fnDisabled   , favorite: 0 },
              
              { type: 'monetary', id: 'idNOTA', name: 'acNOTA', title: 'NOTA', hint: 'Nota Informativa',                        hideIfDisabled: true, click: fnCNOTA, icon: fnIcon1, disabled: fnNotDisabled, favorite: 1 },

              { type: 'other', id: 'idINS1', name: 'acINS1', title: 'INS1', hint: 'Insurance 1',                                hideIfDisabled: true, click: fnCINS1, icon: fnIcon4, disabled: fnNotDisabled, favorite: 0 },
              { type: 'other', id: 'idINS2', name: 'acINS2', title: 'INS2', hint: 'Insurance 2',                                hideIfDisabled: true, click: fnCINS1, icon: fnIcon4, disabled: fnNotDisabled, favorite: 0 },

            ];        
          }  
          
          testDummy(locate: CreditLocateReq = {
            accountNumber: '0',
            cardNumber: '',
            rfc: '',
            clabeSpei: '',
            contactEmail: '',
            phone: '',
            
            // searchId: 'DUMMY',
            // searchProvider: 'LOCAL'
          }) : CreditLocateResp
          {
            let response: CreditLocateResp = {
              clients: [],

            };
            let pd = new Date();
            pd.setDate(pd.getDate() + 10);
            
            
            if (locate.accountNumber == '999') {
              response.clients = [];
            } else {
              response.clients = 
              [
                {
                  id: '100030021',
                  firstName: 'EMYLENE VANESSA',
                  lastNamePaternal: 'PIÑEDA',
                  lastNameMaternal: 'DOMINGUEZ',  
                  customers: 
                  [
                    {
                      id: '0001000000015061250',
                      firstName: 'EMYLENE VANESSA',
                      lastName: 'PIÑEDA',
                      
                      lastNameMaternal     : "DOMINGUEZ",
                      contactStreet        : this.env.tenantAddress[0].street1,
                      contactPostalCode    : this.env.tenantAddress[0].zip,
                      contactSuburb        : this.env.tenantAddress[0].suburb,
                      contactCity          : this.env.tenantAddress[0].metro,
                      contactState         : this.env.tenantAddress[0].state,
                      contactCountry       : this.env.tenantAddress[0].country,
                      contactEmail         : "PINEDA.e.v@"+this.env.tenantEmailSuffix,
                      contactTelephoneHome : "+52 55 732 9664",
                      contactTelephoneCell : "+52 81 266 9377",
                      workStreet           : "Contadero",
                      workSuburb           : "Cuajimalpa",
                      workCity             : "Mexico City",
                      workState            : "D.F",
                      workCountry          : "Mexico",
                      workTelephone        : "+52 55 5268 3000",
                      references           : [ {
                        refName            : "Jerry Seinfeld",
                        refRelationship    : "Hermano",
                        refTelephone       : "+1 818 684 4321",
                        refDate            : new Date('Jan 13, 1998'),
                      },{
                        refName            : "Newman",
                        refRelationship    : "Primo",
                        refTelephone       : "+1 818 684 3102",
                        refDate            : new Date('Dec 15, 2019'),
                      },{
                        refName            : "Elaine Benes",
                        refRelationship    : "",
                        refTelephone       : "+1 818 684 9932",
                      } ],
                      dateOfBirth           : new Date('June 28, 1968'),
                      
                      accounts: 
                      [
                        {
                          id: '0004178490999963631',
                          customerId: '0001000000015061250',
                          balance: 1040.22,
                          otb: 367.38,
                          
                          org       : '100',
                          logo      : '110',
                          minimo    : 100.00,
                          vencido   : 200.00,
                          status    : 'A',
                          bc1       : 'N',
                          bc2       : 'U',
                          ifree     : 2000,
                          refinance : 300,
                          payDate   : pd,
                          imgCard   : "./assets/acct1.jpg",
                          shortName : "EMYLENE PIÑEDA",
                          cycleDue  : 100.34,
                          
                          memoBalance   : 321.00,
                          debitBalance  : 321.10,
                          creditBalance : 321.20,
                          memoPay       : 321.30,
                          
                          userData           : '00000000000000000000',
                          relationshipNumber : '00000000000000000000',
                          employeeCode       : 'EX',
                          blockCode1         : 'N',
                          blockReason1       : 'T',
                          blockCode2         : '',
                          blockReason2       : '',
                          vip                : 'N',
                          flexBill           : 'Y',
                          cardScheme         : '2',
                          displayRequest     : 'N',
                          billLevel         : '1',
                          billCycle         : '2',
                          statementFrequency : '01',
                          statementRequest   : 'C',
                          statementFlag      : '',
                          returnMail         : '00 00 00',
                          owner              : '1',
                          chargeOffStatus    : '0',
                          letterRequest      : 'D10',
                          memoBillCurrency   : '',
                          customerStatement  : '0',
                          letter             : '',
                          collectCardRequest : 'N',
                          reissueScheme      : '2',
                          permCollector      : 'XXX',
                          restructureFlag    : 'N',
                          collateralCode     : 'XX',
                          
                          dateBlock1               : new Date('01/02/2022'),
                          dateBlock2               : new Date('01/03/2022'),
                          dateLastMaint            : new Date('01/04/2022'),
                          dateLastCycle            : new Date('01/05/2022'),
                          dateNextStatement        : new Date('01/06/2022'),
                          dateNotificationReceived : new Date('01/07/2022'),
                          days                     : 25,
                          daysCardExpiry           : new Date('01/09/2022'),
                          dateExpiry               : new Date('01/10/2022'),
                          dateCardFee              : new Date('01/11/2022'),
                          dateApplied              : new Date('01/12/2022'),
                          dateOpened               : new Date('01/13/2022'),
                          dateClosed               : new Date('01/14/2022'),
                          incomeTH                 : 3251642,
                          highBalance              : 43276.22,
                          creditLimit              : 15000,                  
                          
                          cards: 
                          [
                            {
                              id: '0004178490999963632',
                              cardNumber: '0004178490999963632',
                              expiryDate: '12/03',
                              embossedName: 'E.V. PIÑEDA DOMINGUEZ 1',
                              accountId: '0004178490999963631',
                              cardProduct: 1,
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '', 
                            },
                            {
                              id: '0004178490999963633',
                              cardNumber: '0004178490999963633',
                              expiryDate: '12/05',
                              embossedName: 'E.V. PIÑEDA DOMINGUEZ 2',
                              accountId: '0004178490999963631',
                              cardProduct: 4,
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            },
                            {
                              id: '0004178490999963634',
                              cardNumber: '0004178490999963634',
                              expiryDate: '10/09',
                              embossedName: 'E.V. PIÑEDA DOMINGUEZ 3',
                              accountId: '0004178490999963631',
                              cardProduct: 3,
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            },
                            {
                              id: '0004178490999963635',
                              cardNumber: '0004178490999963635',
                              expiryDate: '10/14',
                              embossedName: 'E.V. PIÑEDA DOMINGUEZ 4',
                              accountId: '0004178490999963631',
                              cardProduct: 3,

                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            },
                            {
                              id: '0004178490999963636',
                              cardNumber: '0004178490999963636',
                              expiryDate: '08/25',
                              embossedName: 'E.V. PIÑEDA DOMINGUEZ 5',
                              accountId: '0004178490999963631',
                              cardProduct: 3,

                              
                              blockCode: '',
                              blockCodeDesc: '',
                              st: 'A',
                              stDesc: 'ACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            },
                            {
                              id: '0004178490999963637',
                              cardNumber: '0004178490999963637',
                              expiryDate: '08/26',
                              embossedName: 'E.V. PIÑEDA DOMINGUEZ 6',
                              accountId: '0004178490999963631',
                              cardProduct: 1,

                              
                              blockCode: '',
                              blockCodeDesc: '',
                              st: 'N',
                              stDesc: 'NUEVO',
                              gp: '0',
                              gpDesc: '',
                              tg: '1',
                              tgDesc: '',                       
                            },
                            // {
                            //   id: '0004178490999963640',
                            //   cardNumber: '0004178490999963640',
                            //   expiryDate: '08/26',
                            //   embossedName: 'E.V. PIÑEDA DOMINGUEZ 7',
                            //   accountId: '0004178490999963631',
                            //   cardProduct: 1,

                              
                            //   blockCode: '',
                            //   blockCodeDesc: '',
                            //   st: 'N',
                            //   stDesc: 'NUEVO',
                            //   gp: '0',
                            //   gpDesc: '',
                            //   tg: '1',
                            //   tgDesc: '',                       
                            // },
                            // {
                            //   id: '0004178490999963658',
                            //   cardNumber: '0004178490999963658',
                            //   expiryDate: '10/26',
                            //   embossedName: 'E.V. PIÑEDA DOMINGUEZ 8',
                            //   accountId: '0004178490999963631',
                            //   cardProduct: 1,

                              
                            //   blockCode: '',
                            //   blockCodeDesc: '',
                            //   st: 'N',
                            //   stDesc: 'NUEVO',
                            //   gp: '0',
                            //   gpDesc: '',
                            //   tg: '1',
                            //   tgDesc: '',                       
                            // }
                          ]
                        },
                        {
                          id: '0000013000090015624',
                          customerId: '0002000000015061250',
                          balance: 10220.00,
                          otb: 543.38,
                          
                          org       : '200',
                          logo      : '360',
                          minimo    : 120.00,
                          vencido   : 220.00,
                          status    : 'A',
                          bc1       : 'N',
                          bc2       : 'U',
                          ifree     : 2200,
                          refinance : 320,
                          payDate   : pd,
                          imgCard   : "./assets/acct2.jpg",
                          shortName : "EMYLENE PIÑEDA",
                          cycleDue  : 309.00,
                          
                          memoBalance   : 322.00,
                          debitBalance  : 322.10,
                          creditBalance : 322.20,
                          memoPay       : 322.30,
                          
                          userData           : '00000000000000000000',
                          relationshipNumber : '00000000000000000000',
                          employeeCode       : 'EM',
                          blockCode1         : '',
                          blockReason1       : '',
                          blockCode2         : '',
                          blockReason2       : '',
                          vip                : 'N',
                          flexBill           : 'N',
                          cardScheme         : '2',
                          displayRequest     : 'N',
                          billLevel         : '1',
                          billCycle         : '5',
                          statementFrequency : '01',
                          statementRequest   : 'C',
                          statementFlag      : 'T',
                          returnMail         : '00 00 00',
                          owner              : '1',
                          chargeOffStatus    : '0',
                          letterRequest      : 'F01',
                          memoBillCurrency   : '',
                          customerStatement  : '0',
                          letter             : '',
                          collectCardRequest : 'N',
                          reissueScheme      : '2',
                          permCollector      : 'XXX',
                          restructureFlag    : 'N',
                          collateralCode     : 'XX',
                          
                          dateBlock1               : new Date('02/12/2022'),
                          dateBlock2               : new Date('02/13/2022'),
                          dateLastMaint            : new Date('02/14/2022'),
                          dateLastCycle            : new Date('02/15/2022'),
                          dateNextStatement        : new Date('02/16/2022'),
                          dateNotificationReceived : new Date('02/17/2022'),
                          days                     : 25,
                          daysCardExpiry           : new Date('02/09/2022'),
                          dateExpiry               : new Date('02/10/2022'),
                          dateCardFee              : new Date('02/11/2022'),
                          dateApplied              : new Date('02/12/2022'),
                          dateOpened               : new Date('02/13/2022'),
                          dateClosed               : new Date('02/14/2022'),
                          incomeTH                 : 321931,
                          highBalance              : 9500.11,
                          creditLimit              : 30000,                                    
                          cards: 
                          [
                            {
                              id: '0001300009001562401',
                              cardNumber: '0001300009001562401',
                              expiryDate: '07/24',
                              embossedName: 'MARIA RAMOS REYES',
                              accountId: '0000013000090015624',
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            },
                            {
                              id: '0001300009001562402',
                              cardNumber: '0001300009001562402',
                              expiryDate: '10/09',
                              embossedName: 'JOAQUIN LORA ',
                              accountId: '0000013000090015624',
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            },
                            {
                              id: '0001300009001562403',
                              cardNumber: '0001300009001562403',
                              expiryDate: '08/25',
                              embossedName: 'CARLOS GODOY',
                              accountId: '0000013000090015624',
                              
                              blockCode: '',
                              blockCodeDesc: '',
                              st: 'A',
                              stDesc: 'ACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            }
                            
                          ]
                        },
                        {
                          id: '0000013044090070037',
                          customerId: '0001000000015061250',
                          balance: 10220.00,
                          otb: 543.38,
                          
                          org       : '100',
                          logo      : '110',
                          minimo    : 120.00,
                          vencido   : 220.00,
                          status    : 'A',
                          bc1       : 'N',
                          bc2       : 'U',
                          ifree     : 2200,
                          refinance : 320,
                          payDate   : pd,
                          imgCard   : "./assets/acct2.jpg",
                          
                          memoBalance   : 322.00,
                          debitBalance  : 322.10,
                          creditBalance : 322.20,
                          memoPay       : 322.30,
                          
                          cards: 
                          [
                            {
                              id: '0001300009001562401',
                              cardNumber: '0001300009001562401',
                              expiryDate: '07/24',
                              embossedName: 'SANTOS PEDRO SANCHEZ',
                              accountId: '0000013044090070037',
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            },
                            {
                              id: '0001300009001562402',
                              cardNumber: '0001300009001562402',
                              expiryDate: '10/09',
                              embossedName: 'EMILE PEÃÂA',
                              accountId: '0000013044090070037',
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            },
                            {
                              id: '0001350009001562403',
                              cardNumber: '0001350009001562403',
                              expiryDate: '08/25',
                              embossedName: 'PETER SANCHEZ',
                              accountId: '0000013044090070037',
                              
                              blockCode: '',
                              blockCodeDesc: '',
                              st: 'A',
                              stDesc: 'ACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            }
                            
                          ]
                        }                        
                      ]                  
                    },
                    {
                      id: '0006000000015028858',
                      firstName: 'EMYLENE VANESSA',
                      lastName: 'PIÑEDA',
                      
                      lastNameMaternal     : "DOMINGUEZ",
                      contactStreet        : this.env.tenantAddress[0].street1,
                      contactPostalCode    : this.env.tenantAddress[0].zip,
                      contactSuburb        : this.env.tenantAddress[0].suburb,
                      contactCity          : this.env.tenantAddress[0].metro,
                      contactState         : this.env.tenantAddress[0].state,
                      contactCountry       : this.env.tenantAddress[0].country,
                      contactEmail         : "PIÑEDA.e.v@"+this.env.tenantEmailSuffix,
                      contactTelephoneHome : "+52 55 732 9664",
                      contactTelephoneCell : "+52 81 266 9377",
                      workStreet           : "Contadero",
                      workSuburb           : "Cuajimalpa",
                      workCity             : "Mexico City",
                      workState            : "D.F",
                      workCountry          : "Mexico",
                      workTelephone        : "+52 55 5268 3000",
                      references           : [ {
                        refName            : "Jerry Seinfeld",
                        refRelationship    : "Hermano",
                        refTelephone       : "+1 818 684 4321",
                        refDate            : new Date('Jan 13, 1998'),
                      },{
                        refName            : "Newman",
                        refRelationship    : "Primo",
                        refTelephone       : "+1 818 684 3102",
                        refDate            : new Date('Dec 15, 2019'),
                      },{
                        refName            : "Elaine Benes",
                        refRelationship    : "",
                        refTelephone       : "+1 818 684 9932",
                      } ],
                      dateOfBirth           : new Date('June 28, 1968'),
                      
                      accounts: 
                      [
                        {
                          id: '0006548498399701284',
                          customerId: '0006000000015028858',
                          balance: 1040.22,
                          otb: 367.38,
                          
                          org       : '100',
                          logo      : '110',
                          minimo    : 100.00,
                          vencido   : 200.00,
                          status    : 'A',
                          bc1       : 'N',
                          bc2       : 'U',
                          ifree     : 2000,
                          refinance : 300,
                          payDate   : pd,
                          imgCard   : "./assets/acct1.jpg",
                          shortName : "EMYLENE PIÑEDA",
                          cycleDue  : 100.34,
                          
                          memoBalance   : 321.00,
                          debitBalance  : 321.10,
                          creditBalance : 321.20,
                          memoPay       : 321.30,
                          
                          userData           : '00000000000000000000',
                          relationshipNumber : '00000000000000000000',
                          employeeCode       : 'EX',
                          blockCode1         : 'A',
                          blockReason1       : 'T',
                          blockCode2         : 'A',
                          blockReason2       : 'X',
                          vip                : 'N',
                          flexBill           : 'Y',
                          cardScheme         : '2',
                          displayRequest     : 'N',
                          billLevel         : '1',
                          billCycle         : '2',
                          statementFrequency : '01',
                          statementRequest   : 'C',
                          statementFlag      : '',
                          returnMail         : '00 00 00',
                          owner              : '1',
                          chargeOffStatus    : '0',
                          letterRequest      : 'D10',
                          memoBillCurrency   : '',
                          customerStatement  : '0',
                          letter             : '',
                          collectCardRequest : 'N',
                          reissueScheme      : '2',
                          permCollector      : 'XXX',
                          restructureFlag    : 'N',
                          collateralCode     : 'XX',
                          
                          dateBlock1               : new Date('01/02/2022'),
                          dateBlock2               : new Date('01/03/2022'),
                          dateLastMaint            : new Date('01/04/2022'),
                          dateLastCycle            : new Date('01/05/2022'),
                          dateNextStatement        : new Date('01/06/2022'),
                          dateNotificationReceived : new Date('01/07/2022'),
                          days                     : 25,
                          daysCardExpiry           : new Date('01/09/2022'),
                          dateExpiry               : new Date('01/10/2022'),
                          dateCardFee              : new Date('01/11/2022'),
                          dateApplied              : new Date('01/12/2022'),
                          dateOpened               : new Date('01/13/2022'),
                          dateClosed               : new Date('01/14/2022'),
                          incomeTH                 : 3251642,
                          highBalance              : 43276.22,
                          creditLimit              : 15000,                  
                          
                          cards: 
                          [
                            {
                              id: '0006354194999963632',
                              cardNumber: '0006354194999963632',
                              expiryDate: '12/03',
                              embossedName: 'E.V. PIÑEDA DOMINGUEZ 1',
                              accountId: '0006548498399701284',
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '', 
                            },
                            {
                              id: '0006354194999963633',
                              cardNumber: '0006354194999963633',
                              expiryDate: '12/05',
                              embossedName: 'E.V. PIÑEDA DOMINGUEZ 2',
                              accountId: '0006548498399701284',
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            },
                            {
                              id: '0006354194999963634',
                              cardNumber: '0006354194999963634',
                              expiryDate: '10/09',
                              embossedName: 'E.V. PIÑEDA DOMINGUEZ 3',
                              accountId: '0006548498399701284',
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            }
                          ]
                        }
                      ]                  
                    }                                
                  ]
                },
                
                {
                  id: '100036109',
                  firstName: 'GUILLERMO',
                  lastNamePaternal: 'HERRERA ',
                  lastNameMaternal: 'SANCHEZ',  
                  customers: 
                  [
                    {
                      id: '0002000000015061250',
                      firstName: 'GUILLERMO',
                      lastName: 'HERRERA',
                      
                      lastNameMaternal     : "SANCHEZ",
                      contactStreet        : this.env.tenantAddress[1].street1,
                      contactPostalCode    : this.env.tenantAddress[1].zip,
                      contactSuburb        : this.env.tenantAddress[1].suburb,
                      contactCity          : this.env.tenantAddress[1].metro,
                      contactState         : this.env.tenantAddress[1].state,
                      contactCountry       : this.env.tenantAddress[1].country,
                      contactEmail         : "HERRERA.g@"+this.env.tenantEmailSuffix,
                      contactTelephoneHome : "+52 55 732 3939",
                      contactTelephoneCell : "+52 81 155 2310",
                      workStreet           : "Contadero",
                      workSuburb           : "Cuajimalpa",
                      workCity             : "Mexico City",
                      workState            : "D.F",
                      workCountry          : "Mexico",
                      workTelephone        : "+52 55 5268 3000",
                      references           : [ {
                        refName            : "Jack Nicholson",
                        refRelationship    : "Hermano",
                        refTelephone       : "+1 818 684 4321",
                        refDate            : new Date('Jan 13, 1998'),
                      },{
                        refName            : "Terry",
                        refRelationship    : "Primo",
                        refTelephone       : "+1 818 684 3102",
                        refDate            : new Date('Dec 15, 2019'),
                      },{
                        refName            : "Jim Beam",
                        refRelationship    : "",
                        refTelephone       : "+1 818 684 9932",
                      } ],
                      dateOfBirth           : new Date('April 3, 1981'),
                      
                      accounts: 
                      [
                        {
                          id: '0004008490999988100',
                          customerId: '0002000000015061250',
                          balance: 1040.22,
                          otb: 367.38,
                          
                          org       : '100',
                          logo      : '110',
                          minimo    : 100.00,
                          vencido   : 200.00,
                          status    : 'A',
                          bc1       : 'N',
                          bc2       : 'U',
                          ifree     : 2000,
                          refinance : 300,
                          payDate   : pd,
                          imgCard   : "./assets/acct1.jpg",
                          shortName : "GUILLERMO HERRERA",
                          cycleDue  : 100.34,
                          
                          memoBalance   : 321.00,
                          debitBalance  : 321.10,
                          creditBalance : 321.20,
                          memoPay       : 321.30,
                          
                          userData           : '00000000000000000000',
                          relationshipNumber : '00000000000000000000',
                          employeeCode       : 'EX',
                          blockCode1         : 'A',
                          blockReason1       : 'T',
                          blockCode2         : 'A',
                          blockReason2       : 'X',
                          vip                : 'N',
                          flexBill           : 'Y',
                          cardScheme         : '2',
                          displayRequest     : 'N',
                          billLevel         : '1',
                          billCycle         : '2',
                          statementFrequency : '01',
                          statementRequest   : 'C',
                          statementFlag      : '',
                          returnMail         : '00 00 00',
                          owner              : '1',
                          chargeOffStatus    : '0',
                          letterRequest      : 'D10',
                          memoBillCurrency   : '',
                          customerStatement  : '0',
                          letter             : '',
                          collectCardRequest : 'N',
                          reissueScheme      : '2',
                          permCollector      : 'XXX',
                          restructureFlag    : 'N',
                          collateralCode     : 'XX',
                          
                          dateBlock1               : new Date('01/02/2022'),
                          dateBlock2               : new Date('01/03/2022'),
                          dateLastMaint            : new Date('01/04/2022'),
                          dateLastCycle            : new Date('01/05/2022'),
                          dateNextStatement        : new Date('01/06/2022'),
                          dateNotificationReceived : new Date('01/07/2022'),
                          days                     : 25,
                          daysCardExpiry           : new Date('01/09/2022'),
                          dateExpiry               : new Date('01/10/2022'),
                          dateCardFee              : new Date('01/11/2022'),
                          dateApplied              : new Date('01/12/2022'),
                          dateOpened               : new Date('01/13/2022'),
                          dateClosed               : new Date('01/14/2022'),
                          incomeTH                 : 3251642,
                          highBalance              : 43276.22,
                          creditLimit              : 15000,                  
                          
                          cards: 
                          [
                            {
                              id: '0004008490999963632',
                              cardNumber: '0004008490999963632',
                              expiryDate: '12/03',
                              embossedName: 'G. HERRERA SANCHEZ 1',
                              accountId: '0004008490999988100',
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '', 
                            },
                            {
                              id: '0004008490999963633',
                              cardNumber: '0004008490999963633',
                              expiryDate: '12/05',
                              embossedName: 'G. HERRERA SANCHEZ 2',
                              accountId: '0004008490999988100',
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            },
                            {
                              id: '0004008490999963634',
                              cardNumber: '0004008490999963634',
                              expiryDate: '10/09',
                              embossedName: 'G. HERRERA SANCHEZ 3',
                              accountId: '0004008490999988100',
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            },
                            {
                              id: '0004008490999963635',
                              cardNumber: '0004008490999963635',
                              expiryDate: '10/14',
                              embossedName: 'G. HERRERA SANCHEZ 4',
                              accountId: '0004008490999988100',
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            }
                          ]
                        }
                      ]                  
                    },
                    {
                      id: '0002000000015061291',
                      firstName: 'GUILLERMO',
                      lastName: 'HERRERA',
                      
                      lastNameMaternal     : "SANCHEZ",
                      contactStreet        : this.env.tenantAddress[1].street1,
                      contactPostalCode    : this.env.tenantAddress[1].zip,
                      contactSuburb        : this.env.tenantAddress[1].suburb,
                      contactCity          : this.env.tenantAddress[1].metro,
                      contactState         : this.env.tenantAddress[1].state,
                      contactCountry       : this.env.tenantAddress[1].country,
                      contactEmail         : "HERRERA.g@"+this.env.tenantEmailSuffix,
                      contactTelephoneHome : "+52 55 732 3939",
                      contactTelephoneCell : "+52 81 155 2310",
                      workStreet           : "Contadero",
                      workSuburb           : "Cuajimalpa",
                      workCity             : "Mexico City",
                      workState            : "D.F",
                      workCountry          : "Mexico",
                      workTelephone        : "+52 55 5268 3000",
                      references           : [ {
                        refName            : "Jack Nicholson",
                        refRelationship    : "Hermano",
                        refTelephone       : "+1 818 684 4321",
                        refDate            : new Date('Jan 13, 1998'),
                      },{
                        refName            : "Terry",
                        refRelationship    : "Primo",
                        refTelephone       : "+1 818 684 3102",
                        refDate            : new Date('Dec 15, 2019'),
                      },{
                        refName            : "Jim Beam",
                        refRelationship    : "",
                        refTelephone       : "+1 818 684 9932",
                      } ],
                      dateOfBirth           : new Date('April 3, 1981'),
                      
                      accounts: 
                      [
                        {
                          id: '0004001000999963631',
                          customerId: '0002000000015061291',
                          balance: 1040.22,
                          otb: 367.38,
                          
                          org       : '100',
                          logo      : '110',
                          minimo    : 100.00,
                          vencido   : 200.00,
                          status    : 'A',
                          bc1       : 'N',
                          bc2       : 'U',
                          ifree     : 2000,
                          refinance : 300,
                          payDate   : pd,
                          imgCard   : "./assets/acct1.jpg",
                          shortName : "GUILLERMO HERRERA",
                          cycleDue  : 100.34,
                          
                          memoBalance   : 321.00,
                          debitBalance  : 321.10,
                          creditBalance : 321.20,
                          memoPay       : 321.30,
                          
                          userData           : '00000000000000000000',
                          relationshipNumber : '00000000000000000000',
                          employeeCode       : 'EX',
                          blockCode1         : 'A',
                          blockReason1       : 'T',
                          blockCode2         : 'A',
                          blockReason2       : 'X',
                          vip                : 'N',
                          flexBill           : 'Y',
                          cardScheme         : '2',
                          displayRequest     : 'N',
                          billLevel         : '1',
                          billCycle         : '2',
                          statementFrequency : '01',
                          statementRequest   : 'C',
                          statementFlag      : '',
                          returnMail         : '00 00 00',
                          owner              : '1',
                          chargeOffStatus    : '0',
                          letterRequest      : 'D10',
                          memoBillCurrency   : '',
                          customerStatement  : '0',
                          letter             : '',
                          collectCardRequest : 'N',
                          reissueScheme      : '2',
                          permCollector      : 'XXX',
                          restructureFlag    : 'N',
                          collateralCode     : 'XX',
                          
                          dateBlock1               : new Date('01/02/2022'),
                          dateBlock2               : new Date('01/03/2022'),
                          dateLastMaint            : new Date('01/04/2022'),
                          dateLastCycle            : new Date('01/05/2022'),
                          dateNextStatement        : new Date('01/06/2022'),
                          dateNotificationReceived : new Date('01/07/2022'),
                          days                     : 25,
                          daysCardExpiry           : new Date('01/09/2022'),
                          dateExpiry               : new Date('01/10/2022'),
                          dateCardFee              : new Date('01/11/2022'),
                          dateApplied              : new Date('01/12/2022'),
                          dateOpened               : new Date('01/13/2022'),
                          dateClosed               : new Date('01/14/2022'),
                          incomeTH                 : 3251642,
                          highBalance              : 43276.22,
                          creditLimit              : 15000,                  
                          
                          cards: 
                          [
                            {
                              id: '0004118490999963632',
                              cardNumber: '0004118490999963632',
                              expiryDate: '12/03',
                              embossedName: 'G. HERRERA SANCHEZ 1',
                              accountId: '0004001000999963631',
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '', 
                            },
                            {
                              id: '0004118490999963633',
                              cardNumber: '0004118490999963633',
                              expiryDate: '12/05',
                              embossedName: 'G. HERRERA SANCHEZ 2',
                              accountId: '0004001000999963631',
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            },
                            {
                              id: '0004118490999963634',
                              cardNumber: '0004118490999963634',
                              expiryDate: '10/09',
                              embossedName: 'G. HERRERA SANCHEZ 3',
                              accountId: '0004001000999963631',
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            }
                          ]
                        },
                        {
                          id: '0000013049090015624',
                          customerId: '0002000000015061291',
                          balance: 10220.00,
                          otb: 543.38,
                          
                          org       : '200',
                          logo      : '360',
                          minimo    : 120.00,
                          vencido   : 220.00,
                          status    : 'A',
                          bc1       : 'N',
                          bc2       : 'U',
                          ifree     : 2200,
                          refinance : 320,
                          payDate   : pd,
                          imgCard   : "./assets/acct2.jpg",
                          
                          memoBalance   : 322.00,
                          debitBalance  : 322.10,
                          creditBalance : 322.20,
                          memoPay       : 322.30,
                          
                          cards: 
                          [
                            {
                              id: '0001300009001562401',
                              cardNumber: '0001300009001562401',
                              expiryDate: '07/24',
                              embossedName: 'SANTOS PEDRO SANCHEZ',
                              accountId: '0000013049090015624',
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            },
                            {
                              id: '0001300009001562402',
                              cardNumber: '0001300009001562402',
                              expiryDate: '10/09',
                              embossedName: 'EMILE PEÃÂA',
                              accountId: '0000013049090015624',
                              
                              blockCode: 'E',
                              blockCodeDesc: 'EXTRAVIADA',
                              st: 'I',
                              stDesc: 'INACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            },
                            {
                              id: '0001380009001562403',
                              cardNumber: '0001380009001562403',
                              expiryDate: '08/25',
                              embossedName: 'PETER SANCHEZ',
                              accountId: '0000013049090015624',
                              
                              blockCode: '',
                              blockCodeDesc: '',
                              st: 'A',
                              stDesc: 'ACTIVA',
                              gp: '0',
                              gpDesc: '',
                              tg: '0',
                              tgDesc: '',                       
                            }
                            
                          ]
                        }
                      ]                  
                    }          
                    
                  ]
                }        
              ];  
            }
            return response;
          }  

        }
        
        
        
        