import { MatSelectChange } from '@angular/material/select';
import { CreditCardProduct } from './../../entities/credit.d';
import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { VplService } from '../../services/vpl.service';
import { SelectConfig } from 'src/app/modules/core/entities/core';

@Component({
  selector: 'credit-list-card-products',
  templateUrl: './list-card-products.component.html',
  styleUrls: ['./list-card-products.component.scss']
})
export class ListCardProductsComponent implements OnInit {

  constructor(public credit: VplService) { }

  ngOnInit(): void {
    this.displayedColumns = [ 'id', 'name', 'coercivity', 'status' ];

    this.credit.getCardProducts().subscribe( data => { 
      data.forEach(item => {
        this.cardProductOptions.push({ id: ''+item.id, label: item.name });
        if (item.id == this.credit.card?.cardProduct) {
          this.selectedProduct = item;
        }  
      });
      this.dataSource = data; 
    });
  }

  @Input() hasAccepted: boolean = false;
  @Output() accepted = new EventEmitter();

  // public hasAccepted = false;
  public dataSource!: CreditCardProduct[];
  public selectedProduct!: CreditCardProduct;
  public get productId() {
    if (this.selectedProduct) {
      return ''+ this.selectedProduct.id ?? '';
    }
    return '4';
  }
  public displayedColumns!: string[];

  public cardProductOptions:{ id: string, label: string }[] = [];

  public cardProductConfig:SelectConfig = {
    type: 'cardProduct',
    id: '',
    name: '',
    title: 'Card Product',
    search: false,
    result: false,
    format: '',
    readonly: false,
    required: true,
    placeholder: '',
    hint: 'Card Product',
    icon: '',
    hideIfEmpty: false,
  }

  onAccept() {
    this.accepted.emit(this.selectedProduct);
    this.hasAccepted = true;
  }

  selectProduct(product: MatSelectChange ) {
    this.dataSource.forEach(item => {
      if (item.id == product.value) {
        this.selectedProduct = item;
      }
    })
  }
}
