import { ContextHintData } from './../../entities/core.d';
import { Component, Input, OnInit } from '@angular/core';
import { ContextHintService } from '../../services/context-hint.service';

@Component({
  selector: 'core-context-hint',
  templateUrl: './context-hint.component.html',
  styleUrls: ['./context-hint.component.scss']
})
export class ContextHintComponent implements OnInit {

  constructor(
    public hintService: ContextHintService
  ) { }

  @Input() hintId = "0000";
  @Input() hintData = {};
  @Input() visible = false;

  public hint!: ContextHintData;

  ngOnInit(): void {
    this.hintService.getHint(this.hintId, this.hintData).subscribe(hint => {
      if (hint) {
        this.visible = true;
        this.hint = hint;
      }
    });
  }

  public hintClass () {
    return `context-type-${this.hint?.type ?? 'info'}`
  }

}
