import { EnvironmentService } from 'src/app/services/environment.service';
import { ButtonConfig } from './../../../core/entities/core.d';
import { Component, Input, OnInit } from '@angular/core';
import { VplService } from '../../services/vpl.service';
import { Router } from '@angular/router';

@Component({
  selector: 'credit-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss']
})
export class ActionMenuComponent implements OnInit {

  private _view!: string;
  public buttons: ButtonConfig[] = [];
  public _stateId!: string;

  @Input() set menu(id: string) {
    this._view = id;
  }
  public get menu() {
    return this._view;
  }

  @Input() set stateId(id: string) {
    this._stateId = id;
  }

  constructor(
    public creditSvc: VplService,
    private router: Router,
    public env: EnvironmentService
  ) { }

  ngOnInit(): void {
    this.menu = 'view';
    this.buttons = this.creditSvc.getActionCodes();  
    if (!this.env.showActionCodeValue)
    this.buttons.forEach(b => {
      b.title = '';
    })
  }
}
