import { EnvironmentService } from 'src/app/services/environment.service';
import { WorkflowService } from './../../../../services/workflow.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { MatSort, Sort } from '@angular/material/sort';
import { ChartOptions, ChartTypeRegistry, Color } from 'chart.js';

// import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

export interface BlockCodeTotal {
  group: string;
  company: string;
  zone: string;
  region: string;
  store: string;
  action: string;
  mtd: number;
  ytd: number;
  ltd: number;
  goalMonth: number;
}

interface Total {   
  mtd: number;
  ytd: number;
  ltd: number;
  goalMonth: number;
 };
 
type BCTOrTotal = BlockCodeTotal | Total;

export interface WorkflowChartOptions {
  chartDatasets: any[],
  chartLabels: string[],
  chartOptions?: (ChartOptions & { annotation: any }),
  chartColors?: Color[],
  chartLegend?: boolean,
  chartType?: keyof ChartTypeRegistry,
  chartPlugins?: [],
}

@Component({
  selector: 'flow-stat-table01',
  templateUrl: './stat-table01.component.html',
  styleUrls: ['./stat-table01.component.scss']
})
export class StatTable01Component implements OnInit {

  @ViewChild(MatSort) sort!: MatSort;

  // @Input() dataSource = [{ cola: 'A', colb: 'B', colc: 1, cold: 5 }, { cola: 'A', colb: 'C', colc: 2, cold: 5 }, { cola: 'A', colb: 'C', colc: 3, cold: 10 }];

  dataSource: BCTDataSource;
  displayedColumns = ['group', 'company', 'zone', 'region', 'store', 'action', 'ltd', 'ytd', 'mtd', 'remainMonth', 'goalMonth'];

  // isLastRow = (data, index) => index === this.players.length;

  // players = this.STATS.slice();

  filter = {
    group: '',
    company: '',
    zone: '',
    region: '',
    store: '',
    action: '',
  };
  totals = {
    mtd: 0,
    ytd: 0,
    ltd: 0,
    goalMonth: 0
  };

  // pie
  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
      }
    }
  };
  
  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [ ],
    datasets: [ {
      data: [ ]
    } ]
  };

  public pieChartData2: ChartData<'pie', number[], string | string[]> = {
    labels: [ ],
    datasets: [ {
      data: [ ]
    } ]
  };

  public pieChartType: ChartType = 'pie';
  // public pieChartPlugins = [ DatalabelsPlugin ];

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  constructor(
    public flow: WorkflowService,
    public env: EnvironmentService
    ) { 
    this.dataSource = new BCTDataSource();
  }

  public r1(company: string, zone: string, region: string, store: string) { 
    let defGroup = 'Puerto ' + this.env.tenantAcronym;

    let months = 4;
    let years = 3;
    let res: {group: string, company: string, zone: string, region: string, store: string, action: string, mtd: number, ytd: number, ltd: number, goalMonth: number } [] = [];
    this.env.tenantActions.forEach(action => {
      let m = Math.floor(Math.random()*500 * action.factor); 
      let y = m + Math.floor(m*Math.random()*months); 
      let l = y + Math.floor(y*Math.random()*2*years); 
      let g = Math.floor(m*Math.random()*2);
      res.push({group: defGroup, company: company, zone: zone, region: region, store: store, action: action.id, mtd: m, ytd: y, ltd: l, goalMonth: g });
    });
    return res; 
  }
  public STATS: BlockCodeTotal[] = [];
  
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {

    this.STATS = [];

    this.env.tenantSubsidiaries.forEach(company => {
      for (let z = 0; z < 3; z++) {
        this.env.tenantRegions.forEach(r => {
          this.env.getTenantStoresByRegion(r).forEach(s => {
            this.STATS.push(...this.r1(company, `Zona ${z}`, s.region, s.name));
          });
        });
      }

      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'CENTRO', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'CENTRO', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'CENTRO', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'CENTRO', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'CENTRO', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'CENTRO', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'INSURGENTES', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'INSURGENTES', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'INSURGENTES', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'INSURGENTES', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'INSURGENTES', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'INSURGENTES', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'POLANCO', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'POLANCO', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'POLANCO', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'POLANCO', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'POLANCO', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'POLANCO', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
  
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'POLANCO', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'POLANCO', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'POLANCO', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'POLANCO', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'POLANCO', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'POLANCO', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
  
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'SATELITE', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'SATELITE', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'SATELITE', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'SATELITE', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'SATELITE', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'SATELITE', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
  
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'PERISUR', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'PERISUR', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'PERISUR', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'PERISUR', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'PERISUR', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'PERISUR', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
  
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'COAPA', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'COAPA', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'COAPA', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'COAPA', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'COAPA', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'COAPA', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
  
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'SANTAFE', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'SANTAFE', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'SANTAFE', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'SANTAFE', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'SANTAFE', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 1', region: 'CDMX'   , store: 'SANTAFE', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
  
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'JALAPA', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'JALAPA', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'JALAPA', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'JALAPA', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'JALAPA', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'JALAPA', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
  
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'CORDOBA', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'CORDOBA', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'CORDOBA', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'CORDOBA', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'CORDOBA', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'CORDOBA', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
  
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'VILLAHERMOSA', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'VILLAHERMOSA', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'VILLAHERMOSA', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'VILLAHERMOSA', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'VILLAHERMOSA', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'VILLAHERMOSA', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
  
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'TUXTLA', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'TUXTLA', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'TUXTLA', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'TUXTLA', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'TUXTLA', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'TUXTLA', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
  
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'PUEBLA', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'PUEBLA', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'PUEBLA', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'PUEBLA', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'PUEBLA', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'PUEBLA', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
  
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'COATZACOALCOS', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'COATZACOALCOS', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'COATZACOALCOS', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'COATZACOALCOS', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'COATZACOALCOS', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'COATZACOALCOS', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
  
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'POZARICA', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'POZARICA', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'POZARICA', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'POZARICA', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'POZARICA', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'POZARICA', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
  
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'TAPACHULA', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'TAPACHULA', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'TAPACHULA', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'TAPACHULA', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'TAPACHULA', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 2', region: 'CDMX'   , store: 'TAPACHULA', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
  
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 3', region: 'CDMX'   , store: 'MTYCENTRO', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 3', region: 'CDMX'   , store: 'MTYCENTRO', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 3', region: 'CDMX'   , store: 'MTYCENTRO', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 3', region: 'CDMX'   , store: 'MTYCENTRO', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 3', region: 'CDMX'   , store: 'MTYCENTRO', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 3', region: 'CDMX'   , store: 'MTYCENTRO', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
  
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 4', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 1', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 1', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 1', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 1', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 1', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 1', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 2', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 2', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 2', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 2', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 2', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 2', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 3', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 3', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 3', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 3', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 3', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'CDMX'   , store: this.env.dict.store+' 3', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 5', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal});
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 1', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 1', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 1', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 1', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 1', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 1', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 2', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 2', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 2', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 2', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 2', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 2', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 3', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 3', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 3', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 3', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 3', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'CDMX'   , store: this.env.dict.store+' 3', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 1', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 2', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'EMBO', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'DOMI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'ACTI', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'NOTA', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'M409', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
      // tot=this.r1(); this.STATS.push({  group: defGroup, company: company, zone: 'Zona 6', region: 'EDO MEX', store: this.env.dict.store+' 3', action: 'M456', mtd: tot.mtd, ytd: tot.ytd, ltd: tot.ltd, goalMonth: tot.goal });
    })
    
    this.updateView();
  }

  public updateView() {
//    this.dataSource.use(this.STATS.slice());
    let newData = 
      this.STATS.slice()
      .filter((item, i) => this.filter.company == '' || (item.company.indexOf(this.filter.company) !== -1 ))
      .filter((item, i) => this.filter.zone == ''    || (item.zone.indexOf(this.filter.zone) !== -1))
      .filter((item, i) => this.filter.region == ''  || (item.region.indexOf(this.filter.region) !== -1))
      .filter((item, i) => this.filter.store == ''   || (item.store.indexOf(this.filter.store) !== -1))
      .filter((item, i) => this.filter.action == ''  || (item.action.indexOf(this.filter.action) !== -1));
    this.totals = {
      mtd: 0,
      ytd: 0,
      ltd: 0,
      goalMonth: 0
    }
    newData.forEach(item => {
      this.totals.mtd += item.mtd;
      this.totals.ytd += item.ytd;
      this.totals.ltd += item.ltd;
      this.totals.goalMonth += item.goalMonth;
    });
    this.dataSource.use(
      newData
    );
    this.setChart01(newData);
  }

  public setChart01(data: BlockCodeTotal[]): void {
    //    console.log('getAccountDelqChart');

    // console.log(data);

    this.pieChartData = {
      labels: [ ],
      datasets: [ { data: []} ]
    };
    this.pieChartData2 = {
      labels: [ ],
      datasets: [ { data: []} ]
    };

    // ['DOMI', 'ACTI', 'NOTA', 'EMBO', 'ALCO', 'M409', 'M456'].forEach(action => {
    this.env.tenantActions.forEach(action => {
      let data1 = 
      data.slice()
      .filter((item, i) => item.action == action.id).reduce((sum, current) => {
        sum.mtd += current.mtd;
        sum.ytd += current.ytd;
        return sum;
      }, { mtd: 0, ytd: 0 });
      this.pieChartData.labels?.push( action.id );
      this.pieChartData.datasets[0].data.push(data1.mtd);
      this.pieChartData2.labels?.push( action.id );
      this.pieChartData2.datasets[0].data.push(data1.ytd);
    });
  }
}

export class BCTDataSource extends DataSource<BCTOrTotal> {

  dataWithTotal = new BehaviorSubject<BCTOrTotal[]>([]);
  sort!: Sort;

  use(bcts: BCTOrTotal[]) {
    this.dataWithTotal.next([ ...bcts]);
  }

  connect(): Observable<BCTOrTotal[]> { 
    return this.dataWithTotal.asObservable();
  }

  disconnect() {}
  
}

//NOTE: Next: https://stackoverflow.com/questions/47908179/how-to-load-observable-array-property-as-angular-material-table-data-source


