import { FlowQueueService } from './../../services/flow-queue.service';
import { FlowQueue, FlowQueueObject, FlowStateObject } from './../../entities/workflow.d';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'flow-test-queue',
  templateUrl: './test-queue.component.html',
  styleUrls: ['./test-queue.component.scss']
})
export class TestQueueComponent implements OnInit {

  public queues!: FlowQueue[];
  public queueObjects!: FlowQueueObject[];

  @ViewChild('state') state!: ElementRef<HTMLTextAreaElement>;

  constructor(
    public wf: FlowQueueService
  ) { }

  ngOnInit(): void {
    this.refreshQueues();
  }

  refreshQueues() {
    console.log('refresh queues');
    this.wf.getQueues(true).subscribe(queues => {
      this.queues = queues;
    });
  }

  refreshQueueObjects(queueId: string) {
    this.wf.getQueueObjects(queueId).subscribe(queueObjects => {
      this.queueObjects = queueObjects;
    });
  }

  addQueue(name: string, id?: string) {
    this.wf.addQueue(name, id).subscribe(() => {
      this.refreshQueues();
    });
  }
  
  deleteQueue(id: string) {
    this.wf.deleteQueue(id).subscribe((response) => {
      console.log('delete done');
      console.log(response);
      
      this.refreshQueues();
    });
  }

  toggleQueueStatus(id: string, status: string) {
    console.log(`${id}: ${status}`);
    if (status == 'up') {
      this.wf.actQueue(id, 'down').subscribe(() => {
        this.refreshQueues();
      });
    } else {
      this.wf.actQueue(id, 'up').subscribe(() => {
        this.refreshQueues();
      });
    }
  }

  toggleQueueLabel(status: string) {
    if (status == 'up') {
      return 'Close';
    }
    return 'Open';
  }

  getTemplate() {
    var template = {
      "logId": "log000",
      "type": "action",
      "subType": "EMBO",
      "stateData": {
          "status": "start",
          "step": "PIN issue",
          "cardNumber": "CARD00000005",
          "accountNumber": "ACCT00000001"
      }
    };
    return JSON.stringify(template, null, 2);
  }

  getState(queueId: string, stateId: string) {
    this.wf.actQueueObject(queueId, stateId).subscribe((queueObject) => {
      console.log('NNNNN');
      console.log(queueObject);
      if (queueObject) {
        this.state.nativeElement.value = JSON.stringify(queueObject.state, null, 4);
      }
    });
  }

  pushState(queueId: string, state: string) {
    this.wf.pushStateToQueue(queueId, JSON.parse(state), []).subscribe(() => {
      this.refreshQueueObjects(queueId);
      this.refreshQueues();
    });
  }

  actOnState(queueId: string, stateId: string, action: string, data?: any) {
    this.wf.actQueueObject(queueId, stateId, action, data).subscribe(() => {
      this.refreshQueueObjects(queueId);
      this.refreshQueues();
    });
  }
  
  loadActionState(actionCode: string, account: string, stateId?: string): Observable<any> {

    var state = of(this.initState(actionCode));

    if (stateId) {
      this.wf.getQueueObjects(account, stateId).subscribe(queueStates => {
        console.log(queueStates);
        let state = this.initState(actionCode);
        queueStates.forEach(qs => {
          if (qs.state?.id == stateId) {
            console.log(`action.stateId ${qs.stateId}`);
            console.log(`returning found state`);
            console.log(qs);
            state = qs.state;
          }
        });
        return state;    
      });
    }
    return of(state);
  }

  initState(actionCode: string) {
    let state: FlowStateObject  = {
      id: '',
      logId: '',
      createTimestamp: new Date(),
      type: '',
      subType: '',
      stateData: {},
      timerId: ''
    }

    switch (actionCode) {
      case "EMBO": 
        let stateSpecific = {
          account: '',
          card: '',
          step: 'init'
        };
        state = { ...state, ...stateSpecific };
        break;
    }

    return state;
  }
  
  testLoadState(actionCode: string, stateId: string) {
    return this.loadActionState(actionCode, stateId).subscribe(actionState => {
      // this.state.nativeElement.value = JSON.stringify(actionState, null, 4);
      console.log(actionState);
      this.state.nativeElement.value = actionState;
    }); 
  }
}
