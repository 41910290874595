import { VplService } from './../../services/vpl.service';
import { ContextService } from './../../services/context.service';
import { Component, OnInit } from '@angular/core';
import { MatListOption } from '@angular/material/list';

@Component({
  selector: 'credit-vp-dash',
  templateUrl: './vp-dash.component.html',
  styleUrls: ['./vp-dash.component.scss']
})
export class VpDashComponent implements OnInit {
  public promoCount: number = 0;

  public menuOptions: { id: string, title: string, icon: string, badge: string, default: boolean}[] = [];
 
  // public selectedOption!:string; 

  constructor(
    public context: ContextService,
    public credit: VplService
  ) { }

  ngOnInit(): void {
    // this.selectedOption = this.menuOptions[0].id;
    this.menuOptions = [
      { id: 'dashboard'  , title: 'Dashboard'          , icon: 'dashboard'                , badge: '', default: true},
      // { id: 'team'       , title: 'Team'               , icon: 'leaderboard'              , badge: '', default: false},
      { id: 'division'   , title: 'Division'           , icon: 'leaderboard'              , badge: '', default: false},
      
      // { id: 'cards'      , title: 'Tarjetas'           , icon: 'credit_card'              , badge: '', default: false},
      // { id: 'plans'      , title: 'Saldo Plan'         , icon: 'account_balance_wallet'   , badge: '', default: false},
      // { id: 'account'    , title: 'Datos Cuenta'       , icon: 'info'                     , badge: '', default: false},
      // { id: 'history'    , title: 'Ultimos Movimientos', icon: 'show_chart'               , badge: '', default: false},
      // { id: 'promos'     , title: 'Promociones'        , icon: 'campaign'                 , badge: ''+this.credit.getPromoCount(), default: false},
    ];
  
    this.context.selectedOptionPerformance = [this.menuOptions[0].id];
    console.log('this.context.selectedOptionPerformance: STATUS');
    console.log(this.context.selectedOptionPerformance);
  }

  onSelection(e: any, v: MatListOption[]) { 
    // this.selectedOptionAcct = v[0].value;
    this.context.selectedOptionPerformance = [v[0].value];
  }

}
