import { EnvironmentService } from 'src/app/services/environment.service';
import { VplService } from './../../services/vpl.service';
import { Component, Input, OnInit } from '@angular/core';
import { CreditAccount, CreditCustomer } from '../../entities/credit.d';

@Component({
  selector: 'credit-va-demographic',
  templateUrl: './va-demographic.component.html',
  styleUrls: ['./va-demographic.component.scss']
})
export class VaDemographicComponent implements OnInit {

  callDate!: Date;

  constructor(
    public creditSvc: VplService,
    public env: EnvironmentService
  ) { }

  private _account!: CreditAccount;
  @Input()
  public get account(): CreditAccount {
    if (this.env.newAPI) {
      return (this._account) ? this._account: {
        id: '',
        org: '000'
      };
    } else {
      return (this.creditSvc.account) ? this.creditSvc.account: {
        id: '',
        org: '000'
      };
    }
  }
  public set account(value: CreditAccount) {
    this._account = value;
  }

  private _customer!: CreditCustomer;
  @Input()
  public get customer(): CreditCustomer {
    if (this.env.newAPI) {
      return (this._customer) ? this._customer: {
        id: ''
      };
    } else {
      return (this.creditSvc.customer) ? this.creditSvc.customer: {
        id: '',
      };
    }
  }
  public set customer(value: CreditCustomer) {
    this._customer = value;
  }
  
  ngOnInit(): void {
    this.callDate = this.getDataTime();
  }

  getDataTime(){
    return this.creditSvc.callTimestamp;
  }
  
}




