import { EnvironmentService } from 'src/app/services/environment.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OtpConfig, ButtonConfig } from './../../entities/core.d';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'core-otp-request',
  templateUrl: './otp-request.component.html',
  styleUrls: ['./otp-request.component.scss']
})
export class OtpRequestComponent implements OnInit {

  constructor(
    public env: EnvironmentService
  ) { }

  @Input() config!: OtpConfig;
  @Output() otpSuccess = new EventEmitter();
  @Output() otpFail = new EventEmitter();

  status: string = 'request';

  otpRequestForm!: FormGroup;

  otpVerifyForm!: FormGroup;

  originalEmail!: string;
  originalCell!: string;
  
  requestButtonConfig: ButtonConfig = {
    type: 'text',
    id: '',
    name: '',
    title: `Request ${this.env.dict.otp}`,
    hint: '',
    hideIfDisabled: false,
    click: function (): boolean {
      return false;
    },
    icon: function (): string {
      return 'shield';
    },
    disabled: (() => {return false;})
  }

  verifyInputConfig = {
    label: `Enter ${this.env.dict.otp} value`,
  }

  verifyButtonConfig: ButtonConfig = {
    type: 'text',
    id: '',
    name: '',
    title: '',
    hint: `Verify ${this.env.dict.otp}`,
    hideIfDisabled: false,
    click: function (): boolean {
      return false;
    },
    icon: function (): string {
      return 'verified_user';
    },
    disabled: function (): boolean {
      return false;
    }
  }

  ngOnInit(): void {
    this.originalCell = this.config.cell ?? '';
    this.originalEmail = this.config.email ?? '';

    this.otpVerifyForm = new FormGroup({
      otpValue         : new FormControl('', [ Validators.required ]),
    });

    this.requestButtonConfig.click = () => { 
      this.status = 'verify';
      return true;
    };

    if (this.config.disabled) {
      this.requestButtonConfig.disabled = this.config.disabled;
    }

    this.verifyButtonConfig.click = () => { 
      // Do the OTP verification call from here

      //Fake
      if (this.otpVerifyForm.controls.otpValue.value == '123456') {
        this.status = 'success';
        let hash = Math.trunc(Math.random() * 100000); // Fake
        this.otpSuccess.emit(hash);
        return true;
      }
      this.otpVerifyForm.controls.otpValue.setValue('');
      this.status = 'request';
      this.otpFail.emit(0);
      return false;
    }
  }

  readonly(channel = '') {
    if (channel == 'email' && this.config.cell == this.originalCell) {
      return false;
    }
    if (channel == 'cell' && this.config.email == this.originalEmail) {
      return false;
    }
    return true;
  }
}
