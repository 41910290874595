import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import SignaturePad, { Options } from './src/signature_pad';

@Component({
  selector: 'ic-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class IcSignatureComponent implements OnInit {

  constructor() { }

  public signaturePad!: SignaturePad;

  @Input() options!: Options;

  @Output() acceptSignature = new EventEmitter();
  @Output() cancelSignature = new EventEmitter();

  ngOnInit(): void {
    let canvas = document.querySelector("canvas") || new HTMLCanvasElement();
    this.signaturePad = new SignaturePad(canvas, this.options);
  }

  clear() {
    this.signaturePad.clear();
  }

  undo() {
    let data = this.signaturePad.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }  }

  accept() {
    let data = this.signaturePad.toDataURL('image/png');
    this.acceptSignature.emit(data);
  }

  cancel() {
    this.signaturePad.clear();
    this.cancelSignature.emit();
  }
}
