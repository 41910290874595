import { FirebaseAuthService } from './../../services/firebase-auth.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';

import {Router} from '@angular/router';


@Component({
  selector: 'core-auth-fbui',
  templateUrl: './auth-fbui.component.html',
  styleUrls: ['./auth-fbui.component.scss']
})
export class AuthFbuiComponent implements OnInit {

  @Input() loginTarget = '';
  @Input() loginHome = '';

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private authService: FirebaseAuthService
    ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe(d => console.log(d));
  }

  logout() {
    this.afAuth.signOut();
  }

  successCallback(data: FirebaseUISignInSuccessWithAuthResult) {
    this.router.navigate([this.loginTarget]);
    this.authService.loginSuccess(data);
  }

  errorCallback(data: FirebaseUISignInFailure) {
    console.warn('errorCallback', data);
  }

  uiShownCallback() {
  }

  backToMain() {
    this.router.navigate([this.loginHome]);
  }
}
