<div class="container">
    <div class="local-menu">
        <!-- {{context.selectedOption}} -->
        <mat-selection-list #localMenu [(ngModel)]="context.selectedOption" [multiple]="false" (selectionChange)="onSelection($event, localMenu.selectedOptions.selected)">
            <mat-list-option *ngFor="let opt of menuOptions" [value]="opt.id" [(selected)]="opt.default">
                <div class="menu-item">
                    <mat-icon>{{opt.icon}}</mat-icon>
                    <div class="menu-spacer"></div>
                    {{opt.title}}
                </div>
            </mat-list-option>
            <hr/>
            <mat-list-option class="action" *ngFor="let opt of context.activeActions" [value]="opt.stateId">
                <div class="menu-item">
                    <mat-icon>{{opt.icon}}</mat-icon>
                    <div class="menu-spacer"></div>
                    {{opt.title}}
                    <div class="menu-spacer"></div>
                    <core-timer2 [isRunning]="true" [timerId]="opt.timerId"></core-timer2>
                </div>
            </mat-list-option>
            <hr/>
            <mat-list-option *ngFor="let opt of context.accountActions" [value]="opt.menuItem.id">
                <div class="menu-item" [matTooltip]="opt.menuItem.tag">
                    <mat-icon>{{opt.menuItem.icon}}</mat-icon>
                    <div class="menu-spacer"></div>
                    {{opt.menuItem.title}}
                </div>
            </mat-list-option>
        </mat-selection-list>                  
</div>
    <div class="local-content">
        <credit-action-menu #actionMenu [menu]="context.selectedOption[0]"></credit-action-menu>
    </div>
</div>


