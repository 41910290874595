import { LoginUser } from 'src/app/modules/core/entities/core';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'core-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  public user!: LoginUser;

  constructor( private auth: AuthService) { }

  ngOnInit(): void {
    this.user = this.auth.user;
  }

  public userName() {
    if (this.auth.user.name && this.auth.user.name != '') {
      return this.auth.user.name;
    }
    return `${this.user.firstName} ${this.user.lastName}`; 
  }

}
