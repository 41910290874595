import { WebcamImage } from 'ngx-webcam';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IncodeApiService {

  public baseUri: string = 'https://private-anon-b007bb24f4-incodeomni.apiary-mock.com';
  public apiKey: string = '';
  public token: string = '';
  public interviewCode: string = '';
  public interviewId: string = '';

  constructor(private http: HttpClient) { }

  handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  callIC(endPoint: string, headers: HttpHeaders, body: any, onSuccess: (response: HttpResponse<any>) => void, onFailure: (response: any) => void, method: string = 'POST') {
    switch (method) {
      case 'GET':
        return this.http.get<any>(this.baseUri + '/' + endPoint, { headers: headers, observe: 'response' })
          .pipe(map(response => {
            if (response.status == 200) {
              onSuccess(response);
            } else {
              onFailure(response);
            }
          }));
      default:
        return this.http.post<any>(this.baseUri + '/' + endPoint, body, { headers: headers, observe: 'response' },)
          .pipe(
            // catchError(this.handleError),
            map(response => {
            if (response.status == 200) {
              onSuccess(response);
              // this._user = response.user;
              // localStorage.setItem('isLoggedIn',  JSON.stringify(true));
              // localStorage.setItem('currentUser', JSON.stringify(this.user));
              // localStorage.setItem('auth_token',  JSON.stringify(response.user.authToken));
            } else {
              onFailure(response);
            }
          }));
    }
  }

  onboard(id: string, country: string, redirectionUrl: string, firstName: string, lastName: string, onSuccess: () => void) {
    let body = {
      "countryCode": country,
      "externalId": id,
      "redirectionUrl": redirectionUrl,
      "customFields": {
        "firstName": firstName,
        "lastName": lastName
      }
    };

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('api-version', '1.0');
    headers = headers.set('x-api-key', this.apiKey);

    let success = (response: HttpResponse<any>) => {
      this.token = response.body.token;
      this.interviewCode = response.body.interivewCode;
      onSuccess();
    };

    let failure = (response: any) => {
      console.error('OnBoard Call Failed');
      console.error(response);
    };

    this.callIC('omni/start', headers, body, success, failure).subscribe((response) => {
    });
  }

  addHardware(onSuccess: () => void, onFailure: () => void) {

    let body = {
      "deviceType": "WEBAPP",
      "hash": "42ecaaf4a16e6260f",
      "ip": "67.164.79.209",
      "data": "[{\"key\":\"userAgent\", \"value\":\"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.80 Safari/537.36\"}]",
      "osVersion": "MacOS 12",
      "deviceModel": "Calculator",
      "sdkVersion": "1.0",
    };

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('api-version', '1.0');
    headers = headers.set('x-api-key', this.apiKey);
    headers = headers.set('X-Incode-Hardware-Id', this.token);

    let success = (response: HttpResponse<any>) => {
      onSuccess();
    };

    let failure = (response: any) => {
      console.error('addHardware Call Failed');
      console.error(response);
      onFailure();
    };

    this.callIC('omni/add/device-fingerprint', headers, body, success, failure).subscribe((response) => {
    });
  }

  addSelfie(img: WebcamImage, onSuccess: (response: HttpResponse<any>) => void, onFailure: (response: HttpResponse<any>) => void) {

    let body = {
      "base64Image": img.imageAsBase64,
    };

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('api-version', '1.0');
    headers = headers.set('x-api-key', this.apiKey);
    headers = headers.set('X-Incode-Hardware-Id', this.token);

    let success = (response: HttpResponse<any>) => {
      if (response.body.confidence != 0) {
        onFailure(response);
      } else {
        onSuccess(response);
      }
    };

    let failure = (response: any) => {
      console.error('addSelfie Call Failed');
      console.error(response);
      onFailure(response);
    };

    this.callIC('omni/add/face/third-party?imageType=videoSelfie', headers, body, success, failure).subscribe((response) => {
    });
  }

  addId1Front(img: WebcamImage, onSuccess: (response: HttpResponse<any>) => void, onFailure: (response: HttpResponse<any>) => void) {

    let body = {
      "base64Image": img.imageAsBase64,
    };

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('api-version', '1.0');
    headers = headers.set('x-api-key', this.apiKey);
    headers = headers.set('X-Incode-Hardware-Id', this.token);

    let success = (response: HttpResponse<any>) => {
      if (response.body.classification != true || response.body.readability != true) {
        onFailure(response);
      } else {
        onSuccess(response);
      }
    };

    let failure = (response: any) => {
      console.error('addID1Front Call Failed');
      console.error(response);
      onFailure(response);
    };

    this.callIC('omni/add/front-id/v2?onlyFront=false', headers, body, success, failure).subscribe((response) => {
    });
  }

  addId1Back(img: WebcamImage, onSuccess: (response: HttpResponse<any>) => void, onFailure: (response: HttpResponse<any>) => void) {

    let body = {
      "base64Image": img.imageAsBase64,
    };

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('api-version', '1.0');
    headers = headers.set('x-api-key', this.apiKey);
    headers = headers.set('X-Incode-Hardware-Id', this.token);

    let success = (response: HttpResponse<any>) => {
      if (response.body.classification != true || response.body.readability != true) {
        onFailure(response);
      } else {
        onSuccess(response);
      }
    };

    let failure = (response: any) => {
      console.error('addID1Back Call Failed');
      console.error(response);
      onFailure(response);
    };

    this.callIC('omni/add/back-id/v2?retry=false', headers, body, success, failure).subscribe((response) => {
    });
  }

  getId1OCR(onSuccess: (response: HttpResponse<any>) => void, onFailure: (response: HttpResponse<any>) => void) {

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('api-version', '1.0');
    headers = headers.set('x-api-key', this.apiKey);
    headers = headers.set('X-Incode-Hardware-Id', this.token);

    let success = (response: HttpResponse<any>) => {
      onSuccess(response);
    };

    let failure = (response: any) => {
      console.error('getId1OCR Call Failed');
      console.error(response);
      onFailure(response);
    };

    this.callIC('omni/get/ocr-data/v2', headers, '', success, failure, 'GET').subscribe((response) => {
    });
  }

  addSignature(img: WebcamImage, onSuccess: (response: HttpResponse<any>) => void, onFailure: (response: HttpResponse<any>) => void) {

    let body = {
      "base64Image": img.imageAsBase64,
    };

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('api-version', '1.0');
    headers = headers.set('x-api-key', this.apiKey);
    headers = headers.set('X-Incode-Hardware-Id', this.token);

    let success = (response: HttpResponse<any>) => {
      if (response.body.success != true) {
        onFailure(response);
      } else {
        onSuccess(response);
      }
    };

    let failure = (response: any) => {
      console.error('addSignature Call Failed');
      console.error(response);
      onFailure(response);
    };

    this.callIC('/omni/add/document/v2?type=signature', headers, body, success, failure).subscribe((response) => {
    });
  }

  processFace(onSuccess: (response: HttpResponse<any>) => void, onFailure: (response: HttpResponse<any>) => void) {

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('api-version', '1.0');
    headers = headers.set('x-api-key', this.apiKey);
    headers = headers.set('X-Incode-Hardware-Id', this.token);

    let success = (response: HttpResponse<any>) => {
      if (response.body.confidence != 0) {
        onFailure(response);
      } else {
        onSuccess(response);
      }
    };

    let failure = (response: any) => {
      console.error('processFace Call Failed');
      console.error(response);
      onFailure(response);
    };

    this.callIC('/omni/process/face?imageType=videoSelfie', headers, '', success, failure).subscribe((response) => {
    });
  }

  getImages(onSuccess: (response: HttpResponse<any>) => void, onFailure: (response: HttpResponse<any>) => void) {

    let body = 
    {
      "images": [
        "selfie"
      ]
    };

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('api-version', '1.0');
    headers = headers.set('x-api-key', this.apiKey);
    headers = headers.set('X-Incode-Hardware-Id', this.token);

    let success = (response: HttpResponse<any>) => {
      if (response.body.confidence != 0) {
        onFailure(response);
      } else {
        onSuccess(response);
      }
    };

    let failure = (response: any) => {
      console.error('getImages Call Failed');
      console.error(response);
      onFailure(response);
    };

    this.callIC('/omni/get/images', headers, body, success, failure).subscribe((response) => {
    });
  }
}

