import { AuthService } from './../../../../services/auth.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginUser } from '../../entities/core';

import {Router} from '@angular/router';
import { of } from 'rxjs';

@Component({
  selector: 'core-login-override',
  templateUrl: './login-override.component.html',
  styleUrls: ['./login-override.component.scss']
})
export class LoginOverrideComponent implements OnInit {
  // @Input() loginTarget = '';
  @Output() onSuccess = new EventEmitter();
  @Output() onFailure = new EventEmitter();
  
  loginForm!: FormGroup;
  win!: string;
  user!: LoginUser;
  password: string = '';
  isAuthenticated: boolean = false;
  
  constructor(
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.loginForm = new FormGroup({
      name          : new FormControl('', [Validators.required]),
      password      : new FormControl('', [Validators.required, Validators.minLength(8)]),
    });

    this.user = this.authService.user;
  }

  overrideSubmit() {
    if (this.authService.isLoggedIn) {
      // this.authService.logout();
      let name = this.loginForm.value["name"];
      let password = this.loginForm.value["password"];
      let onSuccess = (result: any) => {
        this.onSuccess.emit(result);
      }
      let onFailure = (result: any) => {
        this.onFailure.emit(result);
      }
      this.authService.loginOverride(name, password, false, onSuccess, onFailure).subscribe((result)=>{});
    }
    return of({ status: 'failure', description: 'Current user not logged in.'});
  }

  isLoggedIn() {
    return this.authService.isLoggedIn;
  }
}

