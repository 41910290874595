<mat-form-field *ngIf="visible; else eb" appearance="fill" class="smart">
    <mat-label>{{ config.title }}</mat-label>
    <mat-select
    #theSelect
    class="form-control" 
    [id]="config.id"
    (selectionChange)="select($event)"
    [(ngModel)]="selectedValue"
    [name]="config.name"
    [required]="config.required"
    >
      <mat-option *ngFor="let option of options" [value]="option.id">
        {{option.label}}
      </mat-option>
    </mat-select>
    <mat-hint>{{config.hint}}</mat-hint>
</mat-form-field>
<ng-template #eb></ng-template>

<!-- 
<div>

    <button mat-raised-button type="button" class="btn btn-primary"  (click)="open(dropdown, origin)" #origin>
    
      <ng-template [ngIf]="!isOpen" [ngIfElse]="searchTpl">
        <mat-icon>{{icon}}</mat-icon>{{ label }}
      </ng-template> 
  
      <ng-template #searchTpl>
        <input [formControl]="searchControl">
      </ng-template>
    </button>
    
    <ng-template #dropdown>
        <div class="select-menu">
          
          <cdk-virtual-scroll-viewport itemSize="32" [style.height.px]="visibleOptions * 28">
            
            <div *ngIf="!options.length">No results found...</div>
      
            <div
              *cdkVirtualFor="let option of options"
              [class.active]="isActive(option)"
              (click)="select(option)">
              {{ option.label }}
            </div>
            
          </cdk-virtual-scroll-viewport>
          
        </div>
      </ng-template>    
  </div> -->