import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'core-data-field-view',
  templateUrl: './data-field-view.component.html',
  styleUrls: ['./data-field-view.component.scss']
})
export class DataFieldViewComponent implements OnInit {

  @Input() label = '';
  @Input() type = 'text';
  @Input() readonly = true;
  @Input() set value(v: any) { this._value = (v == undefined || v == null) ? '' : v;}
  @Input() class = '';

  _value = '';

  public get value() {
    return this._value;
  }

  constructor() { }

  ngOnInit(): void {

  }

}
