import { EnvironmentService } from 'src/app/services/environment.service';
import { CreditAccount } from './../../entities/credit.d';
import { Component, Input, OnInit, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { VplService } from '../../services/vpl.service';

@Component({
  selector: 'credit-navbar-acct',
  templateUrl: './navbar-acct.component.html',
  styleUrls: ['./navbar-acct.component.scss']
})
export class NavbarAcctComponent implements OnInit, OnChanges {

  private _account!: CreditAccount;
  @Input()
  public get account(): CreditAccount {
    if (this.env.newAPI) {
      return (this._account) ? this._account: {
        id: '',
        org: '000'
      };
    } else {
      return (this.creditSvc.account) ? this.creditSvc.account: {
        id: '',
        org: '000'
      };
    }
  }
  public set account(value: CreditAccount) {
    this._account = value;
  }

  constructor(
    public creditSvc: VplService,
    public env: EnvironmentService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges( change: SimpleChanges) {
    if (change.account.currentValue) {
      this.account = change.account.currentValue;
    }
  }

}
