<div class="box box2">
    <form *ngIf="config.update && status == 'request'">
        <mat-form-field appearance="fill" class="core-data-field"><mat-label>Email</mat-label>   <input matInput type="text" name="email" autocomplete="off" [(ngModel)]="config.email" [readonly]="readonly('email')"> </mat-form-field>
        <mat-form-field appearance="fill" class="core-data-field"><mat-label>Cellular</mat-label><input matInput type="text" name="cell"  autocomplete="off" [(ngModel)]="config.cell"  [readonly]="readonly('cell')">  </mat-form-field>
    </form>
    <mat-form-field appearance="outline" *ngIf="!config.update || status != 'request'" class="core-data-field"><mat-label>Email</mat-label>   <input matInput type="text" name="email" autocomplete="off" [(ngModel)]="config.email" [readonly]="true"> </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="!config.update || status != 'request'" class="core-data-field"><mat-label>Cellular</mat-label><input matInput type="text" name="cell"  autocomplete="off" [(ngModel)]="config.cell"  [readonly]="true">  </mat-form-field>
    
    <!-- <core-data-field-view *ngIf="!config.update || status != 'request'" [readonly]=true>Email: {{config.email}}</core-data-field-view>
    <core-data-field-view *ngIf="!config.update || status != 'request'" [readonly]=true>Cell:  {{config.cell}} </core-data-field-view> -->
    <div *ngIf="status == 'request'">
        <core-smart-button [config]="requestButtonConfig"></core-smart-button>    
    </div>
    <div *ngIf="status == 'verify'" class="core-data-field">
        <form [formGroup]="otpVerifyForm">
            <mat-form-field appearance="fill"><mat-label>{{verifyInputConfig.label}}</mat-label><input matInput type="text" formControlName="otpValue" autocomplete="off"></mat-form-field>
            <core-smart-button [config]="verifyButtonConfig"></core-smart-button>    
        </form>
    </div>
</div>
