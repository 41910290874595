<mat-button-toggle-group name="consentType" #consentType="matButtonToggleGroup" value="print">
    <mat-button-toggle value="print">
        <mat-icon>print</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="signature">
        <mat-icon>draw</mat-icon>
    </mat-button-toggle>
    <!-- <mat-button-toggle value="otp">
        <mat-icon>smartphone</mat-icon>
    </mat-button-toggle> -->
</mat-button-toggle-group>
<div class="box box4">
    <!-- <div *ngIf="consentType.value == 'otp'">
        <h3>Consent by {{env.dict.otp}}</h3>
        <core-otp-request [config]="otp1Config" (otpSuccess)="otpSuccess($event)"
            (otpFail)="otpFail($event)"></core-otp-request>
    </div> -->
    <div *ngIf="consentType.value == 'signature'">
        <h3>Consent by Signature</h3>
        <core-signature-pad (acceptSignature)="handleSignature($event)"></core-signature-pad>
    </div>
    <div *ngIf="consentType.value == 'print'">
        <h3>Print and Sign</h3>
        <button mat-button color="primary" [disabled]="printOK" (click)="printSend()">Print</button>
        <button mat-button color="primary" [disabled]="!printOK" (click)="printDone()">OK</button>
    </div>
</div>
