<mat-card>
    <div style="text-align: center;">
        <mat-card-title>Solicidud</mat-card-title>
        <mat-card-content>
            <div *ngIf="step.id=='init' && !getStep('cb001').done">
                <p class="disclaimer">
                    <span class="emphasis">Disclaimer: </span> You are about to make use of an external credit
                    verification service. By continuing you consent to the use of your personal data provided here for
                    the purpose of analyzing your credit history and background check.
                </p>
                <button [disabled]="!getStep('init').active" class="camera-button" mat-raised-button color="primary"
                    matTooltip="{{step.id}}" matTooltipPosition="left" [matTooltipShowDelay]="1000"
                    [matTooltipHideDelay]="1000" (click)="step.active=false; this.setStep('next')">
                    <mat-icon>flag</mat-icon>Accept
                </button>
                <!-- <button [disabled]="!getStep('init').active" class="camera-button" mat-raised-button color="primary"
                    matTooltip="{{step.id}}" matTooltipPosition="left" [matTooltipShowDelay]="1000"
                    [matTooltipHideDelay]="1000" (click)="this.fakeIt()">
                    <mat-icon>outdoor_grill</mat-icon>Fake It
                </button> -->
            </div>

            <div *ngIf="step.id != 'init'">
                <ng-container *ngFor="let step of steps">
                    <div class="step-collapsed" *ngIf="step.show">
                        <mat-icon color="accent" *ngIf="step.done">check_circle</mat-icon>
                        <mat-icon color="accent" *ngIf="!step.done">radio_button_unchecked</mat-icon>
                        {{step.title}} ({{step.apiResponse}})
                    </div>
                </ng-container>

                <h1>{{step.title ?? 'The End'}}</h1>

                <div *ngIf="step.id=='cb001'">
                    <form (ngSubmit)="submitcb001()">
                        <div class="form-grid">
                            <button mat-raised-button color="primary" type="submit" class="btn btn-primary"
                                [disabled]="!getStep('cb001').active">{{getStep('cb001').title}}</button>
                                <div *ngIf="!getStep('cb001').active">
                                    {{getStep('cb001').apiResponse || 'waiting for response...'}}
                                </div>
                        </div>
                    </form>
                </div>

                <div *ngIf="step.id=='cb002'">
                    <form (ngSubmit)="submitcb002()">
                        <div class="form-grid">
                            <button mat-raised-button color="primary" type="submit" class="btn btn-primary"
                                [disabled]="!getStep('cb002').active">{{getStep('cb002').title}}</button>
                                <div *ngIf="!getStep('cb002').active">
                                    {{getStep('cb002').apiResponse || 'waiting for response...'}}
                                </div>
                        </div>
                    </form>
                </div>

                <div *ngIf="step.id=='apply'">
                    <form (ngSubmit)="submitApply()">
                        <div class="form-grid">
                            <button mat-raised-button color="primary" type="submit" class="btn btn-primary"
                                [disabled]="!getStep('apply').active">{{getStep('apply').title}}</button>
                                <div *ngIf="!getStep('apply').active">
                                    {{getStep('apply').apiResponse || 'waiting for response...'}}
                                </div>
                        </div>
                    </form>
                </div>


                <div *ngIf="step.id=='result'">
                    <form (ngSubmit)="sign()">
                        <div class="form-grid">
                            Result: Aprobado!
                            <!-- <img src="./../../../../assets/sig1.jpg" style="aspect-ratio:initial;"> -->
                            <button mat-raised-button color="primary" type="submit" class="btn btn-primary"
                                [disabled]="!getStep('result').active">{{getStep('result').title}}</button>
                                <div *ngIf="!getStep('result').active">
                                    {{getStep('result').apiResponse || 'waiting for response...'}}
                                </div>
                        </div>
                    </form>
                </div>

                <div *ngIf="step.id=='done'">
                    <form (ngSubmit)="setComplete()">
                        <div class="form-grid">
                            <button mat-raised-button color="primary" type="submit" class="btn btn-primary"
                            [disabled]="!getStep('done').active">{{getStep('done').title}}</button>
                        </div>
                    </form>
                </div>

                <div class="step-canvas" *ngIf="step.id=='getSelfie'">
                    <!-- <ic-camera class="camera" (getPicture)="handleImage($event);" [aspect]=1.5
                        [enabledTakeSnapshot]="step.active" [camButtonTitle]="step.title ?? ''">
                    </ic-camera> -->
                    
                    <!-- <div class="webcam-image" *ngIf="this.appData.selfieImage">
                        <img [src]="this.appData.selfieImage.imageAsDataUrl" />
                    </div> -->
                </div>

                <div *ngIf="step.id=='getID1Front'">
                    <!-- <ic-camera class="camera" (getPicture)="handleImage($event);" [aspect]=1.5
                        [enabledTakeSnapshot]="step.active" [camButtonTitle]="step.title ?? ''"></ic-camera>
                    <div class="webcam-image" *ngIf="this.appData.id1ImageFront">
                        <img [src]="this.appData.id1ImageFront.imageAsDataUrl" />
                    </div> -->
                </div>

                <div *ngIf="step.id=='getID1Back'">
                    <!-- <ic-camera class="camera" (getPicture)="handleImage($event);" [aspect]=1.5
                        [enabledTakeSnapshot]="step.active" [camButtonTitle]="step.title ?? ''"></ic-camera>
                    <div class="webcam-image" *ngIf="this.appData.id1ImageBack">
                        <img [src]="this.appData.id1ImageBack.imageAsDataUrl" />
                    </div> -->
                </div>

                <div class="step-collapsed" *ngIf="step.id=='getID1OCR' && !getStep('getID1OCR').done">
                    <!-- <button *ngIf="step.id=='getID1OCR' && !getStep('getID1OCR').done" [disabled]="!getStep('getID1OCR').active" class="camera-button" mat-raised-button color="primary"
                        matTooltip="{{step.id}}" matTooltipPosition="left" [matTooltipShowDelay]="1000"
                        [matTooltipHideDelay]="1000" (click)="this.getID1OCR()">
                        <mat-icon color="accent">description</mat-icon>
                        {{getStep('getID1OCR').title}}
                    </button> -->
                </div>
                <div *ngIf="step.id=='getID1OCR' && getStep('getID1OCR').done == true">
                    <table mat-table [dataSource]="client.id1OCR">
                        <ng-container matColumnDef="key">
                            <th mat-header-cell *matHeaderCellDef>Key </th>
                            <td mat-cell class="odd" *matCellDef="let element">{{ element.key }}</td>
                        </ng-container>
                        <ng-container matColumnDef="value">
                            <th mat-header-cell *matHeaderCellDef>Value </th>
                            <td mat-cell *matCellDef="let element">{{ element.value }}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsId1OCR"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsId1OCR;"></tr>
                    </table>
                    <button *ngIf="getStep('getID1OCR').done" class="camera-button" mat-raised-button color="primary"
                        matTooltip="{{step.id}}" matTooltipPosition="left" [matTooltipShowDelay]="1000"
                        [matTooltipHideDelay]="1000" (click)="this.setStep('next')">
                        <mat-icon color="accent">description</mat-icon> 
                        {{getStep('getID1OCR').title}}
                    </button>
                </div>

                <div *ngIf="step.id=='getSignature'">
                    <!-- <ic-camera class="camera" (getPicture)="handleImage($event);" [aspect]=1.5
                        [enabledTakeSnapshot]="step.active" [camButtonTitle]="step.title ?? ''"></ic-camera>
                    <div class="webcam-image" *ngIf="this.appData.signatureImage">
                        <img [src]="this.appData.signatureImage.imageAsDataUrl" />
                    </div> -->
                </div>

            </div>
        </mat-card-content>
    </div>
</mat-card>