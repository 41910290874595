import { VscapiService } from './../../../core/services/vscapi.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VplService } from '../../services/vpl.service';

@Component({
  selector: 'credit-list-plans',
  templateUrl: './list-plans.component.html',
  styleUrls: ['./list-plans.component.scss']
})
export class ListPlansComponent implements OnInit {

  @Output() selected = new EventEmitter();

  @Input() account: string = '';

  dataSource!: any[];
  displayedColumns: string[] = [ 'select', 'segment', 'plan', 'balance', 'openDate', 'active', 'requiredPayment', 'minimumRequiredPayment', 'sec' ];

  constructor(
    public vplService: VplService,
    public vscapiService: VscapiService,
    public env: EnvironmentService,
    ) { }

  ngOnInit(): void {
  }

  selectPlan(id: string) {
    this.selected.emit(id);
  }

  public getPlans() {
    return this.vscapiService.getAccountPlans(this.account);
  }
  
}
