import { EnvironmentService } from 'src/app/services/environment.service';
import { ContextService } from 'src/app/modules/credit/services/context.service';
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[coreContextMenu]'
})
export class ContextMenuDirective {

  constructor(
    private el: ElementRef,
    private context: ContextService,
    private env:EnvironmentService
  ) { }

  @HostListener('click') onContextMenu() {
    // console.log('context');
    // console.log(this.el.nativeElement.innerHTML); 
    this.el.nativeElement.innerHTML = this.env.dict['store'];
  }

}
