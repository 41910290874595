import { ContextService } from './../../services/context.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { AuthService } from 'src/app/services/auth.service';
import { VplService } from '../../services/vpl.service';
import { CreditLocate } from '../../entities/credit.d';

@Component({
  selector: 'credit-view-performance',
  templateUrl: './view-performance.component.html',
  styleUrls: ['./view-performance.component.scss']
})
export class ViewPerformanceComponent implements OnInit {
  @Input() newSearchFunction!: () => void;
  @Input() newSearchLink: string = '';

  themeP: ThemePalette;

  locator!: CreditLocate;

  classString: string = '';

  // location: Location; //FAKE

  constructor(
    public route: ActivatedRoute,
    // public vplService: VplService,
    public env: EnvironmentService,
    public context: ContextService,

    // FAKE
    public authService: AuthService,
    // @Inject(DOCUMENT) private document: Document

    // FAKE
  ) { 

  }

  public setTheme(classString: string): void {
    this.classString = classString;
  }

  ngOnInit(): void {

    this.route.data.subscribe( (data) => {
      console.log(data);
      if (data.test && data.test == 'abc') {
      // FAKE
      }
    });
  }

  public tabFocusChange($event: any) {
    console.log($event);
  }
}
