import { SnackBarService } from './../../../../core/services/snack-bar.service';
import { ButtonConfig, OtpConfig } from 'src/app/modules/core/entities/core';
import { CreditLocateReq } from './../../../entities/credit.d';
import { VplService } from './../../../services/vpl.service';
import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';

export interface CreditIdMethod {
  name: string,
  type: string,
  prompt?: string,
  value: string,
}

@Component({
  selector: 'credit-check-id',
  templateUrl: './check-id.component.html',
  styleUrls: ['./check-id.component.scss']
})
export class CheckIdComponent implements OnInit {

  @Input() refNumber: string = '';
  @Input() requestId: string = '';
  @Input() allowContinueBeforeVerified: boolean = false;
  /**
   *  @arg {('none'|'password'|'authcode')} authOverride - Which override method to allow. 
  */
  @Input() authOverride: string = 'none';
  @ViewChild('docNumber') docNumber!: ElementRef<HTMLInputElement>;
  @Output() onSuccess = new EventEmitter();
  @Output() onRequestSubmitted = new EventEmitter();
  @Output() onOverrideFailed = new EventEmitter();
  @Output() onFailure = new EventEmitter();

  public checksPassed = false;

  public log: string[] = [];
  public docTypeSelection: string = '';
  public requestStatus: string = 'none';

  public timer!: Observable<number>;
  public timerSub!: Subscription;

  public confirmButtonConfig: ButtonConfig = {
    type: 'text',
    id: '',
    name: '',
    title: 'Continuar',
    hint: '',
    hideIfDisabled: false,
    click: function (): boolean {
      return false;
    },
    icon: function (): string {
      return 'verified_user';
    },
    disabled: function (): boolean {
      return false;
    }
  }

  private fnDisabledCallButton = () => {
    if (this.docNumber && this.docNumber.nativeElement.value.length > 0 && this.docTypeSelection.length > 0) {
      return false;
    }
    return true;
  }

  private fnClickCallButton = () => {
    if (this.docNumber && this.docNumber.nativeElement.value.length > 0 && this.docTypeSelection.length > 0) {
      // call verification API function
      let locate: CreditLocateReq = {
        refNumber: this.refNumber
      }

      this.creditSvc.requestDocVerify(locate, this.docTypeSelection, this.docNumber.nativeElement.value).subscribe((response: any) => {
        if (response.result == 'success') {
          this.requestId = response.data.id;
          this.onRequestSubmitted.emit({
            requestId: this.requestId,
            requestStatus: this.requestStatus,
            note: ''
          });    
          this.log.push(`Back Office: Document request loaded: ${this.requestId} `);
        } else {
          this.log.push(`Back Office: Document request LOAD FAILED: ${this.requestId}`);
        }
        this.checkConditions();
      });
    }
    return false;
  }

  public callButtonConfig: ButtonConfig = {
    type: 'text',
    id: '',
    name: '',
    title: 'Validar',
    hint: '',
    hideIfDisabled: false,
    click: this.fnClickCallButton,
    icon: function (): string {
      return 'verified_user';
    },
    disabled: this.fnDisabledCallButton
  }

  public methods = ['INE/IFE', 'FM1/FM2', 'Pasaporte'];

  constructor(
    public creditSvc: VplService,
    public snack: SnackBarService
  ) { }

  ngOnDestroy() {
    this.timerSub.unsubscribe();
  }

  ngOnInit(): void {
    this.timer = timer(0, 5000);
    this.timerSub = this.timer.subscribe(val => {
      if (this.requestStatus == 'verified') {
        this.timerSub.unsubscribe();
      }
      this.checkConditions();
    });

    this.checkConditions();

    this.confirmButtonConfig.click = () => {
      this.timerSub.unsubscribe();
      this.onSuccess.emit({
        requestId: this.requestId,
        requestStatus: this.requestStatus,
        note: 'Back Office: Document verification passed.' 
      });
      return false;
    }
  }

  checkConditions() {

    if (this.requestId != '') {
      this.creditSvc.checkDocVerify(this.requestId).subscribe((response: any) => {
        if (response.result == 'success') {
          this.requestStatus = response.data.status;

          switch (response.data.status) {
            case 'new':
              this.log.push(`Back Office: Document verification awaiting processing: ${this.requestId}`);
              break;
            case 'in progress':
              this.log.push(`Back Office: Document verification in progress: ${this.requestId}`);
              break;
            case 'verified':
              this.log.push(`Back Office: Document verified: ${this.requestId}`);
              break;
            case 'failed':
              this.log.push(`Back Office: Document VERIFICATION FAILED: ${this.requestId}`);
              this.onFailure.emit({
                requestId: this.requestId,
                requestStatus: this.requestStatus,
                note: response.data.note
              });
              break;
            default:
              this.log.push(`Back Office: Document Check invalid status (${response.result}): ${this.requestId}`);
              break;
          }
        } else {
          this.log.push(`Back Office: Status Check VERIFICATION FAILED: ${this.requestId} ${response.result}`);
          this.onFailure.emit({
            result: 'fail',
            note: `Back Office: Status Check VERIFICATION FAILED: ${this.requestId} ${response.result}`
          });
        }
      });
    }
  }

  public overrideDocStatus(result: any) {
    let securityToken = result.authToken;
    this.creditSvc.updateDocVerify(this.requestId, 'verified', securityToken).subscribe((response: any) => {
      if (response.result == 'success') {
        this.requestStatus = response.data.status;
        this.log.push(`Back Office: Document verification OVERRIDE: ${this.requestId}`);
      } 
    });
  }

  public overrideDocStatusFailed(result: any) {
    this.onOverrideFailed.emit({
      result: 'fail',
      note: `Back Office: Verification Status Override FAILED: ${this.requestId}`
    });
    this.log.push(`Back Office: Verification Status Override FAILED: ${this.requestId}`);
  }
}
