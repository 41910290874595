<div> 
    <mat-expansion-panel *ngIf="visible" hideToggle="false" [ngClass]="hintClass()">
        <mat-expansion-panel-header class="context-hint-header">
            <mat-panel-title class="context-hint-title">
                {{hint.title}}
            </mat-panel-title>
            <mat-panel-description class="context-hint-description">
                {{hint.description}}
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="context-hint-body">
            {{hint.body}}
            <ng-container *ngIf="hint.steps">
                <ul>
                    <li *ngFor="let step of hint.steps"><mat-checkbox *ngIf="step.checkbox==true"></mat-checkbox> &nbsp;<b><i>{{step.title}}</i></b><span *ngIf="step.title.trim().length > 0">: </span>{{step.text}} </li>
                </ul>
            </ng-container>
        </div>
        <div class="context-note">
            Hint ID: {{hintId}}
        </div>
    </mat-expansion-panel>
</div>