<div class="box box3">
    <div class="idstatus">
        <h3>Back Office Document Capture<span class="pass">
                <mat-icon *ngIf="requestStatus=='verified'">check_circle_outline</mat-icon>
            </span><span class="fail">
                <mat-icon *ngIf="requestStatus!='verified'">highlight_off</mat-icon>
            </span></h3>
    </div>
    <div *ngIf="requestStatus=='none'||requestStatus=='denied'">
        <h3 style="grid-column: span 4;">Request</h3>
        <p>Please select document for Back Office verification and hand documents to Back Office</p>
        <mat-radio-group [(ngModel)]="docTypeSelection" style="grid-column: span 2;">
            <div *ngFor="let t of methods">
                <mat-radio-button [value]="t">{{t}}</mat-radio-button>
            </div>
        </mat-radio-group>
        <mat-form-field appearance="fill">
            <mat-label>Document Number</mat-label><input matInput type="text" autocomplete="off" #docNumber>
        </mat-form-field>
        <core-smart-button [config]="callButtonConfig"></core-smart-button>
    </div>
    <div *ngIf="requestStatus!='none'&&requestStatus!='verified'&&requestStatus!='denied'">
        <h3 style="grid-column: span 4;">Status</h3>
        <div class="box box2">
            <div>Back Office Status:</div>
            <div> {{requestStatus | titlecase}}</div>
            <div>Reference:</div>
            <div> {{requestId}}</div>
        </div>

        <div *ngIf="authOverride!='none'">
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Supervisor Override
                </mat-panel-title>
                <mat-panel-description>
                   Back Office Document Verification Override
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngIf="authOverride=='password'">
                <core-login-override (onSuccess)="overrideDocStatus($event)" (onFailure)="overrideDocStatusFailed($event)"></core-login-override>
            </div>
        </mat-expansion-panel>
        </div>
    

        <div *ngIf="log.length > 0">
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Request Status
                    </mat-panel-title>
                    <mat-panel-description>
                        {{log[log.length-1]}}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ul *ngIf="log.length > 0">
                    <li *ngFor="let l of log">{{l}}</li>
                </ul>
            </mat-expansion-panel>
        </div>

        <core-smart-button *ngIf="allowContinueBeforeVerified" [config]="confirmButtonConfig"></core-smart-button>
    </div>

    <div *ngIf="requestStatus=='verified'">
        <div>
            <span class="pass">
                <mat-icon>check_circle_outline</mat-icon>Customer Verified
            </span>
            <core-smart-button [config]="confirmButtonConfig"></core-smart-button>
        </div>
    </div>

    <div *ngIf="requestStatus=='denied'">
        <div>
            <span class="fail">
                <mat-icon>highlight_off</mat-icon>Customer Verification Failed
            </span>
        </div>
    </div>

</div>