<div *ngIf="isCameraExist; else noCameraExist">
    <div #webcamContainer style="text-align:center">
        <webcam
            [trigger]="triggerObservable" 
            [videoOptions]="videoOptions"
            (imageCapture)="handleImage($event)"
            *ngIf="showWebcam" [switchCamera]="nextWebcamObservable"
        ></webcam>
        <div class="btn-group">
            <button mat-raised-button color="primary" class="button" (click)="takeSnapshot()" [disabled]="!enabledTakeSnapshot">
                <mat-icon>photo_camera</mat-icon> {{camButtonTitle}}
            </button>
            <button mat-raised-button color="primary" class="button" (click)="changeWebCame(true)">
                <mat-icon>flip_camera_ios</mat-icon>
            </button>
            <!-- <button class="button" (click)="onOffWebCame()">Switch camera</button> -->
        </div>        
    </div>

    <div *ngIf="errors.length > 0">
        <h4>Error Messages:</h4>
        <ul *ngFor="let error of errors">
            <li>{{ error | json }}</li>
        </ul>
    </div>
</div>

<ng-template #noCameraExist>
    Camera device not available
</ng-template>