<div class="container">
    <table mat-table [dataSource]="dataSourceInsurance">
        <!-- Position Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef> Select </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [value]="element.id" (change)="selectInsurance($event, element)"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let element"> {{ element.type}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Product </th>
            <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
        </ng-container>

        <ng-container matColumnDef="cover">
            <th mat-header-cell *matHeaderCellDef> Cover </th>
            <td mat-cell *matCellDef="let element"> {{element.cover}} </td>
        </ng-container>

        <ng-container matColumnDef="cost">
            <th mat-header-cell *matHeaderCellDef> Cost </th>
            <td mat-cell *matCellDef="let element"> {{element.cost}} % </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsInsurance"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsInsurance;"></tr>
    </table>
    <core-signature *ngIf="needSig()" (onSignatureOK)="sigOK()"></core-signature>
    <button mat-raised-button type="button" class="btn btn-primary" (click)="onAccept($event)" [disabled]="needSig()">
        <mat-icon>check_circle</mat-icon>Aceptar
    </button>

</div>