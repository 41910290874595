<button
    mat-flat-button 
    [id]="config.id"
    type="button" 
    (click)="click()" 
    class="btn btn-primary smart-button" 
    [disabled]="config.disabled()"
    >
    <mat-icon *ngIf="config.icon()">{{config.icon()}}</mat-icon>
    <ng-container *ngIf="config.title!=''">{{config.title}} </ng-container>
    <ng-container *ngIf="config.title!='' && config.hint!=''">: </ng-container>
    <ng-container *ngIf="config.hint!=''" > {{config.hint}} </ng-container> 
</button>
