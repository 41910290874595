import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { Auth0UserComponent } from './components/auth0/user/user.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './components/login/login.component';

import { environment } from '../../../environments/environment';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SmartSelectComponent } from './components/smart-select/smart-select.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UserComponent } from './components/user/user.component';
import { DataFieldViewComponent } from './components/data-field-view/data-field-view.component';
import { SmartButtonComponent } from './components/smart-button/smart-button.component';
import { FilterPipe } from './pipes/filter.pipe';
import { FontImageDirective } from './directives/font-image.directive';
import { OtpRequestComponent } from './components/otp-request/otp-request.component';
import { TimerComponent } from './components/timer/timer.component';
import { QrScanComponent } from './components/qr-scan/qr-scan.component';
import { AudioRecorderComponent } from './components/audio-recorder/audio-recorder.component';
import { Auth0LoginComponent } from './components/auth0/login/login.component';

import { AuthModule } from '@auth0/auth0-angular';
import { Timer2Component } from './components/timer2/timer2.component';
import { ContextMenuDirective } from './directives/context-menu.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthFbuiComponent } from './components/auth-fbui/auth-fbui.component';

import { firebase, firebaseui, FirebaseUIModule } from 'firebaseui-angular';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule, USE_EMULATOR as USE_AUTH_EMULATOR } from "@angular/fire/compat/auth";
import { LoginOverrideComponent } from './components/login-override/login-override.component';
import { SignatureComponent } from './components/signature/signature.component';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { ContextHintComponent } from './components/context-hint/context-hint.component';


const firebaseConfig = {
  apiKey: "AIzaSyBcgOqyo2k0N59IO_IZ9-iGN-auQ7LWlOo",
  authDomain: "lp-demo-003.firebaseapp.com",
  projectId: "lp-demo-003",
  storageBucket: "lp-demo-003.appspot.com",
  messagingSenderId: "868831403883",
  appId: "1:868831403883:web:918a8510a3eec2280a762e",
  measurementId: "G-XMKH1J1TTQ"
};

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      scopes: [
        'public_profile',
        'email',
        'user_likes',
        'user_friends'
      ],
      customParameters: {
        'auth_type': 'reauthenticate'
      },
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID
    },
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    firebase.auth.GithubAuthProvider.PROVIDER_ID,
    {
      requireDisplayName: false,
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    },
    firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  tosUrl: '<your-tos-link>',
  privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};

@NgModule({
  declarations: [
    LoginComponent,
    SmartSelectComponent, 
    UserComponent,
    DataFieldViewComponent,
    SmartButtonComponent,
    FilterPipe,
    FontImageDirective,
    OtpRequestComponent,
    TimerComponent,
    QrScanComponent,
    AudioRecorderComponent,
    Auth0LoginComponent,
    Auth0UserComponent,
    Timer2Component,
    ContextMenuDirective,
    AuthFbuiComponent,
    LoginOverrideComponent,
    SignatureComponent,
    SignaturePadComponent,
    ContextHintComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ScrollingModule,
    MatButtonModule,
    AuthModule.forRoot({
      domain: 'dev-1apxp3f5.us.auth0.com',
      clientId: 'LM0Dvhn0RJr5Q1wrnEg5svHXLMvSpMr2'
    }),
    MatSnackBarModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    MatButtonToggleModule,
    MatIconModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCheckboxModule
  ],
  exports: [ // Add here all the components to be exported for outside use.
    LoginComponent,
    UserComponent,
    DataFieldViewComponent,
    SmartSelectComponent,
    SmartButtonComponent,
    FilterPipe,
    FontImageDirective,
    OtpRequestComponent,
    TimerComponent,
    QrScanComponent,
    AudioRecorderComponent,
    Auth0LoginComponent,
    Auth0UserComponent,
    Timer2Component,
    ContextMenuDirective,
    AuthFbuiComponent,
    LoginOverrideComponent,
    SignatureComponent,
    ContextHintComponent,
  ]
})
export class CoreModule { }
