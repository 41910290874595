<div class="container">

    <h1>{{action.title}} - {{action!.hint}}</h1>

    <div class="box4">
        <credit-list-insurance *ngIf="!actionComplete" (accepted)="insuranceAccepted($event)"></credit-list-insurance>
        <div *ngIf="actionComplete" class="box2">
            <h2>Insurance Products Applied</h2>
            <ul>
                <li *ngFor="let item of selectedProducts">{{item.name}}</li>
            </ul>
        </div>
    </div>

    <div class="box box1" style="grid-column: span 4;">
        <core-smart-button [config]="newButtonConfig"></core-smart-button>
        <core-smart-button [config]="closeButtonConfig" color="warn"></core-smart-button>
    </div>
</div>