import { ChangeDetectionStrategy, Component, ElementRef, EmbeddedViewRef, EventEmitter, Input, NgZone, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { SelectConfig } from '../../entities/core';

@Component({
  selector: 'core-smart-select',
  templateUrl: './smart-select.component.html',
  styleUrls: ['./smart-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartSelectComponent implements OnInit {
  @Input() model: { [x: string]: any; } | undefined;
  @Input() 
  set options(options: any[]) {
    this._options = options;
    this.model = undefined;
    this.selectedValue = undefined;
    if (!this._options || (this._options && this._options.length == 0 && this.config!.hideIfEmpty === true)) {
      this._visible = false;
    } else {
      this._visible = true;
    }
  }

  get options() {
    return this._options;
  }

  get visible() {
    return this._visible;
  }

  @Input() config!: SelectConfig;
  @Input() 
  set selectedOption(id: string) {
    this.selectedValue = id;
  }

  @Output() selectChange = new EventEmitter();
  @Output() closed = new EventEmitter();

  private _options: any[] = [];
  private _visible: boolean = true;
  public selectedValue!: string | undefined;


  constructor() { }

  ngOnInit() {
    if (this.config!.hideIfEmpty === true) {
      this._visible = false;
    } else {
      this._visible = true;
    }
  }

  close() {
    this.closed.emit();
  }

  select(selected: MatSelectChange) {
    // console.log('emit change');
    this.model = this._options[selected.value]
    this.selectChange.emit(selected);
    if (this.options.length == 0 && this.config!.hideIfEmpty) {
      this._visible = false;
    } else {
      this._visible = true;
    }
  }

  // ngOnDestroy() {
  //   console.log('smart select destroy');
  // }
}