import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(
    private _snackBar: MatSnackBar
  ) { }

  public openSnackBar(message: string, action: string, duration: number = 0) {
    // Hack for vertical center aligned popup: defined in styles.xx.scss / styles.custom.scss on .cdk-global-overlay-wrapper
    this._snackBar.open(message, action, { duration: duration, verticalPosition: 'top', horizontalPosition: 'center' });
  }

  public dismiss() {
    this._snackBar.dismiss();
  }

}
