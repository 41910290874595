<div class="vs-page">
    <div class="card-container">

        <mat-card>
            <mat-card-title>
                User
            </mat-card-title>
            <mat-card-subtitle>
                <h2>{{this.env.tenantName}} Credit Services</h2>
            </mat-card-subtitle>
            <mat-card-content>
                <!-- <core-auth0-login routerLink="/home"></core-auth0-login> -->
                <!-- <core-login></core-login> -->
            </mat-card-content>
            <mat-card-actions>
                <!-- <button  class="add-button" >Add to home screen</button> -->
            </mat-card-actions>
        </mat-card>

        <!-- <mat-card *ngIf="!authService.isLoggedIn">
            <mat-card-title>
                User Sign On
            </mat-card-title>
            <mat-card-subtitle>
                <h2>{{this.env.tenantName}} Credit Services</h2>
            </mat-card-subtitle>
            <mat-card-content>
                <core-login loginTarget="/home"></core-login>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
        </mat-card> -->

        <mat-card *ngIf="authService.isLoggedIn">
            <mat-card-title>
                Hola {{ user.firstName }} {{ user.lastName }}! 
            </mat-card-title>
            <mat-card-subtitle>
                <h2>Avisos Generales</h2>
            </mat-card-subtitle>
            <img mat-card-image>
            <mat-card-content>
                Messages...
            </mat-card-content>
            <mat-card-actions>
                <!-- <button  class="add-button" >Add to home screen</button> -->
            </mat-card-actions>
        </mat-card>
    </div>
</div>