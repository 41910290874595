import { VscapiService } from './../../../core/services/vscapi.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { Observable } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextService } from '../../services/context.service';
import { VplService } from '../../services/vpl.service';
import { CreditPromo } from '../../entities/credit.d';

@Component({
  selector: 'credit-list-messages',
  templateUrl: './list-messages.component.html',
  styleUrls: ['./list-messages.component.scss']
})
export class ListMessagesComponent implements OnInit {
  @Input() account: string = '';
  @Output() promoSelected = new EventEmitter();

  public dataSource!: Observable<CreditPromo[]>;
  displayedColumns: string[] = [ 'name', 'status', 'desc', 'select', 'note' ];

  public filter!: { accountNumber: string };

  constructor(
    public credit: VplService,
    public context: ContextService,
    public env: EnvironmentService,
    public vscapiService: VscapiService,
    ) { }

  ngOnInit(): void {
    if (this.env.newAPI) {
      this.dataSource = this.vscapiService.getAccountPromos(this.account);
    } else {
      this.dataSource = this.credit.getPromos();
    }
  }

  public selectPromo(id: string = '', action: string = '') {
    this.promoSelected.emit({id: id, action: action});
  }

  getActionCode(actionCode: string) {
    let action = this.credit.getActionCode('id' + actionCode);
    if (!this.env.showActionCodeValue){
      action.title = '';
    }
    return action;
  }

}
