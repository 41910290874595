import { EventEmitter, Output } from '@angular/core';
/* From https://github.com/killroywashere/ng-audio-recorder */
/* https://www.npmjs.com/package/ng-audio-recorder */

import { Component, Input, OnInit } from '@angular/core';
// import { NgAudioRecorderService, OutputFormat } from 'ng-audio-recorder';

@Component({
  selector: 'core-audio-recorder',
  templateUrl: './audio-recorder.component.html',
  styleUrls: ['./audio-recorder.component.scss']
})
export class AudioRecorderComponent implements OnInit {

  @Input() showDownloadButton: boolean = true;
  @Output() recordingComplete = new EventEmitter();

  public recording = false;
  public audioUrl!: any;

  constructor(/*private audioRecorderService: NgAudioRecorderService*/) { 
    // this.audioRecorderService.recorderError.subscribe(recorderErrorCase => {
    //   // Handle Error
    // });
  }

  ngOnInit(): void {

  }

  recToggle() {
    if (this.recording) {
      this.stopRecording();
    } else {
      this.startRecording();
    }
  }

  startRecording() {
    this.recording = true;
  }

  stopRecording() {
    this.recording = false;
  }

  recButtonIcon() {
    if (this.recording) {
      return "stop";
    }
    return "mic";
  }

  recButtonColor() {
    if (this.recording) {
      return "";
    }
    return "primary";
  }

  dlButtonIcon() {
    if (this.recording) {
      return "block";
    }
    return "file_download";
  }

  dlButtonColor() {
    if (this.recording) {
      return "";
    }
    return "primary";
  }

  download(e: any) {
    const url = this.audioUrl;
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'test.webm';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 100);
  }
}


