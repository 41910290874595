import { IcSignatureComponent } from './ic-signature/signature.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IcFlow01Component } from './ic-flow01/ic-flow01.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CameraComponent } from './camera/camera.component';
import { WebcamModule } from 'ngx-webcam';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { IcFlow02Component } from './ic-flow02/ic-flow02.component';

@NgModule({
  declarations: [
    IcFlow01Component,
    IcSignatureComponent,
    CameraComponent,
    IcFlow02Component
  ],
  imports: [
    CommonModule,
    MatCardModule,

    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    WebcamModule,
    MatDividerModule,
    MatTooltipModule,
    MatFormFieldModule,

    MatInputModule,
    MatButtonModule,
    // MatSelectModule,
    // MatCheckboxModule,
    // MatChipsModule,
    // MatToolbarModule,
    // MatMenuModule,
    MatTableModule,    
    MatTooltipModule
  ],
  exports: [
    IcFlow01Component,
    IcFlow02Component,
    IcSignatureComponent
  ]
})
export class IncodeModule { }
