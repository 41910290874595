import { MatCheckboxModule } from '@angular/material/checkbox';
import { CbFlow01Component } from './components/ext/cb/cb-flow01/cb-flow01.component';
import { IncodeModule } from './components/ext/incode/incode.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgChartsModule } from 'ng2-charts';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { CoreModule } from './../core/core.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LocateClientComponent } from './components/locate-client/locate-client.component';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { ListAcctComponent } from './components/list-acct/list-acct.component';
import { MatTableModule } from '@angular/material/table';
import { ImageCardComponent } from './components/image-card/image-card.component';
import { ViewAccountComponent } from './components/view-account/view-account.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavbarAcctComponent } from './components/navbar-acct/navbar-acct.component';
import { MatTabsModule } from '@angular/material/tabs';
import { VaAcctStatusComponent } from './components/va-acct-status/va-acct-status.component';
import { VaDemographicComponent } from './components/va-demographic/va-demographic.component';
import { VaPointsComponent } from './components/va-points/va-points.component';
import { VaServicesComponent } from './components/va-services/va-services.component';
import { VaDashComponent } from './components/va-dash/va-dash.component';
import { AccountBalancesComponent } from './components/account-balances/account-balances.component';
import { AccountCardsComponent } from './components/account-cards/account-cards.component';
import { AccountComponent } from './components/account/account.component';
import { AccountHistoryComponent } from './components/account-history/account-history.component';
import { ListCardsComponent } from './components/list-cards/list-cards.component';
import { RouterModule } from '@angular/router';
import { ListPlansComponent } from './components/list-plans/list-plans.component';
import { ListTransactionsComponent } from './components/list-transactions/list-transactions.component';
import { VaActionComponent } from './components/va-action/va-action.component';
import { ActionMenuComponent } from './components/action-menu/action-menu.component';
import { AcView001Component } from './components/actions/ac-view001/ac-view001.component';

import { AcMonetary001Component } from './components/actions/ac-monetary001/ac-monetary001.component';
import { AcMaintenance001Component } from './components/actions/ac-maintenance001/ac-maintenance001.component';
import { AcMaintenance002Component } from './components/actions/ac-maintenance002/ac-maintenance002.component';
import { ListNotesComponent } from './components/list-notes/list-notes.component';
import { AcMaintenance003Component } from './components/actions/ac-maintenance003/ac-maintenance003.component';
import { WorkflowModule } from './../workflow/workflow.module';
import { AcMaintenance004Component } from './components/actions/ac-maintenance004/ac-maintenance004.component';
import { Apply2Component, Apply2Dialog } from './components/ext/apply/apply.component';
import { AcMaintenance005Component } from './components/actions/ac-maintenance005/ac-maintenance005.component';
import { IdentifyComponent } from './components/customer/identify/identify.component';
import { AddressComponent } from './components/data/address/address.component';

import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { AccountMessagesComponent } from './components/account-messages/account-messages.component';
import { ListMessagesComponent } from './components/list-messages/list-messages.component';
import { MatBadgeModule} from '@angular/material/badge';
import { CardWizardComponent } from './components/ext/card-wizard/card-wizard.component';
import { ListCardProductsComponent } from './components/list-card-products/list-card-products.component';
import { AcMaintenance006Component } from './components/actions/ac-maintenance006/ac-maintenance006.component';
import { ListInsuranceComponent } from './components/list-insurance/list-insurance.component';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ViewPerformanceComponent } from './components/view-performance/view-performance.component';
import { VpDashComponent } from './components/vp-dash/vp-dash.component';
import { PerformanceDashboardComponent } from './components/performance-dashboard/performance-dashboard.component';
import { PerformanceTeamComponent } from './components/performance-team/performance-team.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AcMaintenance105Component } from './components/actions/ac-maintenance105/ac-maintenance105.component';
import { CheckIdComponent } from './components/ext/check-id/check-id.component';

import {MatExpansionModule} from '@angular/material/expansion';
import { MatVerticalStepperScrollerDirective } from './directives/mat-vertical-stepper-scroller.directive';

@NgModule({
  declarations: [
    // LoginComponent,
    LocateClientComponent,
    ListAcctComponent,
    ImageCardComponent,
    ViewAccountComponent,
    NavbarAcctComponent,
    VaAcctStatusComponent,
    VaDemographicComponent,
    VaPointsComponent,
    VaServicesComponent,
    VaActionComponent,
    VaDashComponent,
    AccountBalancesComponent,
    AccountCardsComponent,
    AccountComponent,
    AccountHistoryComponent,
    ListCardsComponent,
    ListPlansComponent,
    ListTransactionsComponent,
    ActionMenuComponent,
    AcView001Component,
    AcMonetary001Component,
    AcMaintenance001Component,
    AcMaintenance002Component,
    ListNotesComponent,
    AcMaintenance003Component,
    AcMaintenance004Component,
    Apply2Component,
    Apply2Dialog,
    CbFlow01Component,
    AcMaintenance005Component,
    IdentifyComponent,
    AddressComponent,
    AccountMessagesComponent,
    ListMessagesComponent,
    ListInsuranceComponent,
    CardWizardComponent,
    ListCardProductsComponent,
    AcMaintenance006Component,
    ListInsuranceComponent,
    ViewPerformanceComponent,
    VpDashComponent,
    PerformanceDashboardComponent,
    PerformanceTeamComponent,
    AcMaintenance105Component,
    CheckIdComponent,
    MatVerticalStepperScrollerDirective
  ],
  imports: [
    CommonModule,
    CoreModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatTableModule,
    MatToolbarModule,
    MatCardModule,
    MatTabsModule,
    MatMenuModule,
    MatListModule,
    MatGridListModule,
    // NgxChartsModule,
    NgChartsModule,
    RouterModule,
    FormsModule,
    MatDatepickerModule,
    WorkflowModule,
    MatStepperModule,
    MatRadioModule,
    MatDialogModule,
    MatCardModule,
    MatTooltipModule,
    IncodeModule,
    MatButtonToggleModule,
    IncodeModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatExpansionModule
  ],
  exports: [ // Add here all the components to be exported for outside use.
    LocateClientComponent,
    ViewAccountComponent,
    NavbarAcctComponent,
    ListInsuranceComponent,
    ListCardProductsComponent,
    IdentifyComponent
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class CreditModule { }

