<div class="container"> 
    <p [style]="nameToggleStyle()">
        <mat-slide-toggle #nameSearch>{{searchToggleText}}</mat-slide-toggle>
    </p>
    
    <form [formGroup]="locateForm">
        <div class="locate-client-grid">
            <ng-container *ngFor="let field of fieldConfigs | keyvalue">

                <div *ngIf="field.value.type=='submit'" style="grid-column: span 4;">
                    <button mat-raised-button type="button" (click)="locateSubmit()" class="btn btn-primary" [disabled]="!validInput()">
                        <mat-icon *ngIf="!validInput()">edit</mat-icon><mat-icon *ngIf="validInput()">search</mat-icon>Search
                    </button>
                </div>
                
                <div *ngIf="field.value.type=='divider'" style="grid-column: span 4;"><hr></div>

                <core-smart-select *ngIf="field.value.type=='smartselect'" class="smart-field" [options]="fieldOptions.get(field.key)" [config]="field.value" (selectChange)="change($event)"></core-smart-select>

                <mat-form-field *ngIf="(field.value.type=='search' && (!this.env.toggleNameSearch || ((nameSearch.checked && field.value.inNameSearch) || (!nameSearch.checked && !field.value.inNameSearch)))) || field.value.type=='display'" appearance="fill">
                <!-- <mat-form-field *ngIf="(field.value.type=='search') || field.value.type=='display'" appearance="fill"> -->
                    <mat-label>{{ field.value.title }}</mat-label>
                    <input matInput 
                    [id]="field.value.name" 
                    [type]="field.value.format" 
                    class="form-control" 
                    [formControlName]="field.value.name" 
                    [placeholder]="field.value.placeholder"
                    autocomplete="off"
                    [readonly]="field.value.readonly"
                    >
                </mat-form-field>
            </ng-container>
        </div>
    </form>
    <credit-list-acct *ngIf="showAccounts()" [dataSource]="accounts" (selected)="selectAccount($event)"></credit-list-acct>
</div>