import { EnvironmentService } from './../../../../services/environment.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'credit-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.scss']
})
export class ImageCardComponent implements OnInit {

  @Input() cardProduct: number = 0;

  constructor(
    public env: EnvironmentService
  ) { }

  @Input() source!: string;

  ngOnInit(): void {
    this.setProduct(this.cardProduct);
  }

  private setProduct(product: number) {

    let source = this.env.getTenantCardImageUrl(product);
    if (source!='') {
      this.source = source;
    }
  }
}
